import React, { useState, createContext, useContext, useCallback, useEffect } from 'react';
import axios from 'axios';
import { GeneralSetting } from '../types/setting/gridSettingTypes';
import { AuthApiUrl, SettingApiUrl } from '../environment/routeSettings';
import { INameId } from '../types/general/generalTypes';


type ClientSettingContextType = {
  generalSetting: GeneralSetting | null;
  customerDivisions: INameId[] | null;
  setGeneralSetting?: (setting: any) => Promise<void>;
  cleanGeneralSetting?: () => Promise<void>;
  getClientGeneralSetting?: (customerId: string | null) => Promise<void>;
  getCustomerDivisions?: (customerId: string | null) => Promise<void>;
};
const ClientSettingContext = createContext<ClientSettingContextType>({
  generalSetting: null,
  customerDivisions: []
});
const useClientSetting = () => useContext(ClientSettingContext);

function ClientSettingProvider(props: any) {
  const [generalSetting, setGeneral] = useState<GeneralSetting | null>(
    new GeneralSetting()
  );

  const [customerDivisions, setCustomerDivisions] = useState<INameId[] | null>(
    []
  );

  const setGeneralSetting = useCallback(async (setting: React.SetStateAction<GeneralSetting | null>) => {
    setGeneral(setting);
  }, []);
  const cleanGeneralSetting = useCallback(async () => {
    setGeneral(null);
    setCustomerDivisions([])
  }, []);

  const getClientGeneralSetting = useCallback(
    async (customerId: string | null) => {
      if (customerId !== null) {
        try {
          const response = await axios.get(
            SettingApiUrl() + "/api/ClientGeneralSettings/" + customerId,
            {
              headers: {
                'X-CSRF': '1',
                "Content-Type": "application/json",
              },
            }
          );
          const res = await response;
          if (res.status === 200) {
            var resGeneral = res.data as GeneralSetting;
            resGeneral.dateFormat = resGeneral?.dateFormat ?? "yyyy-MM-dd"
            resGeneral.timeFormat = resGeneral?.isAmPm ? " hh:mm a" : " HH:mm";
            resGeneral.dateTimeFormat =
              resGeneral?.dateFormat && resGeneral?.dateFormat !== null
                ? resGeneral?.dateFormat +
                (resGeneral?.isAmPm ? " hh:mm a" : " HH:mm")
                : "yyyy-MM-dd " + (resGeneral?.isAmPm ? " hh:mm a" : " HH:mm");
            setGeneral(resGeneral);
            localStorage.setItem("selectedCustomerId", customerId!)

          }
        } catch {
          setGeneral(new GeneralSetting())
        }
      }
    },
    []
  );

  const getCustomerDivisions = useCallback(
    async (customerId: string | null) => {
      if (customerId !== null) {
        try {
          const response = await axios.get(
            AuthApiUrl() + "/api/Divisions/GetAll/" + customerId,
            {
              headers: {
                'X-CSRF': '1',
                "Content-Type": "application/json",
              },
            }
          );
          const res = await response;
          if (res.status === 200) {
            var resGeneral = res.data as INameId[];
            setCustomerDivisions(resGeneral);
          }
        } catch {
          setCustomerDivisions([])
        }
      }
    },
    []
  );



  useEffect(() => {
    (async function () {
      await getClientGeneralSetting(localStorage.getItem("selectedCustomerId"));
      await getCustomerDivisions(localStorage.getItem("selectedCustomerId"));
    })();
  }, [getClientGeneralSetting, getCustomerDivisions]);

  return (
    <ClientSettingContext.Provider
      value={{ generalSetting, setGeneralSetting, getClientGeneralSetting, cleanGeneralSetting, getCustomerDivisions, customerDivisions }}
      {...props}
    />
  );
}

export { ClientSettingProvider, useClientSetting, GeneralSetting }
