//#region impoprts
import { ValidationGroup } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupPanel,
  Grouping,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../components/permission/permision";
import { MaintenancePermissions } from "../../../../../constants/Permissions";
import { useAuth } from "../../../../../contexts/auth";
import { MaintenanceApiUrl } from "../../../../../environment/routeSettings";
import {
  PersonnelName,
  VMEquipment,
} from "../../../../../types/maintenance/dto/maintenanceDto";
import { Cost } from "../../../../../types/maintenance/maintenanceTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { useScreenSize } from "../../../../../utils/media-query";
import { TesGet } from "../../../../../utils/rest";
//#endregion imports

//#region props
interface IPros {
  currentEquipments: VMEquipment[];
  generalSettings: any;
  assignEquipment: (lstEquipments: VMEquipment[]) => void;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>
}
//#endregion props
const Equipments = (props: IPros) => {
  //#region  consts
  const [showModalAssignEquipments, setShowModalAssignEquipments] =
    useState(false);
  const [initDataEquipments, setInitDataEquipments] = useState<PersonnelName[]>(
    [new PersonnelName()]
  );
  const [showModalCosts, setShowModalCost] = useState(false);
  const [initDataCosts, setInitDataCosts] = useState<Cost[]>([new Cost()]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const datagridCostRef = useRef<DataGridRef<any, any>>(null);
  const dataGridRefAssignEquipment = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [popupHeight, setPopupHeight] = useState<string>("85%")
  const [assignCostGridHeight, setAssignCostGridHeight] = useState<number>(420)
  const [costGridHeight, setCostGridHeight] = useState<number>(430)
  //#endregion consts


  useEffect(() => {
    if (isXLarge) { setPopupHeight("auto"); setAssignCostGridHeight(530); setCostGridHeight(530) }
    else if (is2xLarge) { setPopupHeight("auto"); setAssignCostGridHeight(900); setCostGridHeight(530) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function onAssign() {
    setShowModalAssignEquipments(true);
    if (activeLoading) activeLoading(true);
    setInitDataEquipments(
      await TesGet(
        MaintenanceApiUrl() +
        "/api/equipments/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
    if (activeLoading) activeLoading(false);

  }
  function onCloseModalAssignEquipment() {
    setShowModalAssignEquipments(false);
  }
  function onAssignEquipment() {
    var lstEquipments =
      dataGridRefAssignEquipment.current?.instance().getSelectedRowKeys() as VMEquipment[];
    props.assignEquipment(lstEquipments);
    setShowModalAssignEquipments(false);
    dataGridRefAssignEquipment.current?.instance().deselectAll();
    props.setDataChanged(true)
  }
  function calculateCostsListCell(d: any) {
    return (
      <Button
        className="tes-modal-btn-add"
        onClick={() => onOpenCosts(d.data)}
        text={t("costs")}
      />
    );
  }
  function onCloseModalCosts() {
    setShowModalCost(false);
    dataGridRefAssignEquipment.current?.instance().deselectAll();
  }
  async function onOpenCosts(data: any) {
    if (activeLoading) activeLoading(true);
    setInitDataCosts(
      await TesGet(
        MaintenanceApiUrl() + "/api/costs/equipmentCosts/" + data.id,
        true
      )
    );
    datagridCostRef.current?.instance().refresh();
    setShowModalCost(true);
    if (activeLoading) activeLoading(false);

  }
  //#endregion functions
  return (
    <React.Fragment>
      <ValidationGroup
        ref={props.validationRef}
      >
        <div className={"content-block"}>
          <div className="row">
            <div className="rightColumn">
              <Button
                style={{ marginLeft: 5 }}
                onClick={onAssign}
                icon="fa-solid fa-link"
                text={t("assign")}
                disabled={props.isLocked}
              />
            </div>
          </div>
          {/* Equipment datagrid */}
          <DataGrid
            id="gridContainer"
            ref={dataGridRef}
            dataSource={props.currentEquipments}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            onExporting={OnExporting}
          >
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />

            <Export enabled={true} allowExportSelectedData={true} />

            <FilterRow visible={true} applyFilter="auto" />

            <HeaderFilter visible={true} />

            <SearchPanel visible={true} width={285} placeholder={t("search...")} />
            <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
              <Search enabled />
            </ColumnChooser>
            <Column dataField="name" caption={t("name")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>

            <Column
              alignment="left"
              cellRender={calculateCostsListCell}
              caption={t("costs")}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
          </DataGrid>
        </div>

        {/* Assign Equipment Modal */}
        <Popup
          width="70%"
          height={popupHeight}
          visible={showModalAssignEquipments}
          resizeEnabled={true}
          showTitle={true}
          title={t("assignPersonnel")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalAssignEquipment()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              ref={dataGridRefAssignEquipment}
              dataSource={initDataEquipments}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              height={assignCostGridHeight}
              onExporting={OnExporting}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {dataGridRefAssignEquipment?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="name" caption={t("name")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                cellRender={calculateCostsListCell}
                caption={t("costs")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="costForJob" caption={t("costForJob")} alignment="left">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>

          <div style={{ marginTop: 20 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={onCloseModalAssignEquipment}
                text={t("cancel")}
              />
              <Permission
                allowed={[
                  MaintenancePermissions.Maintenance_D_Job,
                  MaintenancePermissions.Maintenance_E_Job,
                ]}
                hasFeedBackElement={false}
              >
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAssignEquipment}
                  text={t("assign")}
                />
              </Permission>
            </div>
          </div>
        </Popup>

        {/* Cost Modal */}
        <Popup
          width={"65%"}
          height={popupHeight}
          visible={showModalCosts}
          resizeEnabled={true}
          showTitle={true}
          title={t("costs")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalCosts()
          }
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              id="gridContainer"
              ref={datagridCostRef}
              dataSource={initDataCosts}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              height={costGridHeight}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column alignment="left" dataField="price" caption={t("cost")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="fromDate"
                dataType="date"
                format={props.generalSettings?.dateFormat}
                caption={t("from")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="toDate"
                dataType="date"
                format={props.generalSettings?.dateFormat}
                caption={t("to")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>

          <div style={{ marginTop: 15 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                onClick={onCloseModalCosts}
                text={t("close")}
              />
            </div>
          </div>
        </Popup>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default Equipments;
