import { ContextMenu } from "devextreme-react";
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    SearchPanel,
    Selection,
    SortByGroupSummaryInfo, Summary
} from 'devextreme-react/data-grid';
import ValidationGroup, { ValidationGroupRef } from "devextreme-react/validation-group";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../contexts/auth";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import { FieldApiUrl, TrafficApiUrl } from "../../../../../environment/routeSettings";
import { ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { LazyLoadingRequest, LazyLoadingRequestBase, NameValue, RequestResponseResult } from "../../../../../types/general/generalTypes";
import { LocationType } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../../../types/infrastructure/infrastructureTypes";
import { SimpleTrafficCounterDTO } from "../../../../../types/trafficStudy/dtos/TrafficCounterDTO";
import { StudyStatus, StudyType } from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from '../../../../../utils/rest';
import { RightClickMenu } from "../../../../../utils/rightClickMenu";

// props
interface IPros {
    misId: string
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroupRef>;
    generalSettings: GeneralSetting | null
}


const TrafficStudy = (props: IPros) => {
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
    const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
    const [lstTrafficCounter, setLstTrafficCounter] = useState<SimpleTrafficCounterDTO[]>();
    const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());
    const history = useNavigate();
    const { activeLoading } = useAuth();
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const items = [
        { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
        { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
    ];
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getLstTrafficCounter();
                await getInfrastructureTesCodeValues();

                setLstStudyType(Enum2Array(StudyType));
                setLstStudyStatus(Enum2Array(StudyStatus));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dataSource = new CustomStore({
        key: "id",
        load: async (loadOption) => {
            return await TesPost(
                `${TrafficApiUrl()
                }/api/Study/GetLocationStudyLazyLoading`,
                {
                    customerId: localStorage.getItem("selectedCustomerId"),
                    divisionId: localStorage.getItem("selectedDivisionId"),
                    loadOptions: loadOption,
                    filter: { id: props.misId }
                } as LazyLoadingRequest,
                true
            );
        },
    });

    function onRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
        if (e.data.id !== undefined) {

            if (isCtrlKeyPressed) {
                window.open(`/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.id}`, "_blank");
            } else {
                history(`/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.id}`);
            }
        }
    }


    async function getLstTrafficCounter() {
        const postObj: LazyLoadingRequestBase = {
            customerId: localStorage.getItem("selectedCustomerId") as string,
            divisionId: null,
            filter: { returnAll: true }
        }
        const res = await TesPost(
            TrafficApiUrl() +
            "/api/Import/GetCustomerSimplifiedTrafficCounter",
            postObj,
            true
        ) as RequestResponseResult<SimpleTrafficCounterDTO[]>
        if (res.responseCode === ResponseCode.OK) {
            setLstTrafficCounter(res.results);
        } else {
            await RequestErrorHandling(res);
        }
    }

    async function getInfrastructureTesCodeValues() {
        setInitDataTesCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/" + localStorage.getItem('selectedCustomerId'), true));
    }

    const headerFilterData = [
        {
            text: t("volume"),
            value: ['StudyCategory', '=', 1]
        },
        {
            text: t("speed"),
            value: ['StudyCategory', '=', 2]
        },
        {
            text: t("volume") + "," + t("classification"),
            value: ['StudyCategory', '=', 5]
        }
        , {
            text: t("volume") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 9]
        }
        , {
            text: t("volume") + "," + t("classification") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 13]
        },
        {
            text: t("speed") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 10]
        },
        {
            text: t("speed") + "," + t("classification"),
            value: ['StudyCategory', '=', 6]
        }
        , {
            text: t("speed") + "," + t("classification") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 14]
        }
    ];

    const headerLookup = [
        {
            name: t("volume"),
            value: 1
        },
        {
            name: t("speed"),
            value: 2
        },
        {
            name: t("volume") + "," + t("classification"),
            value: 5
        }
        , {
            name: t("volume") + "," + t("continuousCount"),
            value: 9
        }
        , {
            name: t("volume") + "," + t("classification") + "," + t("continuousCount"),
            value: 13
        },
        {
            name: t("speed") + "," + t("continuousCount"),
            value: 10
        },
        {
            name: t("speed") + "," + t("classification"),
            value: 6
        }
        , {
            name: t("speed") + "," + t("classification") + "," + t("continuousCount"),
            value: 14
        }
    ];

    return (
        <div className={`roadSegmentDetails-trafficStudies ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <ContextMenu
                    dataSource={items}
                    width={100}
                    target=".dx-data-row"
                    onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, `/trafficStudy/studies/studyDetails/${selectedRowData.row.data.studyType}/`, LocationType.Midblock, selectedRowData.row.data.locationId)}
                />
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}>

                            <DataGrid id="gridContainer"
                                ref={dataGridRef}
                                dataSource={dataSource}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                onRowClick={onRowClicked}
                                hoverStateEnabled={true}
                                remoteOperations={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                style={{ margin: "0 1rem" }}
                                onExporting={OnExporting}
                                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                            //onContentReady={e => {e.component.deselectAll()}}
                            //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
                            >
                                <Export enabled={true}
                                    allowExportSelectedData={true}
                                />
                                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                                <GroupPanel visible={true} /> {/* or "auto" */}
                                <FilterPanel visible={true} />
                                <FilterBuilderPopup position={'top'} />

                                {/* <StateStoring enabled={true} type="localStorage" storageKey={GridType.AllStudies.toString()} savingTimeout={500} /> */}


                                <SortByGroupSummaryInfo
                                    summaryItem="Total Count"
                                    sortOrder="desc"
                                />
                                <Summary>
                                    <GroupItem
                                        summaryType="count"
                                        alignByColumn
                                        name="Total Count"
                                    />
                                </Summary>
                                <Paging enabled={true} defaultPageSize={100} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                />

                                <Selection
                                    mode="multiple"
                                    selectAllMode={'allPages'}
                                    showCheckBoxesMode={'always'}
                                />
                                <FilterRow visible={true}
                                    applyFilter="auto" />

                                <HeaderFilter visible={true} />


                                <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                >
                                    <Search enabled />
                                </ColumnChooser>

                                <Column alignment="left" dataField="id" visible={false}
                                    caption={t('id')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="customerId" visible={false}
                                    caption={t('customerId')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="divisionId" visible={false}
                                    caption={t('divisionId')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="studyNo"
                                    caption={t('studyNo')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="workOrder.no"
                                    caption={t('workOrderNo')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="geoId"
                                    caption={t('geoId')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column><Column alignment="left" dataField="locationDescription"
                                    caption={t('locationDescription')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                {initDataTesCodeValues.municipalities?.length !== 0 && initDataTesCodeValues.municipalities !== undefined &&

                                    <Column
                                        dataField="municipalityId"
                                        visible={false}
                                        allowHeaderFiltering={true}
                                        caption={t("municipality")}
                                    >
                                        <Search enabled />
                                        <Lookup
                                            dataSource={SortObjectByPropName(initDataTesCodeValues.municipalities, "name")}
                                            valueExpr="id"
                                            displayExpr="name"
                                        />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                }

                                {initDataTesCodeValues.jurisdictions?.length !== 0 && initDataTesCodeValues.jurisdictions !== undefined &&

                                    <Column
                                        dataField="jurisdictionId"
                                        visible={false}
                                        allowHeaderFiltering={true}
                                        caption={t("jurisdiction")}
                                    >
                                        <Search enabled />
                                        <Lookup
                                            dataSource={SortObjectByPropName(initDataTesCodeValues.jurisdictions, "name")}
                                            valueExpr="id"
                                            displayExpr="name"
                                        />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                }

                                <Column dataField="studyType"
                                    caption={t('studyType')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={SortObjectByPropName(lstStudyType, "name")} valueExpr="value" displayExpr="name" />
                                </Column>
                                <Column dataField="studyCategory"
                                    caption={t('studyCategory')}

                                >
                                    <HeaderFilter dataSource={headerFilterData} width={350} >
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={headerLookup} valueExpr="value" displayExpr="name" />
                                </Column>

                                <Column dataField="status"
                                    caption={t('status')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={SortObjectByPropName(lstStudyStatus, "name")} valueExpr="value" displayExpr="name" />
                                </Column>

                                <Column alignment="left" dataField="duration"
                                    caption={t('duration')}
                                    visible={false}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="rawDataStartDT"
                                    caption={t('startDT')}
                                    dataType="datetime"
                                    format={props.generalSettings?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="rawDataEndDT"
                                    caption={t('endDT')}
                                    dataType="datetime"
                                    format={props.generalSettings?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="aadt.totalVolume"
                                    caption={t('totalVolumeAadt')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    visible={false}
                                    caption={t("trafficCounter")}
                                >

                                    <Column
                                        dataField="trafficCounterId"
                                        visible={false}
                                        allowHeaderFiltering={true}
                                        caption={t("trafficCounter")}
                                    >
                                        <Search enabled />
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstTrafficCounter, "name")}
                                            valueExpr="id"
                                            displayExpr="name"
                                        />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column
                                        dataField="binMapId"
                                        visible={false}
                                        allowHeaderFiltering={true}
                                        caption={t("binMaps")}
                                    >
                                        <Search enabled />
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstTrafficCounter?.flatMap(x => x.binMaps), "name")}
                                            valueExpr="id"
                                            displayExpr="name"
                                        />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                </Column>
                                <Column dataField="processDT"
                                    visible={false}
                                    caption={t('submissionDT')}
                                    dataType="datetime"
                                    format={props.generalSettings?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="createdDT"
                                    visible={false}
                                    caption={t('createdDT')}
                                    dataType="datetime"
                                    format={props.generalSettings?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="lastModifiedDT"
                                    visible={false}
                                    caption={t('lastModifiedDT')}
                                    dataType="datetime"
                                    format={props.generalSettings?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                            </DataGrid>
                        </div>
                    </div>
                </ValidationGroup>
            </React.Fragment>
        </div>
    );
}
export default TrafficStudy;