import React, { useMemo, useCallback, useState } from 'react';
import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import { useAuth } from '../../contexts/auth';
import './user-panel.scss';
import { useNavigate } from 'react-router-dom';
import { useClientSetting } from '../../contexts/clientSetting';
import useClaims from '../../apis/claims';
import i18n from '../../react-i18next.d';
import { locale } from 'devextreme/localization';

interface IProps {
  menuMode: 'context' | 'list';
}

export default function UserPanel({ menuMode }: IProps) {
  const { user, logOut } = useAuth();
  const { cleanGeneralSetting } = useClientSetting();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: claims, isLoading } = useClaims();
  const history = useNavigate();

  const navigateToClient = useCallback(() => {
    history('/switchClient');
  }, [history]);

  const navigateToProfile = useCallback(() => {
    history('/profile');
  }, [history]);

  const removeAllContext = useCallback(() => {
    if (cleanGeneralSetting) cleanGeneralSetting();
    if (logOut) logOut();
    // window.location.replace(localStorage.getItem('logoutUrl')!)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logOut, cleanGeneralSetting]);

  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const handleLanguageSelection = useCallback((language: string) => {
    setSelectedLanguage(language)
    i18n.changeLanguage(language)
    localStorage.setItem("TesLng", language)
    locale(language);
    document.location.reload();
    //window.location.reload();

    // document.locsetLocaleation.reload();
  }, []);
  
  const viewHandler = useCallback((e:any) => {
    if (e.itemData.text === "Compact View") {
      localStorage.setItem("GeneralUISetting", JSON.stringify({ ...(JSON.parse(localStorage.getItem("GeneralUISetting")!)), viewMode: "compact" }));
    } else if (e.itemData.text === "Normal View") {
      localStorage.setItem("GeneralUISetting", JSON.stringify({ ...(JSON.parse(localStorage.getItem("GeneralUISetting")!)), viewMode: "normal" }));
    }
    window.location.reload()
  }, []);

  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const menuItems = useMemo(() => [
    {
      text: i18n.t('profile'),
      icon: 'fa-solid fa-user',
      onClick: navigateToProfile,
    },
    {
      text: compactViewModel ? i18n.t('normalView') : i18n.t('compactView') ,
      icon: compactViewModel ? 'fa-solid fa-maximize' : 'fa-solid fa-minimize' ,
      onClick: viewHandler,
    },
    {
      text: i18n.t('language'),
      icon: 'fa-solid fa-language',
      items: [
        {
          text: 'Eng',
          icon: localStorage.getItem("TesLng") === 'en' ? 'fa-solid fa-check check-icon' : 'check-icon',
          onClick: () => handleLanguageSelection('en'),
        },
        {
          text: 'Fr',
          icon: localStorage.getItem("TesLng") === 'fr' ? 'fa-solid fa-check check-icon' : 'check-icon',
          onClick: () => handleLanguageSelection('fr'),
        },
      ],
    },
    {
      text: i18n.t('client'),
      icon: 'fa-solid fa-user-group',
      onClick: navigateToClient,
    },
    {
      text: i18n.t('logout'),
      icon: 'fa-solid fa-right-from-bracket',
      onClick: removeAllContext,
    }
  ], [handleLanguageSelection, navigateToProfile, navigateToClient, removeAllContext, selectedLanguage, viewHandler]);

  return (
    <div className="user-panel">
      <div className="user-info">
        <div className="image-container">
          <div
            style={{
              backgroundSize: 'cover',
            }}
            className="user-image"
          />
        </div>
        <div className="user-name">{user?.userName}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target=".user-button"
          showEvent="dxclick"
          width="100%"
          cssClass="user-menu"
        >
          <Position my={{ x: 'top', y: 'center' }} at={{ x: 'bottom', y: 'center' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className="dx-toolbar-menu-action" items={menuItems} />
      )}
    </div>
  );
}
