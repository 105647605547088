import { Button } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import CustomStore from "devextreme/data/custom_store";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../../components/permission/permision";
import ShareURL from "../../../../components/shareURL/shareURL";
import getTitle from "../../../../components/title/getTitle";
import { CollisionPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { CollisionApiUrl, FieldApiUrl } from "../../../../environment/routeSettings";
import { GeoCodeGroup, IGeoCodeGroupParams } from "../../../../types/collision/collisionTypes";
import { TableFieldRequestDTO } from "../../../../types/field/dto/fieldDTO";
import { FieldCategoryType } from "../../../../types/field/enums/fieldEnums";
import { TesField } from "../../../../types/field/fieldType";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { INameId, LazyLoadingRequest, NameValue, RequestResponseResult } from "../../../../types/general/generalTypes";
import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../../../types/infrastructure/infrastructureTypes";
import { Enum2Array } from "../../../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import Collisions from "./components/collisions/collisions";
import Details from "./components/details/details";
import tabTitles from "./data/index";
import "./geoCodeGroupDetails.scss";

const GeoCodeGroupDetails = () => {
  const history = useNavigate();
  const params = useParams<IGeoCodeGroupParams>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initData, setInitData] = useState<GeoCodeGroup>(new GeoCodeGroup());
  const validationRef = useRef<ValidationGroupRef>(null);
  const [dataChanged, setDataChanged] = useState(false);
  const [backPermission, setBackPermission] = useState(false);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([])
  const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());
  const [initDataDivisions, setInitDataDivisions] = useState<INameId[]>([]);
  const [initDataCollision, setInitDataCollision] = useState<any[]>([]);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const dataSource = new CustomStore({
    key: "_id",
    load: async (loadOption) => {
      return await TesPost(
        `${CollisionApiUrl()
        }/api/Collisions/GetDraftCollisions/`,
        {
          customerId: localStorage.getItem("selectedCustomerId"),
          divisionId: localStorage.getItem("selectedDivisionId"),
          loadOptions: loadOption
        } as LazyLoadingRequest,
        true
      );
    },
  });
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  const title = getTitle('/collision/geoCodeGroup/geoCodeGroupDetails', `${initData.title}`);
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataFields();
        await getInfrastructureTesCodeValues();
        setLstLocationType(Enum2Array(LocationType));
        if (params.geoCodeGroupId !== "AddNew") {
          await getInitData(params.geoCodeGroupId!)
        } else {
          setIsLocked(false)
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.geoCodeGroupId]);


  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        CollisionApiUrl() + "/api/xmlGeoCode/GetXMLGeoCodeGroupDetails/" + id,
        true
      )) as RequestResponseResult<GeoCodeGroup>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results)
        await GetInitDataCollisions(res.results.id)
      } else {
        await RequestErrorHandling(res);;
      }
    }
    catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }


  async function GetInitDataCollisions(id: string) {
    var res = await TesPost(
      `${CollisionApiUrl()
      }/api/Collisions/GetGeoCodeGroupCollisions`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        loadOptions: {},
        filter: {
          id: id
        }
      } as LazyLoadingRequest,
      true
    );

    setInitDataCollision(res.data)

  }

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/" + localStorage.getItem('selectedCustomerId'), true));
  }


  async function getInitialDataFields() {
    var postOb: TableFieldRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.CollisionGeneral]
    }
    var res = await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", postOb, true) as TesField[];
    res = res.sort(function (a, b) {
      return (a.gridViewIndex - b.gridViewIndex)
    });
    setInitDataFields(res);
  }

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }


  const onValueChange = (name: string, value: any) => {
    setInitData({ ...initData, [name]: value });
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.xmlSettingId !== "AddNew") {
                  //updateSign();
                  setDataChanged(false)
                } else {
                  //addSign();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  // async function getFields() {
  //   setFields(await TesGet(
  //     FieldApiUrl() +
  //     "/api/TesFields/GetAllFields/" + localStorage.getItem("selectedCustomerId") + "/" +
  //     FieldServiceType.Collision,
  //     true
  //   )
  //   );
  // }

  async function onUpdate() {
    // const validationRes = validationRef.current?.instance().validate();
    // if (validationRes?.isValid) {
    if (activeLoading) activeLoading(true);
    let postObj = {
      ...initData,
      lstCollisionIds: initDataCollision.map(x => x._id)

    }
    const res = (await TesPost(
      CollisionApiUrl() + "/api/XMLGeoCode/UpdateGeoCodeGroup",
      postObj,
      true
    )) as RequestResponseResult<GeoCodeGroup>;
    if (activeLoading) activeLoading(false);
    if (res.responseCode === ResponseCode.OK) {
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      setDataChanged(false);
    } else if (res.responseCode === ResponseCode.WorkOrderExist) {
      notify(t("workOrderExist"), "error", 5000);
    } else {
      await RequestErrorHandling(res);;
    }
  }



  async function onAdd() {
    // const validationRes = validationRef.current?.instance().validate();
    // if (validationRes?.isValid) {
    try {
      const postObj: GeoCodeGroup = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId") as string,
        lstCollisionIds: initDataCollision.map(x => x._id)

      };
      if (activeLoading) activeLoading(true);
      const res: RequestResponseResult<GeoCodeGroup> = await TesPost(
        CollisionApiUrl() + "/api/XMLGeoCode/AddGeoCodeGroup",
        postObj,
        true
      );
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        await getInitData(params.geoCodeGroupId!)
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
        return;
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };
  //Detail Functions

  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_GeoCode]}
      hasFeedBackElement={true}
    >
      <div className={`geoCodeGroupDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem" }}>
                  <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t('title')}: {initData?.title}</span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={goBackPermission}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />

                </div>
                <div className="rightColumn">
                  {params.geoCodeGroupId !== "AddNew" && (
                    <ShareURL
                      url={url}
                    />
                  )}
                  <Button
                    onClick={() => LockHandler()}
                    icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </div>
                <div className="rightColumn">

                  {params.geoCodeGroupId === "AddNew" && (
                    <Button
                      onClick={onAdd}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")} />
                  )}

                  {params.geoCodeGroupId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={onUpdate}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")} />
                  )}
                </div>
              </div>

            </div>
            <div className={"dx-card "}>
              <Tabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onOptionChanged={onTabsSelectionChanged}
              />

              {selectedIndex === 0 && (
                <Details
                  generalSettings={generalSetting}
                  isLocked={isLocked}
                  validationRef={validationRef}
                  onValueChange={onValueChange}
                  initData={initData}
                  setInitData={setInitData}
                />
              )}
              {selectedIndex === 1 && (
                <Collisions
                  isLocked={isLocked}
                  validationRef={validationRef}
                  initData={initData}
                  setInitData={setInitData}
                  initDataFields={initDataFields}
                  dataSource={dataSource}
                  initDataCollision={initDataCollision}
                  setInitDataCollision={setInitDataCollision}
                  lstLocationType={lstLocationType}
                  initDataTesCodeValues={initDataTesCodeValues}
                  initDataDivisions={initDataDivisions}
                  generalSetting={generalSetting}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default GeoCodeGroupDetails;
