import { DataGrid } from "devextreme-react";
import { Column, ColumnChooser, DataGridRef, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, Search, SearchPanel } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from "../../../constants/Permissions";
import { AuthApiUrl } from "../../../environment/routeSettings";
import { INameId } from "../../../types/general/generalTypes";
import { TesGet } from "../../../utils/rest";

const Customer = () => {

  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [initDataClient, setInitDataClient] = useState<INameId[]>([]);
  const history = useNavigate();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        await getInitDataClient();
        // setLstLanguageType(Enum2Array(LanguageType))
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitDataClient() {
    const res = await TesGet(AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser", true);
    setInitDataClient(res);
  }

  const navigateToCustomerDetails = useCallback((r: any) => {
    history("/translation/customerDetails/" + `${r.data.id}`);
  }, [history]);

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <h2 className={"content-block"}>{t("customers")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initDataClient}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onRowClick={e => navigateToCustomerDetails(e)}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column
                dataField="id"
                allowSorting={false}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="name"
                allowSorting={false}
                caption={t("name")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    </Permission>
  )
}

export default Customer;