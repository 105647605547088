import React, { useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { IChagePassword } from '../../types/identity/dto/identityDTO';
import { TesPost } from '../../utils/rest';
import notify from 'devextreme/ui/notify';
import { t } from 'i18next';
import { RequestResponseResult } from '../../types/general/generalTypes';
import { ResponseCode } from '../../types/general/enums/generalEnums';
import { AuthApiUrl } from '../../environment/routeSettings';


export default function UpdatePasswordForm() {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ newPass: '', currentPass: '' });
  const { userId } = useParams<{ userId: string }>();

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault();
    const { newPass } = formData.current;
    const { currentPass } = formData.current;
    setLoading(true);

    // Send reset password request
    let postObj: IChagePassword = {
      userId: userId!,
      currentPass: currentPass,
      newPass: newPass
    }
    const res = await TesPost(AuthApiUrl() + "/api/auth/UpdatePassword", JSON.stringify(postObj), true);
    if (res == null) {
      notify(t('someError'), "error", 5000);
      return;
    }
    let resp = new RequestResponseResult();
    Object.assign(resp, res);

    if (resp.responseCode === ResponseCode.OK) {
      history('/login');
      notify(t('passwordChanged'), "success", 5000);
    }
    if (resp.responseCode === ResponseCode.PasswordIsNotComplex) {
      setLoading(false);
      notify(t('passwordIsNotComplex'), "error", 5000);
    }
    if (resp.responseCode === ResponseCode.UserNotExists) {
      history('/login');
      notify(t('userNotExists'), "error", 5000);
    }
    if (resp.responseCode === ResponseCode.UsernamePasswordNotValid) {
      setLoading(false);
      notify(t('currentPasswordIsWrong'), "error", 5000);
    }
    if (resp.responseCode === ResponseCode.LoginBlocked) {
      history('/login');
      notify(t('loginBlocked'), "error", 5000);
    }
  }, [history, userId]);

  // Updated confirmPassword function to match the expected input shape
  const confirmPassword = useCallback(
    ({ value }: { value: string | number }) => {
      return typeof value === 'string' && value === formData.current.newPass;
    },
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'currentPass'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'newPass'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : t('continue')
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'New Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm New Password', mode: 'password' };
