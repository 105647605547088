//imports
import { Button, CheckBox, Popup, ScrollView } from "devextreme-react";
import { ArgumentAxis, Chart, CommonSeriesSettings, Export, Legend, Series, Title, Tooltip, ValueAxis } from 'devextreme-react/chart';
import DateBox from 'devextreme-react/date-box';
import Form, { SimpleItem } from "devextreme-react/form";
import PieChart, {
  Connector,
  Label
} from 'devextreme-react/pie-chart';
import notify from 'devextreme/ui/notify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CollapsibleCard from "../../components/collapsibleCard/collapsibleCard";
import { useAuth } from '../../contexts/auth';
import { CollisionApiUrl, TrafficApiUrl } from '../../environment/routeSettings';
import { IDashboardResult } from '../../types/collision/collisionTypes';
import { FromMapRequestCollisionDTO, FromMapResponseCollisionDTO, GetCollisionDatePeriodRequestDTO } from '../../types/collision/dto/collisionDtos';
import { ResponseCode, TesMapModule } from '../../types/general/enums/generalEnums';
import { RequestResponseResult } from '../../types/general/generalTypes';
import { LocationPickerMode, LocationType } from '../../types/infrastructure/enums/infrastructureEnums';
import { GeneralSetting } from '../../types/setting/gridSettingTypes';
import { FromMapRequestTrafficDTO, FromMapResponseTrafficDTO } from '../../types/trafficStudy/dtos/studyDto';
import { CreateDateAsUTC } from '../../utils/dateTimeTools';
import { useScreenSize } from '../../utils/media-query';
import { RequestErrorHandling, TesPost } from '../../utils/rest';
import TransformToSpeedAndCompliance from '../../utils/speedAndCompliance';
import InfrastructureLocationPicker from '../infrastructureLocationPicker/infrastructureLocationPicker';
import design from './overviewReport.module.scss';


//props
interface IPros {
  generalSettings: GeneralSetting | null;
  locationId: string;
  locationType: LocationType
}

const OverViewReport = (props: IPros) => {
  //states
  const [severity, setSeverity] = useState<number[]>([1, 2, 3]);
  const [initData, setInitData] = useState<IDashboardResult | undefined>(undefined);
  const [reportDate, setReportDate] = useState({
    beginDateFrom: new Date(new Date().getFullYear() - 4, 0, 1),
    beginDateTo: new Date(new Date().getFullYear(), 11, 31)
  });
  const [datePeriod, setDatePeriod] = useState<GetCollisionDatePeriodRequestDTO>();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const { isLarge, isMedium, isXLarge, is2xLarge } = useScreenSize();
  const [modal, setModal] = useState<boolean>(false);
  const [chartHeight, setChartHeight] = useState<string>("30.75rem");
  const [locationId, setLocationId] = useState<string | null>(null);
  const [fromMapReqCol, setFromMapReqCol] = useState<FromMapRequestCollisionDTO>({
    fromDate: new Date(new Date().getFullYear() - 4, 0, 1),
    toDate: new Date(new Date().getFullYear(), 11, 31),
    customerId: localStorage.getItem("selectedCustomerId") as string,
    locationId: props.locationId as string
  });
  const [selectedDataCollision, setSelectedDataCollision] = useState<FromMapResponseCollisionDTO>();
  const [selectedDataTraffic, setSelectedDataTraffic] = useState<FromMapResponseTrafficDTO>();
  const [fromMapReqTra, setFromMapReqTra] = useState<FromMapRequestTrafficDTO>({
    fromDate: new Date(new Date().getFullYear() - 4, 0, 1),
    toDate: new Date(new Date().getFullYear(), 11, 31),
    customerId: localStorage.getItem("selectedCustomerId") as string,
    locationId: props.locationId as string
  });
  const [isAvgVisible, setIsAvgVisible] = useState(true);
  const [is85thVisible, setIs85thVisible] = useState(true);
  const [isPostedSpeedVisible, setIsPostedSpeedVisible] = useState(true);
  const [isComplianceVisible, setIsComplianceVisible] = useState(true);

  useEffect(() => {
    setLocationId(props.locationId);
  }, [props.locationId]);

  useEffect(() => {
    if (isLarge && !isXLarge) { setChartHeight("26.75rem") }
    else if (isLarge && isXLarge) { setChartHeight("38.75rem") }
    else if (is2xLarge) { setChartHeight("56.25rem") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  //#region functions
  async function getCollisionOverViewChart(fromDate?: Date, toDate?: Date) {
    try {
      if (activeLoading) activeLoading(true);
      const postObj = {
        customerId: localStorage.getItem('selectedCustomerId'),
        toDate: CreateDateAsUTC(toDate != undefined ? toDate : reportDate.beginDateTo),
        fromDate: CreateDateAsUTC(fromDate != undefined ? fromDate : reportDate.beginDateFrom),
        locationId: props.locationId
      }
      setInitData(await (TesPost(CollisionApiUrl() + "/api/Reports/GetOverviewReport", postObj, true)))
      if (activeLoading) activeLoading(false);
      onCloseModal()
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
      onCloseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  async function getCollisionData(fromDate?: Date, toDate?: Date) {
    try {
      if (activeLoading) activeLoading(true);
      var postObjCol = fromMapReqCol
      postObjCol.locationId = props.locationId as string
      postObjCol.toDate=CreateDateAsUTC(toDate != undefined ? toDate : reportDate.beginDateTo)
      postObjCol.fromDate= CreateDateAsUTC(fromDate != undefined ? fromDate : reportDate.beginDateFrom)


      var resCollision = await TesPost(CollisionApiUrl() + "/api/reports/GetDataFromMap", postObjCol, true) as RequestResponseResult<FromMapResponseCollisionDTO>;
      if (resCollision.responseCode === ResponseCode.OK) {
        setSelectedDataCollision(resCollision.results)
        if (activeLoading) activeLoading(false);
      }
      else if (resCollision.responseCode === ResponseCode.IdNotExists) {
        if (activeLoading) activeLoading(false);
        notify(t("enum-IdNotExists"), "error", 5000);
      }
      await RequestErrorHandling(resCollision);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function getTrafficData(fromDate?: Date, toDate?: Date) {


    try {
      if (activeLoading) activeLoading(true);
      var postObjTra = fromMapReqTra
      postObjTra.locationId = props.locationId as string
      postObjTra.toDate=CreateDateAsUTC(toDate != undefined ? toDate : reportDate.beginDateTo)
      postObjTra.fromDate= CreateDateAsUTC(fromDate != undefined ? fromDate : reportDate.beginDateFrom)
      var resTraffic = await TesPost(TrafficApiUrl() + "/api/report/GetDataFromMap", postObjTra, true) as RequestResponseResult<FromMapResponseTrafficDTO>;
      if (resTraffic.responseCode === ResponseCode.OK) {
        if (activeLoading) activeLoading(false);
        setSelectedDataTraffic(resTraffic.results)
      } else if (resTraffic.responseCode === ResponseCode.IdNotExists) {
        if (activeLoading) activeLoading(false);
        notify(t("enum-IdNotExists"), "error", 5000);
      }
      await RequestErrorHandling(resTraffic);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }

  }




  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (locationId) { // Check if locationId is not null
          await getMinMaxdate();
          // await applyFilter();
        }
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }

      try {
        if (activeLoading) activeLoading(true);
        var postObjCol = fromMapReqCol
        postObjCol.locationId = props.locationId as string
        var resCollision = await TesPost(CollisionApiUrl() + "/api/reports/GetDataFromMap", postObjCol, true) as RequestResponseResult<FromMapResponseCollisionDTO>;
        if (resCollision.responseCode === ResponseCode.OK) {
          setSelectedDataCollision(resCollision.results)
          if (activeLoading) activeLoading(false);
        }
        else if (resCollision.responseCode === ResponseCode.IdNotExists) {
          if (activeLoading) activeLoading(false);
          notify(t("enum-IdNotExists"), "error", 5000);
        }
        await RequestErrorHandling(resCollision);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }

      try {
        if (activeLoading) activeLoading(true);
        var postObjTra = fromMapReqTra
        postObjTra.locationId = props.locationId as string
        var resTraffic = await TesPost(TrafficApiUrl() + "/api/report/GetDataFromMap", postObjTra, true) as RequestResponseResult<FromMapResponseTrafficDTO>;
        if (resTraffic.responseCode === ResponseCode.OK) {
          if (activeLoading) activeLoading(false);
          setSelectedDataTraffic(resTraffic.results)
        } else if (resTraffic.responseCode === ResponseCode.IdNotExists) {
          if (activeLoading) activeLoading(false);
          notify(t("enum-IdNotExists"), "error", 5000);
        }
        await RequestErrorHandling(resTraffic);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }

    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  async function getMinMaxdate() {
    var postObj: GetCollisionDatePeriodRequestDTO = {
      customerId: localStorage.getItem('selectedCustomerId') as string,
    }
    var res = await TesPost(CollisionApiUrl() + "/api/Collisions/GetCollisionDatePeriod", postObj, true) as RequestResponseResult<GetCollisionDatePeriodRequestDTO>
    setDatePeriod(res.results);
    if (res.results == null) return;
    var endDate = new Date(res.results.endDT!)
    if (endDate?.getFullYear() === 1) { endDate = new Date() }
    await setReportDate({
      beginDateFrom: new Date(new Date(endDate!).getFullYear() - 4, 0, 1),
      beginDateTo: new Date((endDate!).getFullYear(), 11, 31)
    })
    await getCollisionOverViewChart(new Date((endDate!).getFullYear() - 4, 0, 1), new Date((endDate!).getFullYear(), 11, 31));
    await getCollisionData(new Date((endDate!).getFullYear() - 4, 0, 1), new Date((endDate!).getFullYear(), 11, 31));
    await getTrafficData(new Date((endDate!).getFullYear() - 4, 0, 1), new Date((endDate!).getFullYear(), 11, 31));
  }


  async function  onapplyChangeDate(){

    await getCollisionOverViewChart(reportDate.beginDateFrom, reportDate.beginDateTo);
    await getCollisionData(reportDate.beginDateFrom, reportDate.beginDateTo);
    await getTrafficData(reportDate.beginDateFrom, reportDate.beginDateTo);

  }

  function onChangeDate(name: string, value: Date) {
    if (name === "beginDateFrom") {
      if (reportDate.beginDateTo < value) {
        notify('Select valid date range.', "error", 5000);
        return;
      }
      setReportDate({
        ...reportDate,
        beginDateFrom: value
      })

      //setBeginDateFrom(value)
    }
    if (name === "beginDateTo") {
      if (reportDate.beginDateFrom > value) {
        notify('Select valid date range.', "error", 5000);
        return;
      }
      setReportDate({
        ...reportDate,
        beginDateTo: value
      })
    }
  }




  function onCloseModal() {
    setModal(false);
  }


  //#endregion

  return (
    <div>
      <div className={`${design.row} Overview_Report`}>
        <Button
          style={{ margin: 10 }}
          onClick={() => setModal(true)}
          icon="fa-solid fa-calendar"
          hint={t("filter")}
        />
      </div>
      <div className={`${design.chartsContainer}`}>
        {initData && initData.yearSummary &&
          (initData.yearSummary.some(x => x.injury !== 0) ||
            initData.yearSummary.some(x => x.pdo !== 0) ||
            initData.yearSummary.some(x => x.fatal !== 0)) &&
          <div className={`${design.chartBlock50}`}>
            <CollapsibleCard title={t("collisionFrequency")}>
              <div style={{ height: chartHeight }}>
                <Chart id="chart"
                  dataSource={initData?.yearSummary}
                  height={"100%"}
                >
                  <CommonSeriesSettings argumentField="description" type="stackedbar" barWidth={60} />
                  {severity.includes(2) && initData.yearSummary.some(x => x.injury !== 0) &&
                    <Series
                      valueField="injury"
                      name={t("injury")}
                      axis="pdoInjury"
                      color="#008ac5"
                    />
                  }
                  {severity.includes(1) && initData.yearSummary.some(x => x.pdo !== 0) &&
                    <Series
                      valueField="pdo"
                      name={t("PDO")}
                      axis="pdoInjury"
                      color="#00a9b5"
                    />
                  }
                  {severity.includes(3) && initData.yearSummary.some(x => x.fatal !== 0) &&
                    <Series
                      valueField="fatal"
                      name={t("fatal")}
                      type="line"
                      axis="fatalAxix"
                      color="#0b1d78"
                    />
                  }
                  {/* x axis */}
                  <ArgumentAxis
                    title={t('year')}
                  />

                  {severity.includes(3) &&
                    // y axis
                    <ValueAxis
                      name="fatalAxix"
                      position="right"
                      // valueType="fatal"
                      showZero={true}
                      valueMarginsEnabled={false}
                      allowDecimals={false}

                    >
                      <Title text={t("numberOfFatalCollisions")} />
                    </ValueAxis>
                  }
                  {(severity.includes(1) || severity.includes(2)) &&
                    <ValueAxis
                      name="pdoInjury"
                      position="left"
                      allowDecimals={false}
                      type={'continuous'}
                    >
                      <Title text={(severity.includes(1) && !severity.includes(2)) ? t("numberOfPDOCollisions") : (severity.includes(1) && severity.includes(2)) ? t("numberOfPDOInjuryCollisions") : t("numberOfInjuryCollisions")}
                      />
                    </ValueAxis>
                  }
                  <Export enabled={true} printingEnabled={false} />
                  <Tooltip
                    enabled={true}
                    location="edge"
                  //  customizeTooltip={this.customizeTooltip}
                  />
                  <Legend position="outside" horizontalAlignment="right" verticalAlignment="top"></Legend>
                </Chart>
              </div>
            </CollapsibleCard>
          </div>}

        <div className={`${initData && initData.yearSummary &&
          (initData.yearSummary.some(x => x.injury !== 0) ||
            initData.yearSummary.some(x => x.pdo !== 0) ||
            initData.yearSummary.some(x => x.fatal !== 0)) ? design.chartBlock50 : design.chartBlock100}`}>
          <CollapsibleCard title={t("summary")}>
            <InfrastructureLocationPicker
              didMount={true}
              initLocationData={{
                customerId: localStorage.getItem('selectedCustomerId') as string,
                id: props.locationId,
              }}
              tesModule={TesMapModule.InfrastructureDetails}
              componentMode={LocationPickerMode.None}
              renderMap={true}
              renderCoordinate={false}
              showIcons={true}
            />
          </CollapsibleCard>
        </div>

        {selectedDataCollision?.impactTypeClient?.length !== 0 && selectedDataCollision?.impactTypeClient !== undefined && selectedDataCollision !== undefined &&
          <div className={`${design.chartBlock33}`}>
            {(initData && initData.yearSummary) &&
              <CollapsibleCard title={t("initialImpactType")}>
                <PieChart
                  id="pie"
                  dataSource={selectedDataCollision?.impactTypeClient ? selectedDataCollision?.impactTypeClient : []}
                  //palette="Ocean"
                  paletteExtensionMode="alternate"
                // onPointClick={pointClickHandler}
                // onLegendClick={legendClickHandler}
                >
                  <Series
                    argumentField="name"
                    valueField="value"
                  >
                    <Label visible={true}>
                      <Connector visible={true} width={1} />
                    </Label>
                  </Series>
                  <Export enabled={true} printingEnabled={false} />
                </PieChart>
              </CollapsibleCard>
            }
          </div>}

        {selectedDataCollision !== undefined && selectedDataCollision?.roadSurfaceCondition !== undefined && selectedDataCollision?.roadSurfaceCondition?.length !== 0 &&
          <div className={`${design.chartBlock33}`}>
            {(initData && initData.yearSummary) &&
              <CollapsibleCard title={t("roadSurfaceCondition")}>
                <PieChart
                  id="pie"
                  dataSource={selectedDataCollision?.roadSurfaceCondition}
                  //palette="Ocean"
                  paletteExtensionMode="alternate"
                // onPointClick={pointClickHandler}
                // onLegendClick={legendClickHandler}
                >
                  <Series
                    argumentField="name"
                    valueField="value"
                  >
                    <Label visible={true}>
                      <Connector visible={true} width={1} />
                    </Label>
                  </Series>
                  <Export enabled={true} printingEnabled={false} />
                </PieChart>
              </CollapsibleCard>
            }
          </div>}

        {selectedDataCollision?.lightingCondition?.length !== 0 && selectedDataCollision?.lightingCondition !== undefined && selectedDataCollision !== undefined &&
          <div className={`${design.chartBlock33}`}>
            {(initData && initData.yearSummary) &&
              <CollapsibleCard title={t("lightingCondition")}>
                <PieChart
                  id="pie"
                  dataSource={selectedDataCollision?.lightingCondition}
                  //palette="Ocean"
                  paletteExtensionMode="alternate"
                // onPointClick={pointClickHandler}
                // onLegendClick={legendClickHandler}
                >
                  <Series
                    argumentField="name"
                    valueField="value"
                  >
                    <Label visible={true}>
                      <Connector visible={true} width={1} />
                    </Label>
                  </Series>
                  <Export enabled={true} printingEnabled={false} />
                </PieChart>
              </CollapsibleCard>
            }
          </div>}
        {selectedDataTraffic?.aadt !== undefined && selectedDataTraffic?.aadt.length > 0 && selectedDataTraffic?.aadt.some(x => x.value !== "0") &&
          <div className={`${design.chartBlock33}`}>
            <CollapsibleCard title={t("aadt")}>
              <Chart
                id="chart"
                dataSource={selectedDataTraffic?.aadt}
              >
                <CommonSeriesSettings argumentField="year" type="line" barWidth={50} />
                <ArgumentAxis allowDecimals={false} argumentType={'string'} title={t('year')} />
                <Series
                  valueField="volume"
                  name={t("aadt")}
                  axis="aadt"
                  color="#008ac5"
                />
                {/*y axis */}
                <ValueAxis
                  name="aadt"
                  position="top"
                  allowDecimals={false}
                  type={'continuous'}
                  title={t('aadt')}
                  visualRange={[0]}
                >
                </ValueAxis>
                <Tooltip
                  enabled={true}
                  location="edge"
                />
                <Legend
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                  itemTextPosition="bottom"
                  visible={false}
                />
              </Chart>
            </CollapsibleCard>
          </div>}
        {props.locationType === LocationType.Midblock && selectedDataTraffic !== undefined && selectedDataTraffic?.studies.length > 0 && selectedDataTraffic?.studies.filter(x => x.studyType === 4).length > 1 &&
          // !selectedDataTraffic?.studies.some(x => x.speedStatisticsSummary === null)  &&
          // !selectedDataTraffic?.studies.some(x => x.volumeSummary === null)  &&
          <div className={`${design.chartBlock33}`}>
            <CollapsibleCard title={t("speedStatisticsSummary")}>
              <Chart id="chart"
                dataSource={TransformToSpeedAndCompliance(selectedDataTraffic?.studies)}
                paletteExtensionMode="alternate"
              >
                <CommonSeriesSettings argumentField="startDT" type="line" />
                <Export enabled={true} printingEnabled={false} />
                <Series
                  valueField="average"
                  name={t("average")}
                  type="line"
                  axis="leftAxis"
                  color="#0b1d78"
                  visible={isAvgVisible}
                />
                <Series
                  valueField="speed85thPercentile"
                  name={t("85th%")}
                  type="line"
                  axis="leftAxis"
                  color="#FFC300"
                  visible={is85thVisible}
                />
                <Series
                  valueField="postedSpeed"
                  name={t("postedSpeed")}
                  type="line"
                  axis="leftAxis"
                  color="#D81E5B"
                  visible={isPostedSpeedVisible}
                />
                <Series
                  valueField="compliance"
                  name={t("compliance")}
                  type="line"
                  axis="rightAxis"
                  color="#8E44AD"
                  visible={isComplianceVisible}
                />
                <ArgumentAxis
                  title={t('Date')}
                />
                <ValueAxis
                  name="leftAxis"
                  position="left"
                  showZero={true}
                  valueMarginsEnabled={false}
                  allowDecimals={false}
                >
                  <Title text={t("speed") + " (km/h)"} />

                </ValueAxis>
                <ValueAxis
                  name="rightAxis"
                  position="right"
                  showZero={true}
                  valueMarginsEnabled={false}
                  allowDecimals={false}
                  visualRange={[0, 100]}
                  valueType='numeric'
                >
                  <Title text={t("compliance") + " (%)"} />
                </ValueAxis>
                <Export enabled={true} />
                <Tooltip
                  enabled={true}
                  location="edge"
                />
                <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
              </Chart>
              <div>
                <CheckBox
                  text={t('average')}
                  value={isAvgVisible}
                  onValueChanged={(e) => setIsAvgVisible(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
                <CheckBox
                  text={t('postedSpeed')}
                  value={isPostedSpeedVisible}
                  onValueChanged={(e) => setIsPostedSpeedVisible(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
                <CheckBox
                  text={t('compliance')}
                  value={isComplianceVisible}
                  onValueChanged={(e) => setIsComplianceVisible(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
                <CheckBox
                  text={t('85th%')}
                  value={is85thVisible}
                  onValueChanged={(e) => setIs85thVisible(e.value)}
                  style={{ margin: "0.75rem 1rem 1rem 1rem" }}
                />
              </div>
            </CollapsibleCard>
          </div>
        }

      </div>

      <Popup
        visible={modal}
        width={"50%"}
        height={"auto"}
        resizeEnabled={true}
        showTitle={true}
        title={t("dateFilter")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width='100%' height='100%'>

          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <DateBox
                label={t("beginDateFrom")}
                defaultValue={reportDate.beginDateTo}
                type="date"
                onValueChange={e => onChangeDate('beginDateFrom', new Date(e))}
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                showClearButton={true}
                value={reportDate.beginDateFrom}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <DateBox
                label={t("beginDateTo")}
                defaultValue={reportDate.beginDateTo}
                type="date"
                onValueChange={e => onChangeDate('beginDateTo', new Date(e))}
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                showClearButton={true}
                value={reportDate.beginDateTo}
              />
            </SimpleItem>

          </Form>
          <div>
            <div className="rightColumn" style={{ marginTop: "1.5rem" }}>
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("reject")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={() => onapplyChangeDate()}
                text={t("confirm")}
              />
            </div>
          </div>
        </ScrollView>



      </Popup>
    </div>





  );
}
export default OverViewReport;