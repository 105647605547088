import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterRow,
  GroupItem,
  HeaderFilter,
  Pager, Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo, Summary
} from 'devextreme-react/data-grid';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useClientSetting } from '../../../../../../../contexts/clientSetting';
import { OnExporting } from '../../../../../../../utils/dataGridTools';

// props
interface IPros {
  data: any
}
const VolumeDetailTemplate = (props: IPros) => {
  const { t } = useTranslation();
  const { generalSetting } = useClientSetting();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);

  useEffect(() => {
    async function fetchMyAPI() {


    }
    fetchMyAPI()
  }, []);

  return (
    <React.Fragment>
      <div className='row' style={{ marginTop: 20, padding: 10 }}>
        <DataGrid id="gridContainer"
          ref={dataGridRef}
          dataSource={props.data.data.volumeDetails}
          rowAlternationEnabled={true}
          showBorders={true}
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true}
            applyFilter="auto" />

          <HeaderFilter visible={true} />

          <SearchPanel visible={true}
            width={285}
            placeholder={t("search...")} />
          <ColumnChooser width={350} height={400}
            enabled={true}
            mode="select"
            sortOrder="asc"
          >
            <Search enabled />
          </ColumnChooser>
          <SortByGroupSummaryInfo
            summaryItem="Total Count"
            sortOrder="desc"
          />
          <Summary>
            <GroupItem
              summaryType="count"
              alignByColumn
              name="Total Count"
            />
          </Summary>
          <Column dataField="startDT"
            format={generalSetting?.isAmPm ? "shortTime" : "HH:mm"}
            dataType="datetime"

            caption={t("startTime")} >
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>

          <Column dataField="endDT"
            format={generalSetting?.isAmPm ? "shortTime" : "HH:mm"}
            dataType="datetime"

            caption={t("endTime")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>

          <Column dataField="total" caption={t("total")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>

          <Column dataField="isPH" caption={t("isPH")} visible={true}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>

        </DataGrid>
      </div>
    </React.Fragment>
  );


}
export default VolumeDetailTemplate;