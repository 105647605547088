import { ValidationGroup } from 'devextreme-react';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterRow, HeaderFilter,
    Pager, Paging,
    Search,
    SearchPanel
} from 'devextreme-react/data-grid';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { VMAppSetups } from '../../../../../types/sign/dto/signDto';
import { Support } from '../../../../../types/sign/supportTypes';
import { OnExporting } from '../../../../../utils/dataGridTools';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';

// props
interface IPros {
    initDataSupport: Support,
    initDataSetups: VMAppSetups
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroupRef>
}

const ChangeLogs = (props: IPros) => {
    const { t } = useTranslation();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);

    function lowerCaseFirstLetter(string: string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }

    function calFromValue(dv: any) {
        if (dv.field === 'SupportId') {
            if (dv.fromValue === '') {
                return ""
            } else {
                return 'Remove From Support'
            }
        }

        if (dv.field.endsWith("Id")) {
            if (dv.fromValue != null) {
                return props.initDataSetups[lowerCaseFirstLetter(dv.field.slice(0, dv.field.length - 2))].find((x: any) => x.id === dv.fromValue).name
            } else {
                return ""
            }
        } else {
            return dv.fromValue;
        }
    }

    function calToValue(dv: any) {
        if (dv.field === 'SupportId') {
            if (dv.toValue === '') {
                return ""
            } else {
                return 'Add To Support'
            }
        }

        if (dv.field.endsWith("Id")) {
            if (dv.toValue != null) {
                return props.initDataSetups[lowerCaseFirstLetter(dv.field.slice(0, dv.field.length - 2))].find((x: any) => x.id === dv.toValue).name
            } else {
                return ""
            }
        } else {
            return dv.toValue;
        }
    }


    return (
        <React.Fragment>
            <ValidationGroup
                ref={props.validationRef}
            >
                <div className='row' style={{ marginTop: 20, padding: 10 }}>
                    <DataGrid id="gridContainer"
                        ref={dataGridRef}
                        dataSource={props.initDataSupport?.changeLogs}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        style={{ margin: "0 1rem" }}
                        onExporting={OnExporting}
                    >

                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                        />

                        <Export enabled={true}
                            allowExportSelectedData={true}
                        />

                        <FilterRow visible={true}
                            applyFilter="auto" />

                        <HeaderFilter visible={true} />


                        <SearchPanel visible={true}
                            width={285}
                            placeholder={t("search...")} />
                        <ColumnChooser width={350} height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                        >
                            <Search enabled />
                        </ColumnChooser>
                        {/* below comment needs to be added when general client Setting is added */}
                        {/* format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy" */}
                        <Column dataField={'changeDT'} dataType="date"
                            caption={t("changeDateTime")}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                        <Column dataField={'field'}
                            caption={t("field")}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column dataField={'fromValue'}
                            caption={t("fromValue")}
                            calculateCellValue={(dv: any) => calFromValue(dv)}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                        <Column dataField={'toValue'}
                            caption={t("toValue")}
                            calculateCellValue={(dv: any) => calToValue(dv)}

                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                        <Column dataField={'username'}
                            caption={t("username")}
                        ></Column>


                    </DataGrid>
                </div>
            </ValidationGroup>
        </React.Fragment >
    );
}

export default ChangeLogs;