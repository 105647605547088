const TrafficStudyPermissions = {
  TrafficStudy_D_Setups: "TrafficStudy_D_Setups",
  TrafficStudy_V_MobileApp: "TrafficStudy_V_MobileApp",
  TrafficStudy_V_MobileAppReport: "TrafficStudy_V_MobileAppReport",
  TrafficStudy_D_Study: "TrafficStudy_D_Study",
  TrafficStudy_E_Study: "TrafficStudy_E_Study",
  TrafficStudy_V_Study: "TrafficStudy_V_Study",
  TrafficStudy_R_Study: "TrafficStudy_R_Study",
  TrafficStudy_V_MMS: "TrafficStudy_V_MMS",
  TrafficStudy_D_MMS: "TrafficStudy_D_MMS",
};
export default TrafficStudyPermissions;
