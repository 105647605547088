//#region imports
import { SelectBox, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, { Column, ColumnChooser, DataGridRef, Export, FilterRow, GroupItem, HeaderFilter, Lookup, Pager, Paging, Search, SearchPanel, SortByGroupSummaryInfo, Summary } from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import FilePicker from "../../../../components/filePicker/filePicker";
import Permission from "../../../../components/permission/permision";
import Stepper from "../../../../components/stepper/stepper";
import getTitle from "../../../../components/title/getTitle";
import { TMSPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { InfrastructureApiUrl } from "../../../../environment/routeSettings";
import { VMStorage } from "../../../../types/fileManagement/dto/fileManagementDTO";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { DataSourceType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { FieldDataSource, FieldDataSourceAddress, InfrastructureLoadDataReq } from "../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";
import "./loadData.scss";
//#endregion imports

const LoadDatas = () => {

  //#region consts
  const [selectedStep, setSelectedStep] = useState(1)
  const [fieldsData, setFieldsData] = useState<FieldDataSource[]>([]);
  // const [selectedFieldDataSource, setSelectedFieldDataSource] = useState<FieldDataSource>(new FieldDataSource())
  const [showFPButton, setShowFPButton] = useState<boolean>(true)
  const [selectedFile, setSelectedFile] = useState<FieldDataSourceAddress>(new FieldDataSourceAddress());
  const dataGridLoadDataRef = useRef<DataGridRef<any, any>>(null);
  const [lstFileAddress, setLstFileAddress] = useState<FieldDataSourceAddress[]>([]);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  //#endregion consts

  //#region functions

  const title = getTitle('/infrastructure/updateImport/loadData', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getFieldsData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getFieldsData() {
    const postObj = { customerId: localStorage.getItem("selectedCustomerId") }
    const res =
      await TesPost(
        InfrastructureApiUrl() +
        "/api/FieldDataSource/GetCustomerFieldDataSources/",
        postObj,
        true) as RequestResponseResult<FieldDataSource[]>
    if (res.responseCode === ResponseCode.OK) {
      setFieldsData(res.results)
    } else {
      await RequestErrorHandling(res);
    }
  }

  function addFromFileStorage(singleFile: VMStorage[]) {
    setSelectedFile({ ...selectedFile, fileAddress: singleFile[0].filePath })
  }

  function onAdd() {
    setLstFileAddress([...lstFileAddress, selectedFile])
    notify(t("theFileSuccessfullyAdded"), "success", 5000)
    setSelectedFile(new FieldDataSourceAddress())
    // setSelectedFieldDataSource(new FieldDataSource())
  }

  function onChangeFile(name: string, value: any) {
    setSelectedFile({ ...selectedFile, [name]: value })
    if (name === "fieldDataSourceId") {
      var currentDataSource = fieldsData.find(x => x.id === value)
      setSelectedFile({ ...selectedFile, [name]: value, dataSourceType: currentDataSource?.dataSourceType })
      if (currentDataSource?.dataSourceType === DataSourceType.Excel) {
        setShowFPButton(false)
      }
    }
  }

  async function startImport() {
    const postObj: InfrastructureLoadDataReq = {
      customerId: localStorage.getItem("selectedCustomerId") as string,
      lstFieldDataSourceAddress: lstFileAddress
    }
    await TesPost(
      InfrastructureApiUrl() +
      "/api/FieldDataSource/AddInfrastructureLoadDataReq",
      postObj,
      true) as RequestResponseResult<InfrastructureLoadDataReq>
  }

  //#endregion functions

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <div className={`infrastructure-loadData ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("loadData")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"} style={{ padding: "2rem 2rem 0rem 2rem" }}>
              <div className="row">
                <Stepper selectedStep={selectedStep} stepNumber={2} />
                {selectedStep === 1 &&
                  <div>
                    <Form colCount={15}>
                      <SimpleItem colSpan={3}>
                        <SelectBox
                          placeholder=""
                          label={t('fieldDataSource')}
                          valueExpr="id"
                          displayExpr="dataSourceName"
                          value={selectedFile.fieldDataSourceId}
                          labelMode='floating'
                          onValueChange={(e) => onChangeFile("fieldDataSourceId", e)}
                          className="modalInput"
                          items={SortObjectByPropName(fieldsData, "dataSourceName")}
                          showClearButton={true}
                          searchEnabled={true}
                        />
                      </SimpleItem>
                      <SimpleItem colSpan={3}>
                        <TextBox
                          label={t("dataSourceType")}
                          labelMode='floating'
                          className="modalInput"
                          value={selectedFile?.dataSourceType === DataSourceType.Excel ? "Excel" : (selectedFile?.dataSourceType === DataSourceType.ArcGIS ? "ArcGIS" : "")}
                          disabled={true}
                        ></TextBox>
                      </SimpleItem>

                      {selectedFile?.dataSourceType === DataSourceType.Excel &&
                        <SimpleItem colSpan={7}>
                          <TextBox
                            label={t("filePath")}
                            labelMode='floating'
                            className="modalInput"
                            value={selectedFile.fileAddress}
                            onValueChange={(e) => onChangeFile("fileAddress", e)}
                          />
                        </SimpleItem>
                      }
                      {selectedFile?.dataSourceType === DataSourceType.Excel &&
                        <SimpleItem colSpan={2}>
                          <FilePicker
                            isLocked={false}
                            label={t("filePicker")}
                            addFromFileStorage={addFromFileStorage}
                            disable={showFPButton}
                            lstLastFiles={[]}
                            multiFileMode={false}
                            AddFolderAllowed={false}
                          />
                        </SimpleItem>
                      }
                      {selectedFile?.dataSourceType === DataSourceType.ArcGIS &&
                        <SimpleItem colSpan={9}>
                          <TextBox
                            label={t("url")}
                            labelMode='floating'
                            className="modalInput"
                            value={selectedFile.fileAddress}
                            onValueChange={(e) => onChangeFile("fileAddress", e)}
                          >
                          </TextBox>
                        </SimpleItem>

                      }
                      {(selectedFile?.dataSourceType === DataSourceType.ArcGIS || (selectedFile.dataSourceType === DataSourceType.Excel && selectedFile.fileAddress !== undefined)) &&
                        <SimpleItem colSpan={15}>
                          <div className="rightColumn" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                            <Button
                              onClick={onAdd}
                              icon="fa-solid fa-circle-plus"
                              text={t("add")}
                            />
                          </div>
                        </SimpleItem>
                      }

                    </Form>
                    <DataGrid
                      id="gridContainer"
                      ref={dataGridLoadDataRef}
                      dataSource={lstFileAddress}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      selection={{ mode: 'single' }}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      onExporting={OnExporting}
                    >
                      <Export enabled={true} allowExportSelectedData={true} />
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />
                      <FilterRow visible={true}
                        applyFilter="auto" />
                      <HeaderFilter visible={true} />
                      <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />
                      <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                      >
                        <Search enabled />
                      </ColumnChooser>

                      <SortByGroupSummaryInfo
                        summaryItem="Total Count"
                        sortOrder="desc"
                      />
                      <Summary>
                        <GroupItem
                          summaryType="count"
                          alignByColumn
                          name="Total Count"
                        />
                      </Summary>
                      <Column dataField="fieldDataSourceId" caption={t("fieldDataSource")} visible={true}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                        <Lookup
                          dataSource={SortObjectByPropName(fieldsData, "dataSourceName")}
                          valueExpr="id"
                          displayExpr="dataSourceName"
                        />
                      </Column>
                      <Column dataField="fileAddress" caption={t("path/url")} visible={true}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                    </DataGrid>
                    <Button
                      onClick={() => startImport()}
                      className='tes-modal-btn-add rightColumn'
                      text={t('next')}
                      style={{ marginTop: "1rem" }}
                    />
                  </div>}
                {selectedStep === 2 &&
                  <div>
                    <Form>
                      <h1>Step2</h1>
                    </Form>
                    <Button
                      // onClick={() => startImport()}
                      className='tes-modal-btn-add rightColumn'
                      text={t('start')}
                    />
                    <Button
                      onClick={() => setSelectedStep(1)}
                      className='tes-modal-btn-add rightColumn'
                      text={t('back')}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        </React.Fragment >
      </div>
    </Permission>
  );
};
export default LoadDatas;