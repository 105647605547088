import { ITesBase } from "../../general/generalInterfaces";
import { PCSPatternType } from "../../trafficStudy/enums/trafficStudyEnums";
import { CountStationType } from "../enums/infrastructureEnums";
import { AdjustmentFactor } from "../infrastructureTypes";

export class CountStationDTO implements ITesBase {
  id!: string;
  customerId!: string;
  divisionId?: string;
  locationId?: string;
  name!: string;
  description!: string;
  installationDate!: Date;
  lhrs!: number;
  offset!: number;
  longitude!: number;
  latitude!: number;
  aadtPatternId?: string;
  patternType!: PCSPatternType;
  countStationType!: CountStationType;
  general!: any;
  isDeleted!: boolean;
  adjustmentFactors!: AdjustmentFactor[];
}
