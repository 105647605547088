import React, { useEffect, useState } from 'react';
import { Form, SimpleItem } from 'devextreme-react/form';
import { SelectBox, TagBox } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import { AllWayStopMinimumVolumeWarrant, AllWayStopWarrants, StudyBaseDTO } from '../../../../../../../types/trafficStudy/dtos/studyDto';
import { NameValueNumber } from '../../../../../../../types/general/generalTypes';
import CollapsibleCard from '../../../../../../../components/collapsibleCard/collapsibleCard';
import { SortObjectByPropName } from '../../../../../../../utils/arrayTools';

// props
interface IPros {
  allWayStopWarrants: AllWayStopWarrants
  isLocked: boolean
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>
  initDataBase: StudyBaseDTO,


}
const CollisionExperience = (props: IPros) => {
  const { t } = useTranslation();
  const [lstYears, setLstYears] = useState<NameValueNumber[]>([]);
  const labelStyle = { fontSize: "16px", fontWeight: 500, marginTop: "1rem" }
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    var yearRes: NameValueNumber[] = [];
    for (let year = new Date().getFullYear(); year > 1980; year--) {
      let newYew: NameValueNumber = {
        name: year.toString(),
        value: year
      }
      yearRes.push(newYew)
    }
    setLstYears(yearRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onValueChange(name: string, value: any) {
    props.setInitDataBase({
      ...props.initDataBase,
      allWayStopWarrantConfigChanged: true,
      allWayStopWarrants: {
        ...props.initDataBase.allWayStopWarrants,
        collisionExperience: { ...props.initDataBase.allWayStopWarrants.collisionExperience, [name]: value }
      }
    })
  }


  return (
    <div className={`studyDetails-allWay-collision ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>
        <div className='row' style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={4}>
            <SimpleItem colSpan={2}>
              <div className='leftColumn' style={{ margin: " 0 0 -3rem -0.75rem" }}>
                {props.initDataBase.allWayStopWarrants.collisionExperience.collisionRequirementLocalCollectorRuralArterialFillFilled ?
                  (<p id="period" style={{ fontWeight: "bold", fontSize: 16 }}>
                    <span>{t("justifiedCollisionLocal")}</span>
                  </p>) :
                  (<p id="period" style={{ fontWeight: "bold", fontSize: 16 }}>
                    <span>{t("notJustifiedCollisionLocal")}</span>
                  </p>)
                }
              </div>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <div className='leftColumn' style={{ marginBottom: "-3rem" }}>
                {props.initDataBase.allWayStopWarrants.collisionExperience.collisionRequirementUrbanArterialFillFilled ?
                  (<p id="period" style={{ fontWeight: "bold", fontSize: 16, marginRight: "2rem" }}>
                    <span>{t("justifiedCollisionArterial")}</span>
                  </p>) :
                  (<p id="period" style={{ fontWeight: "bold", fontSize: 16 }}>
                    <span>{t("notJustifiedCollisionArterial")}</span>
                  </p>)
                }
              </div>
            </SimpleItem>
            <SimpleItem colSpan={4}>
              <div id="period" style={{ fontWeight: "bold", fontSize: 16, marginTop: "1rem" }}>{t('configsRelatedToPeriodSelection')}</div>
            </SimpleItem>
            <SimpleItem colSpan={4}>
              <div id="configurationsCollapse">
                <CollapsibleCard isOpen={false}>
                  <TagBox
                    label={t("years")}
                    labelMode='floating'
                    className="modalInput"
                    dataSource={SortObjectByPropName(lstYears, "name")}
                    value={props.allWayStopWarrants?.collisionExperience?.years}
                    valueExpr="value"
                    displayExpr="name"
                    onValueChange={(e) => onValueChange("years", e)}
                    disabled={props.isLocked}
                  />
                </CollapsibleCard>
              </div>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <label style={labelStyle} className="box-item orange">{t("NoOfCollisions")}: {props.allWayStopWarrants?.collisionExperience?.collisionNo ?? 0}</label>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <label style={labelStyle} className="box-item orange">{t("annualAverage")}: {props.allWayStopWarrants?.collisionExperience?.annualCollisionAverage ?? 0}</label>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <label style={labelStyle} className="box-item orange">{t("collisionRequirementArterialFulfilled")}: {props.allWayStopWarrants?.collisionExperience?.urbanArterialCollisionNo ?? 0}</label>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <label style={labelStyle} className="box-item orange">{t("collisionRequirementCollectorLocalFulfilled")}: {props.allWayStopWarrants?.collisionExperience?.localCollectorRuralArterialCollisionNo ?? 0}</label>
            </SimpleItem>
          </Form>
        </div>
      </React.Fragment>
    </div>
  );


}
export default CollisionExperience;