import { Button, DataGrid, Form, NumberBox, TextBox } from "devextreme-react";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ShareURL from "../../../../components/shareURL/shareURL";
import { useAuth } from "../../../../contexts/auth";
import {
  FieldApiUrl,
  TrafficApiUrl,
} from "../../../../environment/routeSettings";
import { ResponseCode, TesMapModule } from "../../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequest,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import {
  DetailedMMSRes,
  IMMSParams,
  MmsRequest,
  MmsRes,
  TesCodeValue,
} from "../../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import Results from "./components/results/results";
import Smoothing from "./components/smoothing/smoothing";
import tabTitles from "./data";
import CollapsibleCard from "../../../../components/collapsibleCard/collapsibleCard";
import { SimpleItem } from "devextreme-react/form";
import { DataGridRef } from "devextreme-react/cjs/data-grid";
import { GisMap } from "./components/gisMap";
import Permission from "../../../../components/permission/permision";
import TrafficStudyPermissions from "../../../../constants/Permissions/trafficStudyPermissions";

const MMSDetails = () => {
  const [initDataSetting, setInitDataSetting] = useState<MmsRequest>(
    new MmsRequest()
  );
  const [resGridData, setResGridData] = useState<[]>([]);
  const [smoothGridData, setSmoothGridData] = useState<DetailedMMSRes[]>([]);
  const [fieldsDataChanged, setFieldsDataChanged] = useState(false);
  const params = useParams<IMMSParams>();
  const [isLocked, setIsLocked] = useState(true);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const didMount = useRef(false);
  const history = useNavigate();
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dataGridResRef = useRef<DataGridRef<any, any>>(null);
  const dataGridSmoothingRef = useRef<DataGridRef<any, any>>(null);
  const [initDataTesCodeValues, setInitDataTesCodeValues] =
    useState<TesCodeValue>(new TesCodeValue());

  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          if (params.mmsId !== "AddNew") {
            await GetInitDataSetting();
            // await GetInitDataResult();
            await GetMmsGroupedByRin();
            await getInfrastructureTesCodeValues();
          } else {
            setIsLocked(false);
          }
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInfrastructureTesCodeValues() {
    setInitDataTesCodeValues(
      await TesGet(
        FieldApiUrl() +
        "/api/codeValues/infrastructureTesCodeValues/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function GetInitDataSetting() {
    try {
      const res = (await TesGet(
        TrafficApiUrl() + "/api/Mms/GetRequestById/" + params.mmsId,
        true
      )) as RequestResponseResult<MmsRequest>;
      if (res.responseCode === ResponseCode.OK) {
        setInitDataSetting(res.results);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function GetMmsGroupedByRin() {
    try {
      const res = (await TesGet(
        TrafficApiUrl() + "/api/Mms/GetMmsGroupedByRin/" + params.mmsId,
        true
      )) as RequestResponseResult<DetailedMMSRes[]>;
      if (res.responseCode === ResponseCode.OK) {
        setSmoothGridData(res.results);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }



  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function onRun() {
    try {
      if (activeLoading) activeLoading(true);

      const url =
        TrafficApiUrl() +
        (params.mmsId === "AddNew"
          ? "/api/Mms/AddRequest"
          : "/api/Mms/UpdateRequest");

      const res = (await TesPost(
        url,
        {
          ...initDataSetting,
          customerId: localStorage.getItem("selectedCustomerId"),
          divisionId: localStorage.getItem("selectedDivisionId"),
        },
        true
      )) as RequestResponseResult<any>;

      if (activeLoading) activeLoading(false);

      if (res.responseCode === ResponseCode.OK) {
        setFieldsDataChanged(false);
        notify(t("analysisMessage"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }

      setFieldsDataChanged(false);
      history(-1);
    } catch (error) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function goBackPermission() {
    if (fieldsDataChanged) {
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                onRun();
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  function onSettingValueChange(key: string, value: any) {
    setInitDataSetting({
      ...initDataSetting,
      [key]: value,
    });
    setFieldsDataChanged(true);
  }

  return (
    <Permission allowed={[TrafficStudyPermissions.TrafficStudy_V_MMS, TrafficStudyPermissions.TrafficStudy_D_MMS]} hasFeedBackElement={true}>
      <h2 className={"content-block"}>{t("mmsDetails")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card"}>
          <div className="row" style={{ marginTop: 15 }}>
            <div className="leftColumn">
              <Button
                onClick={goBackPermission}
                icon="fa-solid fa-arrow-left"
                hint={t("goBack")}
              />
            </div>
            <Permission allowed={[TrafficStudyPermissions.TrafficStudy_D_MMS]} hasFeedBackElement={true}>
              <div className="rightColumn">
                {!isLocked && (
                  <Button
                    onClick={onRun}
                    icon="fa-solid fa-play"
                    hint={t("run")}
                  />
                )}
                {params.mmsId !== "AddNew" && <ShareURL url={url} />}
                {params.mmsId !== "AddNew" && (
                  <Button
                    onClick={() => LockHandler()}
                    icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                )}
              </div>
            </Permission>
          </div>
        </div>
        <CollapsibleCard title={t("details")}>
          <Form colCount={9}>
            <SimpleItem colSpan={3}>
              <TextBox
                placeholder=""
                label={t("name")}
                labelMode="floating"
                value={initDataSetting.name}
                onValueChange={(e) => onSettingValueChange("name", e)}
                disabled={isLocked}
                showClearButton={true}
                style={{ margin: "-0.75rem 0 0 0" }}
              />
            </SimpleItem>
            <SimpleItem colSpan={3}>
              <TextBox
                placeholder=""
                label={t("description")}
                labelMode="floating"
                value={initDataSetting.description}
                onValueChange={(e) => onSettingValueChange("description", e)}
                disabled={isLocked}
                showClearButton={true}
                style={{ margin: "-0.75rem 0 0 0" }}
              />
            </SimpleItem>
            <SimpleItem colSpan={3}>
              <NumberBox
                label={t("year")}
                labelMode="floating"
                value={initDataSetting.year}
                onValueChange={(e) => onSettingValueChange("year", e)}
                disabled={isLocked}
                style={{ margin: "-0.75rem 0 0 0" }}
              />
            </SimpleItem>
          </Form>
        </CollapsibleCard>
        <div className={"dx-card "}>
          <Tabs
            width={"100%"}
            dataSource={tabTitles}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />
          {selectedIndex === 0 && (
            <Results
              mmsProjectId={params.mmsId!}
              // resGridData={resGridData}
              // dataGridResRef={dataGridResRef}
              initDataTesCodeValues={initDataTesCodeValues}
            />
          )}
          {selectedIndex === 1 && (
            <Smoothing
              smoothGridData={smoothGridData}
              GetMmsGroupedByRin={GetMmsGroupedByRin}
            />
          )}
          {selectedIndex === 2 && (
            <GisMap
              smoothGridData={smoothGridData}
              tesModule={TesMapModule.Infrastructure}
            />
          )}

        </div>
      </div>
    </Permission>
  );
};

export default MMSDetails;
