import { useEffect, useState } from "react";
import { NumberBox, TextBox, Switch, SelectBox } from "devextreme-react";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { t } from "i18next";
import React from "react";
import { IdentityGeneralSetting, SignOnMethod } from "../../../../../types/identity/identityTypes";
import { TesGet, TesPost } from "../../../../../utils/rest";
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { AuthApiUrl } from "../../../../../environment/routeSettings";
import { NameValue } from "../../../../../types/general/generalTypes";
import { Enum2Array } from "../../../../../utils/enumTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { DivisionSystem } from "../../../../../types/identity/enums/identityEnums";
interface IProps {
  clientId: string | undefined
}
const Identity = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<IdentityGeneralSetting>(new IdentityGeneralSetting());
  const [lstSignOnMethod, setLstSignOnMethod] = useState<NameValue[]>([]);
  const [lstDivisionSystem, setLstDivisionSystem] = useState<NameValue[]>([]);
  const lstPassExpression: NameValue[] = [
    { name: 'At least 8 char, uppercase, lowercase, number and specials', value: '/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$/' },
    { name: 'At least 12 char, uppercase, lowercase, number, and specials', value: '/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{12,}$/' },
    { name: 'At least 16 char, uppercase, lowercase, number, and specials', value: '/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{16,}$/' },
  ];

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstSignOnMethod(Enum2Array(SignOnMethod));
        setLstDivisionSystem(Enum2Array(DivisionSystem));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        AuthApiUrl() +
        "/api/GeneralSettings/" +
        props.clientId,
        true
      )
    );
  }

  async function onSave() {
    try {
      const postObj = {
        ...initData,
        customerId: props.clientId,
      };
      if (activeLoading) activeLoading(true);
      await TesPost(
        AuthApiUrl() + "/api/GeneralSettings/AddUpdateGeneralSettings",
        postObj,
        true
      );
      await getInitData();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  const onValueChanged = (key: string, value: any) => {
    setInitData({ ...initData, [key]: value });
  };

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2} className='popupFields'>
          <SimpleItem colSpan={1}>
            <SelectBox
              placeholder=""
              label={t('passwordExpression')}
              valueExpr="value"
              displayExpr="name"
              labelMode='floating'
              value={initData?.passwordExpression}
              onValueChange={(e) => onValueChanged("passwordExpression", e)}
              className="modalInput"
              items={lstPassExpression}
              showClearButton={true}
              searchEnabled={true}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              step={0}
              label={t("passwordExpiryDays")}
              labelMode='floating'
              value={initData?.passwordExpiryDays}
              onValueChange={(e) => onValueChanged("passwordExpiryDays", e)}
              className="modalInput"
              style={{ marginBottom: "1rem" }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <SelectBox
              placeholder=""
              label={t('defaultSignOnMethod')}
              valueExpr="value"
              displayExpr="name"
              labelMode='floating'
              value={initData?.defaultSignOnMethod}
              onValueChange={(e) => onValueChanged("defaultSignOnMethod", e)}
              className="modalInput"
              items={SortObjectByPropName(lstSignOnMethod, "name")}
              showClearButton={true}
              searchEnabled={true}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <SelectBox
              placeholder=""
              label={t('divisionSystem')}
              valueExpr="value"
              displayExpr="name"
              labelMode='floating'
              value={initData?.divisionSystem}
              onValueChange={(e) => onValueChanged("divisionSystem", e)}
              className="modalInput"
              items={SortObjectByPropName(lstDivisionSystem, "name")}
              showClearButton={true}
              searchEnabled={true}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <div>{t("twoFactorEnabled")}</div>
            <Switch
              value={initData?.twoFactorEnabled}
              onValueChange={(e) => onValueChanged("twoFactorEnabled", e)}
              hint={t("isAmPm")}
              style={{ marginBottom: "1rem" }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <div>{t("divisionRoleEnabled")}</div>
            <Switch
              style={{ marginTop: 5, fontWeight: "bold" }}
              value={initData?.divisionRoleEnabled}
              onValueChange={(e) => onValueChanged("divisionRoleEnabled", e)}
              hint={t("isAmPm")}
            />
          </SimpleItem>
        </Form>
        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Identity;