export const pedestrianDelayDataSource = [
  {
    netPedestrianDelay: 300,
    netPedestrianVolume:0,
  }, {
    netPedestrianDelay: 300,
    netPedestrianVolume: 200,
  }, {
    netPedestrianDelay: 130,
    netPedestrianVolume: 200.001,
  }, {
    netPedestrianDelay: 75,
    netPedestrianVolume: 300,
  }, {
    netPedestrianDelay: 75,
    netPedestrianVolume: 1000,
  }];

export const pedestrianVolumeDataSource = [
  {
    eightHourVehicularVolume: 0,
    netPedestrianVolume: 1400,
  }, {
    eightHourVehicularVolume: 1440,
    netPedestrianVolume: 1400,
  }, {
    eightHourVehicularVolume: 1441,
    netPedestrianVolume: 1002,
  },
  {eightHourVehicularVolume: 2650, netPedestrianVolume: 483.325},
  {eightHourVehicularVolume: 2700, netPedestrianVolume: 478.700},
  {eightHourVehicularVolume: 2750, netPedestrianVolume: 474.125},
  {eightHourVehicularVolume: 2800, netPedestrianVolume: 469.6},
  {eightHourVehicularVolume: 2850, netPedestrianVolume: 465.125},
  {eightHourVehicularVolume: 2900, netPedestrianVolume: 460.700},
  {eightHourVehicularVolume: 2950, netPedestrianVolume: 456.325},
  {eightHourVehicularVolume: 3000, netPedestrianVolume: 452},
  {eightHourVehicularVolume: 3050, netPedestrianVolume: 447.725},
  {eightHourVehicularVolume: 3100, netPedestrianVolume: 443.5},
  {eightHourVehicularVolume: 3150, netPedestrianVolume: 439.325},
  {eightHourVehicularVolume: 3200, netPedestrianVolume: 435.2},
  {eightHourVehicularVolume: 3250, netPedestrianVolume: 431.125},
  {eightHourVehicularVolume: 3300, netPedestrianVolume: 427.1},
  {eightHourVehicularVolume: 3350, netPedestrianVolume: 423.125},
  {eightHourVehicularVolume: 3400, netPedestrianVolume: 419.2},
  {eightHourVehicularVolume: 3450, netPedestrianVolume: 415.325},
  {eightHourVehicularVolume: 3500, netPedestrianVolume: 411.5},
  {eightHourVehicularVolume: 3550, netPedestrianVolume: 407.725},
  {eightHourVehicularVolume: 3600, netPedestrianVolume: 404},
  {eightHourVehicularVolume: 3650, netPedestrianVolume: 400.325},
  {eightHourVehicularVolume: 3700, netPedestrianVolume: 396.7},
  {eightHourVehicularVolume: 3750, netPedestrianVolume: 393.125},
  {eightHourVehicularVolume: 3800, netPedestrianVolume: 389.6},
  {eightHourVehicularVolume: 3850, netPedestrianVolume: 386.125},
  {eightHourVehicularVolume: 3900, netPedestrianVolume: 382.7},
  {eightHourVehicularVolume: 3950, netPedestrianVolume: 379.325},
  {eightHourVehicularVolume: 4000, netPedestrianVolume: 376},
  {eightHourVehicularVolume: 4050, netPedestrianVolume: 372.725},
  {eightHourVehicularVolume: 4100, netPedestrianVolume: 369.5},
  {eightHourVehicularVolume: 4150, netPedestrianVolume: 366.325},
  {eightHourVehicularVolume: 4200, netPedestrianVolume: 363.2},
  {eightHourVehicularVolume: 4250, netPedestrianVolume: 360.125},
  {eightHourVehicularVolume: 4300, netPedestrianVolume: 357.1},
  {eightHourVehicularVolume: 4350, netPedestrianVolume: 354.125},
  {eightHourVehicularVolume: 4400, netPedestrianVolume: 351.2},
  {eightHourVehicularVolume: 4450, netPedestrianVolume: 348.325},
  {eightHourVehicularVolume: 4500, netPedestrianVolume: 345.5},
  {eightHourVehicularVolume: 4550, netPedestrianVolume: 342.725},
  {eightHourVehicularVolume: 4600, netPedestrianVolume: 340},
  {eightHourVehicularVolume: 4650, netPedestrianVolume: 337.325},
  {eightHourVehicularVolume: 4700, netPedestrianVolume: 334.7},
  {eightHourVehicularVolume: 4750, netPedestrianVolume: 332.125},
  {eightHourVehicularVolume: 4800, netPedestrianVolume: 329.6},
  {eightHourVehicularVolume: 4850, netPedestrianVolume: 327.125},
  {eightHourVehicularVolume: 4900, netPedestrianVolume: 324.7},
  {eightHourVehicularVolume: 4951, netPedestrianVolume: 322.325},
  {eightHourVehicularVolume: 5000, netPedestrianVolume: 320},
  {eightHourVehicularVolume: 5050, netPedestrianVolume: 317.725},
  {eightHourVehicularVolume: 5100, netPedestrianVolume: 315.5},
  {eightHourVehicularVolume: 5150, netPedestrianVolume: 313.325},
  {eightHourVehicularVolume: 5200, netPedestrianVolume: 311.2},
  {eightHourVehicularVolume: 5250, netPedestrianVolume: 309.125},
  {eightHourVehicularVolume: 5300, netPedestrianVolume: 307.1},
  {eightHourVehicularVolume: 5350, netPedestrianVolume: 305.125},
  {eightHourVehicularVolume: 5400, netPedestrianVolume: 303.2},
  {eightHourVehicularVolume: 5450, netPedestrianVolume: 301.325},
  {eightHourVehicularVolume: 5500, netPedestrianVolume: 299.5},
  {eightHourVehicularVolume: 5550, netPedestrianVolume: 297.725},
  {eightHourVehicularVolume: 5600, netPedestrianVolume: 296},
  {eightHourVehicularVolume: 5650, netPedestrianVolume: 294.325},
  {eightHourVehicularVolume: 5700, netPedestrianVolume: 292.7},
  {eightHourVehicularVolume: 5750, netPedestrianVolume: 291.125},
  {eightHourVehicularVolume: 5800, netPedestrianVolume: 289.6},
  {eightHourVehicularVolume: 5850, netPedestrianVolume: 288.125},
  {eightHourVehicularVolume: 5900, netPedestrianVolume: 286.7},
  {eightHourVehicularVolume: 5950, netPedestrianVolume: 285.325},
  {eightHourVehicularVolume: 6000, netPedestrianVolume: 284},
  {eightHourVehicularVolume: 6050, netPedestrianVolume: 282.725},
  {eightHourVehicularVolume: 6100, netPedestrianVolume: 281.5},
  {eightHourVehicularVolume: 6150, netPedestrianVolume: 280.325},
  {eightHourVehicularVolume: 6200, netPedestrianVolume: 279.2},
  {eightHourVehicularVolume: 6250, netPedestrianVolume: 278.125},
  {eightHourVehicularVolume: 6300, netPedestrianVolume: 277.1},
  {eightHourVehicularVolume: 6350, netPedestrianVolume: 276.125},
  {eightHourVehicularVolume: 6400, netPedestrianVolume: 275.2},
  {eightHourVehicularVolume: 6450, netPedestrianVolume: 274.325},
  {eightHourVehicularVolume: 6500, netPedestrianVolume: 273.5},
  {eightHourVehicularVolume: 6550, netPedestrianVolume: 272.725},
  {eightHourVehicularVolume: 6600, netPedestrianVolume: 272},
  {eightHourVehicularVolume: 6650, netPedestrianVolume: 271.325},
  {eightHourVehicularVolume: 6700, netPedestrianVolume: 270.7},
  {eightHourVehicularVolume: 6750, netPedestrianVolume: 270.125},
  {eightHourVehicularVolume: 6800, netPedestrianVolume: 269.6},
  {eightHourVehicularVolume: 6850, netPedestrianVolume: 269.125},
  {eightHourVehicularVolume: 6900, netPedestrianVolume: 268.7},
  {eightHourVehicularVolume: 6950, netPedestrianVolume: 268.325},
  {eightHourVehicularVolume: 7000, netPedestrianVolume: 268}
  , {
    eightHourVehicularVolume: 15000,
    netPedestrianVolume: 199,
  }
];