import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../types/field/enums/fieldEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";
import { StudyType } from "../../../types/trafficStudy/enums/trafficStudyEnums";

export const useLatestVolumeStudyLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "VolumeStudyLayer")) {
          removeLayer(map, "VolumeStudyLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "VolumeStudyLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "VolumeStudyLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);
      loadModules([
        "esri/layers/FeatureLayer",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/renderers/UniqueValueRenderer",
        "esri/core/reactiveUtils",
      ]).then(
        ([
          FeatureLayer,
          Graphic,
          webMercatorUtils,
          UniqueValueRenderer,
          reactiveUtils,
        ]) => {
          if (isCancelled.current) {
            setIsLayerLoading(false);
            isUnderConstruction.current = false;
            return;
          }

          const midblockLocations = lstStudyLocations.filter(
            (loc) =>
              loc.locationType.toString() === LocationType.Midblock.toString()
          );

          if (midblockLocations.length === 0) return;

          let queryIds = generateQueryIds(
            initDataGis,
            "midblockGeoIdType",
            midblockLocations
          );

          const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

          const roadSegmentQuery = {
            outFields: ["*"],
            where: strRoadSegmentQuery,
            returnGeometry: true,
          };

          function queryingAndCreateLayer() {
            setIsLayerLoading(true);
            isUnderConstruction.current = true;
            view?.whenLayerView(roadSegmentLayer).then(function (layerView) {
              reactiveUtils
                .whenOnce(() => !layerView.updating)
                .then(() => {
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  layerView
                    .queryFeatures(roadSegmentQuery)
                    .then(function (results) {
                      console.log("!!length", results.features.length);
                      if (isCancelled.current || !isTheLastMovement.current) {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                        return;
                      }
                      const lstNewRoadSegmentGraphics = results.features
                        .filter(
                          (feature) => feature.geometry?.paths?.length > 0
                        )
                        .map((place) => {
                          const paths = place.geometry.paths[0];
                          const [avgX, avgY] = getAveragePaths(paths);
                          // Convert to Longitude/Latitude and back to Web Mercator coordinates
                          const [lng, lat] = webMercatorUtils.xyToLngLat(
                            avgX,
                            avgY
                          );
                          const [x, y] = webMercatorUtils.lngLatToXY(lng, lat);

                          // Find matching location's average speed
                          const matchingLocation = lstStudyLocations.find(
                            (loc) =>
                              loc.geoId.toString() ===
                              place.attributes[
                                initDataGis.midblockGeoIdName
                              ].toString()
                          );

                          return new Graphic({
                            attributes: {
                              [initDataGis.midblockGeoIdName]:
                                place.attributes[
                                  initDataGis.midblockGeoIdName
                                ].toString(),
                              northApproachVolume:
                                matchingLocation?.volumeSummary[
                                  "northApproachVolume"
                                ].toString(),
                              southApproachVolume:
                                matchingLocation?.volumeSummary[
                                  "southApproachVolume"
                                ].toString(),
                              westApproachVolume:
                                matchingLocation?.volumeSummary[
                                  "westApproachVolume"
                                ].toString(),
                              eastApproachVolume:
                                matchingLocation?.volumeSummary[
                                  "eastApproachVolume"
                                ].toString(),
                              totalVolume:
                                matchingLocation?.volumeSummary[
                                  "totalVolume"
                                ].toString(),
                              studyType: matchingLocation?.studyType.toString(),
                            },
                            geometry: {
                              type: "point",
                              x,
                              y,
                              spatialReference: {
                                wkid: place.geometry?.spatialReference?.wkid,
                              },
                            },
                          });
                        });

                      const renderer = new UniqueValueRenderer({
                        field: "studyType",
                        uniqueValueInfos: [
                          {
                            value: StudyType.Volume,
                            symbol: {
                              type: "picture-marker",
                              url: "http://staticfile.tes.ca/gisMap/latestStudies/car.png",
                              width: "30px",
                              height: "30px",
                            },
                          },
                        ],
                      });

                      const volumeStudyLayer = new FeatureLayer({
                        id: "VolumeStudyLayer",
                        title: "Volume Study",
                        source: lstNewRoadSegmentGraphics,
                        objectIdField: "ObjectId",
                        outFields: ["*"],
                        fields: [
                          {
                            alias: initDataGis.midblockGeoIdName,
                            defaultValue: null,
                            editable: true,
                            length: -1,
                            name: initDataGis.midblockGeoIdName,
                            nullable: false,
                            type: "string",
                          },
                          {
                            alias: "study Type",
                            defaultValue: null,
                            editable: true,
                            length: -1,
                            name: "studyType",
                            nullable: true,
                            // type: "integer",
                            type: "string",
                          },
                          {
                            alias: "North Approach Volume",
                            defaultValue: null,
                            editable: true,
                            length: -1,
                            name: "northApproachVolume",
                            nullable: true,
                            // type: "integer",
                            type: "string",
                          },
                          {
                            alias: "South Approach Volume",
                            defaultValue: null,
                            editable: true,
                            length: -1,
                            name: "southApproachVolume",
                            nullable: true,
                            // type: "integer",
                            type: "string",
                          },
                          {
                            alias: "East Approach Volume",
                            defaultValue: null,
                            editable: true,
                            length: -1,
                            name: "eastApproachVolume",
                            nullable: true,
                            // type: "integer",
                            type: "string",
                          },
                          {
                            alias: "West Approach Volume",
                            defaultValue: null,
                            editable: true,
                            length: -1,
                            name: "westApproachVolume",
                            nullable: true,
                            // type: "integer",
                            type: "string",
                          },
                          {
                            alias: "Total Volume",
                            defaultValue: null,
                            editable: true,
                            length: -1,
                            name: "totalVolume",
                            nullable: true,
                            // type: "integer",
                            type: "string",
                          },
                        ],
                        // popupTemplates can still be viewed on
                        // individual features
                        popupTemplate: {
                          title: "Latest Volume Study",
                          content: [
                            {
                              type: "fields",
                              fieldInfos: [
                                {
                                  fieldName: initDataGis.midblockGeoIdName,
                                  label: "Geo ID",
                                },
                                // {
                                //     fieldName: "studyType",
                                //     label: "Study Type"
                                // },
                                {
                                  fieldName: "northApproachVolume",
                                  label: "North Approach Volume",
                                },
                                {
                                  fieldName: "southApproachVolume",
                                  label: "South Approach Volume",
                                },
                                {
                                  fieldName: "eastApproachVolume",
                                  label: "East Approach Volume",
                                },
                                {
                                  fieldName: "westApproachVolume",
                                  label: "West Approach Volume",
                                },
                                {
                                  fieldName: "totalVolume",
                                  label: "Total Volume",
                                },
                              ],
                            },
                          ],
                        },
                        renderer: renderer,
                      });

                      volumeStudyLayer.load().then(() => {
                        if (isCancelled.current || !isTheLastMovement.current) {
                          setIsLayerLoading(false);
                          isUnderConstruction.current = false;
                          return;
                        }
                        removeLayer(map, "VolumeStudyLayer");
                        map.add(volumeStudyLayer);
                        legendExpand.expanded = false;
                      });

                      layerSuccessfullyLoadedInDomCallback(
                        view,
                        volumeStudyLayer,
                        () => {
                          setIsLayerLoading(false);
                          isUnderConstruction.current = false;
                        }
                      );
                    });
                });
            });
          }
          view.when(() => {
            if (isCancelled.current) {
              setIsLayerLoading(false);
              return;
            }
            if (
              isLayerExist(map, "VolumeStudyLayer") ||
              isUnderConstruction.current
            ) {
              return;
            }
            queryingAndCreateLayer();
          });
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              if (isLayerExist(map, "VolumeStudyLayer")) {
                isTheLastMovement.current = false;
              }
            },
            1
          );
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              isTheLastMovement.current = true;
              if (!isCancelled.current && !isUnderConstruction.current)
                queryingAndCreateLayer();
            },
            700
          );
        }
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isLatestVolumeStudyLayerLoading: isLayerLoading,
  };
};
