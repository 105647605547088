
import React, { useEffect, useState } from "react";
import './collapsibleCard.scss';
import { Button } from 'devextreme-react/button';

interface IPros {
    children?: any,
    isOpen?: boolean,
    title?: string
    compact?: string; // condensedGrid, textCompact, dashboard
}
const CollapsibleCard = (props: IPros) => {
    const [toggle, setToggle] = useState(props.isOpen===undefined?true:props.isOpen);
    const [compactMode, setCompactMode] = useState<string>("")

    useEffect(()=> {
        if (props.compact === "condensedGrid") {
            setCompactMode("condensedGrid")
        } else if (props.compact === "textCompact") {
            setCompactMode("textCompact")
        } else {
            setCompactMode("content-open")
        }
    })
    
    

 if (props.compact !== "dashboard") {
        return (
        <div className="collapsible-card">
            <div
                className={!props.compact ? "header" : ""}
                onClick={() => {
                    setToggle(prev => {
                        return !prev;
                    });
                }}
            >
                <div className={!props.compact ? "headerTitle" : "headerTitleCompacted"}>
                    {props.title}
                </div>
                <div className="headerIcon">
                    {toggle ?
                        <Button icon="fa-solid fa-chevron-up" />
                        :
                        <Button icon="fa-solid fa-chevron-down" />
                    }
                </div>
            </div>
            <div className={toggle ? compactMode : "content-close"}>
                {props.children}
            </div>
        </div>
    )
 } else return (
    <div className="dashboard-collapsible-card">
        <div
            className="dashboard-header"
            onClick={() => {
                setToggle(prev => {
                    return !prev;
                });
            }}
        >
            <div className="dashboard-headerTitle">
                {props.title}
            </div>
            <div className="dashboard-headerIcon">
                {toggle ?
                    <Button icon="fa-solid fa-chevron-up" />
                    :
                    <Button icon="fa-solid fa-chevron-down" />
                }
            </div>
        </div>
        <div className={toggle ? compactMode : "content-close"}>
            {props.children}
        </div>
    </div>
 )

}

export default CollapsibleCard;