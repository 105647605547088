import { Button, DateBox, Form, ScrollView } from 'devextreme-react';
import { SimpleItem } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { t } from 'i18next';
import { useState } from 'react';
import { useAuth } from '../../contexts/auth';
import { useClientSetting } from '../../contexts/clientSetting';
import { TrafficApiUrl } from '../../environment/routeSettings';
import { ResponseCode } from '../../types/general/enums/generalEnums';
import { SetRawDataDate } from '../../types/trafficStudy/trafficStudyTypes';
import { CreateDateAsUTC } from '../../utils/dateTimeTools';
import { RequestErrorHandling, TesPost } from '../../utils/rest';

interface IProps {
    arrangement: string[]; // [top (in rem), right(in rem), bottom(in rem), left(in rem)]
    studyId: string;
}

const RowDataManager = (props: IProps) => {
    const { activeLoading } = useAuth();
    const [showModal, setShowModal] = useState(false);
    const [showDatePopup, setShowDatePopup] = useState(false);
    const { generalSetting } = useClientSetting();

    const [data, setData] = useState<SetRawDataDate>(new SetRawDataDate)

    const buttonStyle = {
        position: 'absolute',
        top: props.arrangement[0],
        right: props.arrangement[1],
        bottom: props.arrangement[2],
        left: props.arrangement[3],
        cursor: 'pointer',
        zIndex: 1000,
    };

    function onCloseModal() {
        setShowModal(false);
        setData(new SetRawDataDate)
    };

    function onCloseDatePopup() {
        setShowDatePopup(false);
    };

    async function onSave() {
        try {
            if (activeLoading) activeLoading(true);
            const res = (await (TesPost(TrafficApiUrl() + "/api/RawDataTools/UpdateRawDataDate", {...data, rawDataDate: CreateDateAsUTC(data.rawDataDate)}, true)))
            if (res.responseCode === ResponseCode.OK) {
                if (activeLoading) activeLoading(false);
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
                // onCloseDatePopup()
                window.location.reload();
            } else if (res.responseCode === ResponseCode.RawDataHasDuplicateDateTime) {
                notify(t("rawDataHasDuplicateDateTime"), "error", 5000);
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    return (
   
        <div style={{ position: 'relative' }}>
            <Button
                style={buttonStyle}
                className='tes-modal-btn-add'
                onClick={() => setShowModal(true)}
                icon="fa-solid fa-sliders"
                hint={t('rawDataManager')}
            />
            <Popup
                showCloseButton={true}
                visible={showModal}
                onHiding={onCloseModal}
                title={t('rawDataManager')}
                width={"40%"}
                height={"40%"}
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={3}>
                        <SimpleItem colSpan={1}>
                            <Button
                                onClick={() => setShowDatePopup(true)}
                                text={t('changeDate')}
                            />
                        </SimpleItem>
                    </Form>
                </ScrollView>
            </Popup>
            <Popup
                showCloseButton={true}
                visible={showDatePopup}
                onHiding={onCloseDatePopup}
                title={t('selectADay')}
                width={"30%"}
                height="12rem"
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={5}>
                        <SimpleItem colSpan={3}>
                            <DateBox
                                placeholder={t('setDate')}
                                onValueChange={e => {setData({ ...data, studyId: props.studyId, rawDataDate: new Date(e) })}}
                                showClearButton={true}
                                width="100%"
                                displayFormat={generalSetting?.dateFormat}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={2}>
                            <Button
                                onClick={onSave}
                                text={t('change')}
                                style={{ marginTop: "0.5rem" }}
                            />
                        </SimpleItem>
                    </Form>
                </ScrollView>
            </Popup>
        </div>
    );
};

export default RowDataManager;
