import React, { useRef, useEffect, useState, useCallback } from "react";
import { loadModules } from "esri-loader";
import { Button } from "devextreme-react/button";
import CollapsibleCard from "../collapsibleCard/collapsibleCard";
import { TesMapModule } from "../../types/general/enums/generalEnums";
import { LocationType } from "../../types/infrastructure/enums/infrastructureEnums";
import "./collisionDashboardHeatMap.scss";
import size from "./mapSize.module.scss"
import { useTranslation } from 'react-i18next';

// hooks allow us to create a map component as a function
export default function CollisionDashboardHeatMap({ initDataGis, initData, heatMapSetting }) {
  // create a ref to element to be used as the map's container
  const [lang, setLang] = useState("english");
  const [dataIsSelected, setDataIsSelected] = useState(false);
  const [selectedCollisions, setSelectedCollisions] = useState([]);
  const [showOverlayText, setShowOverlayText] = useState(false)
  const { t } = useTranslation();
  const _view = useRef(null);

  //  console.log(ae)
  // use a side effect to create the map after react has rendered the DOM
  useEffect(
    () => {
      // define the view here so it can be referenced in the clean up function
      let map,
        view,
        layerList,
        intersectionsLayer,
        midBlocksLayer,
        heatmaplayer,
        heatmaplayerView,
        csvLayerView,
        csvLayerViewMidBlock,
        polygonGraphicsLayer,
        sketchViewModel,
        grid;
      //const infoDiv = document.getElementById("info");
      // the following code is based on this sample:
      // https://developers.arcgis.com/javascript/latest/sample-code/webmap-basic/index.html
      // first lazy-load the esri classes
      loadModules([
        "esri/views/MapView",
        "esri/Graphic",
        // "esri/widgets/Legend",
        "esri/Map",
        "esri/layers/FeatureLayer",
        "esri/widgets/LayerList",
        "esri/widgets/Expand",
        "esri/widgets/Print",
      ], {
        css: true
      }).then(([
        MapView,
        Graphic,
        Map,
        FeatureLayer,
        LayerList,
        Expand,
        Print,
      ]) => {

        setupTheView();

        /******************************************************
         * Sets up the view. WebMap with winkel III projection
         * basemap and hurricanes CsvLayer is added to the view.
         ******************************************************/
        function setupTheView() {
          const url = initDataGis.intersectionFileAddress;

          const intersectionRenderer = {
            "type": "simple",
            "symbol": {
              "type": "simple-marker",
              "size": 10,
              "color": "#ff8c00",
              "outline": null
            }
          }

          intersectionsLayer = new FeatureLayer({
            title: "Intersection",
            outFields: [initDataGis.intersectionGeoIdName],
            url: initDataGis.intersectionFileAddress,
            apiKey:initDataGis.arcGisApiKey,
            copyright: "NOAA",
            popupTemplate: {
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: initDataGis.intersectionGeoIdName,
                      label: "Geo ID",
                    },
                  ],
                },
              ],
            },
          });


          midBlocksLayer = new FeatureLayer({
            url: initDataGis.midblockFileAddress,
            apiKey:initDataGis.arcGisApiKey,
            title: "Road Segment",
            outFields: [initDataGis.midblockGeoIdName],
            popupTemplate: {
              // title: "{FULLNAME}",
              lastEditInfoEnabled: false,
              content: [
                {
                  type: "fields",
                  fieldInfos: [
                    {
                      fieldName: initDataGis.midblockGeoIdName,
                      label: "Geo ID",
                    },
                  ],
                },
              ],
            },
          });




          let lstLayres = [];
          initDataGis.gisLayers.forEach(layer => {
            lstLayres.push(
              new FeatureLayer({
                url: layer.url,
                // "https://services8.arcgis.com/H9WVPclp1P15wQZi/ArcGIS/rest/services/SEGMENTS_MAR19/FeatureServer/0?token=AA7kYrmiJZ8bcR4qSZxKAgNN4E4P_9eAqQb59l79QEIdwZ3O7XbKCJLRuk7u6MDlTXXQhv4lGgjsKF3JNVuraBkrfE6qIZx2pLr2vd2fzoqOhWLqixxApezaY6rB8ysm2_DloJBqVqiC-9_PcrrLNFBUBvUjmNxPe7lTYlHqvdmvoPq8A0UE3InB8cGJh_pM116COXErL891AlGyn7qJwIn2_pZwTmaH_LyX0Yo8SMBoj43ajaQV_PRbEh6--SIQ",
                outFields: ["GEOID"],

                title: layer.name,
                popupTemplate: {
                  // title: "{FULLNAME}",
                  lastEditInfoEnabled: false,
                  // actions: [
                  //     {
                  //         id: "select-midblock",
                  //         image:
                  //             "https://staticfile.tes.ca/gisMap/midBlock.png",
                  //         title: "Show all collisions"
                  //     },
                  //     {
                  //         id: "new-collision-midblock",
                  //         image:
                  //             "https://staticfile.tes.ca/gisMap/plus.png",
                  //         title: "Add new collision",

                  //     }

                  // ],
                  content: [
                    // {
                    //     type: "text",
                    //     text:
                    //         "Category {JURISDICTI} storm with that occurred at {ISO_time}."
                    // },
                    {
                      type: "fields",
                      fieldInfos: [
                        {
                          fieldName: "GEOID",
                          label: "GeoID"
                        },
                        // {
                        //     fieldName: "FULLNAME",
                        //     label: "Name"
                        // }
                        // ,
                        // {
                        //     fieldName: "LIMIT1",
                        //     label: "Limit 1"
                        // }
                        // ,
                        // {
                        //     fieldName: "LIMIT2",
                        //     label: "Limit 2"
                        // }
                        // ,
                        // {
                        //   fieldName: "wmo_wind",
                        //   label: "Wind Speed (mph)"
                        // }
                      ]
                    }
                  ],

                },
              })

            )
          });

          var graphics = initData.heatMap.features.map(function (place) {
            return new Graphic({
              attributes: {
                ObjectId: place.properties.accNo,
              },
              geometry: {
                longitude: place.geometry.coordinates[0],
                latitude: place.geometry.coordinates[1],
                type: "point"
              }
            });
          });


          var heatMapLayer = new FeatureLayer({
            source: graphics,
            title: "Heat Map",

            renderer: {
              type: "heatmap",
              colorStops: [
                { color: "rgba(63, 40, 102, 0)", ratio: 0 },
                { color: "#472b77", ratio: 0.083 },
                { color: "#4e2d87", ratio: 0.166 },
                { color: "#563098", ratio: 0.249 },
                { color: "#5d32a8", ratio: 0.332 },
                { color: "#6735be", ratio: 0.415 },
                { color: "#7139d4", ratio: 0.498 },
                { color: "#7b3ce9", ratio: 0.581 },
                { color: "#853fff", ratio: 0.664 },
                { color: "#a46fbf", ratio: 0.747 },
                { color: "#c29f80", ratio: 0.83 },
                { color: "#e0cf40", ratio: 0.913 },
                { color: "#ffff00", ratio: 1 }
              ],
              maxPixelIntensity: heatMapSetting.maxPixelIntensity,
              minPixelIntensity: heatMapSetting.minPixelIntensity
            },

            objectIdField: "ObjectID",           // This must be defined when creating a layer from `Graphic` objects
            fields: [
              {
                name: "ObjectID",
                alias: "ObjectID",
                type: "oid"
              },
              {
                name: "address",
                alias: "address",
                type: "string"
              }
            ]
          });

          const map = new Map({
            basemap: "gray-vector",
            layers: [
              intersectionsLayer,
              midBlocksLayer,
              ...lstLayres,
              heatMapLayer
            ]
          });



          view = new MapView({
            container: "viewDiv",
            map: map,
            zoom: 11,
            center: [initDataGis.latitude, initDataGis.longitude],
            // padding: {
            //     bottom: infoDiv.clientHeight // Same value as the #infoDiv height in CSS
            // }
          });
          view.popup.dockEnabled = true;


          view.whenLayerView(midBlocksLayer).then(() => {
            view.goTo({ target: midBlocksLayer.fullExtent.expand(1) })
        });

          view.when(() => {
            //Layer Management tools

            const layerList = new Expand({
              content: new LayerList({
                view: view
              }),
              view: view,
              expanded: false
            });


            //Export tools

            const printTools = new Expand({
              content: new Print({
                view: view,
                // specify your own print service
                printServiceUrl:
                  "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
              }),
              view: view,
              expanded: false
            });


            // Add widget to the top right corner of the view
            view.ui.add(layerList, "top-right");
            view.ui.add(printTools, "top-right");
          });
          view.when(disableZooming);
          _view.current = view;
        }

      });
      return () => {
        // // clean up the map view
        // if (!!view) {
        //     view.destroy();
        //     view = null;
        // }
      };




    },
    // only re-load the map if the id has changed
    [lang, initData, heatMapSetting]
  );

  function disableZooming(view) {
    view.popup.dockEnabled = true;

    // Removes the zoom action on the popup
    view.popup.actions = [];

    // stops propagation of default behavior when an event fires
    function stopEvtPropagation(event) {
      event.stopPropagation();
    }

    function showZomeText(event) {
      event.stopPropagation();
      setShowOverlayText(true)
      setTimeout(() => {
        setShowOverlayText(false)
      }, 4000);
    }

    function disableZomeText(event) {
      setShowOverlayText(false)

    }


    // exlude the zoom widget from the default UI
    //view.ui.components = ["attribution"];

    // disable mouse wheel scroll zooming on the view
    var mouseWheelEvt = view.on("mouse-wheel", showZomeText);
    view.on("click", disableZomeText);

    // disable zooming via double-click on the view
    view.on("double-click", stopEvtPropagation);

    // disable zooming out via double-click + Control on the view
    // view.on("double-click", ["Control"], stopEvtPropagation);

    // disables pinch-zoom and panning on the view
    //view.on("drag", stopEvtPropagation);

    // disable the view's zoom box to prevent the Shift + drag
    // and Shift + Control + drag zoom gestures.
    // view.on("drag", ["Shift"], stopEvtPropagation);
    // view.on("drag", ["Shift", "Control"], stopEvtPropagation);

    // prevents zooming with the + and - keys
    view.on("key-down", function (event) {
      var prohibitedKeys = ["+", "-", "Shift", "_", "="];
      var keyPressed = event.key;
      if (prohibitedKeys.indexOf(keyPressed) !== -1) {
        event.stopPropagation();
      }
      if (event.key === 'Control') {
        if (mouseWheelEvt) {
          setShowOverlayText(false)
          mouseWheelEvt.remove();
          mouseWheelEvt = null;
        }
      } else {
        if (!mouseWheelEvt) {
          mouseWheelEvt = view.on("mouse-wheel", showZomeText);
        }
      }
    });

    view.on("key-up", function (event) {
      if (!mouseWheelEvt) {
        mouseWheelEvt = view.on("mouse-wheel", showZomeText);
      }
    });

    return view;
  }

  function onMouseOver(e) {
    var view = _view.current;
    view.focus();
  }

  return (
    <div class="container" style={{ overflowY: "scroll" }}>

      <div className={showOverlayText ? "parent" : "parent2"}>
        {showOverlayText && <div className="text_box">{t("useCtrl+ScrollToZoomTheMap")}</div>}
        <div onMouseOver={onMouseOver} className={showOverlayText ? "map_box" : "map_box2"}>
          <div id="viewDiv" className={size.size}>
            <div id="info" class="esri-widget"></div>
          </div>
        </div>
      </div>

    </div>
  );
}