import { DropDownButton, Tabs, ValidationGroup } from 'devextreme-react';
import { DataGridRef } from 'devextreme-react/cjs/data-grid';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TesReportViewer from '../../../../../../components/reportViewer/ReportViewer';
import reportLocations from '../../../../../../constants/reportLocations';
import { useAuth } from '../../../../../../contexts/auth';
import { GeneralSetting } from '../../../../../../contexts/clientSetting';
import { ClientReport } from '../../../../../../types/report/reportTypes';
import { StudyBaseDTO, StudyReportRequest, StudyTmcWebDetailsDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { CreateDateAsUTC } from '../../../../../../utils/dateTimeTools';
import MaxDropdownItemWidthCalculator from '../../../../../../utils/dropDownWidthCalculator';
import { SortObjectByPropName } from '../../../../../../utils/arrayTools';
import Diagram from './components/diagram';
import Details from './components/details';
import tabTitles from './data/data';

// props
interface IPros {
  initDataBase: StudyBaseDTO,
  initDataTmc: StudyTmcWebDetailsDTO,
  generalSettings: GeneralSetting | null;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  initDataReports: ClientReport[] | undefined;
  validationRef: React.RefObject<ValidationGroupRef>;
}
const TMCResults = (props: IPros) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
  const [reportPayLoad, setReportPayLoad] = useState<string>();
  const [sortedInitDataTMC, setSortedInitDataTMC] = useState<StudyTmcWebDetailsDTO>(props.initDataTmc);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    // const sortedData = {
    //   ...props.initDataTmc,
    //   result: SortObjectByPropName(props.initDataTmc.result, "periodType")
    // };

    // const updatedResult = [
    //   { ...(sortedData.result![0] as any), details: sortedData.result![0].details.filter((x: { startDT: string }) => new Date(x.startDT).getHours() >= new Date(sortedData.result![0].startDT).getHours()) },
    //   { ...(sortedData.result![1] as any), details: sortedData.result![1].details.filter((x: { startDT: string }) => new Date(x.startDT).getHours() >= new Date(sortedData.result![1].startDT).getHours()) },
    //   { ...(sortedData.result![2] as any), details: sortedData.result![2].details.filter((x: { startDT: string }) => new Date(x.startDT).getHours() >= new Date(sortedData.result![2].startDT).getHours()) },
    // ];

    // setSortedInitDataTMC({
    //   ...sortedData,
    //   result: updatedResult
    // });

    setSortedInitDataTMC({ ...props.initDataTmc, result: SortObjectByPropName(props.initDataTmc.result, "periodType") })

  }, []);

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }
  function onCloseReportModal() {
    setShowReportModal(false);
    dataGridRef.current?.instance().deselectAll();
  }

  function onOpenReport(d: any) {

    var payloadData: StudyReportRequest[] | undefined = [];
    if (d.itemData.tesReportType.name !== "TmcFullDiagram" &&
        d.itemData.tesReportType.name !== "TmcFullAADTDiagram" &&
        d.itemData.tesReportType.name !== "TMCPhsAndFullDiagram" &&
        d.itemData.tesReportType.name !== "15min"
      ) {

      if (dataGridRef.current?.instance().getSelectedRowKeys().length === 0) {
        notify(t('pleaseSelectSomeData'), 'warning', 5000);
        return;
      }
      payloadData = dataGridRef.current?.instance().getSelectedRowKeys().map(x => {
        var res: StudyReportRequest = {
          EndDT: CreateDateAsUTC(x.endDT),
          ReportTitle: t('tmc'),
          StartDT: CreateDateAsUTC(x.startDT),
          StudyId: props.initDataBase.id!,
          TesReportTypeName: d.itemData.tesReportType.name
        }
        return res;
      });
    } else {
      var repReq: StudyReportRequest = {
        StudyId: props.initDataBase.id!,
        TesReportTypeName: d.itemData.tesReportType.name
      }
      payloadData = [repReq]
    }

    setReportPayLoad(JSON.stringify(payloadData))
    setSelectedReport(d.itemData);
    setShowReportModal(true);
  }

  return (
    <React.Fragment>
      <div className='row' style={{ margin: "1rem" }}>
        <ValidationGroup
          ref={props.validationRef}
        >
          <div className={"dx-card"} style={compactViewModel ? { margin: "0rem 1rem -0.75rem 1rem" } : { margin: "0rem 1rem 2rem 1rem" }}>
            <div className="row" style={{ margin: "0.5rem 0 -0.5rem 0" }}>
              <div className="leftColumn">
                {selectedIndex === 1 &&
                  <DropDownButton
                    style={{ marginLeft: 10 }}
                    icon="fa-solid fa-chart-line"
                    hint={t('report')}
                    items={props.initDataReports?.filter((x: ClientReport) => [reportLocations.TrafficStudy_StudyDetails_TmcResults].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0))}
                    dropDownOptions={{ width: props.initDataReports && MaxDropdownItemWidthCalculator(props.initDataReports?.filter((x: ClientReport) => [reportLocations.TrafficStudy_StudyDetails_TmcResults].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0))) }}
                    displayExpr="name"
                    onItemClick={(d) => onOpenReport(d)}
                  />
                }
              </div>
            </div>
          </div>
          <div className={"dx-card "} style={{ margin: "1rem" }}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />
            {selectedIndex === 0 && (
              <Diagram
                generalSettings={props.generalSettings}
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataTmc={sortedInitDataTMC}
              />)}
            {selectedIndex === 1 && (
              <Details
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataBase}
                generalSettings={props.generalSettings}
                initDataTmc={sortedInitDataTMC}
                dataGridRef={dataGridRef}
              />)}


          </div>



          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"60%"}
            height={"75%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseReportModal()}
          >
            <TesReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={[]}
              payload={reportPayLoad}
              isOpen={showReportModal}
            />
          </Popup>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
}
export default TMCResults;