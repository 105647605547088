export enum ApplicationName {
    TESUploadApplication = 1,
    TESMainApplication = 2,
    PhoenixUpdater = 3,
    TesMobileApplication = 4,
}
export enum LogType {
    Error = 1,
    Warning = 2,
    Info = 3,
    AWSError = 4
}