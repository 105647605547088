
interface IProperties {
    accNo: string
}
interface IFeature {
    id:string,
    type: string,
    properties: IProperties,
    geometry: IGeoJson
}

export interface IGeoJson {
    type: string,
    features: IFeature[]
}

export class HeatMapSetting{
    minPixelIntensity!: number;
    maxPixelIntensity!: number;
}