import { Button, ContextMenu, Popup, ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Button as DevExpressButton,
    Export,
    FilterRow,
    Grouping, GroupPanel,
    HeaderFilter,
    Pager, Paging,
    Search,
    SearchPanel,
    Selection
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GeneralSetting } from '../../../../../contexts/clientSetting';
import { SignApiUrl } from '../../../../../environment/routeSettings';
import { VMAppSetups } from '../../../../../types/sign/dto/signDto';
import { TesSign } from '../../../../../types/sign/signTypes';
import { Support } from '../../../../../types/sign/supportTypes';
import { OnExporting } from '../../../../../utils/dataGridTools';
import { TesPut } from '../../../../../utils/rest';
import { RightClickMenu } from '../../../../../utils/rightClickMenu';

// props
interface IPros {
    initDataSupport: Support,
    initDataSetups: VMAppSetups,
    assignSign2Support: (signs: TesSign[]) => void;
    onDelete: (sign: any) => void;
    isLocked: boolean;
    generalSettings: GeneralSetting | null;
    onValueChange: (name: string, value: any) => void;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    validationRef: React.RefObject<ValidationGroupRef>
}

const Signs = (props: IPros) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false)
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const dataGridAssignSignRef = useRef<DataGridRef<any, any>>(null);
    const history = useNavigate();
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const items = [
        { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
        //   { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
    ];

    function replaceStr(str: string) {
        str = str.replaceAll('/', "");
        str = str.replaceAll(':', "");
        str = str.replaceAll('*', "");
        str = str.replaceAll('?', "");
        str = str.replaceAll('>', "");
        str = str.replaceAll('<', "");
        str = str.replaceAll('|', "");
        str = str.replaceAll('\\', "");
        str = str.replaceAll(' ', "-");
        return str;
    }


    function calculateImageTypeCell(d: any) {
        const data = d.data
        if (data.signCode) {
            return (
                <div>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src={'http://staticfile.tes.ca/sign/' + localStorage.getItem('selectedCustomerName') + "/" + replaceStr(data.signCode.code) + '.png'} width="50" height="50" />
                </div>
            )
        }
        if (data.signCodeTitle) {
            return (
                <div>
                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                    <img src={'http://staticfile.tes.ca/sign/' + localStorage.getItem('selectedCustomerName') + "/" + replaceStr(data.signCodeTitle) + '.png'} width="50" height="50" />
                </div>
            )
        }
    }

    function onCloseModal() {
        setShowModal(false)
    }
    function onOpenModal() {
        setShowModal(true)
    }

    function onAssignSign() {
        props.assignSign2Support(dataGridAssignSignRef.current?.instance().getSelectedRowKeys() as TesSign[])
        onCloseModal();
        dataGridAssignSignRef.current?.instance().deselectAll();
        props.setDataChanged(true);
    }

    const dataSourceAssignSign = new CustomStore({
        load: async (loadOption) => {
            return (await TesPut(`${SignApiUrl()}/api/TesSigns/LazyLoading/${localStorage.getItem('selectedCustomerId')}/true`, JSON.stringify(loadOption), true));
        }
    });

    function onRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
        if (e.data.id !== undefined) {

            if (isCtrlKeyPressed) {
                window.open(`/sign/signDetails/${e.data.id}`, "_blank");
            } else {
                history(`/sign/signDetails/${e.data.id}`);
            }
        }
    }

    return (
        <React.Fragment>
            <ContextMenu
                dataSource={items}
                width={100}
                target=".dx-data-row"
                onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/sign/signDetails/")}
            />
            <ValidationGroup
                ref={props.validationRef}
            >
                <div className={'content-block'}>
                    <div className={' responsive-paddings'}>
                        <div className="row">
                            <div className='rightColumn'>
                                <Button style={{ marginLeft: 5 }}
                                    onClick={onOpenModal}
                                    icon="fa-solid fa-link"
                                    text={t('assign')}
                                    disabled={props.isLocked}
                                />
                            </div>
                        </div>

                        <DataGrid id="gridContainer"
                            ref={dataGridRef}
                            dataSource={props.initDataSupport?.signs}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onRowClick={onRowClicked}
                            onExporting={OnExporting}
                            onContextMenuPreparing={e => { setSelectedRowData(e) }}
                        >
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[10, 20, 50, 100]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />

                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>
                            <Column alignment="left"
                                cellRender={calculateImageTypeCell}
                                caption={t("symbol")}
                                width={100}

                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>


                            <Column dataField={'signId'}
                                caption={t("signId")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'version'}
                                caption={t("version")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'dateInstalled'}
                                caption={t("dateInstalled")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'dateExpired'}
                                caption={t("dateExpired")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'total'}
                                caption={t("total")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'id'}
                                caption={t("id")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'customerId'}
                                caption={t("customerId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>


                            <Column dataField={'signLocationType'}
                                caption={t("signLocationType")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signLocationTypeId'}
                                caption={t("signLocationTypeId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'support'}
                                caption={t("support")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'supportId'}
                                caption={t("supportId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signCode'}
                                caption={t("signCode")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signCodeId'}
                                caption={t("signCodeId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signFacingDirection'}
                                caption={t("signFacingDirection")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signFacingDirectionId'}
                                caption={t("signFacingDirectionId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signFaceMaterial'}
                                caption={t("signFaceMaterial")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signFaceMaterialId'}
                                caption={t("signFaceMaterialId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signReflectiveCoating'}
                                caption={t("signReflectiveCoating")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signReflectiveCoatingId'}
                                caption={t("signReflectiveCoatingId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signReflectiveRating'}
                                caption={t("signReflectiveRating")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signReflectiveRatingId'}
                                caption={t("signReflectiveRatingId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signDimension'}
                                caption={t("signDimension")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signDimensionId'}
                                caption={t("signDimensionId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signCondition'}
                                caption={t("signCondition")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'signConditionId'}
                                caption={t("signConditionId")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'materialCost'}
                                caption={t("materialCost")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'labourCost'}
                                caption={t("labourCost")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'installationCost'}
                                caption={t("installationCost")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'height'}
                                caption={t("height")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'retroRefTestFailed'}
                                caption={t("retroRefTestFailed")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'note'}
                                caption={t("note")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'attachments'}
                                caption={t("attachments")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'changeLogs'}
                                caption={t("changeLogs")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'patrols'}
                                caption={t("patrols")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'isDeleted'}
                                caption={t("isDeleted")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataField={'deletedTime'}
                                caption={t("deletedTime")}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                                <DevExpressButton hint={t('delete')} onClick={props.onDelete} icon="fa-solid fa-trash-can">

                                </DevExpressButton>
                            </Column>


                        </DataGrid>
                    </div>
                </div>


                <Popup
                    width={"65%"}
                    visible={showModal}
                    resizeEnabled={true}
                    showTitle={true}
                    title={t('assignSign')}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHiding={() => onCloseModal()}
                >
                    <div style={{ height: "90%" }}>
                        <DataGrid
                            id="gridContainer"
                            ref={dataGridAssignSignRef}
                            dataSource={dataSourceAssignSign}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            style={{ height: '100%' }}
                            keyExpr="id"
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onExporting={OnExporting}
                        //onContentReady={e => {e.component.deselectAll()}}
                        //onFilterValueChange={e => {dataGridAssignSignRef?.current?.instance().deselectAll()}}
                        >
                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />
                            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                            <GroupPanel visible={true} /> {/* or "auto" */}

                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <Selection
                                mode="multiple"
                                selectAllMode={'allPages'}
                                showCheckBoxesMode={'always'}
                            />

                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <Column dataField="signLocationTypeTitle"
                                caption={t("locationType")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signId"
                                caption={t("signId")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="supportTitle"
                                caption={t("supportId")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signCodeTitle"
                                caption={t("code")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signDimensionTitle"
                                caption={t("dimension")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="height"
                                caption={t("height")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signFacingDirectionTitle"
                                caption={t("facingDirection")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signFaceMaterialTitle"
                                caption={t("faceMaterial")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signReflectiveCoatingTitle"
                                caption={t("reflectiveCoating")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signReflectiveRatingTitle"
                                caption={t("reflectiveRating")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="signConditionTitle"
                                caption={t("condition")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="dateInstalled" dataType="date" format={props.generalSettings?.dateFormat}
                                caption={t("installationDate")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="dateExpired" dataType="date" format={props.generalSettings?.dateFormat}
                                caption={t("Expiration Date")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>


                            <Column alignment="left" dataField="materialCost"
                                caption={t("materialCost")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="labourCost"
                                caption={t("labourCost")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="installationCost"
                                caption={t("installationCost")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>




                        </DataGrid>
                    </div>

                    <div style={{ marginTop: 20 }}>
                        <div className='rightColumn'>
                            <Button
                                className='tes-modal-btn-cancel'
                                style={{ marginRight: 20 }}
                                onClick={onCloseModal}
                                text={t('cancel')}
                            />
                            <Button
                                className='tes-modal-btn-add'
                                onClick={onAssignSign}
                                text={t('add')}
                            />

                        </div>
                    </div>
                </Popup>
            </ValidationGroup>
        </React.Fragment >
    );
}

export default Signs;