const CollisionPermissions = {
    Collision_D_Field: "Collision_D_Field",
    Collision_V_Dashboard: "Collision_V_Dashboard",
    Collision_V: "Collision_V",
    Collision_V_MyCollision: "Collision_V_MyCollision",
    Collision_D: "Collision_D",
    Collision_E: "Collision_E",
    Collision_Approve: "Collision_Approve",
    Collision_D_ValidationRule: "Collision_D_ValidationRule",
    Collision_V_Hospitalization: "Collision_V_Hospitalization",
    Collision_D_Hospitalization: "Collision_D_Hospitalization",
    Collision_GeoCode:"Collision_GeoCode",
    Collision_R:"Collision_R",
    Collision_RP:"Collision_RP"
};
export default CollisionPermissions;