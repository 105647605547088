import i18n from "../../../../react-i18next.d";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('general'),
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('location'),
        icon: 'fa-solid fa-location-dot',
        content: 'Location tab content',
    }
];
export default tabTitles;

