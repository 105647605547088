import { height } from "devexpress-reporting/scopes/reporting-designer-bands-metadata";
import { ScrollView, SelectBox, TagBox } from "devextreme-react";
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Title, Export, Tooltip, ArgumentAxis, Size, Grid } from 'devextreme-react/chart';
import { t } from "i18next";
import * as React from "react";
import { useEffect, useState } from "react";
import Permission from "../../../components/permission/permision";
import { AuthApiUrl } from "../../../environment/routeSettings";
import { VMFindUser } from "../../../types/identity/dto/identityDTO";
import { TesGet } from "../../../utils/rest";


const TestSort = () => {
    const [initDataUsers, setInitDataUser] = useState<VMFindUser[]>([]);

    useEffect(() => {
        async function fetchMyAPI() {
            await getInitDataUsers();


        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return <div>
<div>        <TagBox
        dataSource={SortObjectByPropName(
            initDataUsers.filter(x => x.defaultCustomerId === localStorage.getItem("selectedCustomerId")!)
            , "userName")}
        valueExpr="email"
        displayExpr="userName"
        //onValueChanged={onUserChange}
        searchEnabled={true} />;
        </div>
        <div style={{ height: "200px" }}>
            <Chart id="chart"
                
                paletteExtensionMode="alternate"
            >
                {/*<Size height={200} />*/}
                <CommonSeriesSettings argumentField="description" type={"stackedbar"} barWidth={60} />
                <Series
                    valueField="injury"
                    name={t("injury")}
                    axis="pdoInjury"
                    color="#008ac5"
                />

                <Series
                    valueField="pdo"
                    name={t("PDO")}
                    axis="pdoInjury"
                    color="#00a9b5"
                />

                <Series
                    valueField="fatal"
                    name={t("fatal")}
                    type="line"
                    axis="fatalAxix"
                    color="#0b1d78"
                />

                <ArgumentAxis
                    title={t('year')}
                />

                <ValueAxis
                    name="fatalAxix"
                    position="right"
                    // valueType="fatal"
                    showZero={true}
                    valueMarginsEnabled={false}
                    allowDecimals={false}
                >
                    <Title text={t("numberOfFatalCollisions")} />
                </ValueAxis>

                <ValueAxis
                    name="pdoInjury"
                    position="left"
                    allowDecimals={false}
                    type={'continuous'}
                >
                    <Title text={t("collisionFrequency")} />
                </ValueAxis>

                <Export enabled={true} printingEnabled={false} />
                <Tooltip
                    enabled={true}
                    location="edge"
                //  customizeTooltip={this.customizeTooltip}
                />
                <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
            </Chart>

        </div>
</div>
    async function getInitDataUsers() {

        var res = (await TesGet(AuthApiUrl() + "/api/user/findUser", true)) as VMFindUser[];
        setInitDataUser(res.filter(x => x.defaultCustomerId === localStorage.getItem("selectedCustomerId")));


    }

    function SortObjectByPropName<T>(users: T[], sortProperty: string): T[] {
        return users.sort((a, b) => {

            type ObjectKey = keyof typeof a;
            const myVar = sortProperty as ObjectKey;

            const propA = a[myVar];
            const propB = b[myVar];

            if (propA < propB) {
                return -1;
            }
            if (propA > propB) {
                return 1;
            }
            return 0;
        });
    }

}

export default TestSort;
