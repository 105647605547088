import { useTranslation } from "react-i18next";
import { FieldCategoryType } from "../enums/fieldEnums";

const FieldConstants = () => {
  const { t } = useTranslation();
  return {
    in_: [
      { id: "All", name: "All" },
      { id: "Any", name: "Any" },
      { id: "Non", name: "Non" },
      { id: "SortAsc", name: "SortAsc" },
      { id: "SortDesc", name: "SortDesc" },
    ],
    collisionCategory: [
      { id: FieldCategoryType.CollisionGeneral, name: t("general") },
      { id: FieldCategoryType.CollisionRoad, name: t("road") },
      { id: FieldCategoryType.CollisionVehicle, name: t("vehicle") },
      { id: FieldCategoryType.CollisionDriver, name: t("driver") },
      { id: FieldCategoryType.CollisionPassenger, name: t("passenger") },
      { id: FieldCategoryType.CollisionPedestrian, name: t("pedestrian") },
      { id: FieldCategoryType.CollisionPerson, name: t("person") },
    ],
    IntersectionCategory: [
      { id: FieldCategoryType.InfrastructureIntersectionGeneral, name: t("general") },
      { id: FieldCategoryType.InfrastructureIntersectionApproachDetails, name: t("approachDetails") },
      { id: FieldCategoryType.InfrastructureIntersectionDetails, name: t("details") },
      { id: FieldCategoryType.InfrastructureIntersectionOperation, name: t("operation") },
    ],
    RoadSegmentCategory: [
      { id: FieldCategoryType.InfrastructureMidBlockGeneral, name: t("general") },
      { id: FieldCategoryType.InfrastructureMidBlockCrossSection, name: t("crossSection") },
      { id: FieldCategoryType.InfrastructureMidBlockDetails, name: t("details") },
      { id: FieldCategoryType.InfrastructureMidBlockOperation, name: t("operation") },
    ],
    operation: [
      { id: "=", name: "=" },
      { id: "!=", name: "!=" },
      { id: "<", name: "<" },
      { id: ">", name: ">" },
      { id: "<=", name: "<=" },
      { id: ">=", name: ">=" },
      { id: "%", name: "%" },
      { id: "R=", name: "R=" },//Reg
      { id: "{T,0}", name: "{T,0}" },//Take
    ],
  };
};
export default FieldConstants;
