import { SyncStatus, SyncType } from "./enums/tesSyncEnums";

export class TesSync {
    id!: string;
    customerId!: string;
    syncType!: SyncType;
    fromDate!: string;
    toDate!: string;
    syncStatus!: SyncStatus;
    batchSize!: number;
    syncInterval!: number;
    timeout!: number;
    retryNo!: number;
    retryDelay!: number;
    syncUrl!: string;	
}