import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
//component imports
import Details from "./components/details/details";
import Permissions from "./components/share/share";
import Locations from "./components/location/location";
import {
  IAreaParams,
  Area,
  Location,
  UpdateArea as UpdateSiteList,
} from "../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesDelete, TesGet, TesPost, TesPut } from "../../../utils/rest";
import { useNavigate, useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import { t } from "i18next";
import "./areaDetails.scss";
import { custom } from "devextreme/ui/dialog";
import Permission from "../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { AuthApiUrl, InfrastructureApiUrl } from "../../../environment/routeSettings";
import { AreaDTO, VMLocation } from "../../../types/infrastructure/dto/locationdDto";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { PagePermission, ResponseCode } from "../../../types/general/enums/generalEnums";
import getTitle from "../../../components/title/getTitle";

const SiteListDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const params = useParams<IAreaParams>();
  const [backPermission, setBackPermission] = useState(false);
  const [lstUserIds, setLstUserIds] = useState<string[]>();
  const [lstGroupIds, setLstGroupIds] = useState<string[]>();
  const [isLocked, setIsLocked] = useState(true);
  const [intersections, setIntersections] = useState<Location[]>([]);
  const [roadSegments, setRoadSegments] = useState<Location[]>([]);
  const [siteList, setSiteList] = useState<Area>(new Area());
  const [initDataSiteList, setInitDataSiteList] = useState<AreaDTO>(new AreaDTO());
  const [initDataUser, setInitDataUser] = useState([]);
  const [initDataGroup, setInitDataGroup] = useState([]);
  const { activeLoading } = useAuth();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const { getPagePermission } = useAuth();
  const [pagePermissionStatus, setPagePermissionStatus] = useState<PagePermission>(PagePermission.Deny);

  const title = getTitle('/infrastructure/siteListDetails', `${initDataSiteList.name}`);
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataGroup();
        await getInitialDataUser();
        if (params.areaId !== "AddNew") {
          await getInitDataSiteList(params.areaId!);
        } else {
          setIsLocked(false);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.areaId]);

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function getInitDataSiteList(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesGet(InfrastructureApiUrl() + "/api/Areas/areaDetails/" + id, true) as RequestResponseResult<AreaDTO>;
      if (res.responseCode === ResponseCode.OK) {
        setInitDataSiteList(res.results);
        if (getPagePermission) setPagePermissionStatus(getPagePermission([InfrastructurePermissions.Infrastructure_D_Area], res.results));
        if (backPermission === true) {
          history(-1);
        }
        if (activeLoading) activeLoading(false);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function changeLockStatus() {
    setIsLocked(!isLocked);
  }

  //details functions
  const onChangeDetailsFields = (key: string, value: any) => {
    setSiteList({ ...siteList, [key]: value });
  };

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.areaId !== "AddNew") {
                  //updateIntersection()
                } else {
                  addSiteList();
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  async function update() {
    var postObj: UpdateSiteList = {
      id: params.areaId,
      customerId: initDataSiteList.customerId,
      name: initDataSiteList.name,
      listGroupIds: initDataSiteList.groupIds,
      listUserIds: initDataSiteList.userIds,
      lstLocationIds: initDataSiteList.locations?.map((x: VMLocation) => x.id) as string[],
      divisionId: initDataSiteList.divisionId
    };
    try {
      await TesPost(InfrastructureApiUrl() + "/api/Areas/UpdateArea", postObj, true);
      await getInitDataSiteList(params.areaId!);
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (backPermission === true) {
        history(-1);
        return;
      }
    } catch {
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  //add SiteList function
  async function addSiteList() {
    try {
      let postObj: UpdateSiteList = {
        customerId: localStorage.getItem("selectedCustomerId") as string,
        name: initDataSiteList.name,
        lstLocationIds: initDataSiteList.locations.map((x: VMLocation) => x.id) as string[],
        listUserIds: initDataSiteList.userIds,
        listGroupIds: initDataSiteList.groupIds,
        divisionId: localStorage.getItem("defaultDivisionId")!
      };
      const res = await TesPost(InfrastructureApiUrl() + "/api/Areas/AddArea", postObj, true) as RequestResponseResult<any>;
      if (res.responseCode === ResponseCode.OK) {
        history(-1);
        notify(t('dataSuccessfullyAdded'), 'success', 5000);
        if (activeLoading) activeLoading(false);
      } else {
        await RequestErrorHandling(res);
      }
    } catch {
      notify('someErrorOccured', 'error', 5000);
    }
  }


  //permissions function
  async function getInitialDataUser() {
    setInitDataUser(
      await TesGet(
        AuthApiUrl() +
        "/api/user/GetUsersOfClient/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitialDataGroup() {
    setInitDataGroup(
      await TesGet(
        AuthApiUrl() +
        "/api/Groups/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  // async function getInitialDataShareArea(id: string) {
  //   setInitDataShare(
  //     await TesGet(
  //       InfrastructureApiUrl() + "/api/Areas/GetUserSharedArea/" + id,
  //       true
  //     )
  //   );
  // }

  function onPermissionChange(data: any) {
    setLstGroupIds(data.lstGroupIds);
    setLstUserIds(data.lstUserIds);
  }

  //Location Functions
  // async function getAreaLocations(areaId: string) {
  //   const response = await TesGet(
  //     InfrastructureApiUrl() + "/api/Areas/GetLocations/" + areaId,
  //     true
  //   );
  //   setIntersections(
  //     response
  //       .filter((x: any) => x.locationType == LocationType.Intersection)
  //       .map((x: any) => ({
  //         id: x.id,
  //         description: x.description,
  //         geoId: x.geoId,
  //       }))
  //   );
  //   setRoadSegments(
  //     response
  //       .filter((x: any) => x.locationType == LocationType.Midblock)
  //       .map((x: any) => ({
  //         id: x.id,
  //         description: x.description,
  //         geoId: x.geoId,
  //       }))
  //   );
  // }

  function updateIntersection(lstIntersections: Location[] | undefined) {
    if (lstIntersections !== undefined) {
      setIntersections(lstIntersections);
    }
  }

  function updateRoadSegment(lstRoadSegments: Location[] | undefined) {
    if (lstRoadSegments !== undefined) {
      setRoadSegments(lstRoadSegments);
    }
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  async function onDelete() {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [{
        text: t("yes"),
        onClick: async (e) => {

          const res = await TesDelete(InfrastructureApiUrl() +
            "/api/Areas/DeleteArea/" +
            params.areaId,
            true);

            if (res.responseCode === ResponseCode.OK) {
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
              history(-1)
            }

            await RequestErrorHandling(res);

          return { buttonText: e.component.option("text") }
        }
      },
      {
        text: t("no"),
        onClick: (e) => {
          return { buttonText: e.component.option("text") }
        }
      },]
    });
    myDialog.show();
  }

  return (
    <Permission
      allowed={[InfrastructurePermissions.Infrastructure_D_Area]}
      hasFeedBackElement={true}
    >
      <div className={`siteListDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
            {params.areaId !== "AddNew" && (
                <div style={{ marginTop: "1rem"}}>
                  <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t('name')}: {initDataSiteList?.name}</span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                <div className="rightColumn">
                  {params.areaId === "AddNew" && (
                    <Button
                      onClick={() => addSiteList()}
                      style={{ marginRight: '10px' }}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")}
                    />
                  )}
                  {params.areaId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={onDelete}
                      icon="fa-solid fa-trash"
                      style={{ marginRight: 10 }}
                      hint={t("update")}
                    />
                  )}
                  {params.areaId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={() => update()}
                      icon="fa-solid fa-floppy-disk"
                      style={{ marginRight: 10 }}
                      hint={t("update")}
                    />
                  )}
                  {pagePermissionStatus === PagePermission.Edit && params.areaId !==  "AddNew" && <Button
                    onClick={() => changeLockStatus()}
                    icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                    hint={isLocked ? t("unlock") : t("lock")}
                  />}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <Tabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onOptionChanged={onTabsSelectionChanged}
              />

              {selectedIndex === 0 && (
                <Details
                  initDataSiteList={initDataSiteList}
                  setInitDataSiteList={setInitDataSiteList}
                  onChangeDetailsFields={onChangeDetailsFields}
                  isLocked={isLocked}
                />
              )}

              {selectedIndex === 1 && (
                <Locations
                  initDataSiteList={initDataSiteList}
                  setInitDataSiteList={setInitDataSiteList}
                  areaId={params.areaId!}
                  updateIntersection={updateIntersection}
                  updateRoadSegment={updateRoadSegment}
                  intersections={intersections !== undefined ? intersections : []}
                  roadSegments={roadSegments !== undefined ? roadSegments : []}
                  setDataChanged={setDataChanged}
                  isLocked={isLocked}
                />
              )}
              {selectedIndex === 2 && (
                <Permissions
                  areaId={params.areaId!}
                  initDataSiteList={initDataSiteList}
                  setInitDataSiteList={setInitDataSiteList}
                  selectedUserIds={lstUserIds !== undefined ? lstUserIds : []}
                  selectedGroupIds={lstGroupIds !== undefined ? lstGroupIds : []}
                  initDataGroup={initDataGroup !== undefined ? initDataGroup : []}
                  initDataUser={initDataUser !== undefined ? initDataUser : []}
                  onPermissionChange={onPermissionChange}
                  isLocked={isLocked}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default SiteListDetails;
