import { ContextMenu, DataGrid } from "devextreme-react";
import { Column, ColumnChooser, DataGridRef, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Lookup, Pager, Paging, Search, SearchPanel } from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from "../../../constants/Permissions";
import { TranslationApiUrl } from "../../../environment/routeSettings";
import { NameValue } from "../../../types/general/generalTypes";
import { IClientParams } from "../../../types/setting/settingTypes";
import { LanguageDetailsDto } from "../../../types/translation/dtos/translationDtos";
import { LanguageType } from "../../../types/translation/enums/translationEnums";
import { Enum2Array } from "../../../utils/enumTools";
import { TesGet } from "../../../utils/rest";
import { RightClickMenu } from "../../../utils/rightClickMenu";

const CustomerDetails = () => {

  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [langsData, setLangsData] = useState<LanguageDetailsDto[]>([])
  const [statusList, setStatusList] = useState<NameValue[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const params = useParams<IClientParams>();

  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
  ];

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        await getLangsData();
        setStatusList(Enum2Array(LanguageType));
        // setLstLanguageType(Enum2Array(LanguageType))
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getLangsData() {
    const res = await TesGet(TranslationApiUrl() + "/api/Languages/" + `${params.clientId}`, true);
    setLangsData(res?.results!);
  }

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={e => {
            RightClickMenu(e, "",
              `${selectedRowData.row.data.languageType === LanguageType.Standard ? "/translation/baseLanguageDetails/" :
                (selectedRowData.row.data.languageType === LanguageType.Groupe ? "/translation/groupDetails/" :
                  "/translation/customerLanguageDetails/")}` +
              `${selectedRowData.row.data.baseLanguageId}`,
            )
          }}
        />
        <h2 className={"content-block"}>{t("customers")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={langsData}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onContextMenuPreparing={e => { setSelectedRowData(e) }}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column
                dataField="languageName"
                allowSorting={false}
                caption={t("name")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="languageCode"
                allowSorting={false}
                caption={t("languageCode")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="languageType"
                allowSorting={false}
                caption={t("type")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup dataSource={statusList} valueExpr="value" displayExpr="name" />
              </Column>
              <Column
                dataField="isDefault"
                allowSorting={false}
                caption={t("isDefault")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="parentLanguageName"
                allowSorting={false}
                caption={t("parent")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="description"
                allowSorting={false}
                caption={t("description")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    </Permission>
  )
}

export default CustomerDetails;