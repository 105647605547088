import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter,
  Lookup,
  Pager, Paging,
  Search,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import CustomStore from 'devextreme/data/custom_store';
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useClientSetting } from "../../contexts/clientSetting";
import { FieldApiUrl, TrafficApiUrl } from "../../environment/routeSettings";
import { FieldCategoryType, FieldLocation2Show, FieldType } from "../../types/field/enums/fieldEnums";
import { TesField } from '../../types/field/fieldType';
import { GridType, ResponseCode } from "../../types/general/enums/generalEnums";
import { LazyLoadingRequest, NameValue, RequestResponseResult } from "../../types/general/generalTypes";
import { CountStation } from '../../types/infrastructure/infrastructureTypes';
import { SortObjectByPropName } from "../../utils/arrayTools";
import { OnExporting } from "../../utils/dataGridTools";
import { GridActualDateCalculator } from "../../utils/dateTimeTools";
import { EnumFlag2Array } from "../../utils/enumTools";
import { RequestErrorHandling, TesGet, TesPost } from '../../utils/rest';

interface IProps {
  label: string;
  value: string;
  name: string;
  onChangeFields: (name: string, value: any, isUpperCase: boolean) => void;
  isLocked: boolean;
  lstCountStationTypes: NameValue[]
}

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${TrafficApiUrl()
      }/api/CountStation/GetCountStation`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
      } as LazyLoadingRequest,
      true
    );
  },
});

const CountStationPicker = (props: IProps) => {
  const [initDataFields, setInitDataFields] = useState<TesField[]>();
  const [name, setName] = useState('');
  const [showCountStationPicker, setShowCountStationPicker] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { customerDivisions } = useClientSetting();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getCountStation(props.value);
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);


  async function getInitialDataFields() {
    const res = (await TesPost(FieldApiUrl() + "/api/TesFields/GetWebTesFieldsByPermissionByCategory", {
      customerId: localStorage.getItem('selectedCustomerId')!,
      categoryTypes: [FieldCategoryType.CountingStationGeneral, FieldCategoryType.PermanentCountingStationGeneral]
    }, true)) as TesField[];

    setInitDataFields(
      res.filter((obj, _, arr) =>
        arr.filter(o => o.name === obj.name).length <= 1 || obj.fieldCategoryType !== FieldCategoryType.PermanentCountingStationGeneral
      ).sort((a, b) => a.detailViewIndex - b.detailViewIndex)
    );
  }

  async function getCountStation(id: string) {
    if (id === undefined || id === null || id === "00000000-0000-0000-0000-000000000000") return;
    const res = await TesGet(TrafficApiUrl() + "/api/CountStation/GetCountStationDetails/" + id, true) as RequestResponseResult<CountStation>;
    if (res.responseCode === ResponseCode.OK) {
      if (res !== null) {
        setName(`Count Station - ${res.results.description}`)
      }
    } else {
      await RequestErrorHandling(res);
    }
  }

  function onRowClicked(selectedRowData: any) {
    setShowCountStationPicker(false);
    setName(`Count Station - ${selectedRowData.data?.Description}`)
    props.onChangeFields(props.name, selectedRowData.data?._id, false)
  }

  async function onOpenPopUp() {
    if (!props.isLocked) {
      setShowCountStationPicker(true);
      await getInitialDataFields();
    }
  }

  return (
    <React.Fragment>
      <label onClick={() => onOpenPopUp()}>
        <TextBox
          label={props.label}
          labelMode='floating'
          value={name}
          disabled={props.isLocked}
          showClearButton={true}
          buttons={[
            {
              name: 'search',
              location: 'after',
              options: {
                icon: 'fas fa-location-dot',
                stylingMode: 'text',
              }
            }
          ]}
        />
      </label>
      <Popup
        width={"60%"}
        visible={showCountStationPicker}
        resizeEnabled={true}
        showTitle={true}
        title={props.label}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => setShowCountStationPicker(false)}
      >
        <div style={{ height: "100%" }}>
          {showCountStationPicker === true &&
            <DataGrid
              id="gridContainer"
              key={"_id"}
              ref={dataGridRef}
              dataSource={dataSource}
              rowAlternationEnabled={true}
              showBorders={true}
              onRowClick={onRowClicked}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              columnAutoWidth={true}
              height={"100%"}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={GridType.countStation.toString()}
                savingTimeout={500}
              />
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="id"
                allowSorting={true}
                caption={t("id")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="DivisionId"
                allowSorting={true}
                visible={false}
                caption={t("division")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(customerDivisions!, "name")}
                  valueExpr="id"
                  displayExpr="name"
                />
              </Column>
              <Column
                dataField="LocationId"
                allowSorting={true}
                caption={t("locationId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="Name"
                allowSorting={true}
                caption={t("name")}
                width={400}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="Description"
                allowSorting={true}
                caption={t("description")}
                width={400}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="CountStationType"
                allowSorting={true}
                caption={t("countStationType")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="InstallationDate"
                allowSorting={true}
                caption={t("installationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="LHRS"
                allowSorting={true}
                caption={t("lhrs")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="Offset"
                allowSorting={true}
                caption={t("offset")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="Latitude"
                allowSorting={true}
                caption={t("latitude")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="Longitude"
                allowSorting={true}
                caption={t("longitude")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="aadtPatternId"
                allowSorting={true}
                caption={t("aadtPatternId")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="countStationType"
                allowSorting={false}
                caption={t('locationType')}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(props.lstCountStationTypes, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
              <Column
                alignment="left"
                dataField="IsDeleted"
                allowSorting={true}
                caption={t("isDeleted")}
                visible={false}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              {initDataFields && initDataFields.length > 0 && initDataFields.map((d) => {
                if (d.fieldType === FieldType.List) {
                  return (
                    <Column
                      key={d.name}
                      dataField={d.name}
                      caption={d.labelText}
                      allowFiltering={true}
                      visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                    >
                      <Search enabled />
                      <Lookup
                        dataSource={SortObjectByPropName(d.fieldValues, "name")}
                        valueExpr="id"
                        displayExpr="name"
                      />
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Number) {
                  return (
                    <Column
                      key={d.name}
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="number"
                      alignment="left"
                      visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Date) {
                  return (
                    <Column
                      key={d.name}
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="date"
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      format={generalSetting?.dateFormat}
                      visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Time) {
                  return (
                    <Column
                      key={d.name}
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="datetime"
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      format={generalSetting?.timeFormat}
                      visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.DateTime) {
                  return (
                    <Column
                      key={d.name}
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="datetime"
                      calculateCellValue={(e: any) => GridActualDateCalculator(e, d, generalSetting)}
                      format={generalSetting?.dateTimeFormat}
                      visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else if (d.fieldType === FieldType.Boolean) {
                  return (
                    <Column
                      key={d.name}
                      dataField={d.name}
                      caption={d.labelText}
                      dataType="boolean"
                      visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                } else {
                  return (
                    <Column
                      key={d.name}
                      dataField={d.name}
                      caption={d.labelText}
                      allowSorting={true}
                      visible={(EnumFlag2Array(FieldLocation2Show, d.fieldLocation2Show).some(x => x === FieldLocation2Show.GridView)) ? true : false}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  );
                }
              })}
            </DataGrid>
          }
        </div>
      </Popup>
    </React.Fragment>
  );
}
export default CountStationPicker;