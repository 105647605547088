import i18n from "../../../../../../react-i18next.d";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('monthlyAdjustmentFactor'),
        icon: 'fa-regular fa-calendar-days',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('dayOfWeekAdjustmentFactor'),
        icon: 'fa-solid fa-calendar-day',
        content: 'Location tab content',
    },
    {
        id: 2,
        text: i18n.t('hourAdjustmentFactor'),
        icon: 'fa-solid fa-clock',
        content: 'Location tab content',
    }
];
export default tabTitles;