import i18n from "../../../../../../../react-i18next.d";
i18n.loadNamespaces("tabs");

const tabTitles = [
  {
    id: 0,
    text: i18n.t("diagram"),
    content: i18n.t("diagram"),
  },
  {
    id: 1,
    text: i18n.t("details"),
    content: i18n.t("detail"),
  },


]

export default tabTitles;