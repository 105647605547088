import React, { useEffect, useState } from 'react';
import "./5.scss";
import { t } from 'i18next';
import { Justification, Justification5 } from '../../../../../../../types/trafficStudy/dtos/studyDto';
import {SeparatorAdder} from '../../../../../../../utils/numberTools';

// props
interface IPros {
	justificationName: string,
	signalWarrants: Justification
}

const TabFive = (props: IPros) => {
	const [selectedJustification, setSelectedJustification] = useState<Justification5>();


	useEffect(() => {
		async function fetchMyAPI() {
			var justification5 = props.signalWarrants.justification5.find(x => x.name === props.justificationName);
			setSelectedJustification(justification5);

		}
		fetchMyAPI()
    }, [props.justificationName,props.signalWarrants]);

	return (
		<React.Fragment>
			<h2 className={"content-block"} style={{ margin: "1rem" }}>{t("justification5CollisionExperience")}</h2>
			{selectedJustification?.note && selectedJustification.note !== "" && (
                <p style={{fontSize:"1rem", padding:"0", margin:"1.5rem 0 0 1rem"}}>
                    <span style={{fontWeight:"bolder"}}>{t("note")}:{" "}</span>{selectedJustification?.note}
                </p>
            )}
			<div className='row' style={{ marginTop: 20, padding: 10 }}>
				{/* <h6 className={"content-block"} style={{ margin: "0rem 1rem 1rem 1rem" }}>{t("aAllApproaches")}</h6> */}
				<table className='tableBorder'>
					<tbody className='customeTable'>
						<tr>
							<td className="tableBorder" colSpan={3}>{t("reportableCollisionsOver36ConsecutiveMonthsSusceptibleToCorrectionByATrafficSignal")}</td>
						</tr>
						<tr>
							<td className="tableBorder">{t("WarrantValue")}</td>
							<td className="tableBorder">{t("totalNumberOfCrashes")}</td>
							<td className="tableBorder">{t("overallCompliance")}</td>
						</tr>
						<tr>
							<td className="tableBorder">{SeparatorAdder(selectedJustification?.collisionNoWarrant5??0)}</td>
							<td className="tableBorder">{SeparatorAdder(selectedJustification?.totalCollisionNo??0)}</td>
							<td className="tableBorder">{SeparatorAdder(selectedJustification?.compliance??0)} %</td>
						</tr>
						<tr>
							<td className="tableBorder" colSpan={2}>{t("adequateTrialOfLessRestrictiveRemediesHasFailedToReduceAccidentFrequency")}</td>
							<td className="tableBorder">Yes/No</td>
						</tr>
						<tr>
							<td className="tableBorder">{t("justification5")}</td>
							<td className="tableBorder">{t("100Fulfilled")}</td>
							<td className="tableBorder">{selectedJustification?.justification100Satisfied?t("yes"):t("no")}</td>
						</tr>
						<tr>
							<td className="tableBorder"></td>
							<td className="tableBorder">{t("80Fulfilled")}</td>
							<td className="tableBorder">{selectedJustification?.justification80Satisfied?t("yes"):t("no")}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</React.Fragment>
	);


}
export default TabFive;