export enum PasswordMode {
    SendUsernamePasswordByEmail=1,
    SendSetPasswordByEmail=2,
    SetPasswordManually=3

}

export enum DivisionsPermissionTypes
{
    View = 1,
    Define = 2,
    Edit = 4,
    Remove = 8,
    Assign = 16,
    Integration = 32,
    Sync = 64,
}

export enum SingleDivisionAccess
{
    DefaultOnly = 0,
    ReadAll = 1,
    WriteAll = 2
}

export enum DivisionSystem
{
    None = 0,
    SingleOrAll = 1,
    Multiple = 2,
}