import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import SystematicScreeningPermissions from "../../../constants/Permissions/systematicScreeningPermissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { TrafficApiUrl } from "../../../environment/routeSettings";
import { ResponseCode, TesMapModule } from "../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { LocationPickerMode } from "../../../types/infrastructure/enums/infrastructureEnums";
import { IAnalysisResultParam, SignalWarrantAnalysisDetails } from "../../../types/systematicScreening/systematicScreeningTypes";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { RequestErrorHandling, TesGet } from "../../../utils/rest";
import Details from "./components/details/details";
import Map from "./components/map/map";
import SignalWarrantsTab from "./components/signalWarrants/signalWarrants";
import Studies from "./components/studies/studies";
import VolumeRawData from "./components/volumeRawData/volumeRawData";
import VolumeResults from "./components/volumeResults";
import tabTitles from "./data/index";
import "./signalWarrantAnalysisDetails.scss";

const ProjectDetails = () => {
  const [initData, setInitData] = useState<SignalWarrantAnalysisDetails>(new SignalWarrantAnalysisDetails());
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<IAnalysisResultParam>();
  const [isLocked, setIsLocked] = useState(true);
  const [backPermission, setBackPermission] = useState(false);
  const { generalSetting } = useClientSetting();
  const validationRef = useRef<ValidationGroupRef>(null);
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const locDescRef = useRef<HTMLSpanElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          await getInitData(params.analysisResultId!);
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(TrafficApiUrl() + "/api/SignalWarrantPredictionAnalysis/GetSignalWarrantAnalysisDetails/" + id, true)) as RequestResponseResult<SignalWarrantAnalysisDetails>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results)
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                setDataChanged(false)
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
                history(-1);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else {
        textToCopy = locDescRef.current?.innerText!;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[SystematicScreeningPermissions.SystematicScreening_D_SWP,
      SystematicScreeningPermissions.SystematicScreening_V_SWP,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.intersectionId !== "AddNew" && (
              <div style={{ marginTop: "1rem" }}>
                <p className={"detailsHeading"} style={{ display: "inline" }}>
                  <span>{t('location')}: </span>
                  {initData?.analysisResult?.geoId && initData?.analysisResult?.geoId !== "" &&
                    <>
                      <span ref={locDescRef}>{initData?.analysisResult.locationDescription}</span>
                      <span> - </span>
                      <span ref={geoIdRef}>[{initData?.analysisResult?.geoId}]</span>
                    </>
                  }
                </p>
                <DropDownButton
                  style={{ marginLeft: 10, width: "6rem" }}
                  icon="fa-solid fa-copy"
                  items={[{ name: t("geoId"), value: t("geoId") }, { name: t("location"), value: t("location") }]}
                  dropDownOptions={{ width: MaxDropdownItemWidthCalculator([{ name: t("geoId"), value: t("geoId") }, { name: t("location"), value: t("location") }]) }}
                  displayExpr="name"
                  onItemClick={e => copyTextToClipboard(e.itemData.name)}
                  stylingMode="text"
                  hoverStateEnabled={false}
                  focusStateEnabled={false}
                />
                <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
              </div>
            )}
            <div className="row" style={{ marginTop: 15 }}>
            </div>
            <div className="leftColumn">
              <Button
                onClick={goBackPermission}
                icon="fa-solid fa-arrow-left"
                hint={t("goBack")}
              />
            </div>
            <div className="rightColumn">
              <ShareURL
                url={url}
              />
            </div>
          </div>
          <div className={"dx-card "}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />
            {selectedIndex === 0 &&
              <Details
                chartInitData={initData}
                initData={initData}
                generalSetting={generalSetting}
              />
            }
            {selectedIndex === 1 &&
              <Map
                initData={initData?.analysisResult}
                componentMode={LocationPickerMode.TextBox}
                didMount={true}
                renderMap={true}
                tesModule={TesMapModule.Sign}
              />
            }
            {selectedIndex === 2 &&
              <VolumeRawData
                initData={initData}
                generalSettings={generalSetting}
              />
            }
            {selectedIndex === 3 &&
              <VolumeResults
                initData={initData}
                generalSettings={generalSetting}
              />
            }
            {selectedIndex === 4 &&
              <SignalWarrantsTab
                initData={initData?.analysisResult}
              />
            }
            {selectedIndex === 5 &&
              <Studies
                intersectionId={initData.analysisResult.locationId!}
                isLocked={isLocked}
                validationRef={validationRef}
                generalSettings={generalSetting}
              />
            }
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default ProjectDetails;
