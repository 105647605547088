import { Button } from "devextreme-react/button";
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping, GroupPanel,
    HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    SearchPanel,
    StateStoring
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import CustomStore from 'devextreme/data/custom_store';
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { useClientSetting } from "../../contexts/clientSetting";
import { TrafficApiUrl } from "../../environment/routeSettings";
import LocationPopup from "../../pages/trafficStudy/importLogs/locationPopup/locationPupop";
import { GridType } from "../../types/general/enums/generalEnums";
import { LazyLoadingRequest, NameValue } from "../../types/general/generalTypes";
import { SectionDetailType } from "../../types/infrastructure/enums/infrastructureEnums";
import { TesCodeValue } from "../../types/infrastructure/infrastructureTypes";
import { ImportCheckResponse } from "../../types/trafficStudy/dtos/importDto";
import { StudyBaseDTO } from "../../types/trafficStudy/dtos/studyDto";
import { StudyStatus, StudyType } from "../../types/trafficStudy/enums/trafficStudyEnums";
import { SortObjectByPropName } from "../../utils/arrayTools";
import { OnExporting } from "../../utils/dataGridTools";
import { Enum2Array } from '../../utils/enumTools';
import { useScreenSize } from "../../utils/media-query";
import { TesPost } from "../../utils/rest";

interface IProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<any>>;
    label: string;
    importResponse: ImportCheckResponse;
    updateWorkOrder: (fileKey: string, workOrderNo: string, studyId: string, binMapId: string, hasData: boolean) => void;
    step2GridRef: React.RefObject<DataGridRef<any, any>>;
}

const WorkOrderPicker = (props: IProps) => {
    const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
    const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [studyBase, setStudyBase] = useState<StudyBaseDTO>(new StudyBaseDTO());
    const [selectedWorkOrderNo, setSelectedWorkOrderNo] = useState<string>("");
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const { generalSetting } = useClientSetting();
    const { activeLoading } = useAuth();
    const [dataSource, setDataSource] = useState<any>([]);
    const { isXLarge, is2xLarge } = useScreenSize();
    const [gridHeight, setGridHeight] = useState<number | string>("34.5em")
    const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());
    const [lstSectionType, setLstSectionType] = useState<NameValue[]>([]);

    useEffect(() => {
        if (isXLarge) {
            setGridHeight("47em");
        }
        else if (is2xLarge) {
            setGridHeight(900)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                setDataSource(new CustomStore({
                    load: async (loadOption) => {
                        if (props.showModal) return (await TesPost(
                            `${TrafficApiUrl()
                            }/api/Study/GetCustomerStudyByTypeLazyLoading`,
                            {
                                customerId: localStorage.getItem("selectedCustomerId"),
                                divisionId: localStorage.getItem("selectedDivisionId"),
                                loadOptions: loadOption,
                            } as LazyLoadingRequest,
                            true
                        ));
                    }
                }))
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (props.showModal) {
            fetchMyAPI()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showModal]);

    useEffect(() => {
        if (props.showModal) {
            filterByLocationType();
            setLstSectionType(Enum2Array(SectionDetailType));

        }
    }, [props.showModal]);


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                setLstStudyType(Enum2Array(StudyType));
                setLstStudyStatus(Enum2Array(StudyStatus));
                setLstStudyType(Enum2Array(StudyType));
                setLstStudyStatus(Enum2Array(StudyStatus));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setSelectedWorkOrderNo(props.importResponse.workOrderNo)
    }, [props.importResponse.workOrderNo]);


    function onRowClicked(d: any) {
        var hasData = false;
        if (d.data.status !== StudyStatus.WorkOrderCreated && d.data.status !== StudyStatus.Started) {
            hasData = true;
        }
        props.setShowModal(false)
        setSelectedWorkOrderNo(d.data.workOrderNo);
        props.updateWorkOrder(props.importResponse.fileKey, d.data.workOrder.no, d.data.id, props.importResponse.binMapId, hasData);
    }


    async function onClosePopUp() {
        props.setShowModal(false)
    }


    function onNew() {
        setShowModalAdd(true);
        setStudyBase({ ...studyBase, workOrder: { ...studyBase.workOrder, no: selectedWorkOrderNo } })
    }

    function filterByLocationType() {
        dataGridRef?.current?.instance().deselectAll();
        dataGridRef?.current?.instance().beginUpdate();
        dataGridRef?.current?.instance().columnOption("studyType", 'selectedFilterOperation', "equal");
        dataGridRef?.current?.instance().columnOption("studyType", 'filterValues', props.importResponse.studyType.toString());
        dataGridRef?.current?.instance().endUpdate();
    }

    function onMainPopupClose() {
        props.setShowModal(false);
        props.step2GridRef.current?.instance().refresh();
    }
    const headerFilterData = [
        {
            text: t("volume"),
            value: ['StudyCategory', '=', 1]
        },
        {
            text: t("speed"),
            value: ['StudyCategory', '=', 2]
        },
        {
            text: t("volume") + "," + t("classification"),
            value: ['StudyCategory', '=', 5]
        }
        , {
            text: t("volume") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 9]
        }
        , {
            text: t("volume") + "," + t("classification") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 13]
        },
        {
            text: t("speed") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 10]
        },
        {
            text: t("speed") + "," + t("classification"),
            value: ['StudyCategory', '=', 6]
        }
        , {
            text: t("speed") + "," + t("classification") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 14]
        }
    ];

    const headerLookup = [
        {
            name: t("volume"),
            value: 1
        },
        {
            name: t("speed"),
            value: 2
        },
        {
            name: t("volume") + "," + t("classification"),
            value: 5
        }
        , {
            name: t("volume") + "," + t("continuousCount"),
            value: 9
        }
        , {
            name: t("volume") + "," + t("classification") + "," + t("continuousCount"),
            value: 13
        },
        {
            name: t("speed") + "," + t("continuousCount"),
            value: 10
        },
        {
            name: t("speed") + "," + t("classification"),
            value: 6
        }
        , {
            name: t("speed") + "," + t("classification") + "," + t("continuousCount"),
            value: 14
        }
    ];

    return (
        <React.Fragment>
            <Popup
                width={"80%"}
                height={"95%"}
                visible={props.showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t("workOrderPicker")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onMainPopupClose()}
            >
                <div className="row" style={{ margin: "-1rem 0" }}>
                    <div className="rightColumn">
                        <Button
                            onClick={onNew}
                            icon="fa-solid fa-circle-plus"
                            text={t("workOrder")}
                        />
                    </div>
                </div>

                <DataGrid
                    id="gridContainer"
                    dataSource={dataSource}
                    ref={dataGridRef}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    onRowClick={onRowClicked}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    height={gridHeight}
                    onExporting={OnExporting}
                >
                    <Export enabled={true}
                        allowExportSelectedData={true}
                    />
                    <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                    <GroupPanel visible={true} /> {/* or "auto" */}
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup position={'top'} />

                    <StateStoring enabled={true} type="localStorage" storageKey={GridType.WorkOrderPicker.toString()} savingTimeout={500} />


                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />

                    <FilterRow visible={true}
                        applyFilter="auto" />

                    <HeaderFilter visible={true} />


                    <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />
                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>

                    <Column alignment="left" dataField="id" visible={false}
                        caption={t('id')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column alignment="left" dataField="customerId" visible={false}
                        caption={t('customerId')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column alignment="left" dataField="divisionId" visible={false}
                        caption={t('divisionId')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column alignment="left" dataField="studyNo" width={110}
                        caption={t('studyNo')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column alignment="left" dataField="workOrder.no" width={150}
                        caption={t('workOrderNo')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column alignment="left" dataField="geoId" width={120}
                        caption={t('geoId')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column><Column alignment="left" dataField="locationDescription"
                        caption={t('locationDescription')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="sectionType"
                        caption={t('sectionType')} visible={false}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={SortObjectByPropName(lstSectionType, "name")} valueExpr="value" displayExpr="name" />
                    </Column>
                    <Column
                        dataField="municipalityId"
                        visible={initDataTesCodeValues.municipalities?.length !== 0 && initDataTesCodeValues.municipalities !== undefined}
                        allowHeaderFiltering={true}
                        caption={t("municipality")}
                    >
                        <Search enabled />
                        <Lookup
                            dataSource={SortObjectByPropName(initDataTesCodeValues?.municipalities, "name")}
                            valueExpr="id"
                            displayExpr="name"
                        />
                        <HeaderFilter dataSource={initDataTesCodeValues?.municipalities?.map((x: any) => ({ text: x.name, value: x.id }))}>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>


                    <Column
                        dataField="jurisdictionId"
                        visible={initDataTesCodeValues.jurisdictions?.length !== 0 && initDataTesCodeValues.jurisdictions !== undefined}
                        allowHeaderFiltering={true}
                        caption={t("jurisdiction")}

                    >
                        <Search enabled />
                        <Lookup
                            dataSource={SortObjectByPropName(initDataTesCodeValues?.jurisdictions, "name")}
                            valueExpr="id"
                            displayExpr="name"
                        />
                        <HeaderFilter dataSource={initDataTesCodeValues?.jurisdictions?.map(x => ({ text: x.name, value: x.id }))}>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column dataField="studyType" width={120}
                        caption={t('studyType')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={SortObjectByPropName(lstStudyType, "name")} valueExpr="value" displayExpr="name" />
                    </Column>
                    <Column dataField="studyCategory" width={150}
                        caption={t('studyCategory')}

                    >
                        <HeaderFilter dataSource={headerFilterData}>
                            <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={headerLookup} valueExpr="value" displayExpr="name" />
                    </Column>

                    <Column dataField="status" width={100}
                        caption={t('status')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={SortObjectByPropName(lstStudyStatus, "name")} valueExpr="value" displayExpr="name" />

                    </Column>
                    <Column dataField="rawDataStartDT" width={170}
                        caption={t('startDT')}
                        dataType="datetime"
                        format={generalSetting?.dateTimeFormat}

                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column alignment="left" dataField="rawDataEndDT" width={170}
                        caption={t('endDT')}
                        dataType="datetime"
                        format={generalSetting?.dateTimeFormat}

                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column alignment="left" dataField="aadt.totalVolume" width={120}
                        caption={t('totalVolumeAadt')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        visible={false}
                        caption={t("trafficCounter")}
                    >

                    </Column>

                </DataGrid>
                <Button
                    className="tes-modal-btn-add rightColumn"
                    onClick={() => onClosePopUp()}
                    text={t("ok")}
                />
            </Popup>


            <LocationPopup
                label={t('workOrder')}
                importResponse={props.importResponse!}
                updateWorkOrder={props.updateWorkOrder}
                step2GridRef={props.step2GridRef}
                setShowModal={setShowModalAdd}
                showModal={showModalAdd}
                onAdd={onMainPopupClose}
            />


        </React.Fragment>
    );
}
export default WorkOrderPicker;