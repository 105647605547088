import { ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import FileUploader from 'devextreme-react/file-uploader';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientReport } from '../../../../../types/report/reportTypes';


// props
interface IPros {
    isLocked: boolean;
    setInitData: React.Dispatch<React.SetStateAction<any>>;
    initData: ClientReport,
    validationRef: React.RefObject<ValidationGroupRef>
}

const Attachments = (props: IPros) => {
    const [currentFileSelected, setCurrentFileSelected] = useState<any[] | undefined>([]);

    const { t } = useTranslation();

    useEffect(() => {
        async function fetchMyAPI() {
        }
        fetchMyAPI()
    });

    async function handleChangeFile(files: any) {
        var t = await fileToByteArray(files[0]);
        props.setInitData({ ...props.initData, reportFile: t });
    }



    function fileToByteArray(file: any) {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();
                let fileByteArray: number[] = [];
                reader.readAsArrayBuffer(file);
                reader.onloadend = (evt: any) => {
                    if (evt.target.readyState === FileReader.DONE) {
                        let arrayBuffer = evt.target.result,
                            array = new Uint8Array(arrayBuffer);
                        for (var byte of array) {
                            fileByteArray.push(byte);
                        }
                    }
                    resolve(fileByteArray);
                }
            }
            catch (e) {
                reject(e);
            }
        })
    }

    return (
        <React.Fragment>
            <ValidationGroup ref={props.validationRef}>
                <div className={"content-block"}>
                    <div className={" responsive-paddings"}>

                        <div className='row' style={{ margin: " -3rem 0", padding: "0" }}>

                            <FileUploader
                                className="reportSelectButton"
                                selectButtonText={t("selectFile")}
                                labelText=""
                                multiple={true}
                                uploadMode="useForm"
                                value={currentFileSelected}
                                onValueChanged={e => { setCurrentFileSelected(e.value); handleChangeFile(e.value) }}
                                disabled={props.isLocked}
                            />

                        </div>
                    </div>
                </div>
            </ValidationGroup>
        </React.Fragment>
    );


}
export default Attachments;