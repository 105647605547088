import { GridType } from "../general/enums/generalEnums";
import { GridShareStatus } from "./enums/settingsEnums";

interface IFilterPanel {
  filterEnabled: boolean;
}

export interface IGridSetting {
  columns: any[];
  allowedPageSizes: number[];
  filterPanel: IFilterPanel;
  filterValue: string[];
  searchText: string;
  pageSize: number;
  pageIndex: number;
}

export class UserGridProfile {
  id!: string;
  customerId!: string;
  gridType!: GridType;
  name!: string;
  isMine!: boolean;
  shareStatus!: GridShareStatus;
  gridSetting!: any;
}

export class GridProfile {
  id!: string;
  gridType!: GridType;
  isUserDefault!: boolean;
  ownerUserId!: string;
  customerId!: string;
  name!: string;
  gridSetting!: any;
  sharedGridProfiles!: SharedGridProfile[];
}

export class SharedGridProfile {
  id!: string;
  fromUserId!: string;
  toUserId!: string;
  toGroupId!: string;
  gridProfile!: GridProfile;
  gridProfileId!: string;
}

export class GeneralSetting {
  id!: string;
  name!: string;
  customerId!: string;
  webHomeImage!: string;
  currency!: string;
  isAmPm!: boolean;
  dateFormat!: string;
  dateTimeFormat!: string;
  timeFormat!: string;
  passwordExpression!: string;
  syncUrl!: string;
  rd!: string;
  syncInterval!: string;
}