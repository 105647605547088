import i18n from "../../../../../react-i18next.d";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("details"),
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("columnMap"),
    icon: "fa-solid fa-table-cells-large",
    content: "Work Order tab content",
  },

];
export default tabTitles;