import React, { useEffect, useState } from 'react';
import "./6.scss";
import { t } from 'i18next';
import { Justification, Justification6 } from '../../../../../../../types/trafficStudy/dtos/studyDto';
import { pedestrianDelayDataSource, pedestrianVolumeDataSource } from './data/data';
import {
    Chart,
    Series,
    ArgumentAxis,
    CommonSeriesSettings,
    Margin,
    Export,
    Legend,
    Pane,
    Border,
    Grid,
    MinorGrid,
    ValueAxis,
    Label,
    Font,
    Title,
    WholeRange,
    Connector,
} from 'devextreme-react/chart';
import { Form } from 'devextreme-react';
import { SimpleItem } from 'devextreme-react/form';

// props
interface IPros {
    justificationName: string,
    signalWarrants: Justification
}

const TabSix = (props: IPros) => {

    const [lstPedestrianDelayData, setLstPedestrianDelayData] = useState<any[]>([]);
    const [lstPedestrianVolumeData, setLstPedestrianVolumeData] = useState<any[]>([]);
    const [selectedJustification, setSelectedJustification] = useState<Justification6>();

    useEffect(() => {
        async function fetchMyAPI() {
            var justification6 = props.signalWarrants.justification6.find(x => x.name === props.justificationName);
            setLstPedestrianDelayData([...pedestrianDelayDataSource,{netPedestrianVolume: justification6?.pedestrianDelayVolume, selected:justification6?.pedestrianAdjustedVolume}])
            setLstPedestrianVolumeData([...pedestrianVolumeDataSource,{eightHourVehicularVolume: justification6?.vehicleVolume, selected:justification6?.pedestrianAdjustedVolume}])
            setSelectedJustification(justification6);
        }
        fetchMyAPI()
    }, [props.justificationName,props.signalWarrants]);

    
    function customizeLabel1(point: any) {
        return `${t("net8HoursPedestrianVolumeAdjusted")}: ${point.argumentText} \n
                ${t("netPedestrianDelays>10seconds8Hours")}: ${point.valueText}`;
    }

    function customizeLabel2(point: any) {
        return `${t("8HourVehicularVolume")}: ${point.argumentText} \n
                ${t("net8HoursPedestrianVolumeAdjusted")}: ${point.valueText}`;
    }

    return (
        <React.Fragment>
            <h2 className={"content-block"} style={{ margin: "1rem" }}>{t("justification6PedestrianDelay")}</h2>
            {selectedJustification?.note && selectedJustification.note !== "" && (
                <p style={{fontSize:"1rem", padding:"0", margin:"1.5rem 0 2rem 1rem"}}>
                    <span style={{fontWeight:"bolder"}}>{t("note")}:{" "}</span>{selectedJustification?.note} 
                </p>
            )}
            <Form colCount={2} style={{ marginTop: "-2rem" }}>
                <SimpleItem colSpan={1}>
                    <div id="chart-demo" >
                        <Chart
                            style={{ margin: "0rem 4rem", minHeight: "35rem" }}
                            palette="Harmony Light"
                            title={t("pedestrianDelay")}
                            dataSource={lstPedestrianDelayData}
                        >
                            <CommonSeriesSettings
                                argumentField="netPedestrianVolume"
                                hoverMode="none"
                            />
                            <Pane backgroundColor="#2EB62C">
                                <Border visible={true} width={2} />
                            </Pane>
                            <Series type="scatter" valueField="selected" name={t("selected")} opacity={0.5} color="deeppink">
                                <Label
                                    visible={true}
                                    format="fixedPoint"
                                    customizeText={customizeLabel1}
                                >
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>
                            <Series valueField="" name={t("justifiedZone")} opacity={0.5} color="#2EB62C"></Series>
                            <Series type="area" valueField="netPedestrianDelay" name={t("notJustified")} opacity={0.5} color="#bce4ae"/>
                            <ValueAxis>
                                <WholeRange endValue={250} />
                                <Title text={t("netPedestrianDelays>10seconds8Hours")} margin={15}></Title>
                                <MinorGrid visible={true} opacity={0.6} />
                                <Label>
                                    <Font size={13} weight={400} />
                                </Label>
                            </ValueAxis>
                            <ArgumentAxis valueMarginsEnabled={false}>
                                <Title text={t("net8HoursPedestrianVolumeAdjusted")} margin={15}></Title>
                                <Grid visible={true} />
                                <MinorGrid visible={true} opacity={0.6} />
                                <Label>
                                    <Font size={13} weight={400}></Font>
                                </Label>
                            </ArgumentAxis>
                            <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                visible={true}
                                margin={{ left: 55 }}
                                hoverMode="none"
                            >
                                <Font weight={400} />
                            </Legend>
                            <Margin bottom={20} />
                            <Export enabled={true} printingEnabled={false} />
                        </Chart>
                    </div>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                    <div id="chart-demo" >
                        <Chart
                            style={{ margin: "0rem 4rem", minHeight: "35rem" }}
                            palette="Harmony Light"
                            title={t("pedestrianVolume")}
                            dataSource={lstPedestrianVolumeData}
                        >
                            <CommonSeriesSettings
                                argumentField="eightHourVehicularVolume"
                                hoverMode="none"
                            />
                            <Pane backgroundColor="#2EB62C">
                                <Border visible={true} width={2} />
                            </Pane>
                            <Series type='scatter' valueField="selected" name={t("selected")} opacity={0.5} color="deeppink">
                                <Label
                                    visible={true}
                                    format="fixedPoint"
                                    customizeText={customizeLabel2}
                                >
                                    <Connector visible={true} width={1} />
                                </Label>
                            </Series>
                            <Series valueField="" name={t("justifiedZone")} opacity={0.5} color="#2EB62C"></Series>
                            <Series  type="area" valueField="netPedestrianVolume" name={t("notJustified")} opacity={0.5} color="#bce4ae"/>
                            <ValueAxis>
                                <WholeRange endValue={1200} />
                                <Title text={t("net8HoursPedestrianVolumeAdjusted")} margin={15}></Title>
                                <MinorGrid visible={true} opacity={0.5} />
                                <Label>
                                    <Font size={13} weight={400} />
                                </Label>
                            </ValueAxis>
                            <ArgumentAxis valueMarginsEnabled={false}>
                                <Title text={t("8HourVehicularVolume")} margin={15}></Title>
                                <Grid visible={true} />
                                <MinorGrid visible={true} opacity={0.5} />
                                <Label>
                                    <Font size={13} weight={400}></Font>
                                </Label>
                            </ArgumentAxis>
                            <Legend
                                verticalAlignment="bottom"
                                horizontalAlignment="center"
                                visible={true}
                                margin={{ left: 55 }}
                                hoverMode="none"
                            >
                                <Font weight={400} />
                            </Legend>
                            <Margin bottom={20} />
                            <Export enabled={true} printingEnabled={false} />
                        </Chart>
                    </div>
                </SimpleItem>
            </Form>
        </React.Fragment>
    );


}

export default TabSix;