import i18n from "../../../../../../../react-i18next.d";
i18n.loadNamespaces("tabs");

const tabTitles = [
  {
    id: 0,
    text: i18n.t("urbanArterial"),
    content: i18n.t("urbanArterial"),
  },
  {
    id: 1,
    text: i18n.t("collectorRuralArterial"),
    content: i18n.t("collectorRuralArterial"),
  },
  {
    id: 2,
    text: i18n.t("local"),
    content:  i18n.t("local"),
  },
  {
    id: 3,
    text: i18n.t("collisionExperience"),
    content:  i18n.t("local"),
  },
]

export default tabTitles;