import design from './rangeValidator.module.scss';

interface IProps {
  minGoal: number;
  maxGoal: number;
  goal: number;
  value: number;
  unit : string;
  goalText: string;
}

const RangeValidator = (props:IProps) => {

  const percntCal = (intervalStart:number, intervalEnd: number) => {
    return  Math.abs((intervalEnd - intervalStart) / (props.maxGoal - props.minGoal) * 100)
  }

  const leftWidth: number = percntCal(props.minGoal, 0)
  const midWidth: number = percntCal(props.goal,0)
  const rightWidth: number = 100 - (leftWidth + midWidth)
  const goalLoc: number = leftWidth + midWidth
  const valueLoc: number = percntCal(props.value, props.minGoal)
  const tooltipColors: string[] = props.value <= 0 ? ["#DE3E32", "white"] : (props.value >= props.goal ? ["#32A350", "white"] : ["#FBBF03", ""])

  return (
    <div className={design.container} style={{ position: 'relative'}}>

      {/* Left block */}
      <div className={design.label} style={{ left: 0, top: 6 }}>{props.minGoal}{props.unit}</div>
      <div className={`${design.sections} ${design.redSection}`} style={{ width: `${leftWidth}%` }} />
      <div className={design.label} style={{ left: `${leftWidth}%`, top: 6 }}>0{props.unit}</div>

      {/* Mid block */}
      <div className={`${design.sections} ${design.yellowSection}`} style={{ width: `${midWidth}%` }} />
      <div className={design.label} style={{ left: props.unit === "" ? `calc(${goalLoc}% - 15px)` : `calc(${goalLoc}% - 23px)`, top: 6, fontWeight: "bolder" }}>{props.goalText}{props.goalText === "" ? "" : ": "}{props.goal}{props.unit}</div>

      {/* Right block */}
      <div className={design.label} style={{ right: 0, top: 6 }}>{props.maxGoal}{props.unit}</div>
      <div className={`${design.sections} ${design.greenSection}`} style={{ width: `${rightWidth}%` }} />
      
      {/* Goal indicator */}
      <div className={design.relativeDiv} style={{ left: `${valueLoc}%`, top: -3 }} />
      <div className={design.tooltip} style={{  left: props.unit === "" ? `calc(${valueLoc}% - 6px)` : `calc(${valueLoc}% - 11px)`, top: -30, backgroundColor: `${tooltipColors[0]}` , color: `${tooltipColors[1]}` }} >{props.value}{props.unit}</div>
    </div>
  );
};

export default RangeValidator;
