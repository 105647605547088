import { Form } from 'devextreme-react';
import { SimpleItem } from 'devextreme-react/form';
import { ValidationGroup, ValidationGroupRef } from 'devextreme-react/validation-group';
import React from 'react';
import InfrastructureLocationPicker from '../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker';
import { TesMapModule } from '../../../../../types/general/enums/generalEnums';
import { LocationPickerMode } from '../../../../../types/infrastructure/enums/infrastructureEnums';
import { Location } from '../../../../../types/infrastructure/infrastructureTypes';

interface IPros {
    location: Location;
    setLocation: React.Dispatch<React.SetStateAction<any>>
    isLocked: boolean;
    pageMode: number,
    validationRef: React.RefObject<ValidationGroupRef>
}

const LocationMap = (props: IPros) => {

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <ValidationGroup ref={props.validationRef}>
                    <Form colCount={2}>

                        <SimpleItem colSpan={2}>
                            {props.location &&
                                <InfrastructureLocationPicker
                                    didMount={true}
                                    initLocationData={{
                                        customerId: props.location.customerId,
                                        id: props.location.id,
                                    }}
                                    tesModule={TesMapModule.InfrastructureDetails}
                                    componentMode={LocationPickerMode.None}
                                    renderMap={true}
                                    renderCoordinate={false}
                                    showIcons={true}
                                    selectedMapLocation={{ latitude: props.location.latitude, longitude: props.location.longitude, xCoord: props.location.xCoord, yCoord: props.location.yCoord }}
                                />
                            }
                        </SimpleItem>


                    </Form>
                </ValidationGroup>
            </div>

        </React.Fragment>
    );
}
export default LocationMap;