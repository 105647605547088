import { useEffect, useState } from "react";
import { loadModules } from "esri-loader";
import { isLayerExist } from "../utils";

export function useCustomLayers({ map, mapEl, initDataGis }) {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  useEffect(() => {
    if (
      map &&
      initDataGis !== null &&
      mapEl &&
      initDataGis?.gisLayers?.length > 0
    ) {
      setIsLayerLoading(true);
      loadModules(["esri/layers/FeatureLayer"]).then(([FeatureLayer]) => {
        const featureLayers = [];
        initDataGis.gisLayers.forEach((layer) => {
          featureLayers.push(
            new FeatureLayer({
              url: layer.url,
              title: layer.name,
              id: `${layer.name}-layer`,
              popupTemplate: {
                lastEditInfoEnabled: false,
              },
            })
          );
        });

        featureLayers.forEach((layer) => {
          if (!isLayerExist(map, `${layer.name}-layer`)) {
            map.add(layer);
          }
        });

        setIsLayerLoading(false);
      });
    }
  }, [mapEl, initDataGis, map]);

  return {
    isCustomLayerLoading: isLayerLoading,
  };
}
