import {useEffect, useRef, useState} from "react";
import { loadModules } from "esri-loader";
import { TesMapModule } from "../../../types/general/enums/generalEnums";
import { useTranslation } from "react-i18next";
import { isLayerExist, layerSuccessfullyLoadedInDomCallback } from "../utils";
import {LocationType} from "../../../types/infrastructure/enums/infrastructureEnums";

export function useIntersectionLayer({
  view,
  map,
  mapEl,
  initDataGis,
  tesModule,
}) {
  const { t } = useTranslation();

  const intersectionLayer= useRef(null);
  const [intersectionUId, setIntersectionUId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function getIntersectionPopupAction() {
    let lstIntersectionsActions = [];
    if (tesModule.toString() === TesMapModule.Collision.toString()) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show all collisions",
        },
        {
          id: "new-collision-intersection",
          image: "https://staticfile.tes.ca/gisMap/plus.png",
          title: "Add new collision",
        },
      ];
    }

    if (
      tesModule.toString() === TesMapModule.CollisionDetails.toString() ||
      tesModule.toString() === TesMapModule.StudyDetails.toString() ||
      tesModule.toString() === TesMapModule.CollisionGeoCode.toString()
    ) {
      lstIntersectionsActions = [
        {
          id: "select-intersection",
          image: "https://staticfile.tes.ca/gisMap/select.png",
          title: "Select This Infrastructure",
        },
      ];
    }

    if (
      tesModule.toString() ===
      TesMapModule.CollisionOverviewDashboard.toString()
    ) {
      lstIntersectionsActions = [
        {
          id: "go-to-intersection",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show intersection details",
        },
      ];
    }

    if (
      tesModule.toString() === TesMapModule.Infrastructure.toString() ||
      tesModule.toString() === TesMapModule.InfrastructureDetails.toString()
    ) {
      lstIntersectionsActions = [
        {
          id: "go-to-intersection",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show intersection details",
        },
        {
          id: "open-google-maps",
          image: "https://staticfile.tes.ca/gisMap/googleMaps.png",
          title: "Google Maps",
        },
        {
          id: "street-view",
          image: "https://staticfile.tes.ca/gisMap/streetView.png",
          title: "Street View",
        },
      ];
    }

    if (
      tesModule.toString() === TesMapModule.Sign.toString() ||
      tesModule.toString() === TesMapModule.Support.toString()
    ) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title:
            tesModule.toString() === TesMapModule.Support.toString()
              ? t("showSupports")
              : tesModule.toString() === TesMapModule.Sign.toString()
                ? t("showSigns")
                : "",
        },
      ];
    }
    if (tesModule.toString() === TesMapModule.Study.toString()) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show all studies",
        },
      ];
    }
    if (tesModule.toString() === TesMapModule.AADT.toString()) {
      lstIntersectionsActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/intersectionType.png",
          title: "Show all AADTs",
        },
      ];
    }

    return lstIntersectionsActions;
  }
  const getIntersectionFieldInfos = () => {
    const layerAttributes = initDataGis?.gisLayerAttributes
    let filedInfos = [{
      fieldName: initDataGis.intersectionGeoIdName,
      label: "Geo ID",
    }];
    if(layerAttributes?.length > 0) {
      layerAttributes.forEach((attribute) => {
        if(attribute.layerType === LocationType.Intersection) {
        filedInfos.push({
          fieldName: attribute.name,
          label: attribute.label,
        });
        }
      })
    }
    return filedInfos;
  }

  useEffect(() => {
    if (map && initDataGis !== null && mapEl && view) {
      if (isLayerExist(map, "intersection-layer")) return;
      setIsLoading(true);
      loadModules(["esri/layers/FeatureLayer"]).then(([FeatureLayer]) => {
        const featureLayers = new FeatureLayer({
          title: "Intersection",
          id: "intersection-layer",
          outFields: [initDataGis.intersectionGeoIdName],
          apiKey: initDataGis.arcGisApiKey,
          url: initDataGis.intersectionFileAddress,
          copyright: "TNS",
          minScale: 0, // Always visible
          maxScale: 0, // Always visible,
          popupTemplate: {
            actions: getIntersectionPopupAction(),
            content: [
              {
                type: "fields",
                fieldInfos: getIntersectionFieldInfos()
              },
            ],
          },
          renderer: {
            type: "simple",
            symbol: {
              type: "simple-marker",
              color: "#FFFF00",
              size: 5,
              outline: { color: [0, 0, 0], width: 1 },
            },
          },
        });

        featureLayers.load().then(() => {
          map.add(featureLayers);
        });

        intersectionLayer.current = featureLayers;
        setIntersectionUId(featureLayers.uid);

        layerSuccessfullyLoadedInDomCallback(view, featureLayers, () => {
          setIsLoading(false);
        });
      });
    }
  }, [mapEl, initDataGis, map, view]);

  return {
    intersectionLayer: intersectionLayer.current,
    intersectionUId: intersectionUId,
    isIntersectionLoading: isLoading,
  };
}
