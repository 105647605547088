import i18n from "../../../../../react-i18next.d";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('results'),
        icon: 'fa-solid fa-file-lines',
        content: 'Details tab content',
    },
    {
        id: 0,
        text: i18n.t('holidaysAndEffectiveDays'),
        icon: 'fa-regular fa-calendar',
        content: 'Details tab content',
    },
];
export default tabTitles;