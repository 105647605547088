import i18n from "../../../../react-i18next.d";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("general"),
    icon: "fa-solid fa-info",
    content: "General tab content",
  },
  {
    id: 1,
    text: i18n.t("divisions"),
    icon: "fa-solid fa-layer-group",
    content: "Divisions tab content",
  },

];

const withoutDivisionTabTitles = [
  {
    id: 0,
    text: i18n.t("general"),
    icon: "fa-solid fa-info",
    content: "General tab content",
  },

];

export {tabTitles, withoutDivisionTabTitles};