import { Button, DataGrid } from 'devextreme-react';
import { Column, ColumnChooser, DataGridRef, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, Search, SearchPanel } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import { t } from 'i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from '../../../constants/Permissions';
import { useAuth } from '../../../contexts/auth';
import { TranslationApiUrl } from '../../../environment/routeSettings';
import { ResponseCode } from '../../../types/general/enums/generalEnums';
import { LanguageDetailsDto, LanguageDetailsDtoListRequestResponseResult } from '../../../types/translation/dtos/translationDtos';
import { LanguageType } from '../../../types/translation/enums/translationEnums';
import { TesGet } from '../../../utils/rest';

const Groups = () => {

  const { activeLoading } = useAuth();
  const [initDataBaseLangs, setInitDataBaseLangs] = useState<LanguageDetailsDto[]>([])
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const history = useNavigate();


  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData()
        // setLstLanguageType(Enum2Array(LanguageType))
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  async function getInitData() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        TranslationApiUrl() + "/api/Languages/GetAllBaseLanguages" + `?languageType=${LanguageType.Groupe}`,
        true
      )) as LanguageDetailsDtoListRequestResponseResult;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitDataBaseLangs(res.results)
      } else {
       // await RequestErrorHandling(res);
      }
    }
    catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  const navigateToDetails = useCallback(() => {
    history("/translation/groupDetails/AddNew");
  }, [history]);
  
  const onRowClick = (d:any)  => {
    history(`/translation/groupDetails/${d.data.id}`);
  };

  return (
    <Permission
    allowed={[TMSPermissions.TMS_Admin]}
    hasFeedBackElement={true}
  >
    {/* <div className={compactViewModel ? "compactStyle" : ""}> */}
      <React.Fragment>
        <h2 className={"content-block"}>{t("groups")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                  <Button
                    onClick={navigateToDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("group")}
                  />
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initDataBaseLangs}
              rowAlternationEnabled={true}
              showBorders={true}
              onRowClick={e => onRowClick(e)}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              // onExporting={OnExporting}
              // onContextMenuPreparing={e => { setSelectedRowData(e) }}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="languageName" caption={t("name")}>
                <HeaderFilter>
                    <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                    <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="baseLanguageId" caption={t("baseLanguageId")} visible={false}>
                <HeaderFilter>
                    <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="languageCode" caption={t("languageCode")}>
                <HeaderFilter>
                    <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="parentLanguageName" caption={t("parentLanguage")}>
                <HeaderFilter>
                    <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="imageAddress" caption={t("imageUrl")}>
                <HeaderFilter>
                    <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="description" caption={t("description")}>
                <HeaderFilter>
                    <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
        </div>
      </React.Fragment>
    {/* </div> */}
  </Permission>
  );
};

export default Groups;