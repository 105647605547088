import i18n from "../../../../react-i18next.d";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("details"),
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("pages"),
    icon: "fa-regular fa-clone",
    content: "Details tab content",
  },
  {
    id: 2,
    text: i18n.t("attachments"),
    icon: 'fa-solid fa-paperclip',
    content: 'Attachments tab content',
},
 
];
export default tabTitles;