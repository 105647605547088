import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Pager,
  Paging,
  Search,
  Button as DevExpressButton,
  DataGridRef
} from "devextreme-react/data-grid";
import { t } from 'i18next';
import { TesGet, TesPost } from "../../../utils/rest";
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from "../../../constants/Permissions";
import { AuthApiUrl, ReportApiUrl } from "../../../environment/routeSettings";
import { Button, Form, Lookup, ScrollView, SelectBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { MultipleClientReport } from "../../../types/report/reportTypes";
import { useAuth } from "../../../contexts/auth";
import notify from "devextreme/ui/notify";
import FileUploader from 'devextreme-react/file-uploader';
import design from './multiClientReporting.module.scss'
import { UpdateBatchReportsDTO } from "../../../types/report/dto/reportDTO";

interface nameId {
  id: string;
  name: string;
}

const MultiClientsReporting = () => {

  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [clients, setClients] = useState<nameId[]>([]);
  const [reports, setReports] = useState<nameId[]>([]);
  const [addingClientId, setAddingClientId] = useState<string>("");
  const [addingReportId, setAddingReportId] = useState<string>("");
  const [gridData, setGridData] = useState<MultipleClientReport[]>([])
  const { activeLoading } = useAuth();
  const [currentFileSelected, setCurrentFileSelected] = useState<any[] | undefined>([]);
  const [postObj, setPostObj] = useState<UpdateBatchReportsDTO>(new UpdateBatchReportsDTO)

  useEffect(() => {
    GetClients();
  }, []);

  useEffect(() => {
    // Skip the initial execution on page load
    if (addingClientId !== "") {
      GetReports();
    }
  }, [addingClientId]);

  async function GetClients() {
    setClients(
      await TesGet(
        AuthApiUrl() + "/api/CustomerXUsers/ClientsOfUser",
        true
      )
    );
  }

  async function GetReports() {
    try {
      if (activeLoading) activeLoading(true);
      setReports(
        await TesGet(
          ReportApiUrl() + "/api/ClientReports/" + addingClientId,
          true
        )
      );
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onAdd() {
    const selectedClient = clients.find(client => client.id === addingClientId);
    const selectedReport = reports.find(report => report.id === addingReportId);

    if (selectedClient && selectedReport) {
      const newGridData = [{
        clientName: selectedClient.name,
        reportName: selectedReport.name
      }, ...gridData];

      setGridData(newGridData);

      dataGridRef.current?.instance().refresh();

      setPostObj({ ...postObj, ReportIds: [...postObj.ReportIds, addingReportId] })

      setAddingClientId("");
      setAddingReportId("");
    }
  }

  function onDelete(e: any) {
    const updatedGridData = gridData.filter(row => row !== e.row.data);
    setGridData(updatedGridData);
    dataGridRef.current?.instance().refresh();
  }

  async function handleChangeFile(files: any) {
    var t = await fileToByteArray(files[0]);
    setPostObj({ ...postObj, ReportFile: t as any[] });
  }

  function fileToByteArray(file: any) {
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        let fileByteArray: number[] = [];
        reader.readAsArrayBuffer(file);
        reader.onloadend = (evt: any) => {
          if (evt.target.readyState === FileReader.DONE) {
            let arrayBuffer = evt.target.result,
              array = new Uint8Array(arrayBuffer);
            for (var byte of array) {
              fileByteArray.push(byte);
            }
          }
          resolve(fileByteArray);
        }
      }
      catch (e) {
        reject(e);
      }
    }
    )
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      await TesPost(ReportApiUrl() + "/api/ClientReports/updateBatchReports", postObj, true);
      if (activeLoading) activeLoading(false);

      setClients([]);
      setReports([]);
      setAddingClientId("");
      setAddingReportId("");
      setGridData([])
      setCurrentFileSelected([]);
      setPostObj(new UpdateBatchReportsDTO)

      dataGridRef.current?.instance().refresh();

      GetClients();

      notify(t("dataSuccessfullyUpdated"), "success", 5000);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <h2 className={"content-block"}>{t("multiClientReporting")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"} style={{ paddingTop: "0.5rem", paddingBottom: "1rem" }}>
            <ScrollView width="100%" height="100%">
              <Form colCount={18} style={{ marginTop: "0rem" }}>
                <SimpleItem colSpan={8}>
                  <SelectBox
                    label={t("client")}
                    labelMode='floating'
                    value={addingClientId}
                    onValueChanged={(e) => setAddingClientId(e.value)}
                    className="modalInput"
                    valueExpr={"id"}
                    displayExpr={"name"}
                    items={SortObjectByPropName(
                      clients.filter(client => !gridData.some(data => data.clientName === client.name)),
                      "name"
                    )}
                    searchEnabled={true}
                  >
                  </SelectBox>
                </SimpleItem>
                <SimpleItem colSpan={8}>
                  <SelectBox
                    label={t("report")}
                    labelMode='floating'
                    value={addingReportId}
                    onValueChanged={(e) => setAddingReportId(e.value)}
                    className="modalInput"
                    valueExpr={"id"}
                    displayExpr={"name"}
                    items={SortObjectByPropName(reports, "name")}
                    searchEnabled={true}
                    disabled={addingClientId === ""}
                  >
                  </SelectBox>
                </SimpleItem>
                <SimpleItem colSpan={2}>
                  <Button
                    className="tes-modal-btn-add"
                    style={{ marginTop: "0.5rem" }}
                    onClick={() => onAdd()}
                    text={t("add")}
                  />
                </SimpleItem>
                <SimpleItem colSpan={18}>
                  <DataGrid id="gridContainer"
                    ref={dataGridRef}
                    dataSource={gridData}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    style={{ margin: "-2.5rem 1rem 0 1rem" }}
                  >
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[10, 20, 50, 100]}
                      showNavigationButtons={true}
                      showInfo={true}
                      visible={true}
                    />
                    <FilterRow visible={true} applyFilter="auto" />
                    <HeaderFilter visible={true} />
                    <Column dataField="clientName"
                      caption={t('client')}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="reportName"
                      caption={t('report')}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                      <DevExpressButton hint={t('delete')}
                        onClick={e => onDelete(e)}
                        icon="fa-solid fa-trash-can"
                      />
                    </Column>
                  </DataGrid>
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn" style={{ marginLeft: "0.75rem" }}>
                  <FileUploader
                    className={`reportSelectButton ${design.attachment}`}
                    selectButtonText={t("selectFile")}
                    labelText=""
                    multiple={true}
                    uploadMode="useForm"
                    value={currentFileSelected}
                    onValueChanged={e => { setCurrentFileSelected(e.value); handleChangeFile(e.value) }}
                  />
                </div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-add"
                    style={{ marginRight: 15, marginTop: "1rem" }}
                    onClick={onUpdate}
                    text={t("update")}
                  />
                </div>
              </div>
            </ScrollView>
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};

export default MultiClientsReporting;

