import { Button } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { CustomerPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { ReportApiUrl } from "../../../environment/routeSettings";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { IClientReportParams } from "../../../types/report/dto/reportDTO";
import { ClientReport, TesReportType } from "../../../types/report/reportTypes";
import { RequestErrorHandling, TesDelete, TesGet, TesPost } from "../../../utils/rest";
import "./clientReportDetails.scss";
import Attachments from "./components/attachments/attachments";
import Details from "./components/details/details";
import ReportLocations from "./components/pages/pages";
import tabTitles from "./data/index";

const ClientReportDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initData, setInitData] = useState<ClientReport>(new ClientReport());
  const [initDataReportType, setInitDataReportType] = useState<TesReportType[]>([new TesReportType()])
  const params = useParams<IClientReportParams>();
  const generalValidationRef = useRef<ValidationGroupRef>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isLocked, setIsLocked] = useState(true);
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitDataReportTypes();
        if (params.reportId !== "AddNew") {
          await getInitData(params.reportId!);
        } else {
          setIsLocked(false)
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.reportId]);

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  //General Functions
  async function getInitData(id: string) {
    try {
      let res = await TesGet(
        ReportApiUrl() + "/api/ClientReports/ClientReportsDetails/" + id,
        true
      ) as RequestResponseResult<ClientReport>;
      if (res.responseCode === ResponseCode.OK) {
        // res.results.selectedStudyTypes=EnumFlag2Array(StudyType, res.results.studyTypes)
        setInitData(res.results)
      }
      else if (res.responseCode === ResponseCode.IdNotExists) {
        notify(t("idNotExists"), "error", 5000)
        history(-1);
      }
      else {
        await RequestErrorHandling(res);;
      };
    } catch (ex) {
      notify(t("errorInFetchData") + ex, "error", 5000);
    }
  }
  async function getInitDataReportTypes() {
    setInitDataReportType(await TesGet(ReportApiUrl() + "/api/TesReportTypes", true));
  }
  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.reportId !== "AddNew") {
                  onUpdate(true);
                  setDataChanged(false)
                } else {
                  onAdd();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              // setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function onAdd() {
    try {
      const validationRes = generalValidationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...initData,
          customerId: localStorage.getItem("selectedCustomerId")
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          ReportApiUrl() + "/api/ClientReports/add",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          history(-1);
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyAdded"), "success", 5000);
        } else {
          await RequestErrorHandling(res);;
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate(backPermission: boolean) {
    try {
      const validationRes = generalValidationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        const postObj = {
          ...initData,
          customerId: localStorage.getItem("selectedCustomerId")
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          ReportApiUrl() + "/api/ClientReports/update",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          if (backPermission === true) {
            history(-1);
          }
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
        } else {
          await RequestErrorHandling(res);;
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }
  async function onDelete() {
    //dialog to show if you want to save the changed the data or discard it.
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: (e) => {
            deleteData();
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("cancel"),
          onClick: (e) => {
            // setBackPermission(false);
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function deleteData() {
    try {

      if (activeLoading) activeLoading(true);
      let res = (await TesDelete(
        ReportApiUrl() + "/api/ClientReports/" + initData.id,
        true
      )) as RequestResponseResult<null>;
      if (res.responseCode === ResponseCode.OK) {
        history(-1);
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyDeleted"), "success", 5000);
      } else {
        await RequestErrorHandling(res);;
      }

    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[CustomerPermissions.Customer_D_Report]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.intersectionId !== "AddNew" && (
              <div style={{ marginTop: "1rem" }}>
                <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                  <span>{t('name')}: {initData?.name}</span>
                </p>
                <Button
                  onClick={() => copyTextToClipboard()}
                  icon="fa-solid fa-copy"
                  hint={t("copy")}
                />
                <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
              </div>
            )}
            <div className="row">
              <div className="leftColumn">
                <Button
                  onClick={() => goBackPermission()}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">

                {params.reportId === "AddNew" && (
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={() => onAdd()}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("save")}
                  />
                )}
                {params.reportId !== "AddNew" && isLocked === false && (
                  <Button
                    style={{ marginRight: 8 }}
                    onClick={() => onUpdate(false)}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("update")}
                  />
                )}
                {params.reportId !== "AddNew" && isLocked === false && (
                  <Button
                    onClick={() => onDelete()}
                    icon="fa-solid fa-trash"
                    hint={t("delete")}
                  />
                )}
                {params.reportId !== "AddNew" && (
                  <ShareURL
                    url={url}
                  />
                )}
                <Button
                  style={{ marginLeft: 8 }}
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
            </div>
          </div>
          <div className={"dx-card"}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />
            {selectedIndex === 0 && (
              <Details
                initData={initData}
                initDataReportType={initDataReportType}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
                validationRef={generalValidationRef}
              />
            )}

            {selectedIndex === 1 && (
              <ReportLocations
                initData={initData}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
                validationRef={generalValidationRef}
              />
            )}


            {selectedIndex === 2 && (
              <Attachments
                isLocked={isLocked}
                initData={initData}
                setInitData={setInitData}
                validationRef={generalValidationRef}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default ClientReportDetails;
