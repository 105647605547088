import { FieldType, SimplifiedFieldCategory, SimplifiedTesField } from "../../../../types/field/enums/fieldEnums";

const data = [
    { name: "GeoId", fieldType: FieldType.String, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.GeoId },
    { name: "LocationDescription", fieldType: FieldType.String, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.LocationDescription },
    { name: "IsIntersection", fieldType: FieldType.Boolean, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.IsIntersection },
    {
        name: "SectionType", fieldType: FieldType.TesEnum, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.SectionType,
        values: [{ name: "FourLegIntersection", value: 1 }, { name: "TIntersection", value: 2 }]
    },
    {
        name: "SectionDetailType", fieldType: FieldType.TesEnum, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.SectionDetailType,
        values: [{ name: "FourLegIntersection", value: 1 }, { name: "NorthTIntersection", value: 2 }, { name: "EastTIntersection", value: 3 }, { name: "SouthTIntersection", value: 4 },
        { name: "WestTIntersection", value: 5 }]
    },
    { name: "PostedSpeed", fieldType: FieldType.Number, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.PostedSpeed },
    { name: "IsSignalized", fieldType: FieldType.Boolean, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.IsSignalized },
    { name: "TrafficControlClient", fieldType: FieldType.List, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.TrafficControlClient },
    { name: "RIN", fieldType: FieldType.String, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.RIN },

    {
        name: "TrafficControlTes", fieldType: FieldType.TesEnum, simplifiedFieldCategory: SimplifiedFieldCategory.SimplifiedIntersection, simplifiedTesField: SimplifiedTesField.TrafficControlTes,
        values: [{ name: "AllWayStop", value: 1 }, { name: "TwoWayStop", value: 2 }, { name: "Signalized", value: 3 }, { name: "PXO", value: 4 }, { name: "Roundabout", value: 4 }, { name: "Yield", value: 5 }]
    },

]

export default data;