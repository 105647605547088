export enum ReportType {
  Sign = 1,
  Support = 2,
  Job = 3,
  Collision = 4,
  Intersection = 5,
  RoadSegment = 6,
  ModuleData=8,

  //TrafficStudy
  TMCDiagram = 20,
  TMCDetails=21,
  SignalWarrant=22,
  AllWayWarrant=23,
  TmcPhDiagram = 24,
}

export enum ReportLocationType {
  Sign = 1,
  Support = 2,
  Job = 4,
  Collision = 8,
  Intersection = 16,
  RoadSegment = 32,
  TrafficStudy = 64,
  ModuleData = 128,
  TmcStudyResults= 256,
}
