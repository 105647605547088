import i18n from "../../../react-i18next.d";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("myProfile"),
    icon: "fa-solid fa-user",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("password"),
    icon: "fa-solid fa-key",
    content: "Work Order tab content",
  },

];
export default tabTitles;
