//imports
import { useState, useEffect, useCallback } from 'react';
import design from './emphasisArea.module.scss';
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import CheckBox from 'devextreme-react/check-box';
import DateBox from 'devextreme-react/date-box';
import notify from 'devextreme/ui/notify';
import { GeneralSettings, IDashboardResult, MainDashboardRequest } from '../../../../../types/collision/collisionTypes';
import { INameId, NameValue } from '../../../../../types/general/generalTypes';
import { TesPost } from '../../../../../utils/rest';
import TagBox from 'devextreme-react/tag-box';
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Title, Export, Tooltip } from 'devextreme-react/chart';
import { GeneralSetting } from '../../../../../types/setting/gridSettingTypes';
import { CollisionApiUrl, InfrastructureApiUrl } from '../../../../../environment/routeSettings';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../contexts/auth';
import { DashboardReportType, QueryAvgType } from '../../../../../types/collision/enums/collisionEnums';
import { TesCodeValue } from '../../../../../types/infrastructure/infrastructureTypes';
import { Button, ContextMenu, DataGrid, SelectBox } from 'devextreme-react';
import { useScreenSize } from '../../../../../utils/media-query';
import { CreateDateAsUTC, DatePickerDateCalculator } from '../../../../../utils/dateTimeTools';
import { GetCollisionDatePeriodRequestDTO } from '../../../../../types/collision/dto/collisionDtos';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { Enum2Array } from '../../../../../utils/enumTools';
import { ColumnChooser, Pager, Paging, Export as GridExport, Search, FilterRow, HeaderFilter, SearchPanel, Column } from 'devextreme-react/data-grid';
import { RightClickMenu } from '../../../../../utils/rightClickMenu';
import { useNavigate } from 'react-router-dom';
import { IsNullOrEmpty } from '../../../../../utils/stringTools';

//props
interface IPros {
    initDataSiteList: INameId[] | undefined;
    initDataTesCodeValues: TesCodeValue;
    generalSettings: GeneralSetting | null;
    datePeriod: GetCollisionDatePeriodRequestDTO | undefined
    initDataCollisionGeneralSetting: GeneralSettings | undefined

}

const EmphasisArea = (props: IPros) => {
    //states
    const [initData, setInitData] = useState<IDashboardResult | undefined>(undefined);
    const [lstQueryAvgType, setLstQueryAvgType] = useState<NameValue[]>([]);
    const [selectedRowData, setSelectedRowData] = useState<any>(null);

    const [dashboardReq, setDashboardReq] = useState<MainDashboardRequest>({
        customerId: localStorage.getItem('selectedCustomerId')!,
        divisionId: localStorage.getItem("selectedDivisionId")!,
        reportType: DashboardReportType.EmphasisArea,
        severityList: [1, 2, 3],
        emphasisAreaIds: [...props.initDataCollisionGeneralSetting?.dashboardSettings?.emphasisAreaSettings?.map(x => x.fieldId)!],
        beginDateFrom: new Date(new Date(props.datePeriod?.endDT!).getFullYear() - 4, 0, 1, 0, 0, 0),
        beginDateTo: new Date(new Date(props.datePeriod?.endDT!).getFullYear(), 11, 31, 0, 0, 0),
        jurisdictionIds: [],
        municipalityIds: [],
        areaIds: [],
        topNLocation: 10,
        queryAvgType: QueryAvgType.AvgYearly
    });

    const FiveYearIcon = "/images/5YIcon.svg";
    const OneYearIcon = "/images/1YIcon.svg";
    const { activeLoading } = useAuth();
    const { t } = useTranslation();
    const { isXLarge } = useScreenSize();
    const history = useNavigate()
    const items = [
        // { text: t('openInNewTab'), icon:  "fa-solid fa-up-right-from-square"},
        { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
    ];

    //functions

    const applyFilter = useCallback(async () => {
        try {
            if (activeLoading) activeLoading(true);
            var postOb: MainDashboardRequest = {
                ...dashboardReq,
                beginDateFrom: CreateDateAsUTC(dashboardReq.beginDateFrom!),
                beginDateTo: CreateDateAsUTC(dashboardReq.beginDateTo!),
            }
            setInitData(await (TesPost(CollisionApiUrl() + "/api/Reports/GetMainDashboard", postOb, true)))
            if (activeLoading) activeLoading(false);

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 5000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardReq]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                setLstQueryAvgType(Enum2Array(QueryAvgType));
                await applyFilter();
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [applyFilter]);

    const onValueChange = (name: string, value: any) => {
        if (name === "severity") {
            var index = dashboardReq.severityList.indexOf(value)
            if (index !== -1) {
                var lstNewSeverity = dashboardReq.severityList.filter(x => x !== value)
                if (lstNewSeverity.length === 0) {
                    notify(t('oneItemShouldBeSelected'), 'warning', 5000);
                    return;
                }
                setDashboardReq({ ...dashboardReq, severityList: lstNewSeverity });
            } else {
                setDashboardReq({ ...dashboardReq, severityList: [...dashboardReq.severityList, value] });
            }
        } else if (name === "emphasisArea") {
            var index = dashboardReq.emphasisAreaIds.indexOf(value)
            if (index !== -1) {
                var lstNewEmphasisAreaIds = dashboardReq.emphasisAreaIds.filter(x => x !== value)
                if (lstNewEmphasisAreaIds.length === 0) {
                    notify(t('oneItemShouldBeSelected'), 'warning', 5000);
                    return;
                }
                setDashboardReq({ ...dashboardReq, emphasisAreaIds: lstNewEmphasisAreaIds });
            } else {
                setDashboardReq({ ...dashboardReq, emphasisAreaIds: [...dashboardReq.emphasisAreaIds, value] });
            }
        } else if (name === "beginDateFrom") {
            if (dashboardReq.beginDateTo! < value) {
                notify('Select valid date range.', "error", 5000);
                return;
            }
            setDashboardReq({ ...dashboardReq, beginDateFrom: value });
        } else if (name === "beginDateTo") {
            if (dashboardReq.beginDateFrom! > value) {
                notify('Select valid date range.', "error", 5000);
                return;
            }
            setDashboardReq({
                ...dashboardReq,
                beginDateTo: value
            })
        } else if (name === "siteList") {
            setDashboardReq({
                ...dashboardReq,
                areaIds: value
            })
        }
        else if (name === "municipality") {
            setDashboardReq({
                ...dashboardReq,
                municipalityIds: value
            })
        }
        else if (name === "jurisdiction") {
            setDashboardReq({
                ...dashboardReq,
                jurisdictionIds: value
            })
        }
        else if (name === "queryAvgType") {
            setDashboardReq({
                ...dashboardReq,
                queryAvgType: value
            })
        }
    }

    function getLastNYear(decYear: number) {
        var currentYear = new Date().getFullYear();
        setDashboardReq({
            ...dashboardReq,
            beginDateFrom: new Date(currentYear - decYear, 0, 1),
            beginDateTo: new Date(currentYear - 1, 11, 31)
        })
    }

    function onRoadSegmentRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

        if (isCtrlKeyPressed) {
            window.open(`/infrastructure/roadSegmentDetails/${e.data.locationId}`, "_blank");
        } else {
            history(`/infrastructure/roadSegmentDetails/${e.data.locationId}`);
        }
    }

    function onIntersectionRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

        if (isCtrlKeyPressed) {
            window.open(`/infrastructure/intersectionDetails/${e.data.locationId}`, "_blank");
        } else {
            history(`/infrastructure/intersectionDetails/${e.data.locationId}`);
        }
    }

    return (
        <div className={`${design.row}`}>
            <ContextMenu
                dataSource={items}
                width={100}
                target=".dx-data-row"
                onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, "", selectedRowData.row.data.locationType, selectedRowData.row.data.locationId)}
            />
            <div className={`${design.column1}`}>
                <CollapsibleCard compact='dashboard' title={t("severity")}>
                    <div className={`${design.collapsibleContentHorizontal}`}>
                        <CheckBox text={t("fatal")} value={dashboardReq.severityList.includes(3)} onValueChanged={() => onValueChange("severity", 3)}></CheckBox>
                        <CheckBox text={t("injury")} value={dashboardReq.severityList.includes(2)} onValueChanged={() => onValueChange("severity", 2)}></CheckBox>
                        <CheckBox text={t("PDO")} value={dashboardReq.severityList.includes(1)} onValueChanged={() => onValueChange("severity", 1)}></CheckBox>
                    </div>
                </CollapsibleCard>
                <CollapsibleCard compact='dashboard' title={t("emphasisArea")}>
                    <div className={`${design.collapsibleContentHorizontal}`}>
                        {(props.initDataCollisionGeneralSetting?.dashboardSettings?.emphasisAreaSettings?.filter(x => x.totalCollisions === false) ?? []).map(x => (
                            <CheckBox
                                text={x.title}
                                value={dashboardReq.emphasisAreaIds.includes(x.fieldId)}
                                onValueChanged={() => onValueChange("emphasisArea", x.fieldId)}
                                style={isXLarge ? { flex: "0 0 calc(50% - 10px)" } : { marginBottom: "0.25rem" }}
                            />
                        ))}
                    </div>
                </CollapsibleCard>
                <CollapsibleCard compact='dashboard' title={t("filter")}>
                    <div className={`${design.collapsibleContentVertical}`}>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: "20%", display: "inline-block" }}>{t("from")}:</span>
                            <DateBox
                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                value={dashboardReq.beginDateFrom}
                                type="date"
                                onValueChange={e => onValueChange('beginDateFrom', e)}
                                pickerType="calendar"
                                placeholder={props.generalSettings?.dateFormat}
                                displayFormat={props.generalSettings?.dateFormat}
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                                style={{ height: "2rem", marginBottom: "0.5rem", marginTop: "-0.5rem", width: "80%" }}
                            />
                        </div>
                        <div style={{ display: "flex" }}>
                            <span style={{ width: "20%", display: "inline-block" }}>{t("to")}:</span>
                            <DateBox
                                value={dashboardReq.beginDateTo}
                                dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                type="date"
                                onValueChange={e => onValueChange('beginDateTo', e)}
                                pickerType="calendar"
                                placeholder={props.generalSettings?.dateFormat}
                                displayFormat={props.generalSettings?.dateFormat}
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                                style={{ height: "2rem", marginBottom: "0.5rem", width: "80%" }}
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: isXLarge ? "row" : "column", justifyContent: isXLarge ? "space-around" : "center", alignItems: "center" }}>
                            <Button
                                icon={OneYearIcon}
                                text={t("lastYear")}
                                className="dateOptions"
                                style={{ marginBottom: "0.25rem", width: isXLarge ? "auto" : "100%" }}
                                onClick={() => {
                                    getLastNYear(1)
                                }
                                }
                            />
                            <Button
                                icon={FiveYearIcon}
                                text={t("lastFiveYears")}
                                className="dateOptions"
                                style={{ marginBottom: "0.25rem", width: isXLarge ? "auto" : "100%" }}
                                onClick={() => {
                                    getLastNYear(5)
                                }
                                }
                            />
                        </div>
                    </div>
                    <div className={`${design.collapsibleContentVertical}`}>
                        <SelectBox
                            label={t("collisionFrequency")}
                            value={dashboardReq.queryAvgType}
                            onValueChange={(e) => onValueChange("queryAvgType", e)}
                            className="modalInput"
                            valueExpr="value"
                            displayExpr="name"
                            items={SortObjectByPropName(lstQueryAvgType, "name")}
                            searchEnabled={true}
                            style={{ marginBottom: '5px' }}
                        />
                        <TagBox
                            label={t("siteList")}
                            dataSource={props.initDataSiteList}
                            valueExpr="id"
                            displayExpr="name"
                            onValueChanged={a => onValueChange("siteList", a.value)}
                            searchEnabled={true}
                            style={{ marginBottom: '5px' }}

                        />
                        {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) && props.initDataTesCodeValues.jurisdictions?.length !== 0 && props.initDataTesCodeValues.jurisdictions !== undefined &&
                            <TagBox
                                label={t("jurisdiction")}
                                dataSource={SortObjectByPropName(props.initDataTesCodeValues.jurisdictions, "name")}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChanged={a => onValueChange("jurisdiction", a.value)}
                                searchEnabled={true}
                                style={{ marginBottom: '5px' }}
                            />
                        }
                        {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) && props.initDataTesCodeValues.municipalities?.length !== 0 && props.initDataTesCodeValues.municipalities !== undefined &&
                            <TagBox
                                label={t("municipalities")}
                                dataSource={SortObjectByPropName(props.initDataTesCodeValues.municipalities, "name")}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChanged={a => onValueChange("municipality", a.value)}
                                searchEnabled={true}
                                style={{ marginBottom: '5px' }}
                            />
                        }
                    </div>
                </CollapsibleCard>
            </div>

            <div className={`${design.column2}`}>
                <CollapsibleCard title={t('collisionFrequency')}>
                    {initData &&
                        <Chart id="chart"
                            dataSource={initData.emphasisAreas}
                        >
                            <CommonSeriesSettings argumentField="description" type="stackedbar" barWidth={60} />
                            {(dashboardReq.severityList.includes(2) || dashboardReq.severityList.includes(3)) &&
                                <Series
                                    valueField="fatalInjury"

                                    name={(dashboardReq.severityList.includes(2) && dashboardReq.severityList.includes(3)) ? t("fatal/injury") : (dashboardReq.severityList.includes(2)) ? t("injury") : dashboardReq.severityList.includes(3) ? t("fatal") : ""}
                                    axis="collisionAxix"
                                    color="#0b1d78"
                                />
                            }
                            <Series
                                valueField="pdo"
                                name={t("PDO")}
                                axis="collisionAxix"
                                color="#00a9b5"
                            />
                            <ValueAxis
                                name="collisionAxix"
                                position="left"
                                //valueType="fatal"
                                showZero={true}
                                valueMarginsEnabled={false}
                                allowDecimals={false}
                            >
                                <Title text={dashboardReq.queryAvgType === QueryAvgType.Total ? t("numberOfCollisions") : t("avgNumberOfCollisions")} />
                            </ValueAxis>

                            <Legend position="inside" horizontalAlignment="right"></Legend>

                            <Export enabled={true} printingEnabled={false} />
                            <Tooltip
                                enabled={true}
                                location="edge"
                            />
                        </Chart>
                    }
                </CollapsibleCard>
                {/* {initData?.emphasisAreasSummery.map((summary, index) => {
                    return (
                    <CollapsibleCard title={summary.name}>
                        <div style={{display:"flex"}}>
                            <div className={design.halfWidthCol}>
                                <div className={design.gridTitle}>{t("intersectionSummery")}</div>
                                <DataGrid id="gridContainer"
                                  height="40rem"
                                  dataSource={summary.intersectionSummery}
                                  rowAlternationEnabled={true}
                                  showBorders={true}
                                  onRowClick={onIntersectionRowClicked}
                                  hoverStateEnabled={true}
                                  allowColumnReordering={true}
                                  allowColumnResizing={true}
                                  columnAutoWidth={true}
                                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                                // onExporting={OnExporting}
                                style={{margin:"0 0.5rem"}}
                                >
                                  <Paging enabled={true} defaultPageSize={100} />
                                  <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                  />
                                  <GridExport enabled={true} allowExportSelectedData={true} />
                                  <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                  >
                                    <Search enabled />
                                  </ColumnChooser>
                                  <FilterRow visible={true}
                                    applyFilter="auto" />
                                  <HeaderFilter visible={true} />
                                  <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                  <Column dataField="rank"
                                    alignment="left"
                                    caption={t("rank")}
                                    allowFiltering={false}
                                    width={70}
                                  ></Column>
                                  <Column dataField="total"
                                    caption={t("collisionFrequency")}
                                    dataType='number'
                                    alignment="left"
                                    width={120}
                                  ></Column>
                                  <Column dataField="locationDescription"
                                    caption={t("locationDescription")}
                                  ></Column>
                                  <Column dataField="fatal"
                                    caption={t("fatal")}
                                    visible={false}
                                  ></Column>
                                  <Column dataField="injury"
                                    caption={t("injury")}
                                    visible={false}
                                  ></Column>
                                  <Column dataField="pdo"
                                    caption={t("pdo")}
                                    visible={false}
                                  ></Column>
                                  <Column dataField="fatalInjury"
                                    caption={t("fatalInjury")}
                                    visible={false}
                                  ></Column>
                                </DataGrid>
                            </div>
                            <div className={design.halfWidthCol}>
                                <div className={design.gridTitle}>{t("roadSegmentSummery")}</div>
                                <DataGrid id="gridContainer"
                                  height="40rem"
                                  dataSource={summary.roadSegmentSummery}
                                  rowAlternationEnabled={true}
                                  showBorders={true}
                                  onRowClick={onRoadSegmentRowClicked}
                                  hoverStateEnabled={true}
                                  allowColumnReordering={true}
                                  allowColumnResizing={true}
                                  columnAutoWidth={true}
                                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                                // onExporting={OnExporting}
                                style={{margin:"0 0.5rem"}}
                                >
                                  <Paging enabled={true} defaultPageSize={100} />
                                  <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                  />
                                  <GridExport enabled={true} allowExportSelectedData={true} />
                                  <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                  >
                                    <Search enabled />
                                  </ColumnChooser>
                                  <FilterRow visible={true}
                                    applyFilter="auto" />
                                  <HeaderFilter visible={true} />
                                  <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                  <Column dataField="rank"
                                    alignment="left"
                                    caption={t("rank")}
                                    allowFiltering={false}
                                    width={70}
                                  ></Column>
                                  <Column dataField="total"
                                    caption={t("collisionFrequency")}
                                    dataType='number'
                                    alignment="left"
                                    width={120}
                                  ></Column>
                                  <Column dataField="locationDescription"
                                    caption={t("locationDescription")}
                                  ></Column>
                                  <Column dataField="fatal"
                                    caption={t("fatal")}
                                    visible={false}
                                  ></Column>
                                  <Column dataField="injury"
                                    caption={t("injury")}
                                    visible={false}
                                  ></Column>
                                  <Column dataField="pdo"
                                    caption={t("pdo")}
                                    visible={false}
                                  ></Column>
                                  <Column dataField="fatalInjury"
                                    caption={t("fatalInjury")}
                                    visible={false}
                                  ></Column>
                                </DataGrid>
                            </div>
                        </div>   
                    </CollapsibleCard>
                    )
                })} */}
            </div>
        </div>
    );
}
export default EmphasisArea;