export enum ReleaseChannel {
    LTS = 1,
    Preview = 2
}
export enum TesAppVersionType {
    Ontario = 1,
    Ottawa = 2,
}
export enum EnvironmentType {
    Unspecified = 0,
    Oracle = 1,
    OracleTNS = 2,
    SQL = 3
}
export enum OracleConnectionType {
    Unspecified = 0,
    TNS = 1,
    SID = 2,
    ServiceName = 3
}

