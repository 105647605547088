//imports
import { ContextMenu, ScrollView, SelectBox, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import Form, { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { ValidationGroup, ValidationGroupRef } from 'devextreme-react/validation-group';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import Permission from "../../../../../components/permission/permision";
import SafetyPermissions from "../../../../../constants/Permissions/SafetyPermissions";
import { useAuth } from "../../../../../contexts/auth";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { ModalMode } from "../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../types/general/generalTypes";
import { WorkBookDTO } from "../../../../../types/safety/dto/safetyDtos";
import { AnalysisType } from "../../../../../types/safety/enums/analysisEnums";
import { AnalysisBase } from "../../../../../types/safety/safetyTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../utils/enumTools";
import { RightClickMenu } from "../../../../../utils/rightClickMenu";
import "./analysis.scss";

interface IProps {
  isLocked: boolean;
  initData: WorkBookDTO;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  dataSaved: boolean;
  setDataSaved: React.Dispatch<React.SetStateAction<any>>;
}

const Analysis = (props: IProps) => {
  const [selectedData, setSelectedData] = useState<AnalysisBase>(new AnalysisBase());
  const [rowData, setRowData] = useState<AnalysisBase>(new AnalysisBase());
  const [lstAnalysisType, setLstAnalysisType] = useState<NameValue[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const validationRef = useRef<ValidationGroupRef>(null);
  const { generalSetting } = useClientSetting();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];

  useEffect(() => {
    setLstAnalysisType(Enum2Array(AnalysisType));
    // async function fetchMyAPI() {
    //   try {
    //     if (activeLoading) activeLoading(true);
    //     await getInitialDataFields();
    //     await getInitialDataFieldValue();
    //     if (activeLoading) activeLoading(false);
    //   } catch (ex) {
    //     if (activeLoading) activeLoading(false);
    //     notify(t("someErrorOccurred") + ex, "error", 5000);
    //   }
    // }
    // fetchMyAPI();
  }, []);

  // async function getInitialDataFields() {
  //   setInitDataFields(
  //     await TesGet(
  //       apiAddresses.fieldAPI +
  //         "TesFields/GetIntersectionTableFields/" +
  //         localStorage.getItem("selectedCustomerId"),
  //       true
  //     )
  //   );
  // }

  // async function getInitialDataFieldValue() {
  //   setInitDataFieldsValue(
  //     await TesGet(
  //       apiAddresses.fieldAPI +
  //         "CodeValues/GetAllCustomerCodeValue/" +
  //         localStorage.getItem("selectedCustomerId"),
  //       true
  //     )
  //   );
  // }

  function onEdit(d: any) {
    setRowData(d.row.data)
    let newData = new AnalysisBase();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  async function onDelete(d: any) {
    props.setInitData({ ...props.initData, analysis: props.initData.analysis.filter(x => x.title !== d.row.data.title) })
    props.setDataSaved(false)
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setShowModal(false);
    }
    setSelectedData(new AnalysisBase())
  }

  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        selectedData.id = uuid();
        props.setInitData({ ...props.initData, analysis: [...props.initData.analysis, selectedData] })
        props.setDataSaved(false)
        onCloseModal();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      props.setInitData({ ...props.initData, analysis: props.initData.analysis.map(x => { return x.title === rowData.title ? selectedData : x }) })
      props.setDataSaved(false)
      dataGridRef.current?.instance().refresh()
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onValueChanged(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value })
    props.setDataChanged(true)
  }

  // function onRowClicked(data: any) {
  //   if (props.dataSaved) {
  //     if (data.data.analysisType === AnalysisType.EmpiricalBayesAnalysis) {
  //       history("/safety/analysis/networkScreeningEBDetails/" + data.data.id)
  //     }
  //   } else { notify(t("youShouldFirstSaveChanges"), "error", 5000); }
  // }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      if (props.dataSaved && e.data.analysisType === AnalysisType.EmpiricalBayesAnalysis) {
        window.open(`/safety/analysis/networkScreeningEBDetails/${e.data.id}`, "_blank");
      } else { notify(t("youShouldFirstSaveChanges"), "error", 5000); }
    } else {
      if (props.dataSaved && e.data.analysisType === AnalysisType.EmpiricalBayesAnalysis) {
        history(`/safety/analysis/networkScreeningEBDetails/${e.data.id}`);
      } else { notify(t("youShouldFirstSaveChanges"), "error", 5000); }
    }
  }

  return (
    <Permission allowed={[SafetyPermissions.Safety_V_Workbook]} hasFeedBackElement={true}>
      {props.dataSaved && selectedRowData?.row.data.analysisType === AnalysisType.EmpiricalBayesAnalysis &&
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/safety/analysis/networkScreeningEBDetails/")}
        />}
      <React.Fragment>
        <h2 className={"content-block"}>{t("analysis")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Permission
                  allowed={[SafetyPermissions.Safety_D_Workbook]}
                  hasFeedBackElement={false}
                >
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-circle-plus"
                    text={t("analysis")}
                    disabled={props.isLocked}
                  />
                </Permission>
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initData.analysis}
              rowAlternationEnabled={true}
              showBorders={true}
              onRowClick={onRowClicked}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
              onContextMenuPreparing={e => { setSelectedRowData(e) }}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column
                alignment="left"
                dataField="title"
                allowSorting={false}
                caption={t("title")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="analysisType"
                alignment="left"
                caption={t("analysisType")}
              >
                <Lookup
                  dataSource={SortObjectByPropName(lstAnalysisType, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="analysisDateTime"
                allowSorting={false}
                caption={t("analysisDateTime")}
                dataType="datetime"
                format={generalSetting?.dateTimeFormat}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </div>
        </div>

        {showModal && <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            <ValidationGroup ref={validationRef}>
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("title")}
                    labelMode='floating'
                    value={selectedData.title}
                    onValueChange={(e) => onValueChanged("title", e)}
                    className="modalInput"
                  >
                    <Validator>
                      <RequiredRule message={t('fieldIsRequired')} />
                    </Validator>
                  </TextBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("analysisType")}
                    labelMode='floating'
                    value={selectedData.analysisType}
                    onValueChange={(e) => onValueChanged("analysisType", e)}
                    className="modalInput"
                    valueExpr="value"
                    displayExpr="name"
                    items={SortObjectByPropName(lstAnalysisType, "name")}
                    showClearButton={true}
                    searchEnabled={true}
                  >
                    <Validator>
                      <RequiredRule message={t('fieldIsRequired')} />
                    </Validator>
                  </SelectBox>
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ValidationGroup>
          </ScrollView>
        </Popup>}
      </React.Fragment>
    </Permission>
  );
};
export default Analysis;
