import { Attachment, ChangeLog } from "./../sign/signTypes";
import { FieldServiceType } from "./../field/enums/fieldEnums";
import { NameValue } from "../general/generalTypes";
import {
  LocationType,
  MapUpdateStatus,
} from "../infrastructure/enums/infrastructureEnums";
import { IGeoJson } from "../arcGis/arcGisTypes";
import { ValidationRuleLevel } from "../field/enums/fieldEnums";
import {
  ApprovalStatus,
  CollisionCleaningMode,
  CollisionDataSourceType,
  CollisionDiagramType,
  CollisionImportStatus,
  CollisionMergeStatus,
  CollisionPeopleInvolvement,
  CollisionSeverity,
  CollisionStatus,
  CollisionTesStatus,
  CoordinationSourceType,
  DashboardReportType,
  GeoCodeStatus,
  ImportFieldCategory,
  InjuryLevel,
  QueryAvgType,
  XMLImportAction,
  XMLImportType,
} from "./enums/collisionEnums";
import {
  VMMapLocation,
  VMSimplifiedVehicleDriverCombined,
} from "./dto/collisionDtos";
import { VMCodeGroup, VMField } from "../field/dto/fieldDTO";
import { DateTime } from "luxon";
import { extend } from "@devexpress/analytics-core/analytics-internal";
import { ITesBase } from "../general/generalInterfaces";

export interface ICollisionParams {
  [key: string]: any;
  collisionId: string;
}

export interface ISeverityResult {
  rank: number;
  description: string;
  pdo: number;
  injury: number;
  fatal: number;
  selfReported: number;
  fatalInjury: number;
  total: number;
}
export interface ILocationCollisionSummery {
  rank: number;
  locationId: string;
  geoId: string;
  locationType: LocationType;
  locationDescription: string;
  fatal: number;
  injury: number;
  pdo: number;
  total: number;
  fatalInjury: number;
  selfReported: number;
}
interface IComparativeResult {
  name: string;
  defaultPropertyName: string;
  before: ISeverityResult;
  after: ISeverityResult;
  upperRange: number;
  improvementPercentage: number;
  goal: number;
}
export interface IDashboardResult {
  overview: NameValue[];
  emphasisAreas: ISeverityResult[];
  emphasisAreasSummery: IEmphasisAreasSummery[];
  intersectionSummery: ILocationCollisionSummery[];
  roadSegmentSummery: ILocationCollisionSummery[];
  comparativeResults: IComparativeResult[];
  hasError: false;
  errors: string[];
  yearSummary: ISeverityResult[];
  heatMap: IGeoJson;
}
export class ApprovalLevel {
  [key: string]: any;
  id!: string;
  customerId!: string;
  name!: string;
  level!: number;
}
export class ApprovalLevelXUser {
  id!: string;
  userId!: string;
  name!: string;
  groupId!: string;
  fromLevel!: ApprovalLevel;
  fromLevelId!: string;
  toLevel!: ApprovalLevel;
  toLevelId!: string;
}
export class ValidationRule {
  id!: string;
  customerId!: string;
  description!: string;
  validationRuleLevel!: ValidationRuleLevel;
  fieldServiceType!: FieldServiceType;
  rule?: string;
  errorMessage!: string;
  isDeactivated!: boolean;
}
export class MyLevel {
  fromLevel!: string;
  toLevel!: string;
  maxLevel!: number;
}

export class ValidationMessage {
  id!: string;
  validationRuleLevel!: ValidationRuleLevel;
  message!: string;
}
export class SimplifiedVehicle {
  maneuver!: string;
  initialDirectionOfTravel!: string;
}
export class SimplifiedPerson {
  index!: number;
  sex!: string;
  injured!: boolean;
  fatal!: boolean;
  ageGroup!: string;
  severity!: string;
  action!: string;
  condition!: string;
  parent!: SimplifiedCollision;
  injuryLevel!: InjuryLevel;
}
export class SimplifiedCollision {
  version!: number;
  accNumber!: number;
  dateTime!: Date;
  accidentDate!: Date;
  year!: number;
  month!: string;
  monthNumber!: number;
  dayOfWeek!: string;
  dayOfWeekNumber!: number;
  isWeekend!: boolean;
  //timeOfDay!: ;
  trafficControl!: string;
  isCyclistInvolved!: boolean;
  isPedestrianInvolved!: boolean;
  isMotorcycleInvolved!: boolean;
  isHeavyVehicleInvolved!: boolean;
  municipality!: string;
  initialImpactType!: string;
  initialImpactTypeClient!: string;
  roadSurfaceCondition!: string;
  isAnimalInvolved!: boolean;
  isIntersection!: boolean;
  isYoungDriver!: boolean;
  isOldDriver!: boolean;
  isAggressiveDriver!: boolean;
  isDistractedDriver!: boolean;
  hasFatal!: boolean;
  severity!: CollisionSeverity;
  selfReported!: boolean;
  lightingCondition!: boolean;
  driverLostControl!: boolean;
  speedRelated!: boolean;
  numberOfFatal!: boolean;
  numberOfInjured!: boolean;
  numberOfFI!: boolean;
  jurisdiction!: string;
  weatherCondition!: string;
  simplifiedVehicles!: SimplifiedVehicle[];
  drivers!: SimplifiedPerson[];
  pedestrian!: SimplifiedPerson[];
  personInvolved!: SimplifiedPerson[];
  vehicleDriverCombinedList!: VMSimplifiedVehicleDriverCombined;
}

export class NetworkDriveFiles {
  id!: string;
  fileName!: string;
  fileAddress!: string;
  isDeleted!: boolean;
  uploadDT!: DateTime;
}

export class CustomerCollision implements ITesBase {
  constructor() {
    this.roads = [];
    this.vehicles = [];
    this.roads = [];
    this.persons = [];
    this.validationErrors = [];
    this.changeLogs = [];
    this.attachments = [];
    this.updateApproveLevel = true;
    this.relatedCollisions = [];
    this.mergeLogs = [];
    this.networkDriveFiles = [];
  }
  [key: string]: any;
  approvalLevel!: number;
  approvalStatus!: ApprovalStatus;
  attachments!: Attachment[];
  changeLogs!: ChangeLog[];
  cityConfirmationStatus!: CollisionStatus;
  collisionMergeStatus!: CollisionMergeStatus;
  collisionTesStatus!: CollisionTesStatus;
  customerId!: string;
  dataSourceType!: CollisionDataSourceType;
  deleteDT!: Date;
  divisionId!: string;
  divisionName!: string;
  editedSubmissionDate!: Date;
  editSubmissionMapLocation!: VMMapLocation;
  general!: any;
  geoCodeGroupId?: string;
  geoCodeStatus?: GeoCodeStatus;
  geoId?: string;
  id!: string;
  isDeleted!: boolean;
  jurisdictionId?: string;
  lastEditeUserId?: string;
  locationDescription?: string;
  locationId?: string;
  locationType?: LocationType;
  mapLocation!: VMMapLocation;
  mapUpdateStatus!: MapUpdateStatus;
  mergeLogs!: CollisionMergeLog[];
  municipalityId?: string;
  networkDriveFiles!: NetworkDriveFiles[];
  originalSourcetype!: CollisionDataSourceType;
  parentId!: string;
  persons!: any[];
  policeConfirmationStatus!: CollisionStatus;
  relatedCollisions!: any[];
  remark!: any;
  roads!: any[];
  simplifiedCollision!: SimplifiedCollision;
  submissionDt!: Date;
  submissionMapLocation!: VMMapLocation;
  tesId!: string;
  updateApproveLevel?: boolean;
  userId!: string;
  validationErrors!: ValidationMessage[];
  vehicles!: any[];
  xmlImportMessage!: string;
}

export class CollisionMergeLog {
  id!: string;
  fieldName!: string;
  changedDate!: DateTime;
  fromValue!: object;
  toValue!: object;
  cleanMode!: CollisionCleaningMode;
  fieldNamesExist!: boolean;
  note!: string;
}

export class CollisionDiagramRequest {
  [key: string]: any;
  customerId!: string;
  lstCollisionId!: string[];
  type!: CollisionDiagramType;
  fromDate!: Date;
  toDate!: Date;
}

export interface IXMLSettingParam {
  [key: string]: any;
  xmlSettingId: string;
}

export interface IXMLImportLogParam {
  [key: string]: any;
  xmlLogId: string;
}

export interface IGeoCodeGroupParams {
  [key: string]: any;
  geoCodeGroupId: string;
}

export interface XMLRedactionParams {
  [key: string]: any;
  id: string;
}

export class XmlImportSetting {
  constructor() {
    this.fieldSettings = [];
  }
  title!: string;
  id!: string;
  description!: string;
  customerId!: string;
  rootTagName!: string;
  generalTagName!: string;
  driverTagName?: string;
  personTagName!: string;
  pedestrianTagName?: string;
  vehicleTagName!: string;
  locationTagName?: string;
  attachmentTagName?: string;
  environmentTagName?: string;
  lightTagName?: string;
  roadTagName?: string;
  driverIdentifier?: string;
  xmlImportType!: XMLImportType;
  fieldSettings!: XmlTagSetting[];
  enabled?: boolean;
  isDeleted?: boolean;
  deleteNonReportable!: boolean;
  deletePrivateProperty!: boolean;
}

export class XmlTagSetting {
  section!: ImportFieldCategory;
  xmlTagName!: string;
  fieldId!: string;
  multiValue!: boolean;
}
export class XmlImportTask {
  constructor() {
    this.filePaths = [];
  }
  xmlImportSetting!: XmlImportSetting;
  tesFields?: VMField[];
  codeGroups?: VMCodeGroup[];
  fileKeys!: string[];
  filePaths!: string[];
  emails!: string[];
}

export class GeoCodeGroup {
  [key: string]: any;
  id!: string;
  customerId!: string;
  title!: string;
  lstCollisionIds!: string[];
  generals!: any[];
}

export class ImportXmlRequestTask {
  constructor() {
    this.filePaths = [];
  }
  [key: string]: any;
  customerId!: string;
  xmlImportSettingId!: string;
  xmlImportAction!: XMLImportAction;
  emails!: string[];
  filePaths!: string[];
}

export class XMLExportTask {
  constructor() {
    this.CollisionIds = [];
  }
  emails!: string[];
  CollisionIds!: string[];
}

export class CollisionXmlImportLog {
  id!: string;
  filePath!: string;
  status!: CollisionImportStatus;
  note!: string;
  collisionId!: string;
}

export class XmlImportLog {
  id!: string;
  customerId!: string;
  userId!: string;
  startDT!: DateTime;
  endDT!: DateTime;
  importSettingId!: string;
  xmlImportAction!: XMLImportAction;
  logs!: CollisionXmlImportLog[];
}

export class DashboardField {
  filedId!: string;
  fieldName!: string;
  title!: string;
  description!: string;
  unknownTreatment!: UnknownBoolTreatment;
  order!: number;
  minPercentage: number = -100;
  maxPercentage: number = 100;
  goal: number = 5;
  goalMinPercentage: number = -10;
  goalMaxPercentage: number = 10;
}

export enum UnknownBoolTreatment {
  Ignore = 0,
  True = 1,
  False = 2,
}

export class DashboardSettings {
  useOnlyGeoCoded: boolean = true;
  minimumCollisionLevel: number = 1;
  emphasisAreaSettings?: EmphasisAreaSetting[];
}

export class EmphasisAreaSetting {
  fieldId!: string;
  fieldName!: string;
  title!: string;
  description!: string;
  unknownTreatment!: UnknownBoolTreatment;
  order!: number;
  minPercentage: number = -100;
  maxPercentage: number = 100;
  goalTracking: boolean = true;
  goal: number = 5;
  goalMinPercentage: number = -10;
  goalMaxPercentage: number = 10;
  enabled: boolean = true;
  totalCollisions: boolean = false;
}

export class GeneralSettings {
  constructor() {
    this.dashboardFields = [];
    this.dashboardSettings = new DashboardSettings();
  }
  [key: string]: any;
  id!: string;
  customerId!: string;
  coordinationSourceType?: CoordinationSourceType;
  geoCodeCityName!: string;
  arcGISToken!: string;
  arcGISGeoCodeServiceAddress!: string;
  googleAPIKey!: string;
  dashboardSettings?: DashboardSettings;
}

export class MainDashboardRequest {
  constructor() {
    this.jurisdictionIds = [];
    this.municipalityIds = [];
  }
  [key: string]: any;
  customerId!: string;
  divisionId?: string;
  reportType!: DashboardReportType;
  severityList!: CollisionSeverity[];
  peopleInvolvements?: CollisionPeopleInvolvement[];
  emphasisAreaIds!: string[];
  beginDateFrom?: Date;
  beginDateTo?: Date;
  endDateFrom?: Date;
  endDateTo?: Date;
  //locationIds: (string | null)[] = [];
  areaIds: string[] = [];
  jurisdictionIds!: [];
  municipalityIds!: [];
  topNLocation: number = 10;
  queryAvgType!: QueryAvgType;
}

export interface IEmphasisAreasSummery {
  id: string;
  name: string;
  intersectionSummery: ILocationCollisionSummery[];
  roadSegmentSummery: ILocationCollisionSummery[];
}

export class XMLRedaction {
  id!: string;
  customerId!: string;
  name!: string;
  reductionFields!: string[];
  hasPdfRedaction!: boolean;
  pdfRedactionSettings!: PdfRedactionSetting[];
  hasPictureExtraction!: boolean;
  pictureExtractionSettings!: PdfRedactionSetting[];
}

export class PdfRedactionSetting {
  id!: string;
  x!: number;
  y!: number;
  width!: number;
  height!: number;
  pageNumberFromFirst!: number;
  pageNumberFromLast!: number;
  priority!: number;
}
