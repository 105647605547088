import { CheckBox, SelectBox, TextBox } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Form, SimpleItem } from "devextreme-react/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { XmlImportSetting } from "../../../../../../types/collision/collisionTypes";
import { XMLImportType } from "../../../../../../types/collision/enums/collisionEnums";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { GeneralSetting } from "../../../../../../types/setting/gridSettingTypes";

// props
interface IPros {
  onValueChange: (key: string, value: any) => void;
  initData: XmlImportSetting;
  setInitData: React.Dispatch<React.SetStateAction<XmlImportSetting>>;
  generalSettings: GeneralSetting | null;
  lstXMLImportTypes: NameValue[];
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();
  const compactViewModel: boolean =
    (
      JSON.parse(
        localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}'
      ) || {}
    ).viewMode === "compact";

  function onValueChange(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value });
  }

  return (
    <div
      className={`xmlImportSettingDetails-details ${
        compactViewModel ? "compactStyle" : ""
      }`}
    >
      <React.Fragment>
        <Form colCount={2}>
          <SimpleItem>
            <SelectBox
              valueExpr={"value"}
              displayExpr={"name"}
              items={props.lstXMLImportTypes}
              value={props.initData?.xmlImportType}
              onValueChange={(e) => onValueChange("xmlImportType", e)}
              labelMode="floating"
              label={t("xmlImportType")}
            />
          </SimpleItem>
        </Form>
        <div className="row">
          {props.initData?.xmlImportType === XMLImportType.Ontario && (
            <Form colCount={4}>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("title")}
                  labelMode="floating"
                  value={props.initData?.title}
                  onValueChange={(e) => props.onValueChange("title", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("description")}
                  labelMode="floating"
                  value={props.initData?.description}
                  onValueChange={(e) => props.onValueChange("description", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("rootTagName")}
                  labelMode="floating"
                  value={props.initData?.rootTagName}
                  onValueChange={(e) => props.onValueChange("rootTagName", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("generalTagName")}
                  labelMode="floating"
                  value={props.initData?.generalTagName}
                  onValueChange={(e) =>
                    props.onValueChange("generalTagName", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("driverTagName")}
                  labelMode="floating"
                  value={props.initData?.driverTagName}
                  onValueChange={(e) => props.onValueChange("driverTagName", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("personTagName")}
                  labelMode="floating"
                  value={props.initData?.personTagName}
                  onValueChange={(e) => props.onValueChange("personTagName", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("vehicleTagName")}
                  labelMode="floating"
                  value={props.initData?.vehicleTagName}
                  onValueChange={(e) =>
                    props.onValueChange("vehicleTagName", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("locationTagName")}
                  labelMode="floating"
                  value={props.initData?.locationTagName}
                  onValueChange={(e) =>
                    props.onValueChange("locationTagName", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("roadTagName")}
                  labelMode="floating"
                  value={props.initData?.roadTagName}
                  onValueChange={(e) => props.onValueChange("roadTagName", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("attachmentTagName")}
                  labelMode="floating"
                  value={props.initData?.attachmentTagName}
                  onValueChange={(e) =>
                    props.onValueChange("attachmentTagName", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("driverIdentifier")}
                  labelMode="floating"
                  value={props.initData?.driverIdentifier}
                  onValueChange={(e) =>
                    props.onValueChange("driverIdentifier", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <CheckBox
                  text={t("deletePrivateProperty")}
                  value={props.initData?.deletePrivateProperty}
                  onValueChange={(e) =>
                    props.onValueChange("deletePrivateProperty", e)
                  }
                  style={{ margin: "1rem 1rem 1rem" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <CheckBox
                  text={t("deleteNonReportable")}
                  value={props.initData?.deleteNonReportable}
                  onValueChange={(e) =>
                    props.onValueChange("deleteNonReportable", e)
                  }
                  style={{ margin: "1rem 1rem 1rem" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <CheckBox
                  text={t("enabled")}
                  value={props.initData?.enabled}
                  onValueChange={(e) => props.onValueChange("enabled", e)}
                  style={{ margin: "1rem 1rem 1rem" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <CheckBox
                  text={t("isDeleted")}
                  value={props.initData?.isDeleted}
                  onValueChange={(e) => props.onValueChange("isDeleted", e)}
                  style={{ margin: "1rem 1rem 1rem" }}
                />
              </SimpleItem>
            </Form>
          )}
          {props.initData?.xmlImportType === XMLImportType.SpringField && (
            <Form colCount={4}>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("title")}
                  labelMode="floating"
                  value={props.initData?.title}
                  onValueChange={(e) => props.onValueChange("title", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("description")}
                  labelMode="floating"
                  value={props.initData?.description}
                  onValueChange={(e) => props.onValueChange("description", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("rootTagName")}
                  labelMode="floating"
                  value={props.initData?.rootTagName}
                  onValueChange={(e) => props.onValueChange("rootTagName", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("generalTagName")}
                  labelMode="floating"
                  value={props.initData?.generalTagName}
                  onValueChange={(e) =>
                    props.onValueChange("generalTagName", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("personTagName")}
                  labelMode="floating"
                  value={props.initData?.personTagName}
                  onValueChange={(e) => props.onValueChange("personTagName", e)}
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("pedestrianTagName")}
                  labelMode="floating"
                  value={props.initData?.pedestrianTagName}
                  onValueChange={(e) =>
                    props.onValueChange("pedestrianTagName", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <TextBox
                  placeholder=""
                  label={t("vehicleTagName")}
                  labelMode="floating"
                  value={props.initData?.vehicleTagName}
                  onValueChange={(e) =>
                    props.onValueChange("vehicleTagName", e)
                  }
                  disabled={props.isLocked}
                  showClearButton={true}
                />
              </SimpleItem>

              <SimpleItem colSpan={1}>
                <CheckBox
                  text={t("enabled")}
                  value={props.initData?.enabled}
                  onValueChange={(e) => props.onValueChange("enabled", e)}
                  style={{ margin: "1rem 1rem 1rem" }}
                />
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <CheckBox
                  text={t("isDeleted")}
                  value={props.initData?.isDeleted}
                  onValueChange={(e) => props.onValueChange("isDeleted", e)}
                  style={{ margin: "1rem 1rem 1rem" }}
                />
              </SimpleItem>
            </Form>
          )}
        </div>
        {/* </ValidationGroup> */}
      </React.Fragment>
    </div>
  );
};

export default Details;
