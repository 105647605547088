import { v4 as uuid } from 'uuid';
import { MovementType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { BinMapBase } from "../../../../../../types/trafficStudy/trafficStudyTypes";

export const TemplatesData: BinMapBase[] = [
    {
      id: "83431038-8ca4-d6bf-03b5-215058b49cfc",
      name: "Gretch TFR Original",
      noBinInUse: 40,
      type: 8,
      studyType: 2,
      dateFormat: "",
      incomingMovement: MovementType.Unknown,
      outgoingMovement: MovementType.Unknown,
      isDisabled: false,
      binMapClassificationType: 0,
      bins: [
        {
          _id: uuid(),
          bin: 1,
          vehicleClassificationIn: 1,
          movement: 2,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 2,
          vehicleClassificationIn: 1,
          movement: 5,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 3,
          vehicleClassificationIn: 1,
          movement: 8,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 4,
          vehicleClassificationIn: 1,
          movement: 11,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 5,
          vehicleClassificationIn: 2,
          movement: 2,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 6,
          vehicleClassificationIn: 2,
          movement: 5,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 7,
          vehicleClassificationIn: 2,
          movement: 8,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 8,
          vehicleClassificationIn: 2,
          movement: 11,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 9,
          vehicleClassificationIn: 3,
          movement: 2,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 10,
          vehicleClassificationIn: 3,
          movement: 5,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 11,
          vehicleClassificationIn: 3,
          movement: 8,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 12,
          vehicleClassificationIn: 3,
          movement: 11,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 17,
          vehicleClassificationIn: 1,
          movement: 12,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 18,
          vehicleClassificationIn: 1,
          movement: 3,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 19,
          vehicleClassificationIn: 1,
          movement: 6,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 20,
          vehicleClassificationIn: 1,
          movement: 9,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 21,
          vehicleClassificationIn: 2,
          movement: 12,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 22,
          vehicleClassificationIn: 2,
          movement: 3,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 23,
          vehicleClassificationIn: 2,
          movement: 6,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 24,
          vehicleClassificationIn: 2,
          movement: 9,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 25,
          vehicleClassificationIn: 3,
          movement: 1,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 26,
          vehicleClassificationIn: 3,
          movement: 4,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 27,
          vehicleClassificationIn: 3,
          movement: 7,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 28,
          vehicleClassificationIn: 3,
          movement: 10,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 33,
          vehicleClassificationIn: 1,
          movement: 1,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 34,
          vehicleClassificationIn: 1,
          movement: 4,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 35,
          vehicleClassificationIn: 1,
          movement: 7,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 36,
          vehicleClassificationIn: 1,
          movement: 10,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 37,
          vehicleClassificationIn: 2,
          movement: 1,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 38,
          vehicleClassificationIn: 2,
          movement: 4,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 39,
          vehicleClassificationIn: 2,
          movement: 7,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 40,
          vehicleClassificationIn: 2,
          movement: 10,
          lane: 1
        },
        {
          _id: uuid(),
          bin: 41,
          vehicleClassificationIn: 3,
          movement: 1,
          lane: 0
        },
        {
          _id: uuid(),
          bin: 42,
          vehicleClassificationIn: 3,
          movement: 4,
          lane: 0
        },
        {
          _id: uuid(),
          bin: 43,
          vehicleClassificationIn: 3,
          movement: 7,
          lane: 0
        },
        {
          _id: uuid(),
          bin: 44,
          vehicleClassificationIn: 3,
          movement: 10,
          lane: 0
        },
        {
          _id: uuid(),
          bin: 45,
          vehicleClassificationIn: 4,
          movement: 14,
          lane: 0
        },
        {
          _id: uuid(),
          bin: 46,
          vehicleClassificationIn: 4,
          movement: 15,
          lane: 0
        },
        {
          _id: uuid(),
          bin: 47,
          vehicleClassificationIn: 4,
          movement: 18,
          lane: 0
        },
        {
          _id: uuid(),
          bin: 48,
          vehicleClassificationIn: 4,
          movement: 19,
          lane: 0
        }
      ]
    }
  ]