//imports
import { Button, Form, NumberBox, Popup, ScrollView, SelectBox, Switch, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/cjs/form";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from "devextreme-react/data-grid";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import getTitle from "../../../../../components/title/getTitle";
import { GridType, ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { TrendingDetailDTO, TrendingResultsDTO } from "../../../../../types/trafficStudy/dtos/trendingDto";
import { CountCodeType, PCSPatternType, TrendingResultStatus } from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { AlphaCodeType } from "../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { Enum2Array } from "../../../../../utils/enumTools";
import { useAuth } from "../../../../../contexts/auth";
import { RequestErrorHandling, TesPost } from "../../../../../utils/rest";
import { TrafficApiUrl } from "../../../../../environment/routeSettings";
import { RequestResponseResult } from "../../../../../types/general/generalTypes";
import notify from "devextreme/ui/notify";

interface IPros {
  initData: TrendingDetailDTO;
  setInitData?: React.Dispatch<React.SetStateAction<TrendingDetailDTO>>
}

const Results = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const lstPatternType = Enum2Array(PCSPatternType)
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(new TrendingResultsDTO)
  const [popupData, setPopupData] = useState(new TrendingResultsDTO)
  const lstCountCode = Enum2Array(CountCodeType)
  const lstTrendingResultStatus = Enum2Array(TrendingResultStatus)
  const lstAlphaCodeType = Enum2Array(AlphaCodeType)
  const { activeLoading } = useAuth();

  const title = getTitle('trafficStudy/tools/trendingProjects', '');

  useEffect(() => {
    document.title = title;
  }, [title]);

  async function onRowClick(e: any) {
    setRowData(e.data)
    setPopupData(e.data)
    setShowModal(true)
  }

  function onCloseModal() {
    setShowModal(false);
  }

  async function onRecalculate() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Trending/RunAlgorithm2", popupData,
        true
      )) as RequestResponseResult<TrendingResultsDTO>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
          setPopupData(res.results)
        notify(t("dataSuccessfullyRecalculated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  async function onApply() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Trending/UpdateTrendingResult", popupData,
        true
      )) as RequestResponseResult<TrendingResultsDTO>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
          setPopupData(res.results)
          const updatedResults = props.initData.results.map(
            result => result.id === rowData.id ?
              {...popupData, trafficSectionName: rowData.trafficSectionName, countStationName: rowData.countStationName}
              :
              result
          );
          if (props.setInitData) {
            props.setInitData({
              ...props.initData,
              results: updatedResults
            });
          }
          dataGridRef.current?.instance().refresh()
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        setShowModal(false)
      } else {
        await RequestErrorHandling(res);
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  return (
    <React.Fragment>
      <div className='row' style={{ marginTop: 20, padding: 10 }}>
        <ScrollView width="100%" height="100%">
          <Form colCount={3}>
            <SimpleItem colSpan={3}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={props.initData.results}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={e => { onRowClick(e) }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.TrendingResults.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="trafficSectionName" caption={t("trafficSectionName")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="countStationName" caption={t("countStationName")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="historicalPatternType"
                  allowSorting={false}
                  caption={t('historicalPatternType')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstPatternType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="mpAadt" caption={t("mpAadt")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="trendedAadt" caption={t("trendedAadt")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="recommendedAadt" caption={t("recommendedAadt")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="countCode"
                  allowSorting={false}
                  caption={t('countCode')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstCountCode, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="assignedPatternType"
                  allowSorting={false}
                  caption={t('assignedPatternType')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstPatternType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="status"
                  allowSorting={false}
                  caption={t('status')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstTrendingResultStatus, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="stnError" caption={t("stnError")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="alphaCode"
                  allowSorting={false}
                  caption={t('alphaCode')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstAlphaCodeType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="validatedTrending" caption={t("validatedTrending")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </SimpleItem>
          </Form>
        </ScrollView>
      </div>

      <Popup
        width={"90%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("result")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <div style={{margin: "0 1rem"}}>
                <CollapsibleCard title={t("details")} isOpen={true}>
                  <Form colCount={25}>
                    <SimpleItem colSpan={5}>
                      <TextBox
                        placeholder=""
                        label={t('trafficSectionName')}
                        labelMode='floating'
                        value={rowData.trafficSectionName}
                        showClearButton={true}
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <TextBox
                        placeholder=""
                        label={t('countStationName')}
                        labelMode='floating'
                        value={rowData.countStationName}
                        showClearButton={true}
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <NumberBox
                        placeholder=""
                        label={t('mpAadt')}
                        labelMode='floating'
                        value={popupData.mpAadt}
                        showClearButton={true}
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <NumberBox
                        placeholder=""
                        label={t('trendedAadt')}
                        labelMode='floating'
                        value={popupData.trendedAadt}
                        showClearButton={true}
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <NumberBox
                        placeholder=""
                        label={t('stnError')}
                        labelMode='floating'
                        value={popupData.stnError}
                        showClearButton={true}
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <SelectBox
                        labelMode="floating"
                        label={t('assignedPatternType')}
                        value={popupData.assignedPatternType}
                        items={lstPatternType}
                        showClearButton={true}
                        searchEnabled={true}
                        valueExpr="value"
                        displayExpr="name"
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <SelectBox
                        labelMode="floating"
                        label={t('historicalPatternType')}
                        value={rowData.historicalPatternType}
                        items={lstPatternType}
                        showClearButton={true}
                        searchEnabled={true}
                        valueExpr="value"
                        displayExpr="name"
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <SelectBox
                        labelMode="floating"
                        label={t('countCode')}
                        value={popupData.countCode}
                        items={lstCountCode}
                        showClearButton={true}
                        searchEnabled={true}
                        valueExpr="value"
                        displayExpr="name"
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <SelectBox
                        labelMode="floating"
                        label={t('status')}
                        value={popupData.status}
                        items={lstTrendingResultStatus}
                        showClearButton={true}
                        searchEnabled={true}
                        valueExpr="value"
                        displayExpr="name"
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <div>{t("validatedTrending")}</div>
                      <Switch
                        style={{ marginTop: 5 }}
                        value={popupData.validatedTrending}
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={5}>
                      <SelectBox
                        labelMode="floating"
                        label={t('alphaCode')}
                        value={popupData.alphaCode}
                        items={lstAlphaCodeType}
                        showClearButton={true}
                        searchEnabled={true}
                        valueExpr="value"
                        displayExpr="name"
                        disabled={true}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={4}>
                      <NumberBox
                        placeholder=""
                        label={t('recommendedAadt')}
                        labelMode='floating'
                        value={popupData.recommendedAadt}
                        onValueChange={e => setPopupData({...popupData, recommendedAadt: e})}
                        showClearButton={true}
                        style={{marginRight:"-1rem"}}
                      />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <Button
                        onClick={() => onRecalculate()}
                        icon="fa-solid fa-calculator"
                        hint={t("recalculate")}
                        style={{marginTop:"0.5rem"}}
                      />
                    </SimpleItem>
                  </Form>
                </CollapsibleCard>
                
              </div>
            </SimpleItem>
            <SimpleItem colSpan={2}>
            <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={rowData.lastNYearTrendings}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={e => { onRowClick(e) }}
                style={{margin:"0 1rem"}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.MatchingResults.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="year" caption={t("year")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="trendedAadt" caption={t("trendedAadt")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="countCode"
                  allowSorting={false}
                  caption={t('countCode')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstCountCode, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="status"
                  allowSorting={false}
                  caption={t('status')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstTrendingResultStatus, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="alphaCode"
                  allowSorting={false}
                  caption={t('alphaCode')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstAlphaCodeType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </SimpleItem>
          </Form>
          <div>
          <div className="rightColumn"style={{marginTop:"0.5rem"}}>
            <Button
              className="tes-modal-btn-cancel"
              style={{ marginRight: 20 }}
              onClick={() => onCloseModal()}
              text={t("cancel")}
            />
            <Button
              className="tes-modal-btn-add"
              style={{ marginRight: 20 }}
              onClick={() => onApply()}
              text={t("apply")}
            />
          </div>
          </div>
        </ScrollView>
      </Popup>

    </React.Fragment>
  );
};
export default Results;