import { Button, DataGrid, NumberBox, ScrollView, SelectBox, Switch, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Column, ColumnChooser, DataGridRef, Button as DevExpressButton, Editing, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, Lookup, Pager, Paging, Search, SearchPanel, SortByGroupSummaryInfo, Summary } from "devextreme-react/data-grid";
import DropDownButton from 'devextreme-react/drop-down-button';
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from 'uuid';
import { useAuth } from "../../../../../../contexts/auth";
import { ModalMode } from "../../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { BinMapClassificationType } from "../../../../../../types/trafficStudy/enums/importEnums";
import { BinMapType, MovementType, StudyType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { BinMapBase, TrafficCounter, VehicleClassification } from "../../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import MaxDropdownItemWidthCalculator from "../../../../../../utils/dropDownWidthCalculator";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { useScreenSize } from "../../../../../../utils/media-query";
import "./binMaps.scss";
import { TemplatesData } from "./templatesData";


// props
interface IPros {
  initData: TrafficCounter,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroupRef>
}

const BinMaps = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const binMapsDataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<BinMapBase>(new BinMapBase());
  const [binSelectedData, setBinSelectedData] = useState<any[]>([]);
  const [lstVClassificationData, setLstVClassificationData] = useState<VehicleClassification[]>([new VehicleClassification(),])
  const [lstMovementTypes, setLstMovementTypes] = useState<NameValue[]>([])
  const [lstBinMapClassificationType, setBinMapClassificationType] = useState<NameValue[]>([])
  const [lstMovementDirectionType, setLstMovementDirectionType] = useState<NameValue[]>([]);
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
  const validationRef = useRef<ValidationGroupRef>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const binMapTypes = [
    { name: t("tmcjcd"), value: BinMapType.TmcJCD, studyType: StudyType.TMC },
    { name: t('armadilloSpeed'), value: BinMapType.ArmadilloSpeed, studyType: StudyType.Speed },
    { name: t('oldArmadilloSpeed'), value: BinMapType.OldArmadilloSpeed, studyType: StudyType.Speed },
    { name: t('prnSpeed'), value: BinMapType.PRNSpeed, studyType: StudyType.Speed },
    { name: t('prnVolume'), value: BinMapType.PRNVolume, studyType: StudyType.Volume },
    { name: t('metroCountInductionLoopsVolume'), value: BinMapType.MetroCountInductionLoopsVolume, studyType: StudyType.Volume },
    { name: t('miovisionVolume'), value: BinMapType.MiovisionVolume, studyType: StudyType.Volume },
    { name: t('gretchTRF'), value: BinMapType.GretchTRF, studyType: StudyType.TMC },
    { name: t('goldenRiver'), value: BinMapType.GoldenRiver, studyType: StudyType.Volume },
    { name: t('MioVisionExcelTMC'), value: BinMapType.MioVisionExcelTMC, studyType: StudyType.TMC },
    { name: t('HoustonRadarSpeedClassCSV'), value: BinMapType.HoustonRadarSpeedClassCSV, studyType: StudyType.Speed }

  ]
  const lstTemplates = [
    { name: t('Gretch TFR Original'), value: BinMapType.GretchTRF, studyType: StudyType.TMC },
  ]
  const dateFormats = [{ name: "MM/dd/yy", value: "MM/dd/yy" }, { name: "dd/MM/yy", value: "dd/MM/yy" }, { name: "MM/dd/yyyy", value: "MM/dd/yyyy" }]
  const { isXLarge, is2xLarge } = useScreenSize();
  const [formHeight, setFormHeight] = useState<string>("88%")

  useEffect(() => {
    if (isXLarge) { setFormHeight("91%") }
    else if (is2xLarge) { setFormHeight("95%") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstMovementDirectionType(Enum2Array(MovementType).filter(x => x.value.toString().toLocaleLowerCase() === MovementType.NT.toString().toLocaleLowerCase()
          || x.value.toString().toLocaleLowerCase() === MovementType.ST.toString().toLocaleLowerCase()
          || x.value.toString().toLocaleLowerCase() === MovementType.ET.toString().toLocaleLowerCase()
          || x.value.toString().toLocaleLowerCase() === MovementType.WT.toString().toLocaleLowerCase()
        ));

        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    setLstMovementTypes(Enum2Array(MovementType));
    setBinMapClassificationType(Enum2Array(BinMapClassificationType));
    setLstStudyType(Enum2Array(StudyType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setLstVClassificationData(props.initData.vehicleClassifications);
  }

  function onValueChanged(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value })
    // props.setInitData({ ...props.initData, [name]: value })
    props.setDataChanged(true)
  }

  function onCloseModal() {
    setShowModal(false);
  }

  function onNew(e: any) {
    setModalMode(ModalMode.Add);
    setShowModal(true);
    var d = binMapTypes.find(x => x.value === e.itemData.value);
    var binMap = new BinMapBase();
    binMap.type = e.itemData.value
    binMap.studyType = d?.studyType!;
    setSelectedData(binMap);
    setBinSelectedData([]);
  }

  function onNewTemplate(e: any) {
    setModalMode(ModalMode.Add);
    setShowModal(true);
    var binMap = TemplatesData.find(x => x.name === e.itemData.name);
    setSelectedData(binMap!);
    setBinSelectedData(binMap?.bins!);
    console.log(binSelectedData)
  }

  function onAdd() {
    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      props.setInitData({
        ...props.initData, binMaps: [...props.initData.binMaps, {
          id: uuid(), name: selectedData.name, noBinInUse: selectedData.noBinInUse, type: selectedData.type, incomingMovement: selectedData.incomingMovement,
          outgoingMovement: selectedData.outgoingMovement,
          dateFormat: selectedData.dateFormat, bins: binMapsDataGridRef.current?.instance().getDataSource().items(),
          studyType: selectedData.studyType,
          isDisabled: selectedData.isDisabled,
          binMapClassificationType: selectedData.binMapClassificationType,
        }]
      })
      onCloseModal()
      setSelectedData(new BinMapBase())
      setBinSelectedData([]);
    } else {
      notify(t('fieldIsRequired'), 'error', 5000)
    }
  }

  function onUpdate() {
    const validationRes = validationRef.current?.instance().validate();
    if (validationRes?.isValid) {
      var index = props.initData.binMaps.findIndex(x => x.id === selectedData.id)
      if (index !== -1) {
        var currentBinMaps = props.initData.binMaps
        currentBinMaps[index].name = selectedData.name
        currentBinMaps[index].noBinInUse = selectedData.noBinInUse
        currentBinMaps[index].dateFormat = selectedData.dateFormat
        currentBinMaps[index].studyType = selectedData.studyType
        currentBinMaps[index].incomingMovement = selectedData.incomingMovement
        currentBinMaps[index].outgoingMovement = selectedData.outgoingMovement
        currentBinMaps[index].type = selectedData.type
        currentBinMaps[index].isDisabled = selectedData.isDisabled
        currentBinMaps[index].binMapClassificationType = selectedData.binMapClassificationType
        currentBinMaps[index].bins = binMapsDataGridRef.current?.instance().getDataSource().items()!
        props.setInitData({ ...props.initData, binMaps: currentBinMaps })
      }
      dataGridRef.current?.instance().refresh();
      setSelectedData(new BinMapBase())
      setBinSelectedData([]);
      onCloseModal()
    } else {
      notify(t('fieldIsRequired'), 'error', 5000)
    }
  }

  function onEdit(d: any) {
    setModalMode(ModalMode.Edit);
    setShowModal(true);
    setSelectedData(d.row.data)
    setBinSelectedData(d.row.data.bins)
  }

  function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [{
        text: t("yes"),
        onClick: async (e) => {
          try {
            props.setInitData({
              ...props.initData, binMaps: [...props.initData.binMaps.filter(
                x => x.id !== d.row.data.id)]
            })
            notify(t("dataSuccessfullyDeleted"), "success", 5000);

          } catch {
            notify(t("someErrorOccurred"), "error", 5000);
          }
          return { buttonText: e.component.option("text") }
        }
      },
      {
        text: t("no"),
        onClick: (e) => {
          return { buttonText: e.component.option("text") }
        }
      },]
    });
    myDialog.show();
  }

  function onDuplicate(d: any) {
    const binMapsData = props.initData?.binMaps
    binMapsData.push({ ...d.row.data, name: `${d.row.data.name} (Duplicated)`, id: uuid() })
    props.setInitData({ ...props.initData, binMaps: binMapsData })
    dataGridRef.current?.instance().refresh();
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("binMaps")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className="row">
            <div className="rightColumn">
              <DropDownButton
                style={{ marginLeft: 10 }}
                text={t('binMaps')}
                icon="fa-solid fa-circle-plus"
                items={SortObjectByPropName(binMapTypes.filter(x => props.initData.selectedStudyTypes.includes(x.studyType)), "name")}
                dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(binMapTypes.filter(x => props.initData.selectedStudyTypes.includes(x.studyType)), "name")) }}
                displayExpr="name"
                onItemClick={onNew}
                disabled={props.isLocked}
              />
              <DropDownButton
                style={{ marginLeft: 10 }}
                text={t('templates')}
                icon="fa-solid fa-circle-plus"
                items={SortObjectByPropName(lstTemplates, "name")}
                dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(lstTemplates, "name")) }}
                displayExpr="name"
                onItemClick={onNewTemplate}
                disabled={props.isLocked}
              />
            </div>
          </div>
          <ValidationGroup ref={props.validationRef}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initData.binMaps}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="name" caption={t("name")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="noBinInUse" caption={t("noBinInUse")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="type" caption={t("type")} visible={true}>
                <Lookup dataSource={SortObjectByPropName(binMapTypes, "name")} displayExpr="name" valueExpr="value" />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="binMapClassificationType" caption={t("binMapClassificationType")} visible={true}>
                <Lookup dataSource={SortObjectByPropName(lstBinMapClassificationType, "name")} displayExpr="name" valueExpr="value" />
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>

              </Column>
              <Column dataField="isDisabled" caption={t("isDisabled")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
                <DevExpressButton
                  hint={t("duplicate")}
                  onClick={onDuplicate}
                  icon="fa-solid fa-clone"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </ValidationGroup>
        </div>
      </div>

      <Popup
        width={"85%"}
        height={"90%"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t("add") : t("update")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <div style={{ height: formHeight }}>
          <ScrollView width="100%" height="100%">
            <ValidationGroup ref={validationRef}>
              <Form colCount={2} className='popupFields'>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("name")}
                    labelMode='floating'
                    value={selectedData.name}
                    onValueChange={(e) => onValueChanged("name", e)}
                    className="modalInput"
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </TextBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    label={t("noBinInUse")}
                    labelMode='floating'
                    value={selectedData.noBinInUse}
                    onValueChange={(e) => onValueChanged("noBinInUse", e)}
                    className="modalInput"
                  >
                    <Validator>
                      <RequiredRule message={t("fieldIsRequired")} />
                    </Validator>
                  </NumberBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("dateFormat")}
                    labelMode='floating'
                    value={selectedData.dateFormat}
                    onValueChange={(e) => onValueChanged("dateFormat", e)}
                    items={SortObjectByPropName(dateFormats, "name")}
                    valueExpr="value"
                    displayExpr="name"
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("type")}
                    labelMode='floating'
                    value={selectedData.type}
                    onValueChange={(e) => onValueChanged("type", e)}
                    items={SortObjectByPropName(binMapTypes.filter(x => props.initData.selectedStudyTypes.includes(x.studyType)), "name")}
                    valueExpr="value"
                    displayExpr="name"
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                {(selectedData.type === BinMapType.ArmadilloSpeed || selectedData.type === BinMapType.OldArmadilloSpeed || selectedData.type === BinMapType.GoldenRiver || selectedData.type === BinMapType.HoustonRadarSpeedClassCSV) &&
                  <SimpleItem>
                    <SelectBox
                      placeholder=""
                      label={t('incomingMovement')}
                      valueExpr="value"
                      displayExpr="name"
                      labelMode='floating'
                      value={selectedData.incomingMovement}
                      onValueChange={(e) => onValueChanged("incomingMovement", e)}
                      className="modalInput"
                      items={SortObjectByPropName(lstMovementDirectionType, "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                }
                {(selectedData.type === BinMapType.ArmadilloSpeed || selectedData.type === BinMapType.OldArmadilloSpeed || selectedData.type === BinMapType.GoldenRiver || selectedData.type === BinMapType.HoustonRadarSpeedClassCSV) &&

                  <SimpleItem >
                    <SelectBox
                      placeholder=""
                      label={t('outgoingMovement')}
                      valueExpr="value"
                      displayExpr="name"
                      labelMode='floating'
                      value={selectedData.outgoingMovement}
                      onValueChange={(e) => onValueChanged("outgoingMovement", e)}
                      className="modalInput"
                      items={SortObjectByPropName(lstMovementDirectionType, "name")}
                      showClearButton={true}
                      searchEnabled={true}
                    />
                  </SimpleItem>
                }

                <SimpleItem>
                  <SelectBox
                    placeholder=""
                    label={t('binMapClassificationType')}
                    valueExpr="value"
                    displayExpr="name"
                    labelMode='floating'
                    value={selectedData.binMapClassificationType}
                    onValueChange={(e) => onValueChanged("binMapClassificationType", e)}
                    className="modalInput"
                    items={SortObjectByPropName(lstBinMapClassificationType, "name")}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>


                <SimpleItem>
                  <SelectBox
                    placeholder=""
                    label={t('studyType')}
                    valueExpr="value"
                    displayExpr="name"
                    labelMode='floating'
                    value={selectedData.studyType}
                    onValueChange={(e) => onValueChanged("studyType", e)}
                    className="modalInput"
                    items={SortObjectByPropName(lstStudyType, "name")}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>

                <SimpleItem colSpan={1}>
                  <div>{t("isDisabled")}</div>
                  <Switch
                    style={{ marginTop: 5, fontWeight: "bold" }}
                    value={selectedData.isDisabled}
                    onValueChange={(e) => onValueChanged("isDisabled", e)}
                  />
                </SimpleItem>

              </Form>

              <DataGrid
                id="gridContainer"
                ref={binMapsDataGridRef}
                dataSource={binSelectedData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Editing
                  mode="row"
                  allowUpdating={true}
                  allowDeleting={true}
                  allowAdding={true} />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column dataField="bin" caption={t("bin")} visible={true} dataType="number">
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="vehicleClassificationIn" caption={t("vehicleClassification")} visible={true}>
                  <Lookup dataSource={SortObjectByPropName(lstVClassificationData, "name")} displayExpr="name" valueExpr="in" />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                {(selectedData.type === BinMapType.TmcJCD || selectedData.type === BinMapType.MetroCountInductionLoopsVolume
                  || selectedData.type === BinMapType.GretchTRF || selectedData.type === BinMapType.GoldenRiver) &&
                  <Column dataField="movement" caption={t("movement")} visible={true}>
                    <Lookup dataSource={SortObjectByPropName(lstMovementTypes, "name")} displayExpr="name" valueExpr="value" />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                }
                {(selectedData.studyType === StudyType.Speed) &&
                  <Column dataField="lowerSpeed" caption={t("lowerSpeed")} visible={true} dataType="number">
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                }
                {(selectedData.studyType === StudyType.Speed) &&
                  <Column dataField="upperSpeed" caption={t("upperSpeed")} visible={true} dataType="number">
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                }
                {(selectedData.studyType === StudyType.Speed || selectedData.studyType === StudyType.Volume) &&
                  <Column dataField="lane" caption={t("lane")} visible={true} dataType="number">
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                }
              </DataGrid>
            </ValidationGroup>
          </ScrollView>
        </div>

        <div className="rightColumn" style={{ marginTop: 20 }}>
          <Button
            className="tes-modal-btn-cancel"
            style={{ marginRight: 20 }}
            onClick={() => onCloseModal()}
            text={t("cancel")}
          />
          {modalMode === ModalMode.Add ? (
            <Button
              className="tes-modal-btn-add"
              onClick={onAdd}
              text={t("add")}
            />
          ) : (
            <Button
              className="tes-modal-btn-add"
              onClick={onUpdate}
              text={t("update")}
            />
          )}
        </div>
      </Popup>

    </React.Fragment>
  );
};

export default BinMaps;
