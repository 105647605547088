export enum DistanceUnit     {
    Meter = 1,
    Kilometer=2,
    Mile=3
}

export enum SpfEquationType
{
    Type1 = 1, // a * F(tot)^b
    Type2 = 2, // a * F(maj)^b * F(min)^c
    Type3 = 3, // a * F(maj)^b * (F(min)/F(tot))^c
    Type4 = 4, // a * L * F^c * e^dx
    Type5 = 5, // a * L^b * F^c * e^dx
}