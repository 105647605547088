import { ResponseCode } from "../general/enums/generalEnums";
import { DivisionSystem } from "./enums/identityEnums";

export class Client {
  [key: string]: any;
  id!: string;
  name!: string;
  fms!: boolean;
  tms!: boolean;
  tcbOnline!: boolean;
  tcbOffline!: boolean;
  tmc!: boolean;
  sign!: boolean;
  safety!: boolean;
  infrastructure!: boolean;
  maintenance!: boolean;
  collision!: boolean;
  trafficStudy!: boolean;
  moduleOfModule!: boolean;
  dashboard!: boolean;
  systematicScreening!: boolean;

  getValue(name: string, obj: any): any {
    for (let key in obj) {
      if (key === name) return obj[key];
    }
    return null;
  }

  assign(name: string, value: any): any {
    let that = this as any;
    for (let key in this) {
      if (key === name) {
        that[key] = value;
      }
    }
    return this;
  }
}

export interface ILoginResponse {
  token: string;
  twoFactorEnabled: boolean;
  setupTwoFactor: boolean;
  resetPassword: boolean;
  account: string;
  manualEntryKey: string;
  qrCodeSetupImageUrl: string;
  responseCode: ResponseCode;
}

export class IdentityGeneralSetting {
  id!: string;
  passwordExpression!: string;
  twoFactorEnabled!: boolean;
  passwordExpiryDays!: number;
  divisionSystem: DivisionSystem = DivisionSystem.None;
  customer!: Client;
  divisionRoleEnabled!: boolean;
  customerId!: string;
  defaultSignOnMethod: SignOnMethod = SignOnMethod.TesIdentity;
}

export interface IDivisionParams {
  [key: string]: any;
  divisionId: string;
}
export interface IRoleParams {
  [key: string]: any;
  roleId: string;
}
export interface IUserDetailsParams {
  [key: string]: any;
  userId: string;
}

export class Division {
  [key: string]: any;
  id?: string;
  name?: string;
  city?: string;
  customerId!: string;
  customer?: Client;
}
export class Group {
  id!: string;
  name!: string;
  customerId!: string;
  description!: string;
}


export class CustomerXUser {
  id?: string;
  userId!: string;
  customerId!: string
}

export class ChangePassword {
  userId!: string;
  currentPass!: string;
  newPass!: string;
}

export enum SignOnMethod {
  TesIdentity = 1,
  AzureSingleSignOn = 2,
}