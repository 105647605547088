import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../types/field/enums/fieldEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";

export const useMmsRankLayer = ({
  showLayer,
  legend,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
}) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    if (
      map &&
      view &&
      roadSegmentLayer &&
      initDataGis &&
      lstStudyLocations &&
      showLayer &&
      lstStudyLocations.length !== 0
    ) {
      if (isLayerExist(map, "mmsRankLayer")) return;
      setIsLayerLoading(true);
      loadModules(["esri/layers/FeatureLayer", "esri/core/reactiveUtils"]).then(
        ([FeatureLayer, reactiveUtils]) => {
          let queryIds = generateQueryIds(
            initDataGis,
            "midblockGeoIdType",
            lstStudyLocations
          );

          const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

          const roadSegmentQuery = {
            outFields: ["*"],
            where: strRoadSegmentQuery,
            returnGeometry: true,
          };

          function queryingAndCreateLayer() {
            console.log("!!startGen");
            setIsLayerLoading(true);
            isUnderConstruction.current = true;
            view?.whenLayerView(roadSegmentLayer).then((layerView) => {
              reactiveUtils
                .whenOnce(() => !layerView.updating)
                .then((e) => {
                  if (!isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  console.log("!!startGen_1");
                  layerView.queryFeatures(roadSegmentQuery).then((results) => {
                    if (!isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    console.log("!!startGen_2");
                    const graphics = results.features;
                    graphics.forEach((graphic) => {
                      const matchedLocation = lstStudyLocations.find(
                        (loc) =>
                          loc.geoId.toString() ===
                          graphic.attributes[
                            initDataGis.midblockGeoIdName
                          ].toString()
                      );
                      graphic.attributes["smoothMMS"] =
                        matchedLocation?.smoothMMS.toString();
                      graphic.attributes["rin"] = matchedLocation?.rin;
                    });

                    const mmsRankLayer = new FeatureLayer({
                      id: "mmsRankLayer",
                      title: "MMSRankLayer",
                      source: graphics,
                      editable: true,
                      objectIdField: "ObjectId",
                      outFields: ["*"],
                      fields: [
                        {
                          alias: "Smooth MMS",
                          name: "smoothMMS",
                          type: "string",
                          editable: true,
                          nullable: true,
                        },
                        {
                          alias: initDataGis.midblockGeoIdName,
                          name: initDataGis.midblockGeoIdName,
                          type: "string",
                          editable: true,
                          nullable: false,
                        },
                        {
                          alias: "RIN",
                          name: "rin",
                          type: "string",
                          editable: false,
                          nullable: true,
                        },
                      ],
                      popupTemplate: {
                        title: "MMS Rank",
                        content: [
                          {
                            type: "fields",
                            fieldInfos: [
                              {
                                fieldName: initDataGis.midblockGeoIdName,
                                label: "Geo ID",
                              },
                              { fieldName: "mmsRank", label: "Total" },
                            ],
                          },
                        ],
                      },
                      labelingInfo: [
                        {
                          deconflictionStrategy: "none",
                          labelExpressionInfo: {
                            expression: "$feature.rin",
                          },
                          labelPlacement: "center-along",
                          symbol: {
                            type: "text", // autocasts as new TextSymbol()
                            font: {
                              size: 9,
                              weight: "bold",
                            },
                            color: "#ffffff",
                            haloColor: "#1c1c1c",
                            haloSize: 1,
                          },
                        },
                      ],
                      renderer: {
                        type: "unique-value",
                        field: "smoothMMS",
                        uniqueValueInfos: [
                          {
                            value: 1,
                            symbol: {
                              type: "simple-line",
                              color: "#ff0000",
                              width: "3",
                            },
                            label: "1",
                          },
                          {
                            value: 2,
                            symbol: {
                              type: "simple-line",
                              color: "#f5a30a",
                              width: "3",
                            },
                            label: "2",
                          },
                          {
                            value: 3,
                            symbol: {
                              type: "simple-line",
                              color: "#a4f40f",
                              width: "3",
                            },
                            label: "3",
                          },
                          {
                            value: 4,
                            symbol: {
                              type: "simple-line",
                              color: "#045add",
                              width: "3",
                            },
                            label: "4",
                          },
                          {
                            value: 5,
                            symbol: {
                              type: "simple-line",
                              color: "#a306dd",
                              width: "3",
                            },
                            label: "5",
                          },
                          {
                            value: 6,
                            symbol: {
                              type: "simple-line",
                              color: "#a26d08",
                              width: "3",
                            },
                            label: "6",
                          },
                        ],
                      },
                    });
                    console.log("!!here");

                    mmsRankLayer.load().then(() => {
                      if (!isTheLastMovement.current) {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                        return;
                      }
                      removeLayer(map, "mmsRankLayer");
                      map.add(mmsRankLayer);
                      legend.layerInfos.push({
                        layer: mmsRankLayer,
                      });
                      // legendExpand.expanded = true;
                    });

                    layerSuccessfullyLoadedInDomCallback(
                      view,
                      mmsRankLayer,
                      () => {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                      }
                    );
                  });
                });
            });
          }
          view.when(() => {
            if (
              isLayerExist(map, "mmsRankLayer") ||
              isUnderConstruction.current
            ) {
              return;
            }
            queryingAndCreateLayer();
          });
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              if (isLayerExist(map, "mmsRankLayer")) {
                isTheLastMovement.current = false;
              }
            },
            1
          );
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              isTheLastMovement.current = true;
              if (!isUnderConstruction.current) queryingAndCreateLayer();
            },
            700
          );
        }
      );
    }
  }, [map, view, initDataGis, lstStudyLocations, roadSegmentLayer, showLayer]);

  return {
    isMmsRankLayerLoading: isLayerLoading,
  };
};
