import { AttachmentType } from "./../../sign/enums/signEnums";
import { VMWebSupport } from "./../../sign/dto/supportDto";
import { VMWebSign } from "./../../sign/dto/signDto";
import { Cost, Job, Personnel } from "./../maintenanceTypes";

export class VMCost {
  id!: string;
  price!: number;
  fromDate!: Date;
  toDate!: Date;
}

export class VMEquipment {
  id!: string;
  customerId!: string;
  name!: string;
  costs!: VMCost[];
  costForJob!: number;
}

export class PersonnelName {
  id!: string;
  userId!: string;
  customerId!: string;
  firstname!: string;
  lastname!: string;
  costs!: VMCost[];
  costForJob!: number;
}

export class VMPersonnel extends Personnel {
  id!: string;
  userId!: string;
  customerId!: string;
  costs!: Cost[];
  costForJob!: number;
  jobs!: Job[];
  note!: string;
  isDeleted!: boolean;
  deletedDate!: Date;
}

export class VMAttachment {
  id!: string;
  uploadDT!: Date;
  key!: string;
  fileName!: string;
  attachmentType!: AttachmentType;
}

export class VMJob {
  constructor() {
    this.tags = [];
  }
  [key: string]: any;
  id!: string;
  customerId!: string;
  jobId!: string;
  name!: string;
  jobTypeId!: string;
  jobTypeName!: string;
  jobTypeNote!: string;
  jobStatusId!: string;
  jobStatusName!: string;
  assignDate!: Date;
  completionDate!: Date;
  duration!: string;
  labourCost!: number;
  materialCost!: number;
  equipmentCost!: number;
  otherCost!: number;
  total!: number;
  equipments!: VMEquipment[];
  personnels!: PersonnelName[];
  tags!: string[];
  signs!: VMWebSign[];
  supports!: VMWebSupport[];
  note!: string;
  attachments!: VMAttachment[];
  tagNames!: string;
  signRelated!: boolean;
  supportRelated!: boolean;
  maintenanceType!: string;
  accountNo!: string;
  jobTypeTitle!: string;
}
