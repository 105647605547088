import { DateTime } from "luxon";
import { GeneralSetting } from "../contexts/clientSetting";
import { VMField } from "../types/field/dto/fieldDTO";
import { TesField } from "../types/field/fieldType";
import { FieldType } from "../types/field/enums/fieldEnums";

function CombineDateAndTime(date: Date, time: Date): Date {
    var timeString = time.getHours() + ':' + time.getMinutes() + ':00';

    var year = date.getFullYear();
    var month = date.getMonth() + 1; // Jan is 0, dec is 11
    var day = date.getDate();
    var dateString = '' + year + '-' + month + '-' + day;
    var combined = new Date(dateString + ' ' + timeString);

    return combined;
}

function CreateDateAsUTC(inDate: Date): Date {
    var date = new Date(inDate);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

function GetHourDiff(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
}
function AddDays2Date(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

function GridActualDateCalculator(rowData: any, field: (TesField | VMField), generalSetting: GeneralSetting | null) {
    if (rowData[field.name] === null || rowData[field.name] === "" || rowData[field.name] === undefined) {
        return null;
    }
    var dtFormat = generalSetting?.dateTimeFormat!;

    if (field.fieldType === FieldType.DateTime) {
        dtFormat = generalSetting?.dateTimeFormat!
    } else if (field.fieldType === FieldType.Time) {
        dtFormat = generalSetting?.timeFormat!
    }
    else if (field.fieldType === FieldType.Date) {
        dtFormat = generalSetting?.dateFormat!
    }

    if (field.exactDT === true) {
        var newDate = DateTime.fromISO(rowData[field.name], { zone: 'utc' });
        return newDate.toFormat(dtFormat)
    } else {
        var newDate = DateTime.fromISO(rowData[field.name]);
        return newDate.toFormat(dtFormat)
    }

}

function DatePickerDateCalculator(val: any) {
    if (val !== null) {var newDate = DateTime.fromISO(val, { zone: 'utc' });
    var formattedDT = newDate.setZone(DateTime.local().zoneName, { keepLocalTime: true }).toISO();
    return formattedDT}
}


function FormatLabelDT(val: any, field: (TesField | VMField), generalSetting: GeneralSetting | null) {
    if (val === null || val === "" || val === undefined) {
        return null;
    }

    var dtFormat = generalSetting?.dateTimeFormat!;

    if (field.fieldType === FieldType.DateTime) {
        dtFormat = generalSetting?.dateTimeFormat!
    } else if (field.fieldType === FieldType.Time) {
        dtFormat = generalSetting?.timeFormat!
    }
    else if (field.fieldType === FieldType.Date) {
        dtFormat = generalSetting?.dateFormat!
    }
    if (field.exactDT === true) {
        var newDate = DateTime.fromISO(val, { zone: 'utc' });
        return newDate.toFormat(dtFormat)
    } else {
        var newDate = DateTime.fromISO(val);
        return newDate.toFormat(dtFormat)
    }
}

function FormatDT(val: any, fieldType: (FieldType), exactDT: boolean, generalSetting: GeneralSetting | null) {
    if (val === null || val === "" || val === undefined) {
        return null;
    }

    var dtFormat = generalSetting?.dateTimeFormat!;

    if (fieldType === FieldType.DateTime) {
        dtFormat = generalSetting?.dateTimeFormat!
    } else if (fieldType === FieldType.Time) {
        dtFormat = generalSetting?.timeFormat!
    }
    else if (fieldType === FieldType.Date) {
        dtFormat = generalSetting?.dateFormat!
    }
    if (exactDT === true) {
        var newDate = DateTime.fromISO(val, { zone: 'utc' });
        return newDate.toFormat(dtFormat)
    } else {
        var newDate = DateTime.fromISO(val);
        return newDate.toFormat(dtFormat)
    }
}

export { CombineDateAndTime, CreateDateAsUTC, GetHourDiff, AddDays2Date, GridActualDateCalculator, DatePickerDateCalculator, FormatLabelDT, FormatDT }