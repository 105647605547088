import i18n from "../../../../../react-i18next.d";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("details"),
    icon: "fa-solid fa-ellipsis",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("hourFactors"),
    icon: "fa-solid fa-clock",
    content: "Details tab content",
  },
  {
    id: 2,
    text: i18n.t("dayFactors"),
    icon: "fa-solid fa-sun",
    content: "Details tab content",
  },
  {
    id: 3,
    text: i18n.t("monthFactors"),
    icon: "fa-solid fa-calendar-days",
    content: "Details tab content",
  },
  {
    id: 4,
    text: i18n.t("locations"),
    icon: "fa-solid fa-map",
    content: "locations tab content",
  },
];
export default tabTitles;