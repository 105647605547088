//imports
import { Button } from "devextreme-react/button";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary,
} from "devextreme-react/data-grid";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GridFilter from "../../../../../components/gridFilter/gridFilter";
import GridProfilePicker from "../../../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../../../constants/Permissions";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { TesField } from "../../../../../types/field/fieldType";
import { GridType, TesMapModule } from "../../../../../types/general/enums/generalEnums";
import { GridSetting, INameId, NameValue } from "../../../../../types/general/generalTypes";
import { TrafficSection } from "../../../../../types/infrastructure/infrastructureTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../utils/dataGridTools";

interface IPros {
  initDataFields: TesField[] | undefined;
  initDataSiteSubTypes: INameId[] | undefined;
  initDataTrafficSection: TrafficSection;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroupRef>
  lstLocationType: NameValue[]
}

const Locations = (props: IPros) => {
  const [showMap, setShowMap] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { customerDivisions } = useClientSetting();

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_D_Site,
        InfrastructurePermissions.Infrastructure_R_Site,
        InfrastructurePermissions.Infrastructure_V_Site,
      ]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("locations")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>

                  <Button onClick={() => setShowMap(true)}
                    icon="fa-solid fa-map-location-dot"
                  />

                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId") ?? ""}
                    gridType={GridType.Location}
                    dataGridRef={dataGridRef}
                    setGridSetting={setGridSetting}
                  />

                  <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => setShowFilter(true)}
                    icon="fa-solid fa-filter"
                    hint={t("filter")}
                  />

                </div>
              </div>
              {props.initDataFields && props.initDataFields.length > 0 && (
                <DataGrid
                  id="gridContainer"
                  key={"_id"}
                  ref={dataGridRef}
                  dataSource={props.initDataTrafficSection.locations}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  // onRowClick={onRowClicked}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  style={{ margin: "0 1rem" }}
                  onExporting={OnExporting}
                  // onContextMenuPreparing={e => { setSelectedRowData(e) }}
                  columnAutoWidth={gridSetting.horizontalScrolling}
                // disabled={props.isLocked}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <StateStoring
                    enabled={true}
                    type="localStorage"
                    storageKey={GridType.Location.toString()}
                    savingTimeout={500}
                  />
                  <SortByGroupSummaryInfo
                    summaryItem="Total Count"
                    sortOrder="desc"
                  />
                  <Summary>
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                    deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  {/* <SearchPanel visible={true} width={285} placeholder="Search..." /> */}
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column
                    dataField="description"
                    allowSorting={true}
                    caption={t("description")}
                    width={400}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="geoId"
                    allowSorting={true}
                    caption={t("geoId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="divisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="siteSubTypeId"
                    allowSorting={true}
                    visible={false}
                    caption={t("siteSubType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(props.initDataSiteSubTypes!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                  <Column
                    dataField="locationType"
                    allowHeaderFiltering={true}
                    caption={t("locationType")}
                  >
                    <Search enabled />
                    <Lookup
                      dataSource={SortObjectByPropName(props.lstLocationType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                    />
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="latitude"
                    dataType="number"
                    caption={t("latitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>

                  <Column
                    alignment="left"
                    dataField="longitude"
                    dataType="number"
                    caption={t("longitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="xCoord"
                    caption={t("xCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="yCoord"
                    caption={t("yCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="isDeleted"
                    allowHeaderFiltering={true}
                    caption={t("isDeleted")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="inactive"
                    allowHeaderFiltering={true}
                    caption={t("inactive")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="overwriteAutoGenerateDescription"
                    allowHeaderFiltering={true}
                    caption={t("overwriteAutoGenerateDescription")}
                    visible={false}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                </DataGrid>
              )}
            </div>
          </div>

          <GridFilter
            dataGridRef={dataGridRef}
            setShowModal={setShowFilter}
            showModal={showFilter}
            tesModule={TesMapModule.Collision}
          />

        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Locations;
