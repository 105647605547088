import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../types/field/enums/fieldEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";

export const useTrafficRoadSegmentAADTLayer = ({
  showLayer,
  legend,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "RoadSegmentAADTLayer")) {
          removeLayer(map, "RoadSegmentAADTLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "RoadSegmentAADTLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "RoadSegmentAADTLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);
      loadModules(["esri/layers/FeatureLayer", "esri/core/reactiveUtils"]).then(
        ([FeatureLayer, reactiveUtils]) => {
          if (isCancelled.current) {
            setIsLayerLoading(false);
            isUnderConstruction.current = false;
            return;
          }

          const midblockLocations = lstStudyLocations.filter(
            (loc) =>
              loc.locationType.toString() === LocationType.Midblock.toString()
          );

          if (midblockLocations.length === 0) return;

          let queryIds = generateQueryIds(
            initDataGis,
            "midblockGeoIdType",
            midblockLocations
          );

          const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

          const roadSegmentQuery = {
            outFields: ["*"],
            where: strRoadSegmentQuery,
            returnGeometry: true,
          };

          function queryingAndCreateLayer() {
            setIsLayerLoading(true);
            isUnderConstruction.current = true;
            view?.whenLayerView(roadSegmentLayer).then((layerView) => {
              reactiveUtils
                .whenOnce(() => !layerView.updating)
                .then(() => {
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }

                  layerView.queryFeatures(roadSegmentQuery).then((results) => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }

                    const graphics = results.features;

                    // Attach roadSegmentAADT to the graphics attributes
                    graphics.forEach((graphic) => {
                      const matchedLocation = lstStudyLocations.find(
                        (loc) =>
                          loc.geoId.toString() ===
                          graphic.attributes[
                            initDataGis.midblockGeoIdName
                          ].toString()
                      );
                      graphic.attributes["roadSegmentAADT"] =
                        matchedLocation?.roadSegmentAADT.toString();
                    });
                    const roadSegmentAADTLayer = new FeatureLayer({
                      id: "RoadSegmentAADTLayer",
                      title: "RoadSegmentAADTLayer",
                      source: graphics,
                      editable: true,
                      objectIdField: "ObjectId",
                      outFields: ["*"],
                      fields: [
                        {
                          alias: "RoadSegment AADT",
                          name: "roadSegmentAADT",
                          type: "string",
                          editable: true,
                          nullable: true,
                        },
                        {
                          alias: initDataGis.midblockGeoIdName,
                          name: initDataGis.midblockGeoIdName,
                          type: "string",
                          editable: true,
                          nullable: false,
                        },
                      ],
                      popupTemplate: {
                        title: "Road Segment AADT",
                        content: [
                          {
                            type: "fields",
                            fieldInfos: [
                              {
                                fieldName: initDataGis.midblockGeoIdName,
                                label: "Geo ID",
                              },
                              { fieldName: "roadSegmentAADT", label: "Total" },
                            ],
                          },
                        ],
                      },
                      renderer: {
                        type: "class-breaks",
                        field: "roadSegmentAADT",
                        classBreakInfos: [
                          {
                            minValue: 1,
                            maxValue: 1000,
                            symbol: {
                              type: "simple-line",
                              color: "#00ff00",
                              width: "4px",
                            },
                            label: "> 0",
                          },
                          {
                            minValue: 1001,
                            maxValue: 5000,
                            symbol: {
                              type: "simple-line",
                              color: "#FFFF00",
                              width: "6px",
                            },
                            label: "> 1000",
                          },
                          {
                            minValue: 5001,
                            maxValue: 10000,
                            symbol: {
                              type: "simple-line",
                              color: "#ee8200",
                              width: "10px",
                            },
                            label: "> 5000",
                          },
                          {
                            minValue: 10001,
                            maxValue: 20000,
                            symbol: {
                              type: "simple-line",
                              color: "#ff0000",
                              width: "13px",
                            },
                            label: "> 10000",
                          },
                          {
                            minValue: 20001,
                            maxValue: 500000,
                            symbol: {
                              type: "simple-line",
                              color: "#8B4000",
                              width: "13px",
                            },
                            label: "> 20000",
                          },
                        ],
                      },
                    });

                    roadSegmentAADTLayer.load().then(() => {
                      if (isCancelled.current || !isTheLastMovement.current) {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                        return;
                      }
                      removeLayer(map, "RoadSegmentAADTLayer");
                      map.add(roadSegmentAADTLayer);
                      legend.layerInfos.push({
                        layer: roadSegmentAADTLayer,
                      });
                      legendExpand.expanded = true;
                    });

                    layerSuccessfullyLoadedInDomCallback(
                      view,
                      roadSegmentAADTLayer,
                      () => {
                        setIsLayerLoading(false);
                        isUnderConstruction.current = false;
                      }
                    );
                  });
                });
            });
          }
          view.when(() => {
            if (isCancelled.current) {
              setIsLayerLoading(false);
              return;
            }
            if (
              isLayerExist(map, "RoadSegmentAADTLayer") ||
              isUnderConstruction.current
            ) {
              return;
            }
            queryingAndCreateLayer();
          });
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              if (isLayerExist(map, "RoadSegmentAADTLayer")) {
                isTheLastMovement.current = false;
              }
            },
            1
          );
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              isTheLastMovement.current = true;
              if (!isCancelled.current && !isUnderConstruction.current)
                queryingAndCreateLayer();
            },
            700
          );
        }
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficRoadSegmentAADTLayerLoading: isLayerLoading,
  };
};
