import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../types/field/enums/fieldEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";

export const useTrafficAverageSpeedLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "AverageSpeedLayer")) {
          removeLayer(map, "AverageSpeedLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "AverageSpeedLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "AverageSpeedLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);
      loadModules([
        "esri/layers/FeatureLayer",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/core/reactiveUtils",
      ]).then(([FeatureLayer, Graphic, webMercatorUtils, reactiveUtils]) => {
        if (isCancelled.current) {
          setIsLayerLoading(false);
          isUnderConstruction.current = false;
          return;
        }

        const midblockLocations = lstStudyLocations.filter(
          (loc) =>
            loc.locationType.toString() === LocationType.Midblock.toString() &&
            loc.averageSpeed > 0
        );

        if (midblockLocations.length === 0) return;

        let queryIds = generateQueryIds(
          initDataGis,
          "midblockGeoIdType",
          midblockLocations
        );

        const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

        const roadSegmentQuery = {
          outFields: ["*"],
          where: strRoadSegmentQuery,
          returnGeometry: true,
        };

        function queryingAndCreateLayer() {
          setIsLayerLoading(true);
          isUnderConstruction.current = true;
          view?.whenLayerView(roadSegmentLayer).then((layerView) => {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                if (isCancelled.current || !isTheLastMovement.current) {
                  setIsLayerLoading(false);
                  isUnderConstruction.current = false;
                  return;
                }
                layerView.queryFeatures(roadSegmentQuery).then((results) => {
                  if (isCancelled.current || !isTheLastMovement.current) {
                    setIsLayerLoading(false);
                    isUnderConstruction.current = false;
                    return;
                  }
                  console.log("!!length", results.features.length);
                  const lstNewRoadSegmentGraphics = results.features
                    .filter((feature) => feature.geometry?.paths?.length > 0)
                    .map((place) => {
                      const paths = place.geometry.paths[0];
                      const [avgX, avgY] = getAveragePaths(paths);

                      // Convert to Longitude/Latitude and back to Web Mercator coordinates
                      const [lng, lat] = webMercatorUtils.xyToLngLat(
                        avgX,
                        avgY
                      );
                      const [x, y] = webMercatorUtils.lngLatToXY(lng, lat);

                      // Find matching location's average speed
                      const matchingLocation = lstStudyLocations.find(
                        (loc) =>
                          loc.geoId.toString() ===
                          place.attributes[
                            initDataGis.midblockGeoIdName
                          ].toString()
                      );

                      return new Graphic({
                        attributes: {
                          averageSpeed: matchingLocation?.averageSpeed,
                        },
                        geometry: {
                          type: "point",
                          x,
                          y,
                          spatialReference: {
                            wkid: place.geometry?.spatialReference?.wkid,
                          },
                        },
                      });
                    });

                  const averageSpeedLayer = new FeatureLayer({
                    id: "AverageSpeedLayer",
                    title: "Average Speed",
                    source: lstNewRoadSegmentGraphics,
                    objectIdField: "ObjectId",
                    outFields: ["*"],
                    fields: [
                      {
                        alias: "Average Speed",
                        defaultValue: null,
                        editable: true,
                        length: -1,
                        name: "averageSpeed",
                        nullable: true,
                        type: "double",
                      },
                    ],
                    labelingInfo: [
                      {
                        labelExpressionInfo: {
                          expression: "Round($feature.averageSpeed) + ' Km/h';",
                        },
                        labelPlacement: "above-center",
                        symbol: {
                          type: "text", // autocasts as new TextSymbol()
                          font: {
                            size: 12,
                            weight: "bold",
                          },
                          color: "#fdd3d2",
                          // haloColor: "red",
                          haloColor: "#8e0000",
                          haloSize: 1.2,
                        },
                      },
                    ],
                    renderer: {
                      type: "simple", // autocasts as new SimpleRenderer()
                      symbol: {
                        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
                        size: 0,
                      },
                    },

                    // popupTemplates can still be viewed on
                    // individual features
                    popupTemplate: {
                      title: "Average Speed",
                      content: [
                        {
                          type: "fields",
                          fieldInfos: [
                            {
                              fieldName: initDataGis.midblockGeoIdName,
                              label: "Geo ID",
                            },
                            {
                              fieldName: "averageSpeed",
                              label: "Average Speed",
                            },
                          ],
                        },
                      ],
                    },
                  });

                  averageSpeedLayer.load().then(() => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    removeLayer(map, "AverageSpeedLayer");
                    map.add(averageSpeedLayer);
                    legendExpand.expanded = false;
                  });

                  layerSuccessfullyLoadedInDomCallback(
                    view,
                    averageSpeedLayer,
                    () => {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                    }
                  );
                });
              });
          });
        }
        view.when(() => {
          if (isCancelled.current) {
            setIsLayerLoading(false);
            return;
          }
          if (
            isLayerExist(map, "AverageSpeedLayer") ||
            isUnderConstruction.current
          ) {
            return;
          }
          queryingAndCreateLayer();
        });
        afterMapStationaryWithDebounceCallback(
          view,
          () => {
            if (isLayerExist(map, "AverageSpeedLayer")) {
              isTheLastMovement.current = false;
            }
          },
          1
        );
        afterMapStationaryWithDebounceCallback(
          view,
          () => {
            isTheLastMovement.current = true;
            if (!isCancelled.current && !isUnderConstruction.current)
              queryingAndCreateLayer();
          },
          700
        );
      });
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficAverageSpeedLayerLoading: isLayerLoading,
  };
};
