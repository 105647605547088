import { Button, Popup, ScrollView, SelectBox, Switch, TextBox, ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Button as DevExpressButton,
    Export,
    FilterRow, HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    SearchPanel
} from 'devextreme-react/data-grid';
import { Form, SimpleItem } from "devextreme-react/form";
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../../contexts/auth';
import { XmlImportSetting, XmlTagSetting } from "../../../../../../types/collision/collisionTypes";
import { ImportFieldCategory } from '../../../../../../types/collision/enums/collisionEnums';
import { FieldCategoryType } from '../../../../../../types/field/enums/fieldEnums';
import { TesField } from '../../../../../../types/field/fieldType';
import { ModalMode } from '../../../../../../types/general/enums/generalEnums';
import { NameValue } from '../../../../../../types/general/generalTypes';
import { Enum2Array } from '../../../../../../utils/enumTools';

// props
interface IPros {
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroupRef>
    initData: XmlImportSetting;
    setInitData: React.Dispatch<React.SetStateAction<XmlImportSetting>>;
    fields: TesField[]
}

const FieldSettings = (props: IPros) => {
    const { t } = useTranslation();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedData, setSelectedData] = useState<XmlTagSetting>(new XmlTagSetting());
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [lstCategoryType, setLstCategoryType] = useState<NameValue[]>();
    const { activeLoading } = useAuth();
    const [fieldCat, setFieldCat] = useState<FieldCategoryType[]>([]);
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                setLstCategoryType(Enum2Array(ImportFieldCategory));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function mapFieldCategory(section: ImportFieldCategory) {
        let fieldcategory: FieldCategoryType[] = [];
        if (section === ImportFieldCategory.Root || section === ImportFieldCategory.General || section === ImportFieldCategory.Location) {
            fieldcategory.push(FieldCategoryType.CollisionGeneral)
        }
        if (section === ImportFieldCategory.Driver) {
            fieldcategory.push(FieldCategoryType.CollisionDriver, FieldCategoryType.CollisionPerson)
        }
        if (section === ImportFieldCategory.InvolvedPerson) {
            fieldcategory.push(FieldCategoryType.CollisionPerson, FieldCategoryType.CollisionPassenger, FieldCategoryType.CollisionPedestrian)
        }
        if (section === ImportFieldCategory.Pedestrian) {
            fieldcategory.push(FieldCategoryType.CollisionPerson, FieldCategoryType.CollisionPedestrian)
        }
        if (section === ImportFieldCategory.Road) {
            fieldcategory.push(FieldCategoryType.CollisionRoad)
        }
        if (section === ImportFieldCategory.Vehicle) {
            fieldcategory.push(FieldCategoryType.CollisionVehicle)
        }
        if (section === ImportFieldCategory.Attachment) {
            // fieldcategory = FieldCategoryType.CollisionGeneral
        }
        if (section === ImportFieldCategory.Light) {
            fieldcategory.push(FieldCategoryType.CollisionGeneral)
        }
        if (section === ImportFieldCategory.Environment) {
            fieldcategory.push(FieldCategoryType.CollisionGeneral)
        }
        return fieldcategory;
    }

    function onOpenPopUp() {
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }

    function onCloseModal() {
        setSelectedData(new XmlTagSetting())
        setShowModal(false)
    }

    function onValueChange(name: string, value: any) {
        if (name === "section") {
            setFieldCat(mapFieldCategory(value))
        }
        setSelectedData({
            ...selectedData, [name]: value
        })
    }

    function onEdit(d: any) {
        let newData = new XmlTagSetting();
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModal(true);
    }

    function onAdd() {
        if (props.initData) {
            props.setInitData({
                ...props.initData,
                fieldSettings: [
                    ...(props.initData?.fieldSettings || []),
                    { ...selectedData }
                ]
            });
        }
        dataGridRef.current?.instance().refresh();
        onCloseModal();
    }

    // function onUpdate() {
    //     const fieldSettings = props.initData?.fieldSettings;
    //     const selectedDataIndex: number = fieldSettings?.findIndex((x: XmlTagSetting) => x.fieldId === selectedData.fieldId);
    //     console.log(selectedData)
    //     fieldSettings[selectedDataIndex] = selectedData;
    //     props.setInitData({
    //         ...props.initData,
    //         fieldSettings: fieldSettings
    //     });

    //     dataGridRef.current?.instance().refresh();
    //     onCloseModal();
    // }

    function onDelete(d: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [
                {
                    text: t("yes"),
                    onClick: (e) => {
                        try {
                            props.setInitData({
                                ...props.initData,
                                fieldSettings: props.initData.fieldSettings.filter(x => x.fieldId !== d.row.data.fieldId)
                            });
                        } catch {
                            notify(t("someErrorOccurred"), "error", 5000);
                        }
                        return { buttonText: e.component.option("text") };
                    },
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") };
                    },
                },
            ],
        });
        myDialog.show();
    }
    return (
        <div className={`xmlImportSettingDetails-fields ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <div className="row">
                        <div className="rightColumn" style={{ marginTop: "1rem", marginRight: "1.5rem" }}>
                            <Button
                                onClick={() => onOpenPopUp()}
                                icon="fa-solid fa-circle-plus"
                                text={t("fieldSetting")}
                            />
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: 20, padding: 10 }}>
                        <DataGrid id="fieldGridContainer"
                            ref={dataGridRef}
                            dataSource={props.initData?.fieldSettings}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            style={{ margin: "0 1rem" }}
                        >

                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />

                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>


                            <Column dataField={'section'}
                                caption={t("section")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                                <Lookup dataSource={lstCategoryType} displayExpr="name" valueExpr="value" />
                            </Column>
                            <Column dataField={'xmlTagName'}
                                caption={t('xmlTagName')}
                            >
                            </Column>
                            <Column dataField={'fieldId'}
                                caption={t('fieldName')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>

                                <Lookup dataSource={props.fields} displayExpr="name" valueExpr="id" />
                            </Column>
                            <Column dataField={'multiValue'}
                                caption={t('multiValue')
                                }
                            >
                            </Column>
                            <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                                {/* <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

                                </DevExpressButton> */}
                                <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                                </DevExpressButton>
                            </Column>
                        </DataGrid>
                    </div>
                </ValidationGroup>

                {/* Popup for field setting*/}
                <Popup
                    width={"50%"}
                    height={"auto"}
                    visible={showModal}
                    resizeEnabled={true}
                    showTitle={true}
                    title={t('add')}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHiding={() => onCloseModal()}
                >
                    <ScrollView width='100%' height='100%'>
                        <ValidationGroup
                            ref={props.validationRef}
                        >
                            <Form
                                colCount={4}>
                                <SimpleItem colSpan={2}>
                                    <SelectBox
                                        label={t("section")}
                                        labelMode='floating'
                                        value={selectedData?.section}
                                        onValueChange={(e) => onValueChange("section", e)}
                                        className="modalInput"
                                        items={lstCategoryType}
                                        valueExpr="value"
                                        displayExpr="name"
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={2}>
                                    <TextBox
                                        label={t("xmlTagName")}
                                        labelMode='floating'
                                        value={selectedData?.xmlTagName}
                                        onValueChange={(e) => onValueChange("xmlTagName", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={2} >
                                    <div >{t('multiValue')}</div>
                                    <Switch
                                        style={{ marginTop: 5, fontWeight: 'bold' }}
                                        value={selectedData.multiValue}
                                        onValueChange={e => onValueChange("multiValue", e)}
                                        hint={t('multiValue')}
                                    />
                                </SimpleItem>                                <SimpleItem colSpan={2}>
                                    <SelectBox
                                        label={t("fields")}
                                        labelMode='floating'
                                        searchEnabled={true}
                                        value={selectedData?.fieldId}
                                        showClearButton={true}
                                        onValueChange={(e) => onValueChange("fieldId", e)}
                                        className="modalInput"
                                        items={props.fields?.filter(x => mapFieldCategory(selectedData?.section).includes(x.fieldCategoryType)).map(x => {
                                            return ({
                                                id: x.id,
                                                name: x.name + " ( " + FieldCategoryType[x.fieldCategoryType] + " )"
                                            })
                                        })}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                </SimpleItem>
                            </Form>
                        </ValidationGroup>
                        <div>
                            <div className='rightColumn'>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20, marginTop: '10px' }}
                                    onClick={() => onCloseModal()}
                                    text={t('cancel')}
                                />
                                <Button
                                    style={{ marginTop: '10px' }}
                                    className='tes-modal-btn-add'
                                    onClick={onAdd}
                                    text={t('add')}
                                />


                            </div>
                        </div>
                    </ScrollView>
                </Popup>
            </React.Fragment>
        </div>
    );
}
export default FieldSettings;