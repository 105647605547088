import { ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import { Form, SimpleItem } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import InfrastructureLocationPicker from '../../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker';
import { useAuth } from '../../../../../../contexts/auth';
import { VMMapLocation } from '../../../../../../types/collision/dto/collisionDtos';
import { TesMapModule } from '../../../../../../types/general/enums/generalEnums';
import { VMLocation } from '../../../../../../types/infrastructure/dto/locationdDto';
import { LocationPickerMode, LocationType } from '../../../../../../types/infrastructure/enums/infrastructureEnums';
import { AADTType } from '../../../../../../types/trafficStudy/enums/trafficStudyEnums';

// props
interface IPros {
    didMount: boolean,
    tempMapLocation?: VMMapLocation;
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroupRef>;
    renderMap: boolean;
    initLocationData: VMLocation;
    tesModule: TesMapModule;
    componentMode: LocationPickerMode;
    renderCoordinate: boolean;
    aadtType: string;
}

const Map = (props: IPros) => {
    const { t } = useTranslation();
    const { activeLoading } = useAuth();

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <Form colCount={2}>
                        <SimpleItem colSpan={2}>
                            <InfrastructureLocationPicker
                                didMount={props.didMount}
                                renderMap={props.renderMap}
                                initLocationData={props.initLocationData}
                                tesModule={TesMapModule.StudyDetails}
                                componentMode={LocationPickerMode.TextBox}
                                renderCoordinate={false}
                                locationType={props.aadtType === AADTType.Intersection.toString() ? LocationType.Intersection : LocationType.Midblock}
                                showIcons={true}
                                isLocked={props.isLocked}
                            />
                        </SimpleItem>
                    </Form>
                </ValidationGroup>
            </div>
        </React.Fragment>
    );


}
export default Map;