import React, { useRef, useState, useEffect } from "react";
import "./gisMap.scss";
import { Button } from "devextreme-react/button";
import { TesMapModule } from "../../../types/general/enums/generalEnums";
import { useInitializeMap } from "../hooks/useInitializeMap";
import { useIntersectionLayer } from "../hooks/useIntersectionsLayer";
import { useRoadSegmentLayer } from "../hooks/useRoadSegmentLayer";
import { useTranslation } from "react-i18next";
import { useCustomLayers } from "../hooks/useCustomLayers";
import {
  addClickOnMapTrigger,
  addDoubleClickWithControlOnMapTrigger,
  adjustViewZooming,
  createGraphic,
  findIntersections,
  findRoadSegments,
  onSelectedFeature_v1,
} from "../utils";
import { addPopupActions } from "../utils/popupsActions";
import { addSketchViewModel } from "../utils/addSketchViewModel";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";

let intersectionHighlightedRefs = [];
let roadSegmentHighlightedRefs = [];
let userInfrastructureSelectedHighlightedRef = [];

export function GeneralGisMap_v1({
  initDataGis,
  tesModule,
  go2Intersection,
  go2RoadSegment,
  filterByGeoId,
  addLocationFromMap,
  filterByLatLong,
  handleLocationChangeDirectly,
  go2Collision,
  go2SignSupport,
  selectLocation,
  lstLatLng,
  geoCodingBehavior = false,
  specifiedInfrastructureData,
}) {
  const { t } = useTranslation();

  const mapEl = useRef(null);
  const zoomTimerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showOverlayText, setShowOverlayText] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [selectedArea, setSelectedArea] = useState({});
  const [lstLocalSelectedIntersection, setLstLocalSelectedIntersection] =
    useState([]);
  const [lstLocalSelectedRoadSegment, setLstLocalSelectedRoadSegment] =
    useState([]);

  let isMeasurementModeActive = false;
  let selectedLongitude = 0;
  let selectedLatitude = 0;
  let selectedXCoord = 0;
  let selectedYCoord = 0;

  const isSelectionEnabled =
    tesModule === TesMapModule.Collision ||
    tesModule === TesMapModule.Infrastructure ||
    tesModule === TesMapModule.AreaDetails ||
    tesModule === TesMapModule.Sign ||
    tesModule === TesMapModule.Support ||
    tesModule === TesMapModule.PatrolArea ||
    tesModule === TesMapModule.Study ||
    tesModule === TesMapModule.AADT;
  const isMovingMapEnabled =
    tesModule === TesMapModule.CollisionDetails ||
    tesModule === TesMapModule.InfrastructureDetails;

  const onClickedOnMap = (event, hitTestResponse) => {
    if (
      tesModule === TesMapModule.CollisionDetails ||
      tesModule === TesMapModule.StudyDetails
    ) {
      selectedLongitude = event.mapPoint.longitude;
      selectedLatitude = event.mapPoint.latitude;
      selectedXCoord = event.mapPoint.x;
      selectedYCoord = event.mapPoint.y;
    }
    customizePopupByClickOnMap(hitTestResponse);
  };

  const onDoubleClickedOnMap = (event) => {
    view.popup.close();
    view.graphics.removeAll();
    handleLocationChangeDirectly({
      latitude: event.mapPoint.latitude,
      longitude: event.mapPoint.longitude,
      xCoord: event.mapPoint.x,
      yCoord: event.mapPoint.y,
    });
    createGraphic(
      view,
      event.mapPoint.latitude,
      event.mapPoint.longitude,
      null
    );
  };
  const foundInfrastructureLatLongCallback = ({ lat, long, x, y }) => {
    if (
      initDataGis &&
      geoCodingBehavior &&
      initDataGis?.resetLstLatLongToSelectedInfrastructureInGeoCoding
    ) {
      view.graphics.removeAll();
      handleLocationChangeDirectly({
        latitude: lat,
        longitude: long,
        xCoord: x,
        yCoord: y,
      });
      createGraphic(view, lat, long, null);
    }
  };

  const customizePopupByClickOnMap = (response) => {
    const attributes = response.graphic.attributes;
    if (attributes.id) {
      setTimeout(() => {
        view.popup.close();
      }, 100);
      if (
        tesModule === TesMapModule.Collision ||
        tesModule === TesMapModule.Infrastructure ||
        tesModule === TesMapModule.Study ||
        tesModule === TesMapModule.AADT
      ) {
        setTimeout(() => {
          view.popup.open({
            // open a popup to show some of the results
            location: response.mapPoint,
            title: "Collision Data",
            actions: [
              {
                id: "go-to-collision",
                image: "https://staticfile.tes.ca/gisMap/car-collision.png",
                title: "Show Collision Detail",
              },
            ],
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "id",
                    label: attributes.id,
                  },
                ],
              },
            ],
          });
        }, 1000);
      }

      if (
        tesModule === TesMapModule.Sign ||
        tesModule === TesMapModule.Support
      ) {
        setTimeout(() => {
          view.popup.open({
            // open a popup to show some of the results
            location: response.mapPoint,
            title: "Sign/Support Data",
            actions: [
              {
                id: "go-to-signSupport",
                image: "https://staticfile.tes.ca/gisMap/sign.png",
                title:
                  tesModule === TesMapModule.Support
                    ? "Show Support Details"
                    : tesModule === TesMapModule.Sign
                      ? "Show Sign Details"
                      : "",
              },
            ],
            content: [
              {
                type: "fields",
                fieldInfos: [
                  {
                    fieldName: "id",
                    label: attributes.id,
                  },
                ],
              },
            ],
          });
        }, 1000);
      }
    }
  };

  const removeAllAutomatedHighlight = () => {
    if (intersectionHighlightedRefs.length > 0) {
      intersectionHighlightedRefs.forEach((e) => e.remove());
    }
    if (roadSegmentHighlightedRefs.length > 0) {
      roadSegmentHighlightedRefs.forEach((e) => e.remove());
    }
  };
  const removeAllUserHighlight = () => {
    if (userInfrastructureSelectedHighlightedRef.length > 0) {
      userInfrastructureSelectedHighlightedRef.forEach((e) => e.remove());
    }
  };

  function setRoadSegmentHighlightedRefs(refs, shouldOverRide) {
    if (shouldOverRide) {
      roadSegmentHighlightedRefs = [...refs];
    } else {
      roadSegmentHighlightedRefs = [...roadSegmentHighlightedRefs, ...refs];
    }
  }
  function setIntersectionHighlightedRefs(refs, shouldOverRide) {
    if (shouldOverRide) {
      intersectionHighlightedRefs = [...refs];
    } else {
      intersectionHighlightedRefs = [...intersectionHighlightedRefs, ...refs];
    }
  }
  const withoutPermissionZoomTrying = (value) => {
    if (value === false) {
      setShowOverlayText(false);
    } else {
      if (!showOverlayText) {
        setShowOverlayText(true);
        if (zoomTimerRef.current) {
          clearTimeout(zoomTimerRef.current);
        }
        zoomTimerRef.current = setTimeout(() => {
          setShowOverlayText(false);
          zoomTimerRef.current = null;
        }, 4000);
      }
    }
  };

  // Initialize map with the required configuration
  const { view, map, measurementWidget, isInitialLoading, w } =
    useInitializeMap({
      mapEl,
      initDataGis,
      isMovingMapEnabled,
      hasLegend: false,
      hasLayerListWidget: true,
      hasPrintWidget: true,
      zoomBehavior: {
        isDefault: false,
        zoomOptions: {
          disableMouseWheel: true,
          disableDoubleClickZoom: true,
          disableDoubleClickWithControlPressed: !isMeasurementModeActive,
          disableKeyboardZoom: true,
          disablePopupZoom: true,
        },
        withoutPermissionZoomTryingCallback: withoutPermissionZoomTrying,
      },
    });

  const { intersectionLayer, intersectionUId, isIntersectionLoading } =
    useIntersectionLayer({ view, map, mapEl, initDataGis, tesModule });
  const { roadSegmentLayer, roadSegmentUId, isRoadSegmentLoading } =
    useRoadSegmentLayer({ view, map, mapEl, initDataGis, tesModule });

  const { isCustomLayerLoading } = useCustomLayers({ map, mapEl, initDataGis });

  const onSelectedFeatureCallback = (graphic, view) => {
    removeAllUserHighlight();
    view.whenLayerView(graphic.layer).then((layerView) => {
      const highlight = layerView.highlight(graphic);
      // const graphicType = graphic?.geometry?.type;
      userInfrastructureSelectedHighlightedRef = [
        ...userInfrastructureSelectedHighlightedRef,
        highlight,
      ];
    });
  };

  useEffect(() => {
    if (
      !isInitialLoading &&
      !isRoadSegmentLoading &&
      !isIntersectionLoading &&
      !isCustomLayerLoading &&
      view &&
      roadSegmentLayer &&
      intersectionLayer
    ) {
      adjustViewZooming({
        expand: 1,
        view,
        layers: [intersectionLayer, roadSegmentLayer],
        finishedZoomingCallback: (zoomResult) => {
          setIsLoading(false);
        },
      });
    }
  }, [
    isInitialLoading,
    isRoadSegmentLoading,
    isIntersectionLoading,
    isCustomLayerLoading,
    view,
    roadSegmentLayer,
    intersectionLayer,
  ]);

  useEffect(() => {
    if (view) {
      onSelectedFeature_v1(view, (graphic) => {
        onSelectedFeatureCallback(graphic, view);
      });
    }
  }, [view]);

  useEffect(() => {
    if (
      map &&
      view &&
      intersectionLayer &&
      roadSegmentLayer &&
      initDataGis !== null
    ) {
      isSelectionEnabled &&
        addSketchViewModel({
          view,
          map,
          layers: [intersectionLayer, roadSegmentLayer],
          initDataGis,
          setSelectedArea,
          setSelectedData,
        });
      addClickOnMapTrigger(view, onClickedOnMap);

      if (
        tesModule === TesMapModule.CollisionDetails ||
        tesModule === TesMapModule.CollisionGeoCode ||
        tesModule === TesMapModule.StudyDetails
      ) {
        addDoubleClickWithControlOnMapTrigger(view, onDoubleClickedOnMap);
      }

      addPopupActions({
        view,
        initDataGis,
        setIntersectionHighlightedRefs,
        setRoadSegmentHighlightedRefs,
        removeAllHighlight: () => {
          removeAllUserHighlight();
          removeAllAutomatedHighlight();
        },
        intersectionLayer,
        roadSegmentLayer,
        go2RoadSegment,
        go2Intersection,
        go2Collision,
        filterByGeoId,
        go2SignSupport,
        selectLocation,
        selectedLatitude,
        selectedLongitude,
        selectedXCoord,
        selectedYCoord,
        setLstLocalSelectedIntersection,
        setLstLocalSelectedRoadSegment,
      });
    }
  }, [map, view, intersectionLayer, roadSegmentLayer, initDataGis]);

  useEffect(() => {
    const infrastructuresData = Array.isArray(specifiedInfrastructureData)
      ? specifiedInfrastructureData
      : [specifiedInfrastructureData];
    if (
      view &&
      roadSegmentLayer &&
      intersectionLayer &&
      initDataGis &&
      !isLoading &&
      ((infrastructuresData.length > 0 && infrastructuresData[0].geoId) ||
        (lstLatLng.length > 0 && lstLatLng[0]?.latitude))
    ) {
      removeAllUserHighlight();
      removeAllAutomatedHighlight();
      let lstRoadSegmentGeoIds = [];
      let lstIntersectionGeoIds = [];

      infrastructuresData.forEach((infrastructure) => {
        if (infrastructure.locationType === LocationType.Intersection) {
          lstIntersectionGeoIds.push(infrastructure.geoId);
        } else if (infrastructure.locationType === LocationType.Midblock) {
          lstRoadSegmentGeoIds.push(infrastructure.geoId);
        }
      });

      if (lstRoadSegmentGeoIds.length > 0) {
        setLstLocalSelectedRoadSegment(lstRoadSegmentGeoIds);
        findRoadSegments({
          view,
          roadSegmentLayer,
          initDataGis,
          shouldGoToFirstFoundItem: geoCodingBehavior
            ? true
            : lstRoadSegmentGeoIds.length === 1 &&
              (lstLatLng.length === 0 ||
                !lstLatLng ||
                !lstLatLng[0].latitude ||
                !lstLatLng[0].longitude),
          lstLatLng,
          lstRoadSegmentGeoIds,
          setRoadSegmentHighlightedRefs,
          foundRoadSegmentLatLongCallback: foundInfrastructureLatLongCallback,
        });
      }
      if (lstIntersectionGeoIds.length > 0) {
        setLstLocalSelectedIntersection(lstIntersectionGeoIds);
        findIntersections({
          view,
          intersectionLayer,
          initDataGis,
          shouldGoToFirstFoundItem: geoCodingBehavior
            ? true
            : lstIntersectionGeoIds.length === 1 &&
              (lstLatLng.length === 0 ||
                !lstLatLng ||
                !lstLatLng[0].latitude ||
                !lstLatLng[0].longitude),
          lstLatLng,
          lstIntersectionGeoIds,
          setIntersectionHighlightedRefs,
          foundIntersectionLatLongCallback: foundInfrastructureLatLongCallback,
        });
      }
    }
  }, [
    view,
    roadSegmentLayer,
    intersectionLayer,
    initDataGis,
    specifiedInfrastructureData,
    lstLatLng,
    isLoading,
  ]);

  useEffect(() => {
    if (!isLoading && lstLatLng.length > 0 && view) {
      view.graphics.removeAll();
      const interval = setInterval(() => {
        if (view.graphics.length === 0) {
          clearInterval(interval);
          lstLatLng.forEach((loc) => {
            if (loc.latitude !== 0.0 && loc.longitude !== 0.0) {
              createGraphic(view, loc.latitude, loc.longitude, loc.id);
            }
          });
        }
      }, 50);
      if (lstLatLng.length === 1 && lstLatLng[0].latitude) {
        view.goTo({
          center: [lstLatLng[0].longitude, lstLatLng[0].latitude],
          zoom: 15,
        });
      }
    }
  }, [lstLatLng, isLoading, view]);

  const MAP_TOP_BUTTON = {
    [TesMapModule.Collision]: {
      textKey: "showAllCollisions",
      onClick: () => filterByGeoId(selectedData),
    },
    [TesMapModule.Infrastructure]: {
      textKey: "showAllLocations",
      onClick: () => filterByGeoId(selectedData),
    },
    [TesMapModule.Study]: {
      textKey: "showAllStudies",
      onClick: () => filterByGeoId(selectedData),
    },
    [TesMapModule.AADT]: {
      textKey: "showAllAadts",
      onClick: () => filterByGeoId(selectedData),
    },
    [TesMapModule.AreaDetails]: {
      textKey: "addLocations",
      onClick: () => addLocationFromMap(selectedData),
    },
    [TesMapModule.PatrolArea]: {
      textKey: "addLocations",
      onClick: () => addLocationFromMap(selectedData),
    },
    [TesMapModule.Sign]: {
      textKey: "showSigns",
      onClick: () => filterByLatLong(selectedArea),
    },
    [TesMapModule.Support]: {
      textKey: "showSupports",
      onClick: () => filterByLatLong(selectedArea),
    },
  };
  const go2SelectedLocation = () => {
    if (lstLocalSelectedIntersection.length === 1) {
      findIntersections({
        view,
        intersectionLayer,
        initDataGis,
        shouldGoToFirstFoundItem: true,
        lstLatLng,
        lstLocalSelectedIntersection,
        setIntersectionHighlightedRefs,
      });
    } else if (lstLocalSelectedRoadSegment.length === 1) {
      findRoadSegments({
        view,
        roadSegmentLayer,
        initDataGis,
        shouldGoToFirstFoundItem: true,
        lstLatLng,
        lstLocalSelectedRoadSegment,
        setRoadSegmentHighlightedRefs,
      });
    }
  };
  const go2FirstLatLng = () => {
    view?.goTo({
      center: [lstLatLng[0].longitude, lstLatLng[0].latitude],
      zoom: 15,
    });
  };

  const renderTopButton = () => {
    if (!MAP_TOP_BUTTON?.[tesModule] || selectedData.length < 1) return null;
    return (
      <Button
        className="tes-modal-btn-add"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={MAP_TOP_BUTTON[tesModule].onClick}
        style={{ width: "10rem", margin: "-1rem 0.5rem 0.5rem 0" }}
      >
        {t(MAP_TOP_BUTTON[tesModule].textKey)}
      </Button>
    );
  };

  return (
    <div className="generalGISMapNew">
      <div className="mainContainer">{renderTopButton()}</div>
      <div
        style={{
          position: "relative",
        }}
      >
        {isLoading && (
          <div className={"mapLoadingContainer"}>{t("loading")}</div>
        )}
        {showOverlayText && (
          <div
            className={"text_box_v1"}
            onMouseOver={() => {
              view && view.focus();
            }}
          >
            {t("useCtrl+ScrollToZoomTheMap")}
          </div>
        )}
        <div
          ref={mapEl}
          onMouseOver={() => {
            view && view.focus();
          }}
          className={"map_box2"}
        >
          {isMovingMapEnabled === true && (
            <>
              <div
                id="go-to-location"
                className="esri-widget esri-widget--button esri-widget esri-interactive"
                title="Go to Selected Location"
                onClick={go2SelectedLocation}
              >
                <span className="esri-icon-maps"></span>
              </div>

              <div
                id="go-to-coordinate"
                className="esri-widget esri-widget--button esri-widget esri-interactive"
                title="Go to Selected Coordinate"
                onClick={go2FirstLatLng}
              >
                <span className="esri-icon-north-navigation-filled"></span>
              </div>
            </>
          )}
          {isSelectionEnabled === true && (
            <>
              <div
                id="select-by-polygon"
                className="esri-widget esri-widget--button esri-widget esri-interactive"
                title="Select features by polygon"
              >
                <span className="esri-icon-checkbox-unchecked"></span>
              </div>

              <div
                id="clear-polygon"
                className="esri-widget esri-widget--button esri-widget esri-interactive"
                title="Clear Selection"
              >
                <span className="esri-icon-close-circled"></span>
              </div>
            </>
          )}
          <div
            id="distance"
            className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
            style={{ marginTop: "6.1rem", marginRight: "0.9rem" }}
            title="Distance Measurement Tool"
            onClick={() => {
              measurementWidget.activeTool = "distance";
              isMeasurementModeActive = true;
            }}
          >
            <span className="esri-icon-measure-line"></span>
          </div>

          <div
            id="clear-distance"
            className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
            style={{ marginTop: "8.15rem", marginRight: "0.9rem" }}
            title="Clear Measurement Tool"
            onClick={() => {
              measurementWidget.clear();
              isMeasurementModeActive = false;
            }}
          >
            <span className="esri-icon-trash"></span>
          </div>

          <div id="info" className="esri-widget"></div>
        </div>
      </div>
    </div>
  );
}
