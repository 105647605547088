export enum FileType
{
    Video = 1,
    DataBase = 2,
    Image = 3,
    Folder = 4,
    Zip = 5,
    txt = 6,
    Doc = 7,
    App = 8,
    TES = 9,
  }