//#region imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TesGet } from "../../../../utils/rest";
import { useTranslation } from "react-i18next";
import "./collisionGeoCoding.scss";
import notify from "devextreme/ui/notify";
import { VMMapLocation } from "../../../../types/collision/dto/collisionDtos";
import { CustomerCollision } from "../../../../types/collision/collisionTypes";
import { VMLocation } from "../../../../types/infrastructure/dto/locationdDto";
import { Form } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import InfrastructureLocationPicker from "../../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import { LocationPickerMode } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { TesMapModule } from "../../../../types/general/enums/generalEnums";
import { useNavigate } from "react-router-dom";

//#endregion


// props
interface IPros {
  didMount: boolean;
  initDataCollision: CustomerCollision;
  tempMapLocation: VMMapLocation;
  mapLocation: VMMapLocation;
  setInitDataCollision: React.Dispatch<React.SetStateAction<any>>;
  setMapLocation: React.Dispatch<React.SetStateAction<any>>
  isLocked: boolean;
}

const CollisionGeoCoding = (props: IPros) => {
  //#region consts
  const history = useNavigate();



  function handleLocationChange(loc: VMMapLocation) {
    props.setMapLocation(loc)
  }



  function setInfrastructureData(
    inData: VMLocation) {
    props.setInitDataCollision({
      ...props.initDataCollision,
      locationId: inData.id,
      locationDescription: inData.description,
      locationType: inData.locationType,
      geoId: inData.geoId,
      jurisdictionId: inData.jurisdictionId,
      municipalityId: inData.municipalityId
    });
  }


  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 10, padding: 10 }}>
        {/* <ValidationGroup ref={props.validationRef}> */}
        <Form colCount={2}>
          <SimpleItem colSpan={2}>
            <InfrastructureLocationPicker
              renderMap={true}
              didMount={props.didMount}
              setInfrastructureData={setInfrastructureData}
              initLocationData={{
                customerId: props.initDataCollision.customerId,
                id: props.initDataCollision.locationId,
              }}
              tesModule={TesMapModule.CollisionGeoCode}
              handleLocationChange={handleLocationChange}
              tempMapLocation={props.tempMapLocation}
              componentMode={LocationPickerMode.TextBox}
              selectedMapLocation={props.mapLocation}
              renderCoordinate={true}
              isLocked={props.isLocked}
              showIcons={true}
            />
          </SimpleItem>
        </Form>
        {/* </ValidationGroup> */}
      </div>
    </React.Fragment>
  );
};
export default CollisionGeoCoding;