import "./stepper.scss"

//each step is a <li>
//width property of ".stepper li" in css should be 100% divided by number of steps
//if you want to add label to any steps, just wrap text by corresponding <li>

interface IPros {
    selectedStep: number
    stepNumber: number
}

function Stepper(props: IPros) {
    let arr = []
    for (let index = 1; index <= props.stepNumber; index++) {
        arr.push(index);
    }
    return (
        <div className="container">
            <ul className="stepper">
                {arr.map((x, i) => {
                    return (
                        <li id={x.toString()} className={x <= props.selectedStep ? "active" : ""} style={{ width: 100 / arr.length + "%" }}></li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Stepper;