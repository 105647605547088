import { VMField } from "../types/field/dto/fieldDTO";
import { INameId } from "../types/general/generalTypes";

const fieldValueId2Name = (id: any, initDataFields: VMField[]) => {
    id = id.toLowerCase();
    const v = initDataFields.filter(x => x.fieldValues !== undefined).flatMap((x: VMField) => x?.fieldValues).filter((x: INameId) => x?.id === id);
    if (v.length >= 1) {
        return v[0].name;
    } else {
        return id;
    }
};

function Rule2View(rule: string, initDataFields: VMField[]) {
    console.log("init data"+JSON.stringify(initDataFields))
    if (rule !== undefined && rule !== null) {
        let lstGuid = rule.match(
            // eslint-disable-next-line no-useless-escape
            /(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}/g
        );
        let str = rule;
        if (lstGuid != null) {
            lstGuid.forEach((g) => {
                const v = fieldValueId2Name(g, initDataFields);
                str = str.replace(g, v);
            });
        }
        return str
    } else {
        return ""
    }
};


export { Rule2View }