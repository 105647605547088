import { Button, DataGrid, Form, ScrollView, TextBox } from 'devextreme-react';
import { Column, ColumnChooser, DataGridRef, Button as DevExpressButton, FilterRow, HeaderFilter, Pager, Paging, Search, SearchPanel } from 'devextreme-react/data-grid';
import FileUploader from 'devextreme-react/file-uploader';
import { SimpleItem } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { Tooltip } from 'devextreme-react/tooltip';
import ValidationGroup, { ValidationGroupRef } from 'devextreme-react/validation-group';
import { custom } from "devextreme/ui/dialog";
import notify from 'devextreme/ui/notify';
import { t } from 'i18next';
import React, { useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { CollisionApiUrl } from '../../../../../environment/routeSettings';
import { CustomerCollision, ICollisionParams, NetworkDriveFiles } from '../../../../../types/collision/collisionTypes';
import { ModalMode } from '../../../../../types/general/enums/generalEnums';
import { AttachmentDownloadURL } from '../../../../../types/general/generalTypes';
import { NewAttachmentType } from '../../../../../types/sign/enums/signEnums';
import { AttachmentTypeFinder, FileKeyCutterWithExtension, IsImage } from '../../../../../utils/fileTools';
import { TesPost } from '../../../../../utils/rest';
import { useParams } from 'react-router-dom';

// props
interface IPros {
    files: any[],
    initDataCollision: CustomerCollision;
    isLocked: boolean;
    onDeleteNewFile: (key: string) => void;
    onDeleteExistingFile: (key: string) => void;
    handleChangeFile: (files: any[] | undefined) => void;
    validationRef: React.RefObject<ValidationGroupRef>
    setInitDataCollision: React.Dispatch<React.SetStateAction<CustomerCollision>>
}

const Attachments = (props: IPros) => {
    const [selectedFileName, setSelectedFileName] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [showURLModal, setShowURLModal] = useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [currentFileSelected, setCurrentFileSelected] = useState<any[] | undefined>([]);
    const [downloadIconColor, setDownloadIconColor] = useState<string>("#F0B70D");
    const [trashIconColor, setTrashIconColor] = useState<string>("#F0B70D");
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [selectedData, setSelectedData] = useState<NetworkDriveFiles>(new NetworkDriveFiles());
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const params = useParams<ICollisionParams>();
    const iconMap: Record<NewAttachmentType, string> = {
        [NewAttachmentType.compressed]: 'fa-file-zipper',
        [NewAttachmentType.video]: 'fa-youtube',
        [NewAttachmentType.location]: 'fa-location-dot',
        [NewAttachmentType.clone]: 'fa-compact-disk',
        [NewAttachmentType.image]: 'fa-image',
        [NewAttachmentType.word]: 'fa-file-word',
        [NewAttachmentType.html]: 'fa-file-code',
        [NewAttachmentType.note]: 'fa-file-lines',
        [NewAttachmentType.pdf]: 'fa-file-pdf',
        [NewAttachmentType.music]: 'fa-headphones',
        [NewAttachmentType.powerpoint]: 'fa-file-powerpoint',
        [NewAttachmentType.excel]: 'fa-file-excel',
        [NewAttachmentType.unknown]: 'fa-file-circle-question'
    };

    const onMouseIn = (event: any, key: string) => {
        setSelectedFileName(key)
    };

    const onMouseOutDelete = (event: any, key: string) => {
        setSelectedFileName("")
    };

    async function showAttachment(fileKey: string) {
        var res = await TesPost(CollisionApiUrl() + "/api/Collisions/DownloadAttachment", { key: fileKey }, true) as AttachmentDownloadURL
        setUrl(res.downloadUrl);
        IsImage(fileKey) ? setShowImageModal(true) : setShowURLModal(true)
    }

    async function onDelete(key: string, mode: number) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [
                {
                    text: t("yes"),
                    onClick: async (e) => {
                        if (mode === 1) {
                            props.onDeleteExistingFile(key)
                        }
                        if (mode === 2) {
                            props.onDeleteNewFile(key)
                        }

                        return { buttonText: e.component.option("text") };
                    },
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") };
                    },
                },
            ],
        });
        myDialog.show();
    }


    function onCloseModal() {
        setSelectedData(new NetworkDriveFiles());
        setShowModal(false);
    }
    function onNew() {
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }

    function onEdit(d: any) {
        let newData = new NetworkDriveFiles();
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModal(true);
    }

    function onAdd() {
        props.setInitDataCollision({
            ...props.initDataCollision,
            networkDriveFiles:
                [
                    ...props.initDataCollision?.networkDriveFiles,
                    { ...selectedData, id: uuid() }
                ]
        });
        dataGridRef.current?.instance().refresh();
        onCloseModal();
    }

    function onChangeField(name: string, value: any) {
        const updatedValue = value !== null ? "file:///" + value : "";
        const fileName = value ? value.split('/').pop() : "";
        setSelectedData({
            ...selectedData,
            "fileAddress": updatedValue,
            "fileName": fileName
        })
    }
    async function onDeleteAddress(d: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    props.setInitDataCollision({
                        ...props.initDataCollision,
                        NetworkDriveFiles: [
                            ...props.initDataCollision.networkDriveFiles.filter((x: any) => x.Id !== d.row.data.Id)
                        ]
                    });
                    dataGridRef.current?.instance().refresh();
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }


    function onUpdate() {
        const data = props.initDataCollision.networkDriveFiles;
        const selectedDataIndex: number = data.findIndex((x: NetworkDriveFiles) => x.id === selectedData.id);
        data[selectedDataIndex] = selectedData;
        props.setInitDataCollision({
            ...props.initDataCollision,
            networkDriveFiles: data
        });
        dataGridRef.current?.instance().refresh();
        onCloseModal();
    }

    return (
        <div className={`collision-attachments ${compactViewModel ? "compactStyle" : ""}`}>

            <React.Fragment>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    {props.initDataCollision?.attachments === null ?
                        <div className='row' style={{ marginTop: 20, padding: 10 }}>
                            <div className='halfColumn' >
                                <p style={{ fontWeight: 'bold' }}>{t("thereIsNoDataInThisTab")}</p>
                            </div>
                        </div>
                        :
                        <div className={"content-block"}>
                            <div className={" responsive-paddings"}>
                                <div className="row">
                                    <div className="rightColumn">
                                        <Button
                                            onClick={() => setShowUploadModal(true)}
                                            icon="fa-solid fa-circle-plus"
                                            text={t("add")}
                                            disabled={props.isLocked}
                                        />

                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: "-4.75rem" }}>
                                    {(props.initDataCollision?.attachments !== null) && params.collisionId !== "AddNew" &&
                                        <h5 style={{ marginBottom: "1rem" }}>{t("previousAttachments")}</h5>
                                    }
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {props.initDataCollision?.attachments && props.initDataCollision?.attachments?.map((f, index) => (
                                            <div style={{ width: '12%', justifyContent: 'center', alignItems: 'center', margin: "0 1.5rem -1rem 0" }} onMouseLeave={e => onMouseOutDelete(e, f.key)} onMouseOver={e => onMouseIn(e, f.key)} >
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid ${iconMap[AttachmentTypeFinder(f.key)]} ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.key &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>
                                                                {!props.isLocked &&
                                                                    <i
                                                                        className={`fa-solid fa-trash-can ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                        onClick={() => onDelete(f.key, 1)}
                                                                        style={{ display: "block", margin: "1rem 0 0 0", cursor: "pointer", color: trashIconColor }}
                                                                        onMouseOver={e => setTrashIconColor("#212121")}
                                                                        onMouseLeave={e => setTrashIconColor("#F0B70D")}
                                                                    >
                                                                    </i>}
                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {props.files?.length !== 0 && <h5 style={{ margin: "1rem 0" }}>{t("recentAttachments")}</h5>}
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {props.files?.map((f, index) => (
                                            <div style={{ width: '12%', justifyContent: 'center', alignItems: 'center', margin: "0 1.5rem -1rem 0" }} onMouseLeave={e => onMouseOutDelete(e, f.name)} onMouseOver={e => onMouseIn(e, f.name)}>
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid ${iconMap[AttachmentTypeFinder(f.name)]} ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.name &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showAttachment(f.name)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>
                                                                {!props.isLocked &&
                                                                    <i
                                                                        className={`fa-solid fa-trash-can ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                        onClick={() => onDelete(f.name, 2)}
                                                                        style={{ display: "block", margin: "1rem 0 0 0", cursor: "pointer", color: trashIconColor }}
                                                                        onMouseOver={e => setTrashIconColor("#212121")}
                                                                        onMouseLeave={e => setTrashIconColor("#F0B70D")}
                                                                    >
                                                                    </i>}
                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`recentFile${index}`}>{FileKeyCutterWithExtension(f.name, 15)}</p>
                                                    {f.name.length > 15 &&
                                                        <Tooltip
                                                            target={`#recentFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.name}</div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>


                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className="row" style={{ marginTop: 20, padding: 10 }}>
                        <h5 style={{ marginTop: "-5rem", marginLeft: "1.5rem" }}>{t("networkDriveFiles")}</h5>
                        <div className="row" >
                            <div className='rightColumn'>
                                <Button
                                    onClick={onNew}
                                    icon="fa-solid fa-circle-plus"
                                    text={t('networkDriveFile')}
                                    disabled={props.isLocked}
                                />
                            </div>
                        </div>

                        <DataGrid id="gridContainer"
                            ref={dataGridRef}
                            dataSource={props.initDataCollision?.networkDriveFiles}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            hoverStateEnabled={true}
                            remoteOperations={false}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                        >
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>



                            <Column dataField="fileName"
                                caption={t('fileName')}
                                alignment='left'
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="fileAddress"
                                caption={t('fileAddress')}
                                alignment='left'
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                                <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

                                </DevExpressButton>
                                <DevExpressButton hint={t('delete')} onClick={onDeleteAddress} icon="fa-solid fa-trash-can">

                                </DevExpressButton>
                            </Column>
                        </DataGrid>
                    </div> */}

                </ValidationGroup>
            </React.Fragment>

            {/* Image modal */}
            <Popup
                width={"50%"}
                height={"auto"}
                visible={showImageModal}
                resizeEnabled={true}
                showTitle={true}
                title={t('image')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowImageModal(false)}
            >
                <ScrollView width='100%' height='100%'>
                    <div style={{ height: "90%" }}>

                        <div className="row">
                            <div className="col-md-12">
                                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                <img src={url} id="pic" style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain' }} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='rightColumn' style={{ marginTop: 10 }}>
                            <Button
                                className='tes-modal-btn-add'
                                style={{ marginRight: 20 }}
                                onClick={() => window.open(url, "_blank")}
                                text={t('download')}
                            />
                            <Button
                                className='tes-modal-btn-add'
                                onClick={() => { navigator.clipboard.writeText(url); notify(t("linkCopied"), "success", 5000); }}
                                text={t('copyLink')}
                            />

                        </div>
                    </div>
                </ScrollView>
            </Popup>


            {/* URL modal */}
            <Popup
                width={"50%"}
                height={"auto"}
                visible={showURLModal}
                resizeEnabled={true}
                showTitle={true}
                title={t('url')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowURLModal(false)}
            >
                <ScrollView width='100%' height='100%'>
                    <div style={{ height: "90%" }}>

                        <div className="row">
                            <div className="col-md-6">
                                <span style={{ color: "#F0B70D", fontWeight: 'bold' }}>{t("link")}: </span>
                                <a href={url} style={{ textDecoration: 'underline', color: '#404040' }}>{url}</a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='rightColumn' style={{ marginTop: 10 }}>
                            <Button
                                className='tes-modal-btn-add'
                                style={{ marginRight: 20 }}
                                onClick={() => window.open(url, "_blank")}
                                text={t('download')}
                            />
                            <Button
                                className='tes-modal-btn-add'
                                onClick={() => { navigator.clipboard.writeText(url); notify(t("linkCopied"), "success", 5000); }}
                                text={t('copyLink')}
                            />

                        </div>
                    </div>
                </ScrollView>
            </Popup>


            {/* Upload modal */}
            <Popup
                width={"50%"}
                height={"auto"}
                visible={showUploadModal}
                resizeEnabled={true}
                showTitle={true}
                title={t('upload')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowUploadModal(false)}
            >
                <ScrollView width='100%' height='100%'>
                    <div style={{ height: "90%" }}>

                        <div className="row">
                            <FileUploader selectButtonText={t("selectFile")} labelText="" multiple={true}
                                uploadMode="useForm"
                                value={currentFileSelected}
                                onValueChanged={e => setCurrentFileSelected(e.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <div className='rightColumn' style={{ marginTop: 10 }}>
                            <Button
                                className='tes-modal-btn-add'
                                style={{ marginRight: 20 }}
                                onClick={() => { props.handleChangeFile(currentFileSelected); setShowUploadModal(false); setCurrentFileSelected([]) }}
                                text={t('add')}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>


            {/* Add/Update File Address */}
            <Popup
                width={"50%"}
                height={"auto"}
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={modalMode === ModalMode.Add ? t('add') : t('update')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <ScrollView width='100%' height='100%'>
                    <Form
                        colCount={2}>
                        <SimpleItem colSpan={1} >
                            <TextBox
                                placeholder=""
                                label={t('fileAddress')}
                                value={selectedData?.fileAddress}
                                onValueChange={e => onChangeField("fileAddress", e)}
                            />
                        </SimpleItem>
                        {/* <SimpleItem colSpan={1} >
                                <TextBox
                                    placeholder=""
                                    label={t('fileName')}
                                    value={selectedData?.fileName}
                                    onValueChange={e => onChangeField("fileName", e)}
                                />
                            </SimpleItem> */}

                    </Form>
                    {/* </ValidationGroup> */}
                    <div>
                        <div className='rightColumn'>
                            <Button
                                className='tes-modal-btn-cancel'
                                style={{ marginRight: 20, marginTop: '10px' }}
                                onClick={() => onCloseModal()}
                                text={t('cancel')}
                            />
                            {modalMode === ModalMode.Add ?
                                <Button
                                    style={{ marginTop: '10px' }}
                                    className='tes-modal-btn-add'
                                    onClick={onAdd}
                                    text={t('add')}
                                />
                                :
                                <Button
                                    style={{ marginTop: '10px' }}

                                    className='tes-modal-btn-add'
                                    onClick={onUpdate}
                                    text={t('update')}
                                />
                            }
                        </div>
                    </div>

                </ScrollView>
            </Popup>
        </div >
    )
}

export default Attachments;
