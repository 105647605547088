import { VMMapLocation } from "../../collision/dto/collisionDtos";
import { NameValue } from "../../general/generalTypes";
import { LocationType, MapUpdateStatus, SectionDetailType, SectionType } from "../../infrastructure/enums/infrastructureEnums";
import { ToMapRequest } from "../../map/mapTypes";
import { RawDataDataSourceType } from "../enums/importEnums";
import { AADTCalculationType, AADTSourceType, AggregationInterval, AllWayStopType, ApproachLaneNo, ApproachType, FlowConditionType, MovementType, ReportingPeriodType, StudyCategory, StudyStatus, StudyType, TrafficMapMode, VehicleClassificationType } from "../enums/trafficStudyEnums";
import { IntersectionAADT, RoadSegmentAADT, SignalWarrantConfig, SimplifiedStudy, SpeedResult, StudyValidationLog, TmcResult, VolumeResult } from "../trafficStudyTypes";
import { ImportResponseDTO } from "./importDto";


export class Justification {
    isJustified!: boolean;
    justification1!: Justification1[]
    justification2!: Justification2[]
    justification3!: Justification3[]
    justification4!: Justification4[]
    justification5!: Justification5[]
    justification6!: Justification6[]
}

export class JustificationDTO extends Justification {
    justificationSummary!: string;
}

export class AllWayStopWarrants {
    urbanArterials!: AllWayStopMinimumVolumeWarrant[];
    collectorRuralArterial!: AllWayStopMinimumVolumeWarrant[];
    local!: AllWayStopMinimumVolumeWarrant[];
    collisionExperience!: AllWayStopMinimumCollisionWarrant;
}
export class AllWayStopMinimumVolumeWarrant {
    sectionType!: SectionType;
    allWayStopType!: AllWayStopType;
    name!: string;
    totalVolumeAllLegs!: number;
    totalVehPedVolumeCrossingMajor!: number;
    totalDelayCrossingMajor!: number;
    splitOnMajor4Leg!: number;
    splitOnMajor3Leg!: number;
    hoursNeededForJustification!: number;
    hasCrossingTrafficDelayed!: boolean;
    isJustified!: boolean;
    results!: AllWayStopMinimumVolumeWarrantResult[]
}
export class AllWayStopMinimumVolumeWarrantResult {
    hour!: string;
    startDT!: Date;
    endDT!: Date;
    allApproachVolume!: number;
    allApproachVolumeSatisfied!: boolean;
    minorApproachVolume!: number;
    pedestrianCrossingOnMajorVolume!: number;
    totalVolumeMinor!: number;
    minorApproachVolumeSatisfied!: boolean;
    majorApproachVolume!: number;
    totalPercentOnMajor!: number;
    totalPercentOnMajorSatisfied!: boolean;
}

export class AllWayStopMinimumCollisionWarrant {
    allWayStopType!: AllWayStopType;
    collisionNo!: number;
    years!: number[];
    localCollectorRuralArterialCollisionNo!: number;
    urbanArterialCollisionNo!: number;
    annualCollisionAverage!: number;
    collisionRequirementUrbanArterialFillFilled!: boolean;
    collisionRequirementLocalCollectorRuralArterialFillFilled!: boolean;
}

export class Justification1Result {
    hour!: number;
    allApproachVolume!: number;
    allApproachCompliance!: number;
    minorApproachVolume!: number;
    minorApproachCompliance!: number;
}

export class Justification2Result {
    hour!: number;
    majorStreetVolume!: number;
    majorApproachCompliance!: number;
    crossingMajorStreetVolume!: number;
    crossingMajorStreetCompliance!: number;
    satisfied!: number;
}

export class Justification4Result {
    hour!: number;
    majorStreetVolume!: number;
    minorStreetHigherApproachVolume!: number;
    crossingMajorStreetVolume!: boolean;
    minValueForJustification!: number
}
export class Justification1 {
    id!: string;
    name!: string;
    totalFullfilled!: number;
    minorFullfilled!: number;
    finalFullfilled!: number;
    is100Justified!: boolean;
    is80Justified!: boolean;
    approachLaneOnMajor!: ApproachLaneNo;
    approachLaneOnMinor!: ApproachLaneNo;
    flowCondition!: FlowConditionType;
    allApproach100Volume!: number;
    allApproach80Volume!: number;
    minorApproach100Volume!: number;
    minorApproach80Volume!: number;

    note!: string;
    results!: Justification1Result[]
}


export class Justification2 {
    id!: string;
    name!: string;
    majorStreetFullfilled!: number;
    crossingMajorStreetFullfilled!: number;
    finalFullfilled!: number;
    is100Justified!: boolean;
    is80Justified!: boolean;
    approachLaneOnMajor!: ApproachLaneNo;
    approachLaneOnMinor!: ApproachLaneNo;
    flowCondition!: FlowConditionType;
    majorStreet100Volume!: number;
    majorStreet80Volume!: number;
    crossingMajorStreet100Volume!: number;
    crossingMajorStreet80Volume!: number;

    note!: string;
    results!: Justification2Result[]
}

export class Justification3 {
    id!: string;
    name!: string;
    note!: string;
    justification180Satisfied!: boolean;
    justification280Satisfied!: boolean;
    twoJustificationSatisfied!: boolean;
}
export class Justification4 {
    id!: string;
    name!: string;
    note!: string;
    justificationSatisfied!: boolean;
    results!: Justification4Result[]

}
export class Justification5 {
    id!: string;
    name!: string;
    note!: string;
    totalCollisionNo!: number;
    collisionNoWarrant5!: number;
    compliance!: number;
    duration!: number;
    justification80Satisfied!: boolean;
    justification100Satisfied!: boolean;
}
export class Justification6 {
    id!: string;
    name!: string;
    note!: string;
    vehicleVolume!: number;
    pedestrianAdjustedVolume!: number;
    pedestrianDelayVolume!: number;
    pedestrianVolumeJustified!: boolean;
    pedestrianDelayVolumeJustified!: boolean;
    twoJustificationSatisfied!: boolean;
}
export class ExtraPercentile {
    percentile!: string;
    percentileValue!: number;
}
export class SpeedStatisticsResult {
    [key: string]: any;
    startD!: Date;
    approachType!: ApproachType;
    average!: number;
    median!: number;
    speed85thPercentile!: number;
    speed95thPercentile!: number;
    extraPercentile!: ExtraPercentile[];
    compliance!: number;
    standardDeviation!: number;
    maximumSpeed!: number;
    minimumSpeed!: number;
    total!: number;
    postedSpeed!: number;
}

export class FileWebDTO {
    fileName!: string;
    key!: string
}

export class StudyBaseDTO {
    constructor() {
        this.aggregationInterval = AggregationInterval.fifteen;
    }
    id?: string;
    studyNo?: number;
    customerId?: string;
    studyCategory?: StudyCategory;
    divisionId?: string;
    jurisdictionId?: string;
    municipalityId?: string;
    binMapId?: string;
    trafficCounterId?: string;
    signalWarrantConfigChanged!: boolean;
    allWayStopWarrantConfigChanged!: boolean;
    studyType?: StudyType;
    status?: StudyStatus;
    locationId?: string;
    locationDescription?: string;
    geoId?: string;
    locationType?: LocationType;
    sectionType?: SectionDetailType;
    speedLimit?: number;
    aggregationInterval?: AggregationInterval;
    rawDataStartDT?: Date;
    rawDataEndDT?: Date;
    mapLocation!: VMMapLocation
    workOrder!: WorkOrderBaseDTO;
    reportingPeriods!: ReportingPeriodDTO[];
    signalWarrantConfig!: SignalWarrantConfig;
    signalWarrants!: Justification;
    allWayStopWarrants!: AllWayStopWarrants;
    rawData?: any[];
    speedStatisticsResults!: SpeedStatisticsResult[]
    logs!: StudyValidationLog[]
    aadtCalculationType!: AADTCalculationType;
    files!: FileWebDTO[];
    dataSourceType!: RawDataDataSourceType;
    importLogs!: ImportResponseDTO[];
    relatedStudies?: StudyBaseDTO[]
    justificationSummary?: string;
    mapUpdateStatus!: MapUpdateStatus;
}



export class FlatRawDataDTO {
    id?: string;
    startDT?: Date;
    movement?: MovementType;
    vehicleClassName?: string;
    vehicleClassificationType?: VehicleClassificationType
    approach?: ApproachType;
    lane?: number;
    count?: number;
}

export class WorkOrderBaseDTO {
    no!: string;
    userId?: string;
    username?: string;
    studyId?: string;
    startDT?: Date;
    endDT?: Date;

}
export class ReportingPeriodDTO {
    id?: string;
    customerId?: string;
    startTime?: Date;
    endTime?: Date;
    label?: string;
    type?: ReportingPeriodType;
}

class StudyWebDetailsBaseDTO<TRaw, TResult> extends StudyBaseDTO {
    rawData?: TRaw[];
    result?: TResult;
}

export class StudyTmcWebDetailsDTO extends StudyWebDetailsBaseDTO<FlatRawDataDTO, TmcResult[]> {
    aadTs!: IntersectionAADT[]
    aadt!: IntersectionAADT
}
export class StudyVolumeWebDetailsDTO extends StudyWebDetailsBaseDTO<FlatRawDataDTO, VolumeResult[]> { }
export class StudySpeedWebDetailsDTO extends StudyWebDetailsBaseDTO<any, SpeedResult> {
    aadTs!: RoadSegmentAADT[]
    aadt!: RoadSegmentAADT
    speedStatisticsSummary!: SpeedStatisticsResult
    speedStatisticsSummaryDirection1!: SpeedStatisticsResult
    speedStatisticsSummaryDirection2!: SpeedStatisticsResult
}


export class StudyAADTCalculationResult {
    intersectionAADTs!: IntersectionAADT[]
    intersectionAADT!: IntersectionAADT
    roadSegmentAADT!: RoadSegmentAADT
    roadSegmentAADTs!: RoadSegmentAADT[]
}


export class StudyReportRequest {
    StudyId?: string;
    ReportTitle?: string;
    StartDT?: Date;
    EndDT?: Date;
    TesReportTypeName?: string;
    ApproachType?: ApproachType
}

export class FlatRawDataReq {
    studyId?: string;
    startDT?: Date;
    endDT?: Date;
    showZeroPriods?: boolean;

}

export class RecalculationRequestDTO {
    customerId!: string;
    studyIds!: string[]
}

export class ChangeStudyStatusDTO extends RecalculationRequestDTO {
    status!: StudyStatus
}



export class ToMapRequestTrafficDTO extends ToMapRequest {
    constructor() {
        super();
        this.showAverageSpeed = false;
        this.show85PercentileSpeed = false;
        this.showAverageXPostedSpeed = false;
        this.showIntersectionAADT = false;
        this.showPostedSpeed = false;
        this.showRoadSegmentAADT = false;
        this.showPostedSpeedByColor = false;
        this.showAverageSpeedByColor = false;
        this.showCompliance = false;
        this.showLatestSpeedStudy = false;
        this.showLatestVolumeStudy = false;
        this.showLatestTMCStudy = false;
    }

    showPostedSpeed!: boolean;
    showAverageSpeed!: boolean;
    showAverageXPostedSpeed?: boolean;
    show85PercentileSpeed?: boolean;
    showRoadSegmentAADT!: boolean;
    showIntersectionAADT!: boolean;
    showPostedSpeedByColor?: boolean;
    showAverageSpeedByColor?: boolean;
    showCompliance!: boolean;
    showLatestVolumeStudy!: boolean;
    showLatestSpeedStudy!: boolean;
    showLatestTMCStudy!: boolean;
    mapMode!: TrafficMapMode;
}

export class ToMapResponseTrafficDTO {
    trafficLocations!: LocationTrafficSummaryDTO[];
    studies!: SimplifiedStudy[]
}

export class LocationTrafficSummaryDTO {
    geoId!: string;
    postedSpeed!: number;
    averageSpeed!: number;
    percentile85!: number;
    locationType!: LocationType;
    roadSegmentAADT!: number;
    intersectionAADT!: number;
    northApproachVolume!: number;
    eastApproachVolume!: number;
    westApproachVolume!: number;
    southApproachVolume!: number;
    totalVolume!: number;
}

export class FromMapRequestTrafficDTO {
    customerId!: string;
    locationId!: string;
    fromDate!: Date;
    toDate!: Date;
}

export class FromMapResponseTrafficDTO {
    aadt!: NameValue[];
    locationTrafficSummary!: LocationTrafficSummaryDTO;
    studies!: SimplifiedStudy[]
}

export class VolumeTimeChartDTO {
    startDT!: Date;
    totalCountCar!: number;
    totalCountTruck!: number;
    totalCountCyclist!: number;
    totalCountVehicle!: number;
    totalPedestrian!: number;
}

export class IntersectionAADTDetails extends IntersectionAADT {
    studyId!: string;
    studyType!: StudyType
}
export class RoadSegmentAADTDetails extends RoadSegmentAADT {
    studyId!: string;
    studyType!: StudyType
}
export class AADTDetail {
    divisionId!: string;
    intersectionAADTs!: IntersectionAADTDetails[]
    intersectionAADT!: IntersectionAADT
    roadSegmentAADT!: RoadSegmentAADT
    roadSegmentAADTs!: RoadSegmentAADTDetails[]
    customerId!: string;
}

export class StudyAadtRecalculationReqDTO {
    studyId!: string
    sourceType!: AADTSourceType
}

export class StudyAllWayStopWarrantRecalculationReqDTO {
    studyId!: string
    allWayStopWarrants!: AllWayStopWarrants
}
export class StudySignalWarrantRecalculationReqDTO {
    studyId!: string
    signalWarrantConfig!: SignalWarrantConfig
}