import { Form, ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import DateBox from 'devextreme-react/date-box';
import { SimpleItem } from 'devextreme-react/form';
import { SelectBox } from 'devextreme-react/select-box';
import { Switch } from 'devextreme-react/switch';
import TextBox from 'devextreme-react/text-box';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { TMSPermissions } from '../../../../../constants/Permissions';
import { useAuth } from '../../../../../contexts/auth';
import { useClientSetting } from '../../../../../contexts/clientSetting';
import { InfrastructureApiUrl } from '../../../../../environment/routeSettings';
import { FieldCategoryType, FieldPermission, FieldType } from '../../../../../types/field/enums/fieldEnums';
import { TesField } from '../../../../../types/field/fieldType';
import { PageMode, ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { INameId, RequestResponseResult } from '../../../../../types/general/generalTypes';
import { IntersectionDescription, Location } from '../../../../../types/infrastructure/infrastructureTypes';
import { GeneralSetting } from '../../../../../types/setting/gridSettingTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { DatePickerDateCalculator } from '../../../../../utils/dateTimeTools';
import { RequestErrorHandling, TesGet } from '../../../../../utils/rest';

// props
interface IPros {
    description: IntersectionDescription;
    initDataFields: TesField[] | undefined;
    location: Location;
    setLocation: React.Dispatch<React.SetStateAction<any>>
    generalSettings: GeneralSetting | null;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroupRef>;
    pageMode: number
    onChangeDescription: (name: string, value: string) => void;
}

const General = (props: IPros) => {

    const [streetName1, setStreetName1] = useState('');
    const [streetName2, setStreetName2] = useState('');
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const { customerDivisions } = useClientSetting();
    const { user } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            if (!props.location.overwriteAutoGenerateDescription) {
                setStreetName1(await getStreetName(props?.location?.street1Id));
                setStreetName2(await getStreetName(props?.location?.street2Id));
            }
        };

        fetchData();
    }, [props.location]);

    function onChangeFields(name: string, value: any, isUpperCase: boolean) {
        if (name === "geoId" || name === "inactive" || name === "divisionId") {
            props.setLocation({
                ...props.location,
                [name]: value
            })
            if (props.pageMode === PageMode.Update) {
                props.setDataChanged(true)
            }
        } else {
            props.setLocation({
                ...props.location,
                general: {
                    ...props.location.general,
                    [name]: value
                }
            })
        }
        if (props.pageMode === PageMode.Update) {
            props.setDataChanged(true)
        }
        if (name === "overwriteDescription") {
            props.setLocation({ ...props.location, overwriteAutoGenerateDescription: value })
            props.setDataChanged(true)
        }
        if (name === "description") {
            props.setLocation({ ...props.location, description: value })
            props.onChangeDescription("description", props.location.description)
            props.setDataChanged(true)
        }
    }

    async function getStreetName(id: string) {
        if (id === undefined || id === null || id === "") return "";
        const res = await TesGet(InfrastructureApiUrl() + "/api/streets/GetStreetName/" + id, true) as RequestResponseResult<INameId>;
        if (res.responseCode === ResponseCode.OK) {
            return (res?.results?.name ?? "");
        } else {
            await RequestErrorHandling(res);
        }
        return ("");
    }

    return (
        <div className={`intersectionDetails-general ${compactViewModel ? "compactStyle" : ""}`}>

            <React.Fragment>
                <div className='row' style={{ marginTop: 20, padding: 10 }}>
                    <ValidationGroup ref={props.validationRef}>
                        {props.initDataFields?.filter(x => x.fieldCategoryType === FieldCategoryType.InfrastructureIntersectionGeneral).length === 0 &&
                            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                                <div className='halfColumn' >
                                    <p style={{ fontWeight: 'bold' }}>{t("thereIsNoDataInThisTab")}</p>
                                </div>
                            </div>
                        }

                        <Form colCount={2}>
                            <SimpleItem colSpan={1}>
                                <div style={{ color: '#424242' }}>{t("overwriteDescription")}</div>
                                <Switch
                                    style={{ marginTop: 5 }}
                                    hint={t("overwriteDescription")}
                                    value={props.location.overwriteAutoGenerateDescription}
                                    onValueChange={e => onChangeFields("overwriteDescription", e, false)}
                                    disabled={props.isLocked}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1} />
                            <SimpleItem colSpan={1}>
                                <TextBox
                                    label={t('description')}
                                    labelMode='floating'
                                    value={
                                        !props.location.overwriteAutoGenerateDescription
                                            ? `${streetName1} @ ${streetName2}`
                                            : props.location.description
                                    }
                                    onValueChange={e => onChangeFields('description', e, false)}
                                    disabled={props.isLocked || (!props.isLocked && !props.location.overwriteAutoGenerateDescription)}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <TextBox
                                    // style={{ marginBottom: 20 }}
                                    label={t('geoId')}
                                    labelMode='floating'
                                    value={props.location.geoId}
                                    onValueChange={e => onChangeFields('geoId', e, false)}
                                    disabled={props.isLocked}
                                >
                                    <Validator>
                                        <RequiredRule message={t("fieldIsRequired")} />
                                    </Validator>
                                </TextBox>
                            </SimpleItem>


                            <SimpleItem colSpan={1} visible={user?.permissions?.some(x => x === TMSPermissions.TMS_Admin)}>
                                <SelectBox
                                    placeholder=""
                                    // style={{ marginBottom: 20 }}
                                    label={t("division")}
                                    labelMode='floating'
                                    displayExpr="name"
                                    valueExpr="id"
                                    value={props.location.divisionId}
                                    onValueChange={e => onChangeFields('divisionId', e, false)}
                                    items={SortObjectByPropName(customerDivisions!, "name")}
                                    disabled={props.isLocked}
                                    showClearButton={true}
                                    searchEnabled={true}
                                />
                            </SimpleItem>
                            {/* eslint-disable-next-line array-callback-return */}
                            {props.initDataFields && props.initDataFields.filter(x => x.fieldCategoryType === FieldCategoryType.InfrastructureIntersectionGeneral && (props.isLocked ? true : x.readOnlyField === false)).map((f, i) => {
                                if (f.fieldType === FieldType.String) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <TextBox
                                                // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                labelMode='floating'
                                                value={props.location.general && props.location.general[f.name]}
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={i}
                                                name={f.name}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            />
                                        </SimpleItem>
                                    );
                                }
                                if (f.fieldType === FieldType.Number || f.fieldType === FieldType.DecimalNumber || f.fieldType === FieldType.DoubleNumber) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <TextBox
                                                // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                labelMode='floating'
                                                value={props.location.general && props.location.general[f.name]}
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={i}
                                                name={f.name}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.Date) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <DateBox
                                                // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                                dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                type="date"
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={i}
                                                name={f.name}
                                                pickerType="calendar"
                                                placeholder={props.generalSettings?.dateFormat}
                                                displayFormat={props.generalSettings?.dateFormat}
                                                useMaskBehavior={true}
                                                openOnFieldClick={true}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.Time) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <DateBox
                                                // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                                dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                type="time"
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={i}
                                                name={f.name}
                                                pickerType="rollers"
                                                placeholder={props.generalSettings?.timeFormat}
                                                displayFormat={props.generalSettings?.timeFormat}
                                                useMaskBehavior={true}
                                                openOnFieldClick={true}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.DateTime) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <DateBox
                                                // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                                dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                type="datetime"
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={i}
                                                name={f.name}
                                                pickerType="calendar"
                                                placeholder={props.generalSettings?.dateTimeFormat}
                                                displayFormat={props.generalSettings?.dateTimeFormat}
                                                useMaskBehavior={true}
                                                openOnFieldClick={true}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                            />
                                        </SimpleItem>
                                    );
                                }


                                if (f.fieldType === FieldType.List) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <SelectBox
                                                placeholder=""
                                                // style={{ marginBottom: 20 }}
                                                label={f.labelText}
                                                labelMode='floating'
                                                displayExpr="name"
                                                valueExpr="id"
                                                value={props.location.general && props.location.general[f.name]}
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                key={i}
                                                items={SortObjectByPropName(f.fieldValues, "name")}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                                showClearButton={true}
                                                searchEnabled={true}
                                            />
                                        </SimpleItem>
                                    );
                                }

                                if (f.fieldType === FieldType.Boolean) {
                                    return (
                                        <SimpleItem colSpan={1}>
                                            <div style={{ color: '#424242' }}>{f.labelText}</div>
                                            <Switch
                                                // style={{ marginBottom: 20 }}
                                                onValueChange={e => onChangeFields(f.name, e, true)}
                                                value={props.location.general && props.location.general[f.name]}
                                                key={i}
                                                name={f.name}
                                                disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            />
                                        </SimpleItem>
                                    );
                                }


                            })
                            }
                            <SimpleItem colSpan={1}>
                                <div style={{ color: '#424242' }}>{t("inactive")}</div>
                                <Switch
                                    style={{ marginTop: 5 }}
                                    hint={t("inactive")}
                                    value={props.location.inactive}
                                    onValueChange={e => onChangeFields("inactive", e, false)}
                                    disabled={props.isLocked}
                                />
                            </SimpleItem>
                        </Form>
                    </ValidationGroup>
                </div>
            </React.Fragment>
        </div>
    );
}
export default General;
