const CustomerPermissions = {
    Customer_V_Web: "Customer_V_Web",
    Customer_V_App: "Customer_V_App",
    Customer_D_User: "Customer_D_User",
    Customer_E_User: "Customer_E_User",
    Customer_R_User: "Customer_R_User",
    Customer_D_Group: "Customer_D_Group",
    Customer_A_Group: "Customer_A_Group",
    Customer_D_Division: "Customer_D_Division",
    Customer_A_Division: "Customer_A_Division",
    Customer_D_Report: "Customer_D_Report",
    Customer_D_Environment: "Customer_D_Environment",
    Customer_TES_Sync: "Customer_TES_Sync",
};
export default CustomerPermissions;