import { t } from 'i18next';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ChangePasswordForm, UpdatePasswordForm } from './components';
import { SingleCard } from './layouts';
function External() {
  // console.log("REDIRECT TO LOGIN")
  // alert("Hello! I am an alert box!!");
  window.location.href = '/bff/login?returnUrl=/';
  return null;
}

export default function NotAuthenticatedContent() {


  return (
    <Routes>
      <Route path='/updatePassword/:userId'
        element={
          <SingleCard title={t('updatePassword')}>
            <UpdatePasswordForm />
          </SingleCard>
        }
      ></Route>
      <Route
        path="*"
        element={<External />}
      />
      <Route
        path="/login"
        element={<External />}
      />

      <Route path='/setPassword/:userId'
        element={
          <SingleCard title={t("setPassword")}>
            <ChangePasswordForm />
          </SingleCard>
        }
      >
      </Route>

      {/* <Route path='/twoStep/:twoStepToken'
        element={
          <SingleCard title="Two Step">
            <TwoStep />
          </SingleCard>
        }>
      </Route>

      <Route path='/updatePassword/:userId'
        element={
          <SingleCard title="Update Password">
            <UpdatePasswordForm />
          </SingleCard>
        }
      >
      </Route>

      <Route path='/setupTwoStep/:twoStepToken/:twoStepQR/:twoStepCode'
        element={
          <SingleCard title="Setup Two Step">
            <SetupTwoStepForm />
          </SingleCard>
        }
      >
      </Route>

      <Route path='/create-account'
        element={
          <SingleCard title="Sign Up">
            <CreateAccountForm />
          </SingleCard>
        }
      >
      </Route>
      <Route path='/reset-password'
        element={
          <SingleCard
            title="Reset Password"
            description="Please enter the email address that you used to register, and we will send you an email with a link to reset your password."
          >
            <ResetPasswordForm />
          </SingleCard>
        }
      >
      </Route>
      <Route path='/change-password/:recoveryCode'
        element={
          <SingleCard title="Change Password">
            <ChangePasswordForm />
          </SingleCard>
        }
      >
      </Route> */}



    </Routes>
  );
}
