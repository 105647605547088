import { openInGoogleMap, openStreetView } from "./index";
import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";

export const addPopupActions = async ({
  view,
  initDataGis,
  setIntersectionHighlightedRefs,
  setRoadSegmentHighlightedRefs,
  removeAllHighlight,
  intersectionLayer,
  roadSegmentLayer,
  go2RoadSegment,
  go2Intersection,
  go2Collision,
  filterByGeoId,
  go2SignSupport,
  selectLocation,
  selectedLatitude,
  selectedLongitude,
  selectedXCoord,
  selectedYCoord,
  setLstLocalSelectedIntersection,
  setLstLocalSelectedRoadSegment,
}) => {
  if (!view) return;

  try {
    const [reactiveUtils] = await loadModules(["esri/core/reactiveUtils"]);

    await view.when();
    view.popup.dockEnabled = true;

    const popup = view.popup;
    popup.viewModel.on("trigger-action", function (event) {
      const actionId = event.action.id;
      const attributes = popup.viewModel?.selectedFeature?.attributes;
      const content = popup.viewModel?.content;

      switch (actionId) {
        case "go-to-collision":
          go2Collision(
            content[0].fieldInfos.find((x) => x.fieldName === "id").label
          );
          break;
        case "go-to-midblock":
          go2RoadSegment(attributes[initDataGis.midblockGeoIdName]);
          break;
        case "go-to-intersection":
          go2Intersection(attributes[initDataGis.intersectionGeoIdName]);
          break;
        case "show-all-data-of-geoId":
          //Intersection
          if (popup.viewModel.selectedFeature.geometry.paths === undefined) {
            filterByGeoId([
              {
                geoId: attributes[initDataGis.intersectionGeoIdName],
                locationType: LocationType.Intersection,
              },
            ]);
          } else {
            filterByGeoId([
              {
                geoId: attributes[initDataGis.midblockGeoIdName],
                locationType: LocationType.Midblock,
              },
            ]);
          }
          break;
        case "go-to-signSupport":
          go2SignSupport(
            content[0].fieldInfos.find((x) => x.fieldName === "id").label
          );
          break;
        case "select-intersection":
          selectLocation(
            attributes[initDataGis.intersectionGeoIdName],
            LocationType.Intersection,
            {
              latitude: selectedLatitude,
              longitude: selectedLongitude,
              xCoord: selectedXCoord,
              yCoord: selectedYCoord,
            }
          );

          removeAllHighlight();

          view?.whenLayerView(intersectionLayer).then(function (layerView) {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                setIntersectionHighlightedRefs(
                  layerView.highlight(popup.viewModel.selectedFeature)
                );
              });
          });
          setLstLocalSelectedIntersection([
            attributes[initDataGis.intersectionGeoIdName],
          ]);
          setLstLocalSelectedRoadSegment([]);
          view.popup.close();
          break;
        case "select-roadSegment":
          selectLocation(
            attributes[initDataGis.midblockGeoIdName],
            LocationType.Midblock,
            {
              latitude: selectedLatitude,
              longitude: selectedLongitude,
              xCoord: selectedXCoord,
              yCoord: selectedYCoord,
            }
          );

          removeAllHighlight();

          view?.whenLayerView(roadSegmentLayer).then(function (layerView) {
            reactiveUtils
              .whenOnce(() => !layerView.updating)
              .then(() => {
                setRoadSegmentHighlightedRefs(
                  layerView.highlight(popup.viewModel.selectedFeature)
                );
              });
          });
          setLstLocalSelectedRoadSegment([
            attributes[initDataGis.midblockGeoIdName],
          ]);
          setLstLocalSelectedIntersection([]);
          view.popup.close();

          break;
        case "open-google-maps":
          openInGoogleMap(popup.viewModel.selectedFeature);
          break;
        case "street-view":
          openStreetView(popup.viewModel.selectedFeature);
          break;
      }
    });
  } catch (error) {
    console.error("Error loading reactiveUtil:", error);
  }
};
