import i18n from "../../../../react-i18next.d";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('patients'),
        icon: 'fa-solid fa-user-injured',
        content: 'Patients tab content',
    },

];
export default tabTitles;

