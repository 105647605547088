import { TabType } from "./enums/moduleOfModulesEnums";

export interface IModuleParams {
  [key: string]: any;
  moduleId?: string
}
export interface IDataParams {
  [key: string]: any;
  moduleId?: string
  dataId?: string
}
export class ClientModule {
  id?: string;
  customerId?: string;
  divisionId?: string;
  name?: string;
  moduleTabs!: ModuleTab[];
  data!: ClientData[];
  constructor() {
    this.moduleTabs = [];
  }
}

export class ModuleTab {
  id?: string;
  name?: string;
  defaultTab?: boolean;
  type?: TabType;
  order?: number
}

export class ClientData {
  id!: string;
  moduleId!: string;
  submissionDT!: Date;
  detailsData!: any;
  listData!: any[];
}
