//#region imports
import { Lookup } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterRow,
    GroupItem,
    HeaderFilter,
    Pager, Paging,
    Search,
    SearchPanel,
    SortByGroupSummaryInfo, Summary
} from 'devextreme-react/data-grid';
import ValidationGroup, { ValidationGroupRef } from 'devextreme-react/validation-group';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../contexts/auth';
import { GeneralSetting } from '../../../../../contexts/clientSetting';
import { CustomerCollision } from '../../../../../types/collision/collisionTypes';
import { VMCollisionGroupedField } from '../../../../../types/field/dto/fieldDTO';
import { ValidationRuleLevel } from '../../../../../types/field/enums/fieldEnums';
import { NameValue } from '../../../../../types/general/generalTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { OnExporting } from '../../../../../utils/dataGridTools';
import { Enum2Array } from '../../../../../utils/enumTools';
//#endregion imports
//#region props
interface IPros {
    initDataFields: VMCollisionGroupedField;
    initDataCollision: CustomerCollision;
    generalSettings: GeneralSetting | null;
    isLocked: boolean
    validationRef: React.RefObject<ValidationGroupRef>

}
//#endregion props
const Validations = (props: IPros) => {
    //#region consts
    const [validationRuleLevels, setValidationRuleLevels] = useState<NameValue[]>([]);
    const { t } = useTranslation();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const { activeLoading } = useAuth();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    //#endregion consts
    //#region functions

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                setValidationRuleLevels(Enum2Array(ValidationRuleLevel))
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function calculateLevelTypeCell(d: any) {
        if (d.data.validationRuleLevel === 3) {
            return (
                <Button
                    height={40}
                    text={t('error')}
                    className="tes-modal-btn-cancel"
                // stylingMode='outlined'
                // type='danger'
                />
            )
        }
        if (d.data.validationRuleLevel === 2) {
            return (
                <Button
                    height={40}
                    text={t('warning')}
                    className="tes-modal-btn-cancel"
                // stylingMode='outlined'
                // type='default'
                />)
        }
        if (d.data.validationRuleLevel === 1) {
            return (
                <Button
                    height={40}
                    text={t('info')}
                    className="tes-modal-btn-add"
                // stylingMode='outlined'
                // type='normal'
                />)
        }
    }

    //#endregion functions
    return (
        <div className={`collisionDetails-validations ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <div className='row' style={{ marginTop: 20, padding: 10 }}>
                        {/* <div className="div-content"> */}
                        <DataGrid
                            id="gridContainer"
                            ref={dataGridRef}
                            dataSource={props.initDataCollision?.validationErrors}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onExporting={OnExporting}
                        >

                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />

                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <SortByGroupSummaryInfo
                                summaryItem="Total Count"
                                sortOrder="desc"
                            />
                            <Summary>
                                <GroupItem
                                    summaryType="count"
                                    alignByColumn
                                    name="Total Count"
                                />
                            </Summary>

                            <Column dataField={'validationRuleLevel'}
                                caption={t("level")}
                                width={150}
                                cellRender={calculateLevelTypeCell}
                            >
                                <Lookup dataSource={SortObjectByPropName(validationRuleLevels, "name")} valueExpr="value" displayExpr="name" />
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>


                            <Column dataField={'message'}
                                caption={t("message")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                        </DataGrid>
                    </div>
                    {/* </div> */}
                </ValidationGroup>
            </React.Fragment >
        </div>
    );
}
export default Validations;