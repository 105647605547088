//imports
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Button as DevExpressButton, Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Pager, Paging,
    Search,
    SearchPanel,
    StateStoring,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import React, { useEffect, useRef, useState } from 'react';

import TextBox from 'devextreme-react/text-box';

import { ContextMenu, Lookup } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { Form, SimpleItem } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/auth';
import { useClientSetting } from '../../../contexts/clientSetting';
import { AuthApiUrl } from '../../../environment/routeSettings';
import { GridType, ModalMode } from '../../../types/general/enums/generalEnums';
import { Client, Division } from '../../../types/identity/identityTypes';
import { SortObjectByPropName } from '../../../utils/arrayTools';
import { OnExporting } from '../../../utils/dataGridTools';
import { TesDelete, TesGet, TesPost, TesPut } from '../../../utils/rest';
import { RightClickMenu } from '../../../utils/rightClickMenu';
import './divisions.scss';

const Divisions = () => {
    //const [gridProfileId, setGridProfileId] = useState(null);
    const [initData, setInitData] = useState({});
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [selectedData, setSelectedData] = useState<Partial<Division>>(new Division());
    const history = useNavigate();
    const { activeLoading } = useAuth();
    const { t } = useTranslation();
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const items = [
        { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
        //   { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
    ];
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const { customerDivisions } = useClientSetting();

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //Get InitData

    async function getInitData() {
        setInitData(await TesGet(AuthApiUrl() + "/api/divisions/" + localStorage.getItem('selectedCustomerId'), true));
    }

    function onEdit(d: any) {
        let newData = new Client();
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModal(true);
    }
    function onNew() {
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }

    const onValueChanged = (key: string, value: any) => {
        setSelectedData({ ...selectedData, [key]: value });
    }


    function onCloseModal() {
        if (modalMode === ModalMode.Add) {
            setShowModal(false);
        } else {
            setSelectedData(new Client());
            setShowModal(false);
        }
    }

    async function onAdd() {

        try {
            const postObj: Division = {
                ...selectedData,
                customerId: localStorage.getItem("selectedCustomerId") as string
            }
            if (activeLoading) activeLoading(true);
            await TesPost(AuthApiUrl() + "/api/divisions", postObj, true);
            await getInitData();
            onCloseModal();
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyAdded"), "success", 5000);

        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    async function onUpdate() {
        try {
            if (activeLoading) activeLoading(true);
            await TesPut(AuthApiUrl() + "/api/divisions/" + selectedData.id, selectedData, true);
            await getInitData();
            onCloseModal();
            notify(t("dataSuccessfullyUpdated"), "success", 5000);
            if (activeLoading) activeLoading(false);

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {

                    try {
                        await TesDelete(AuthApiUrl() + "/api/divisions/" + d.row.data.id, true);
                        await getInitData();
                        notify(t("dataSuccessfullyDeleted"), "success", 5000);

                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }


                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }

    function onRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
        if (e.data.id !== undefined) {

            if (isCtrlKeyPressed) {
                window.open(`/users/divisionDetails/${e.data.id}`, "_blank");
            } else {
                history(`/users/divisionDetails/${e.data.id}`);
            }
        }
    }

    return (
        <div className={compactViewModel ? "compactStyle" : ""}>
            <React.Fragment>
                <ContextMenu
                    dataSource={items}
                    width={100}
                    target=".dx-data-row"
                    onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/users/divisionDetails/")}
                />
                <h2 className={'content-block'}>{t('divisions')}</h2>
                <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>
                        <div className="row">

                            <div className='rightColumn'>
                                <Button
                                    onClick={onNew}
                                    icon="fa-solid fa-circle-plus"
                                    text={t('add')}

                                />
                            </div>
                        </div>
                        <DataGrid id="gridContainer"
                            ref={dataGridRef}
                            dataSource={initData}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onRowClick={onRowClicked}
                            style={{ margin: "0 1rem" }}
                            onExporting={OnExporting}
                            onContextMenuPreparing={e => { setSelectedRowData(e) }}
                        >
                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />
                            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                            <GroupPanel visible={true} /> {/* or "auto" */}
                            <FilterPanel visible={true} />
                            <FilterBuilderPopup position={'top'} />

                            <StateStoring enabled={true} type="localStorage" storageKey={GridType.Divisions.toString()} savingTimeout={500} />

                            <Summary>
                                <TotalItem
                                    column="GeoId"
                                    summaryType="count"
                                />
                                <GroupItem
                                    summaryType="count"
                                    alignByColumn
                                    name="Total Count"
                                />
                            </Summary>


                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <Column dataField="id"
                                caption={t('id')}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="name"
                                caption={t('name')}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column
                                dataField="DivisionId"
                                allowSorting={true}
                                visible={false}
                                caption={t("division")}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                                <Lookup
                                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                                    valueExpr="id"
                                    displayExpr="name"
                                />
                            </Column>

                            <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">

                                <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

                                </DevExpressButton>
                                <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                                </DevExpressButton>
                            </Column>
                        </DataGrid>
                    </div>
                </div>

                <Popup
                    width={"50%"}
                    height={"auto"}
                    visible={showModal}
                    resizeEnabled={true}
                    showTitle={true}
                    title={modalMode === ModalMode.Add ? t('add') : t('update')}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHiding={() => onCloseModal()}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={3}>
                            <SimpleItem colSpan={3} >
                                <TextBox
                                    label={t('name')}
                                    labelMode='floating'
                                    value={selectedData.name}
                                    onValueChange={e => onValueChanged("name", e)}
                                    className="modalInput"
                                />
                            </SimpleItem>
                        </Form>
                        <div>

                            <div className='rightColumn'>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => onCloseModal()}
                                    text={t('cancel')}
                                />
                                {modalMode === ModalMode.Add ?
                                    <Button
                                        className='tes-modal-btn-add'
                                        onClick={onAdd}
                                        text={t('add')}
                                    />
                                    :
                                    <Button
                                        className='tes-modal-btn-add'
                                        onClick={onUpdate}
                                        text={t('update')}
                                    />
                                }
                            </div>
                        </div>

                    </ScrollView>
                </Popup>
            </React.Fragment>
        </div>
    );
}
export default Divisions;