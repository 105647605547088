const MaintenancePermissions = {
    Maintenance_D_Job: "Maintenance_D_Job",
    Maintenance_E_Job: "Maintenance_E_Job",
    Maintenance_R_Job: "Maintenance_R_Job",
    Maintenance_V_AllJob: "Maintenance_V_AllJob",
    Maintenance_CompleteJob: "Maintenance_CompleteJob",
    Maintenance_D_Setups: "Maintenance_D_Setups",
    Maintenance_D_Personnel: "Maintenance_D_Personnel",
    Maintenance_D_Equipment: "Maintenance_D_Equipment",
    Maintenance_V_Report: "Maintenance_V_Report",
};
export default MaintenancePermissions;