//imports
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Pager,
    Paging,
    Search,
    SearchPanel,
    SortByGroupSummaryInfo,
    StateStoring,
    Summary
} from "devextreme-react/data-grid";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../components/permission/permision";
import getTitle from "../../../../../components/title/getTitle";
import { TrafficStudyPermissions } from "../../../../../constants/Permissions";
import { GridType } from "../../../../../types/general/enums/generalEnums";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { MatchingHolidaysDTO } from "../../../../../types/trafficStudy/dtos/matchingDto";
import { GeneralSetting } from "../../../../../contexts/clientSetting";

interface IPros {
    initDataHolidays: MatchingHolidaysDTO[]
    generalSettings: GeneralSetting | null
 }

const Holidays = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('trafficStudy/tools/matchingProjects', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <Permission allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
        ]}
        hasFeedBackElement={true}
      >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={props.initDataHolidays}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.MatchingHolidays.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")} width={100} >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="holidayDate" caption={t("holidayDate")} width={100}
                  format={props.generalSettings?.dateTimeFormat}
                  dataType="date"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="dayOfWeek" caption={t("dayOfWeek")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Holidays;
