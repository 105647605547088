import { Button } from 'devextreme-react/button';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { IUser, VMAddUserData, VMUserProfileDetails } from '../../../../../types/identity/dto/identityDTO';
import { RequestErrorHandling, TesGet, TesPost } from '../../../../../utils/rest';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { useAuth } from '../../../../../contexts/auth';
import { INameId, NameValue, RequestResponseResult } from '../../../../../types/general/generalTypes';
import { Form, SimpleItem } from 'devextreme-react/form';
import TextBox from 'devextreme-react/text-box';
import { Switch } from 'devextreme-react/switch';
import { useNavigate } from 'react-router-dom';
import Permission from '../../../../../components/permission/permision';
import { TMSPermissions, UserPermissions } from '../../../../../constants/Permissions';
import { AuthApiUrl } from '../../../../../environment/routeSettings';
import { SignOnMethod } from '../../../../../types/identity/identityTypes';
import { DateBox, SelectBox } from 'devextreme-react';
import { Enum2Array } from '../../../../../utils/enumTools';
import { useClientSetting } from '../../../../../contexts/clientSetting';
import design from "./details.module.scss"
import { SortObjectByPropName } from '../../../../../utils/arrayTools';


// props
interface IPros {
    initData: VMUserProfileDetails,
    userId: string,
    user: IUser | null;
    setInitData: React.Dispatch<React.SetStateAction<any>>;
}

const Details = (props: IPros) => {
    const [lstSignOnMethod, setLstSignOnMethod] = useState<NameValue[]>([]);
    const [lstUserDivisionRole, setLstUserDivisionRole] = useState<VMAddUserData>();
    const { activeLoading } = useAuth();
    const history = useNavigate();
    const { generalSetting } = useClientSetting();


    useEffect(() => {
        setLstSignOnMethod(Enum2Array(SignOnMethod));
    }, [props.initData]);



    useEffect(() => {
        async function fetchMyAPI() {
            getUserRolesDivisions(localStorage.getItem("selectedCustomerId") as string)
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function onSave() {
        try {
            if (activeLoading) activeLoading(true);
            const res = (await (TesPost(AuthApiUrl() + "/api/User/UpdateUserGeneralInformation", props.initData, true))) as RequestResponseResult<null>
            if (res.responseCode === ResponseCode.OK) {
                if (activeLoading) activeLoading(false);
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
            } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
                if (activeLoading) activeLoading(false);
                notify(t("customerNotPermitted"), "error", 5000);
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);;
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    async function getUserRolesDivisions(customerId: string) {
        try {
            if (activeLoading) activeLoading(true);
            setLstUserDivisionRole(await TesGet(AuthApiUrl() + "/api/User/GetAddUserData/" + customerId, true));
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
        }
    }



    async function onDelete() {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    await deleteUser();
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }

    async function deleteUser() {
        try {
            if (activeLoading) activeLoading(true);
            const res = (await (TesGet(AuthApiUrl() + "/api/User/DeleteUser/" + props.initData.id, true))) as RequestResponseResult<null>
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyDeleted"), "success", 5000);
                history(-1);
            } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                notify(t("userNotPermitted"), "error", 5000);
            }
            else if (res.responseCode === ResponseCode.DeletingError) {
                notify(t("deletingError"), "error", 5000);
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);;
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    async function onDeletePermanently() {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    await deleteUserPermanently();
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }
    async function deleteUserPermanently() {
        try {
            if (activeLoading) activeLoading(true);
            const res = (await (TesGet(AuthApiUrl() + "/api/User/DeleteUserPermanently/" + props.initData.id, true))) as RequestResponseResult<null>
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyDeleted"), "success", 5000);
                history(-1);
            } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                notify(t("userNotPermitted"), "error", 5000);
            }
            else if (res.responseCode === ResponseCode.DeletingError) {
                notify(t("deletingError"), "error", 5000);
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);;
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    function onValueChanged(name: string, value: any) {
        if (name === "singleDivision") {
            props.setInitData({
                ...props.initData,
                singleDivision: {
                    id: value
                }
            })
        } else {
            props.setInitData({ ...props.initData, [name]: value })
        }
    }

    async function unlockUser() {
        await TesGet(AuthApiUrl() + "/api/user/DisableUserLockout/" + props.userId, true);
    };


    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                {props.initData.lockoutEnabled &&
                    <div className={`${design.main}`}>
                        <div className={`${design.container}`}>
                            {t('lockedAccountMessage')}
                        </div>
                        <Button
                            className='tes-modal-btn-cancel'
                            text={t('unlock')}
                            style={{ marginLeft: "1rem" }}
                            onClick={unlockUser}
                        />
                    </div>
                }


                <Form style={{ marginTop: 10 }}
                    className='popupFields'
                    colCount={2}>
                    <SimpleItem colSpan={1} >
                        <TextBox
                            label={t('email')}
                            labelMode='floating'
                            value={props.initData.email}
                            onValueChange={e => onValueChanged("email", e)}
                            className="modalInput"
                            disabled={(props.initData.signOnMethod === SignOnMethod.AzureSingleSignOn && !props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1} >
                        <TextBox
                            label={t('firstName')}
                            labelMode='floating'
                            value={props.initData.firstName}
                            onValueChange={e => onValueChanged("firstName", e)}
                            className="modalInput"
                            disabled={(props.initData.signOnMethod === SignOnMethod.AzureSingleSignOn && !props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1} >
                        <TextBox
                            label={t('lastName')}
                            labelMode='floating'
                            value={props.initData.lastName}
                            onValueChange={e => onValueChanged("lastName", e)}
                            className="modalInput"
                            disabled={(props.initData.signOnMethod === SignOnMethod.AzureSingleSignOn && !props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1} >
                        <SelectBox
                            label={t('signOnMethod')}
                            value={props.initData.signOnMethod}
                            onValueChange={e => onValueChanged("signOnMethod", e)}
                            valueExpr={"value"}
                            displayExpr={"name"}
                            disabled={(!props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                            items={SortObjectByPropName(lstSignOnMethod, "name")}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    {props.user?.permissions?.includes(TMSPermissions.TMS_Admin) &&
                        <SimpleItem >
                            <SelectBox
                                // style={{marginLeft:"-1rem"}}
                                placeholder=""
                                label={t('defaultDivision')}
                                valueExpr="id"
                                displayExpr="name"
                                labelMode='floating'
                                value={props.initData?.singleDivision?.id}
                                onValueChange={e => onValueChanged("singleDivision", e)}
                                items={SortObjectByPropName(lstUserDivisionRole?.divisions, "name")}
                                showClearButton={true}
                                searchEnabled={true}
                            >
                            </SelectBox>
                        </SimpleItem>
                    }

                    <SimpleItem >
                        <div >{t('isActive')}</div>
                        <Switch
                            style={{ marginTop: 5 }}
                            hint={t('isActive')}
                            value={props.initData.isActive}
                            onValueChange={e => onValueChanged("isActive", e)}
                        />
                    </SimpleItem>

                    <Permission
                        allowed={[
                            TMSPermissions.TMS_Admin,
                        ]}
                        hasFeedBackElement={false}
                    >
                        <SimpleItem >
                            <div >{t('twoFactorEnabled')}</div>
                            <Switch
                                style={{ marginTop: 5 }}
                                hint={t('twoFactorEnabled')}
                                value={props.initData.twoFactorEnabled}
                                onValueChange={e => onValueChanged("twoFactorEnabled", e)}
                                disabled={(props.initData.signOnMethod === SignOnMethod.AzureSingleSignOn && !props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                            />
                        </SimpleItem>
                    </Permission>
                    <Permission
                        allowed={[
                            TMSPermissions.TMS_Admin,
                        ]}
                        hasFeedBackElement={false}
                    >
                        <SimpleItem colSpan={1}>
                            <div >{t('skipCaptcha')}</div>
                            <Switch
                                style={{ marginTop: 5 }}
                                hint={t('skipCaptcha')}
                                value={props.initData.skipCaptcha}
                                onValueChange={e => onValueChanged("skipCaptcha", e)}
                                disabled={(props.initData.signOnMethod === SignOnMethod.AzureSingleSignOn && !props.user?.permissions?.includes(TMSPermissions.TMS_Admin))}
                            />
                        </SimpleItem>
                    </Permission>
                    <SimpleItem colSpan={1} >
                        <TextBox
                            label={t('defaultCustomerName')}
                            labelMode='floating'
                            value={props.initData.defaultCustomerName}
                            onValueChange={e => onValueChanged("defaultCustomerName", e)}
                            className="modalInput"
                            disabled={true}
                        />
                    </SimpleItem>


                    {props.initData.lockoutEnabled &&
                        <SimpleItem colSpan={1}>
                            <DateBox
                                label={t("lockedDate")}
                                type="datetime"
                                onValueChange={e => onValueChanged("locked", e)}
                                pickerType="calendar"
                                placeholder={generalSetting?.dateTimeFormat}
                                displayFormat={generalSetting?.dateTimeFormat}
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                                value={props.initData.lockoutEnd}
                            />
                        </SimpleItem>
                    }
                </Form>

            </div>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <div className='rightColumn'>
                    <Permission
                        allowed={[
                            UserPermissions.User_RP,
                        ]}
                        hasFeedBackElement={false}
                    >
                        <Button
                            className='tes-modal-btn-cancel'
                            onClick={onDeletePermanently}
                            text={t('deletePermanently')}
                        />
                    </Permission>
                    <Permission
                        allowed={[
                            UserPermissions.User_R,
                        ]}
                        hasFeedBackElement={false}
                    >
                        <Button
                            style={{ marginRight: 10, marginLeft: 10 }}
                            className='tes-modal-btn-cancel'
                            onClick={onDelete}
                            text={t('delete')}
                        />
                    </Permission>
                    {(props.initData && props.initData.allPermissions?.fms) &&

                        <Button
                            style={{ marginRight: 10 }}

                            className='tes-modal-btn-add'
                            onClick={onSave}
                            text={t('createFolder')}
                        />
                    }
                    <Button
                        className='tes-modal-btn-add'
                        onClick={onSave}
                        text={t('save')}
                    />

                </div>
            </div>
        </React.Fragment>
    );


}
export default Details;