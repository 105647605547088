import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import "./workbookDetails.scss";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import Permission from "../../../components/permission/permision";
import { ModuleOfModulePermissions } from "../../../constants/Permissions";
import Analysis from "./components/analysis/analysis";
import SiteList from "./components/siteList/siteList";
import { WorkBookDTO } from "../../../types/safety/dto/safetyDtos";
import { IWorkbookParams, Workbook } from "../../../types/safety/safetyTypes";
import { SafetyApiUrl } from "../../../environment/routeSettings";
import SafetyPermissions from "../../../constants/Permissions/SafetyPermissions";
import { useClientSetting } from "../../../contexts/clientSetting";
import ShareURL from "../../../components/shareURL/shareURL";

const WorkbookDetails = () => {
  const [initData, setInitData] = useState<WorkBookDTO>(new WorkBookDTO());
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<IWorkbookParams>();
  const [isLocked, setIsLocked] = useState(true);
  const [backPermission, setBackPermission] = useState(false);
  const [dataSaved, setDataSaved] = useState<boolean>(true);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          await getInitData(params.workbookId!);
        if (activeLoading) activeLoading(false);
        return (didMount.current = true);
      }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet( SafetyApiUrl() + "/api/Workbooks/GetWorkbookDetails/" + id, true)) as RequestResponseResult<any>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results)
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                onUpdate();
                setDataChanged(false)
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
                history(-1);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      const postData: Workbook = 
      { id:initData.id,
        customerId:initData.customerId,
        title:initData.title,
        description:initData.description,
        areaIds:initData.areas.map(x=>x.id),
        analysis:initData.analysis }
      const res = (await TesPost(SafetyApiUrl() + "/api/Workbooks/UpdateWorkbookDetails/", postData, true ));
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setDataSaved(true)
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        await getInitData(initData.id)
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }

  return (
    <Permission
    allowed={[SafetyPermissions.Safety_V_Workbook]}
    hasFeedBackElement={true}
    >
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card"}>
     
          <div className="row" style={{ marginTop: 15 }}>
            <div className="leftColumn">
              <Button
                onClick={goBackPermission}
                icon="fa-solid fa-arrow-left"
                hint={t("goBack")}
              />
            </div>
            <div className="rightColumn">
                {params.workbookId !== "AddNew" && (
                  <ShareURL
                    url={url}
                  />
                )}
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
            </div>
            <div className="rightColumn">
              <Permission allowed={[SafetyPermissions.Safety_D_Workbook]} hasFeedBackElement={false}>
              {isLocked===false && (
                <Button onClick={onUpdate} icon="fa-solid fa-floppy-disk" hint={t("update")} />
              )}
              </Permission>
            </div>
          </div>
        </div>
        <div className={"dx-card "}>
          <Tabs
            width={"100%"}
            dataSource={tabTitles}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />

          {selectedIndex === 0 &&
            <SiteList
              initData={initData}
              setInitData={setInitData}
              isLocked={isLocked}
              setDataChanged={setDataChanged}
              dataSaved={dataSaved}
              setDataSaved={setDataSaved}
              />
            }

          {selectedIndex === 1 &&
            <Analysis
            initData={initData}
            setInitData={setInitData}
            isLocked={isLocked}
            setDataChanged={setDataChanged}
            dataSaved={dataSaved}
            setDataSaved={setDataSaved}
            />
          }
        </div>
      </div>
    </React.Fragment>
    </Permission>
  );
};
export default WorkbookDetails;
