import { LocationType } from "../infrastructure/enums/infrastructureEnums";

export class ToMapRequest {
  customerId?: string;
  divisionId?: string;
  fromDate?: Date;
  toDate?: Date;
  filter?: any;
}



