import { Button, DropDownButton } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import Tabs from "devextreme-react/tabs";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { SignPermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { useClientSetting } from "../../../contexts/clientSetting";
import { MaintenanceApiUrl, ReportApiUrl, SignApiUrl } from "../../../environment/routeSettings";
import { VMMapLocation } from "../../../types/collision/dto/collisionDtos";
import { TabTitle } from "../../../types/general/generalTypes";
import { Job } from "../../../types/maintenance/maintenanceTypes";
import { ClientReport } from "../../../types/report/reportTypes";
import { VMAppSetups } from "../../../types/sign/dto/signDto";
import { VMSupportUpdate } from "../../../types/sign/dto/supportDto";
import { Attachment, TesSign } from "../../../types/sign/signTypes";
import { ISupportParams, Support } from "../../../types/sign/supportTypes";
import MaxDropdownItemWidthCalculator from "../../../utils/dropDownWidthCalculator";
import { TesGet, TesPost, TesPutUploadFile } from "../../../utils/rest";
import Attachments from "./components/attachments/attachments";
import ChangeLogs from "./components/changeLogs/changeLogs";
import Details from "./components/details/details";
import Location from "./components/location/location";
import Maintenance from "./components/maintenance/maintenance";
import Signs from "./components/signs/signs";
import TabsData from "./data";
import "./supportDetails.scss";

const SupportDetails = () => {
  const history = useNavigate();
  const params = useParams<ISupportParams>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initDataSetups, setInitDataSetups] = useState<VMAppSetups>(new VMAppSetups());
  const [initDataReports, setInitDataReports] = useState<ClientReport>(new ClientReport());
  const [initDataSupport, setInitDataSupport] = useState<Support>(new Support());
  const [initDataMaintenance, setInitDataMaintenance] = useState<Job[]>([]);
  const [existingFiles, setExistingFiles] = useState<Attachment[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [removedFile, setRemovedFile] = useState<string[]>([]);
  const [tempMapLocation, setTempMapLocation] = useState<VMMapLocation>(new VMMapLocation());
  const [mapLocation, setMapLocation] = useState<VMMapLocation>(new VMMapLocation())
  const [dataChanged, setDataChanged] = useState(false);
  const [backPermission, setBackPermission] = useState<boolean>(false);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const { generalSetting } = useClientSetting();
  const [isLocked, setIsLocked] = useState(true);
  const [titles, setTitles] = useState<TabTitle[]>([]);
  const validationRef = useRef<ValidationGroupRef>(null);
  const [showURLModal, setShowURLModal] = useState<boolean>(false);
  const loc = useLocation();
  const url = `${window.location.origin}${loc.pathname}`;
  const geoIdRef = useRef<HTMLSpanElement>(null);
  const supportIdRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (activeLoading) activeLoading(true);
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataSetups();
        await getInitialDataReports();
        setTitles(TabsData(params.supportId!));
        if (params.supportId !== "AddNew") {
          await getInitDataSupport(params.supportId);
        }
        else {
          setIsLocked(false)
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    if (activeLoading) activeLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.supportId]);

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }
  //TesGets
  async function getInitialDataSetups() {
    setInitDataSetups(
      await TesGet(
        SignApiUrl() +
        "/api/sync/GetSetups/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitialDataReports() {
    setInitDataReports(
      await TesGet(
        ReportApiUrl() +
        "/api/ClientReports/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitDataSupport(id: string | undefined) {
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesGet(
        SignApiUrl() + "/api/Supports/GetSupportData/" + id,
        true
      );
      var support: Support = res;
      if (res) {
        setInitDataSupport(support);
        setExistingFiles(support.attachments);
        await getInitDataMaintenance();
      }

      if (res) {
        setInitDataSupport(res);
        setExistingFiles(res.attachments);

        var loc: VMMapLocation = {
          latitude: res.latitude,
          longitude: res.longitude,
          xCoord: res.xCoord,
          yCoord: res.yCoord,
        }

        setTempMapLocation(loc);
        setMapLocation(loc)



      }
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  async function getInitDataMaintenance() {
    setInitDataMaintenance(
      await TesGet(
        MaintenanceApiUrl() + "/api/jobs/AssetJobs/" + params.supportId,
        true
      )
    );
  }

  function goBackPermission() {
    if (dataChanged === true) {

      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.supportId !== "AddNew") {
                  setBackPermission(true);
                  updateSupport();
                  setDataChanged(false)
                } else {
                  setBackPermission(true);
                  addSupport();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  //add and update functions
  async function updateSupport() {

    var postObj: VMSupportUpdate = {
      ...initDataSupport,
      ...mapLocation,
      customerId: localStorage.getItem("selectedCustomerId")!,
      signs: initDataSupport?.signs
        ?.filter((x: TesSign) => x.id != null)
        .map((x: TesSign) => x.id),
      deletedAttachments: removedFile,
      assets: initDataMaintenance
        ?.filter((x: Job) => x.id != null)
        .map((x: Job) => x.id),
    };
    try {
      if (activeLoading) activeLoading(true);

      const res = await TesPost(
        SignApiUrl() + "/api/supports/UpdateSupport",
        postObj,
        true
      );
      if (res.isChanged) {
        await uploadImages(initDataSupport?.id, false)
      }
      if (activeLoading) activeLoading(false);
    } catch {
      notify(t("someErrorOccurred"), "error", 5000);
      if (activeLoading) activeLoading(false);
    }
    setDataChanged(false)
  }

  async function addSupport() {
    try {
      const postObj: VMSupportUpdate = {
        ...initDataSupport,
        ...mapLocation,
        signs:
          initDataSupport?.signs
            ?.filter((x: TesSign) => x.id !== null)
            ?.map((x: TesSign) => x.id) ?? [],
        customerId: localStorage.getItem("selectedCustomerId")!,
        assets: initDataMaintenance
          ?.filter((x: Job) => x.id !== null)
          ?.map((x: Job) => x.id),
      };
      if (activeLoading) activeLoading(true);
      const res = await TesPost(SignApiUrl() + "/api/Supports/AddSupport", postObj, true);
      if (activeLoading) activeLoading(false);
      if (res.isChanged) {
        uploadImages(res.id, true)
      }
      history(-1)
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
    setDataChanged(false)
  }

  //Detail Functions
  const onValueChange = (name: string, value: any) => {
    setInitDataSupport({ ...initDataSupport, [name]: value });
    setDataChanged(true)
  };

  function onChangeDetailsFields(name: string, value: any) {
    if (name === "supportCodeId") {
      const c = initDataSetups.supportCode.find((x) => x.id === value);
      if (c !== undefined) {
        setInitDataSupport({
          ...initDataSupport,
          supportCodeId: value,
          materialCost: c.materialCost,
          labourCost: c.labourCost,
          installationCost: c.installationCost,
        });
      }
      return;
    }
    setInitDataSupport({ ...initDataSupport, [name]: value });
    setDataChanged(true);
  }

  //Maintenance Functions
  function addMaintenace(lstMaintenaces: Job[]) {
    setInitDataMaintenance([
      ...initDataMaintenance,
      ...lstMaintenaces.filter(
        (x: Job) => !initDataMaintenance.map((a: Job) => a.id).includes(x.id)
      ),
    ]);
    setDataChanged(true);
  }

  function onDeleteMaintenace(deletedMaintenace: Job) {
    setInitDataMaintenance(
      initDataMaintenance.filter((x: Job) => x.id !== deletedMaintenace.id)
    );
    setDataChanged(true);
  }

  //Sign Functions
  function assignSign2Support(signs: TesSign[]) {

    setInitDataSupport({
      ...initDataSupport,
      signs: [...initDataSupport?.signs,
      ...signs.filter((s: TesSign) => !initDataSupport.signs.map((x: TesSign) => x.id).includes(s.id))
      ]
    });

    // setInitDataSupport({
    //   ...initDataSupport,
    //   signs: [
    //     ...initDataSupport?.signs,
    //     ...signs.filter(
    //       (s: TesSign) =>
    //         !initDataSupport.signs.map((x: TesSign) => x.id).includes(s.id)
    //     ),
    //   ],
    // });

    setDataChanged(true);
  }

  function onDeleteSign(sign: TesSign) {
    setInitDataSupport({
      ...initDataSupport,
      signs: initDataSupport?.signs.filter((x: TesSign) => x.id !== sign.id),
    });
    setDataChanged(true);
  }


  //Attachments

  async function uploadImages(id: string, isNew: boolean) {
    try {
      const data = new FormData();
      // eslint-disable-next-line array-callback-return
      files.map((a: any) => {
        data.append('file', a)
      });
      data.append(id, id)
      await TesPutUploadFile(SignApiUrl() + "/api/Attachments/Support/" + isNew, data, true);
      if (params.supportId !== "AddNew") {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        if (backPermission === true) {
          history(-1);
        }
      } else {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
      }

    } catch (ex) {

    }
  }


  function handleChangeFile(files: any) {
    setFiles(files)
    setDataChanged(true);

  }

  function onDeleteNewFile(name: string) {
    setFiles([...files.filter(x => x.name !== name)])
    setDataChanged(true);
  }

  function onDeleteExistingFile(id: string) {
    setInitDataSupport({ ...initDataSupport, attachments: initDataSupport?.attachments.filter(x => x.id !== id) })
    setRemovedFile([...removedFile, id])
    setDataChanged(true);
  }

  const copyTextToClipboard = (option: string) => {
    if (geoIdRef.current && supportIdRef.current) {
      let textToCopy: string;
      if (option === "Geo ID") {
        textToCopy = geoIdRef.current.innerText;
      } else {
        textToCopy = supportIdRef.current.innerText;
      }
      textToCopy = textToCopy.replace(/^\[|\]$/g, "");
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  const copyOneTextToClipboard = () => {
    if (supportIdRef.current) {
      const textToCopy = supportIdRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[
        SignPermissions.Sign_D,
        SignPermissions.Sign_R,
        SignPermissions.Sign_V,
        SignPermissions.Sign_E,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.intersectionId !== "AddNew" && (
              <div style={{ marginTop: "1rem" }}>
                <p className={"detailsHeading"} style={{ display: "inline" }}>
                  <span>{t('support')}: </span>
                  {initDataSupport?.geoId && initDataSupport?.geoId !== "" &&
                    <span ref={geoIdRef}>[{initDataSupport?.geoId}]</span>
                  }
                  <span> {t("withSupportId")}: </span>
                  <span ref={supportIdRef}>{initDataSupport?.supportId}</span>
                </p>
                {initDataSupport?.geoId && initDataSupport?.geoId !== "" ?
                  <DropDownButton
                    style={{ marginLeft: 10, width: "6rem" }}
                    text={t("copy")}
                    items={[{ name: t("geoId"), value: t("geoId") }, { name: t("signId"), value: t("signId") }]}
                    dropDownOptions={{ width: MaxDropdownItemWidthCalculator([{ name: t("geoId"), value: t("geoId") }, { name: t("signId"), value: t("signId") }]) }}
                    displayExpr="name"
                    onItemClick={e => copyTextToClipboard(e.itemData.name)}
                    stylingMode="text"
                    hoverStateEnabled={false}
                    focusStateEnabled={false}
                  />
                  :
                  <Button
                    onClick={() => copyOneTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                }
                <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
              </div>
            )}
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={() => goBackPermission()}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                <Permission
                  allowed={[SignPermissions.Sign_E]}
                  hasFeedBackElement={false}
                >
                  <Button
                    onClick={() => LockHandler()}
                    icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </Permission>
              </div>
              <div className="rightColumn" style={{ alignItems: 'center', justifyContent: "center" }}>
                {params.supportId === "AddNew" && (
                  <Permission
                    allowed={[SignPermissions.Sign_D]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={() => addSupport()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")}
                    />
                  </Permission>
                )}
                {params.supportId !== "AddNew" && isLocked === false && (
                  <Permission
                    allowed={[SignPermissions.Sign_E]}
                    hasFeedBackElement={false}
                  >
                    <Button
                      onClick={() => updateSupport()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  </Permission>
                )}
                <ShareURL
                  url={url}
                />
              </div>
            </div>
          </div>
          <div className={"dx-card "}>
            <Tabs
              width={"100%"}
              dataSource={titles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />

            {selectedIndex === 0 && (
              <Details
                initDataSetups={initDataSetups}
                onValueChange={onValueChange}
                initDataSupport={initDataSupport}
                onChangeFields={onChangeDetailsFields}
                generalSettings={generalSetting}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                validationRef={validationRef}
              />
            )}

            {selectedIndex === 1 && (
              <Location
                initDataSupport={initDataSupport}
                initDataSetups={initDataSetups}
                onChangeFields={onChangeDetailsFields}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                onValueChange={onValueChange}
                tempMapLocation={tempMapLocation}
                mapLocation={mapLocation}
                setMapLocation={setMapLocation}
                setInitDataSupport={setInitDataSupport}
                validationRef={validationRef}

              />
            )}
            {selectedIndex === 2 && (
              <Signs
                initDataSupport={initDataSupport}
                initDataSetups={initDataSetups}
                assignSign2Support={assignSign2Support}
                onDelete={onDeleteSign}
                isLocked={isLocked}
                generalSettings={generalSetting}
                setDataChanged={setDataChanged}
                onValueChange={onValueChange}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 3 && (
              <Attachments
                isLocked={isLocked}
                initDataSupport={initDataSupport}
                files={files}
                handleChangeFile={handleChangeFile}
                onDeleteExistingFile={onDeleteExistingFile}
                onDeleteNewFile={onDeleteNewFile}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 4 && (
              <ChangeLogs
                initDataSupport={initDataSupport}
                initDataSetups={initDataSetups}
                isLocked={isLocked}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 5 && (
              <Maintenance
                initDataMaintenance={initDataMaintenance}
                addMaintenace={addMaintenace}
                onDeleteMaintenace={onDeleteMaintenace}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
                validationRef={validationRef}
              />
            )}
          </div>
        </div>





      </React.Fragment>
    </Permission>
  );
};
export default SupportDetails;
