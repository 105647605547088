import React, { useState } from 'react';
import styles from './test.module.scss'; // Import the SCSS module

const Test = () => {
  // Sample data for carousel items
  const items = Array.from({ length: 15 }, (_, index) => ({
    id: index + 1,
    content: `This is item ${index + 1} with a longer description.`,
  }));

  const [currentIndex, setCurrentIndex] = useState(0); // Start at the beginning (index 0)

  // Function to go to the next slide
  const nextSlide = () => {
    if (currentIndex + 5 < items.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  // Function to go to the previous slide
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  // Determine whether the arrows should be disabled
  const isLeftArrowDisabled = currentIndex === 0;
  const isRightArrowDisabled = currentIndex + 5 >= items.length;

  return (
    <div style={{ margin: "0.5rem" }}>
      <div className={styles.carouselContainer}>
        <button
          onClick={prevSlide}
          className={styles.arrowButton}
          disabled={isLeftArrowDisabled}
        >
          &laquo; {/* Left Double Chevron */}
        </button>

        {/* Fixed Item */}
        <div className={styles.fixedItem}>
          Fixed Item
        </div>

        <div className={styles.carouselWrapper}>
          {items.slice(currentIndex, currentIndex + 5).map((item) => (
            <div key={item.id} className={styles.carouselItem}>
              {item.content}
            </div>
          ))}
        </div>

        <button
          onClick={nextSlide}
          className={styles.arrowButton}
          disabled={isRightArrowDisabled}
        >
          &raquo; {/* Right Double Chevron */}
        </button>
      </div>
    </div>
  );
};

export default Test;