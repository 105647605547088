import { Button, DateBox, ScrollView } from "devextreme-react"
import { useEffect, useMemo, useState } from "react"
import { SectionDetailType } from "../../types/infrastructure/enums/infrastructureEnums"
import { FlatRawDataDTO, StudyTmcWebDetailsDTO, StudyVolumeWebDetailsDTO } from "../../types/trafficStudy/dtos/studyDto"
import { MovementType, ReportingPeriodType, StudyType, VehicleClassificationType } from "../../types/trafficStudy/enums/trafficStudyEnums"
import design from "./sectionsDiagram.module.scss"
import { t } from "i18next";
import { SeparatorAdder } from "../../utils/numberTools"
import { SumIntArray } from "../../utils/arrayTools"
import RadioGroup from 'devextreme-react/radio-group';
import { GeneralSetting } from "../../types/setting/gridSettingTypes"

interface IPros {
    size: number //DON't PASS SIZE LESS THAN 900
    sectionType: SectionDetailType
    data: FlatRawDataDTO[];
    NorthStreetName: string;
    EastStreetName: string;
    SouthStreetName: string;
    WestStreetName: string;
    leftHandDrive: boolean;
    oneDayCalendarRange: [Date, Date] | undefined;
    generalSettings: GeneralSetting | null;
    initDataBase: StudyVolumeWebDetailsDTO | StudyTmcWebDetailsDTO;
    showPeriod: boolean
}

function SectionsDiagram(props: IPros) {

    const timeRadioButtonsOptions = props.initDataBase.studyType === StudyType.TMC ?
        [
            { id: 0, text: t("fullStudy") },
            { id: 1, text: t("am") },
            { id: 4, text: t("amPeak") },
            { id: 2, text: t("midday") },
            { id: 5, text: t("middayPeak") },
            { id: 3, text: t("pm") },
            { id: 6, text: t("pmPeak") },
            { id: 7, text: t("customRange") },
        ] :
        [
            { id: 0, text: t("fullStudy") },
            { id: 1, text: t("am") },
            { id: 2, text: t("midday") },
            { id: 3, text: t("pm") },
            { id: 7, text: t("customRange") },
        ];

    const [oneDayRange, setOneDayRange] = useState<Date | undefined>(new Date());
    const [selectedTimeRange, setSelectedTimeRange] = useState<number>(timeRadioButtonsOptions[0].id);
    const [startTime, setStartTime] = useState<Date | undefined>(new Date());
    const [endTime, setEndTime] = useState<Date | undefined>(new Date());
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    function handleRadioChange(e: any) { setSelectedTimeRange(e) };
    function handleStartTimeChange(e: any) { setStartTime(e.value); }
    function handleEndTimeChange(e: any) { setEndTime(e.value); }

    function filterDataByTimeRange(data: FlatRawDataDTO[]): FlatRawDataDTO[] {
        console.log("new calculation")
        if (selectedTimeRange === 0) {
            return data; // No filtering needed for "allTime"
        }

        const filteredData: FlatRawDataDTO[] = [];

        const selectedDate = oneDayRange || new Date(); // Use the selected date or today's date
        if (selectedTimeRange === 7 && startTime && endTime) {
            const startHour = startTime.getHours();
            const startMinutes = startTime.getMinutes();
            const endHour = endTime.getHours();
            const endMinutes = endTime.getMinutes();

            // Check if startTime is equal to endTime
            if (startHour === endHour && startMinutes === endMinutes) {
                // If so, return an empty array for filteredData
                return [];
            }

            for (const item of data) {
                if (item.startDT) {
                    const itemTime = new Date(item.startDT);
                    const itemHour = itemTime.getHours();
                    const itemMinutes = itemTime.getMinutes();

                    // Check if the item's date matches the selected date and falls within the time range
                    if (
                        itemTime.toDateString() === selectedDate.toDateString() &&
                        (
                            (itemHour > startHour || (itemHour === startHour && itemMinutes >= startMinutes)) &&
                            (itemHour < endHour || (itemHour === endHour && itemMinutes < endMinutes))
                        )
                    ) {
                        filteredData.push(item);
                    }
                }
            }
        }
        else if (selectedTimeRange === 1 || selectedTimeRange === 2 || selectedTimeRange === 3) {
            const timeRanges: [number[], number[]][] = [
                [
                    [   getHourFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.AM)!.startTime),
                        getMinutesFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.AM)!.startTime)
                    ], 
                    [   getHourFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.AM)!.endTime),
                        getMinutesFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.AM)!.endTime)
                    ]
                ],
                [
                    [   getHourFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.MidDay)!.startTime),
                        getMinutesFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.MidDay)!.startTime)
                    ],
                    [   getHourFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.MidDay)!.endTime),
                        getMinutesFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.MidDay)!.endTime)
                    ]
                ],
                [
                    [   getHourFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.PM)!.startTime),
                        getMinutesFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.PM)!.startTime)
                    ],
                    [   getHourFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.PM)!.endTime),
                        getMinutesFromString(props.initDataBase.reportingPeriods.find(x => x.type === ReportingPeriodType.PM)!.endTime)
                    ]
                ],
            ];

            const [rangeStartTime, rangeEndTime] = timeRanges[selectedTimeRange - 1];

            for (const item of data) {
                if (item.startDT) {
                    const itemTime = new Date(item.startDT);
                    const startHour = itemTime.getHours();
                    const startMinutes = itemTime.getMinutes();

                    // Check if the item's date matches the selected date
                    if (
                        itemTime.toDateString() === selectedDate.toDateString() &&
                        (
                            (startHour > rangeStartTime[0] && startHour < rangeEndTime[0]) ||
                            (startHour === rangeStartTime[0] && startMinutes >= rangeStartTime[1]) ||
                            (startHour === rangeEndTime[0] && startMinutes < rangeEndTime[1])
                        )
                    ) {
                        filteredData.push(item);
                    }
                }
            }
        } else if (selectedTimeRange === 4 || selectedTimeRange === 5 || selectedTimeRange === 6) {
            const baseResult = props.initDataBase.result! as any;
            const result = baseResult![selectedTimeRange - 4];

            if (result && 'details' in result && Array.isArray(result.details)) {
                const details = result.details;
                
                const PHTrue = details
                .filter((item: any) => item.isPH)
                .sort((a: any, b: any) => new Date(a.startDT).getTime() - new Date(b.startDT).getTime());

                if (PHTrue.length > 3) {
                    const peakStartTime = new Date(PHTrue[0].startDT);
                    const peakEndTime = new Date(PHTrue[PHTrue.length - 1].endDT);
                    const timeDifference = peakEndTime.getTime() - peakStartTime.getTime();
                    const differenceInSeconds = timeDifference / 1000;

                    if (differenceInSeconds === 3600) {
                        for (const item of data) {
                            if (item.startDT) {
                                const itemTime = new Date(item.startDT);

                                if (itemTime >= peakStartTime && itemTime < peakEndTime) {
                                    filteredData.push(item);
                                }
                            }
                        }
                    }
                }
            }
        }
        return filteredData;
    }

    function getHourFromString(dateTimeString: any) {
        const dateObject = new Date(dateTimeString);
        const hour = dateObject.getHours();
        return hour;
    }

    function getMinutesFromString(dateTimeString: any) {
        const dateObject = new Date(dateTimeString);
        const minutes = dateObject.getMinutes();
        return minutes;
    }

    // N Block Variables
    const carNR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NR && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckNR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NR && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistNR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NR && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carNT = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NT && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckNT = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NT && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistNT = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NT && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carNL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NL && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckNL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NL && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistNL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NL && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carNU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NU && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckNU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NU && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistNU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NU && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedNRC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NRC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedNLC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.NLC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const totalPedN = useMemo(() => pedNRC + pedNLC, [pedNRC, pedNLC]);
    const totalVehicleNR = useMemo(() => carNR + truckNR + cyclistNR, [carNR, truckNR, cyclistNR]);
    const totalVehicleNT = useMemo(() => carNT + truckNT + cyclistNT, [carNT, truckNT, cyclistNT]);
    const totalVehicleNL = useMemo(() => carNL + truckNL + cyclistNL, [carNL, truckNL, cyclistNL]);
    const totalVehicleNU = useMemo(() => carNU + truckNU + cyclistNU, [carNU, truckNU, cyclistNU]);
    const totalVehicleN = useMemo(() => totalVehicleNR + totalVehicleNT + totalVehicleNL + totalVehicleNU, [totalVehicleNR, totalVehicleNT, totalVehicleNL, totalVehicleNU]);

    // E Block Variables
    const carER = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ER && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckER = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ER && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistER = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ER && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carET = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ET && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckET = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ET && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistET = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ET && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carEL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EL && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckEL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EL && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistEL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EL && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carEU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EU && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckEU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EU && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistEU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EU && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedEUC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EUC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedEDC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.EDC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const totalPedE = useMemo(() => pedEUC + pedEDC, [pedEUC, pedEDC]);
    const totalVehicleER = useMemo(() => carER + truckER + cyclistER, [carER, truckER, cyclistER]);
    const totalVehicleET = useMemo(() => carET + truckET + cyclistET, [carET, truckET, cyclistET]);
    const totalVehicleEL = useMemo(() => carEL + truckEL + cyclistEL, [carEL, truckEL, cyclistEL]);
    const totalVehicleEU = useMemo(() => carEU + truckEU + cyclistEU, [carEU, truckEU, cyclistEU]);
    const totalVehicleE = useMemo(() => totalVehicleER + totalVehicleET + totalVehicleEL + totalVehicleEU, [totalVehicleER, totalVehicleET, totalVehicleEL, totalVehicleEU]);

    // S Block Variables
    const carSR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SR && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckSR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SR && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistSR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SR && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carST = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ST && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckST = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ST && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistST = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.ST && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carSL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SL && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckSL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SL && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistSL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SL && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carSU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SU && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckSU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SU && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistSU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SU && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedSRC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SRC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedSLC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.SLC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const totalPedS = useMemo(() => pedSRC + pedSLC, [pedSRC, pedSLC]);
    const totalVehicleSR = useMemo(() => carSR + truckSR + cyclistSR, [carSR, truckSR, cyclistSR]);
    const totalVehicleST = useMemo(() => carST + truckST + cyclistST, [carST, truckST, cyclistST]);
    const totalVehicleSL = useMemo(() => carSL + truckSL + cyclistSL, [carSL, truckSL, cyclistSL]);
    const totalVehicleSU = useMemo(() => carSU + truckSU + cyclistSU, [carSU, truckSU, cyclistSU]);
    const totalVehicleS = useMemo(() => totalVehicleSR + totalVehicleST + totalVehicleSL + totalVehicleSU, [totalVehicleSR, totalVehicleST, totalVehicleSL, totalVehicleSU]);


    // W Block Variables
    const carWR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WR && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckWR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WR && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistWR = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WR && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carWT = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WT && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckWT = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WT && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistWT = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WT && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carWL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WL && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckWL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WL && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistWL = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WL && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const carWU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WU && (x.vehicleClassificationType === VehicleClassificationType.Car || x.vehicleClassificationType === VehicleClassificationType.Unknown)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const truckWU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WU && (x.vehicleClassificationType === VehicleClassificationType.Truck || x.vehicleClassificationType === VehicleClassificationType.HeavyTruck || x.vehicleClassificationType === VehicleClassificationType.Bus)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const cyclistWU = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WU && x.vehicleClassificationType === VehicleClassificationType.Cyclist).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedWUC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WUC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const pedWDC = useMemo(() => SumIntArray(filterDataByTimeRange(props.data).filter(x => x.movement === MovementType.WDC && (x.vehicleClassificationType === VehicleClassificationType.AssistedPedestrian || x.vehicleClassificationType === VehicleClassificationType.Pedestrian)).map(x => x.count)), [selectedTimeRange, props.data, oneDayRange, startTime, endTime]);
    const totalPedW = useMemo(() => pedWUC + pedWDC, [pedWUC, pedWDC]);
    const totalVehicleWR = useMemo(() => carWR + truckWR + cyclistWR, [carWR, truckWR, cyclistWR]);
    const totalVehicleWT = useMemo(() => carWT + truckWT + cyclistWT, [carWT, truckWT, cyclistWT]);
    const totalVehicleWL = useMemo(() => carWL + truckWL + cyclistWL, [carWL, truckWL, cyclistWL]);
    const totalVehicleWU = useMemo(() => carWU + truckWU + cyclistWU, [carWU, truckWU, cyclistWU]);
    const totalVehicleW = useMemo(() => totalVehicleWR + totalVehicleWT + totalVehicleWL + totalVehicleWU, [totalVehicleWR, totalVehicleWT, totalVehicleWL, totalVehicleWU]);

    // N Input totals
    const totalCarInputN = useMemo(() => carER + carST + carWL + carNU, [carER, carST, carWL, carNU]);
    const totalTruckInputN = useMemo(() => truckER + truckST + truckWL + truckNU, [truckER, truckST, truckWL, truckNU]);
    const totalCyclistInputN = useMemo(() => cyclistER + cyclistST + cyclistWL + cyclistNU, [cyclistER, cyclistST, cyclistWL, cyclistNU]);
    const totalVehicleInputN = useMemo(() => totalCarInputN + totalTruckInputN + totalCyclistInputN, [totalCarInputN, totalTruckInputN, totalCyclistInputN]);
    const totalN = useMemo(() => totalVehicleInputN + totalVehicleN, [totalVehicleInputN, totalVehicleN]);

    // E Input totals
    const totalCarInputE = useMemo(() => carSR + carWT + carNL + carEU, [carSR, carWT, carNL, carEU]);
    const totalTruckInputE = useMemo(() => truckSR + truckWT + truckNL + truckEU, [truckSR, truckWT, truckNL, truckEU]);
    const totalCyclistInputE = useMemo(() => cyclistSR + cyclistWT + cyclistNL + cyclistEU, [cyclistSR, cyclistWT, cyclistNL, cyclistEU]);
    const totalVehicleInputE = useMemo(() => totalCarInputE + totalTruckInputE + totalCyclistInputE, [totalCarInputE, totalTruckInputE, totalCyclistInputE]);
    const totalE = useMemo(() => totalVehicleInputE + totalVehicleE, [totalVehicleInputE, totalVehicleE]);

    // S Input totals
    const totalCarInputS = useMemo(() => carWR + carNT + carEL + carSU, [carWR, carNT, carEL, carSU]);
    const totalTruckInputS = useMemo(() => truckWR + truckNT + truckEL + truckSU, [truckWR, truckNT, truckEL, truckSU]);
    const totalCyclistInputS = useMemo(() => cyclistWR + cyclistNT + cyclistEL + cyclistSU, [cyclistWR, cyclistNT, cyclistEL, cyclistSU]);
    const totalVehicleInputS = useMemo(() => totalCarInputS + totalTruckInputS + totalCyclistInputS, [totalCarInputS, totalTruckInputS, totalCyclistInputS]);
    const totalS = useMemo(() => totalVehicleInputS + totalVehicleS, [totalVehicleInputS, totalVehicleS]);

    // W Input totals
    const totalCarInputW = useMemo(() => carNR + carET + carSL + carWU, [carNR, carET, carSL, carWU]);
    const totalTruckInputW = useMemo(() => truckNR + truckET + truckSL + truckWU, [truckNR, truckET, truckSL, truckWU]);
    const totalCyclistInputW = useMemo(() => cyclistNR + cyclistET + cyclistSL + cyclistWU, [cyclistNR, cyclistET, cyclistSL, cyclistWU]);
    const totalVehicleInputW = useMemo(() => totalCarInputW + totalTruckInputW + totalCyclistInputW, [totalCarInputW, totalTruckInputW, totalCyclistInputW]);
    const totalW = useMemo(() => totalVehicleInputW + totalVehicleW, [totalVehicleInputW, totalVehicleW]);

    //Styling States
    const [NWCarHover, setNWCarHover] = useState<boolean>(false)
    const [NWTruckHover, setNWTruckHover] = useState<boolean>(false)
    const [NWCyclistHover, setNWCyclistHover] = useState<boolean>(false)
    const [NWTotalHover, setNWTotalHover] = useState<boolean>(false)
    const [SECarHover, setSECarHover] = useState<boolean>(false)
    const [SETruckHover, setSETruckHover] = useState<boolean>(false)
    const [SECyclistHover, setSECyclistHover] = useState<boolean>(false)
    const [SETotalHover, setSETotalHover] = useState<boolean>(false)
    const [NWVisibility, setNWVisibility] = useState<any>("visible")
    const [WVisibility, setWVisibility] = useState<any>("visible")
    const [SWVisibility, setSWVisibility] = useState<any>("visible")
    const [NVisibility, setNVisibility] = useState<any>("visible")
    const [SVisibility, setSVisibility] = useState<any>("visible")
    const [NEVisibility, setNEVisibility] = useState<any>("visible")
    const [EVisibility, setEVisibility] = useState<any>("visible")
    const [SEVisibility, setSEVisibility] = useState<any>("visible")
    const [NWWBlackBorder, setNWWBlackBorder] = useState<boolean>(true)
    const [WSWBlackBorder, setWSWBlackBorder] = useState<boolean>(true)
    const [NWNBlackBorder, setNWNBlackBorder] = useState<boolean>(true)
    const [WCBlackBorder, setWCBlackBorder] = useState<boolean>(false)
    const [SWSBlackBorder, setSWSBlackBorder] = useState<boolean>(true)
    const [NCBlackBorder, setNCBlackBorder] = useState<boolean>(false)
    const [CSBlackBorder, setCSBlackBorder] = useState<boolean>(false)
    const [NNEBlackBorder, setNNEBlackBorder] = useState<boolean>(true)
    const [CEBlackBorder, setCEBlackBorder] = useState<boolean>(false)
    const [SSEBlackBorder, setSSEBlackBorder] = useState<boolean>(true)
    const [NEEBlackBorder, setNEEBlackBorder] = useState<boolean>(true)
    const [ESEBlackBorder, setESEBlackBorder] = useState<boolean>(true)
    const [displayWStreetName, setDisplayWStreetName] = useState<string>("flex")
    const [displayNStreetName, setDisplayNStreetName] = useState<string>("flex")
    const [displaySStreetName, setDisplaySStreetName] = useState<string>("flex")
    const [displayEStreetName, setDisplayEStreetName] = useState<string>("flex")
    const [NWPedVisibility, setNWPedVisibility] = useState<any>("visible")
    const [NEPedVisibility, setNEPedVisibility] = useState<any>("visible")
    const [SEPedVisibility, setSEPedVisibility] = useState<any>("visible")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [SWPedVisibility, setSWPedVisibility] = useState<any>("visible")
    const [WUArrow, setWUArrow] = useState<boolean>(true)
    const [W2NArrow, setW2NArrow] = useState<boolean>(true)
    const [WTArrow, setWTArrow] = useState<boolean>(true)
    const [W2SArrow, setW2SArrow] = useState<boolean>(true)
    const [NUArrow, setNUArrow] = useState<boolean>(true)
    const [N2WArrow, setN2WArrow] = useState<boolean>(true)
    const [NTArrow, setNTArrow] = useState<boolean>(true)
    const [N2EArrow, setN2EArrow] = useState<boolean>(true)
    const [SUArrow, setSUArrow] = useState<boolean>(true)
    const [S2WArrow, setS2WArrow] = useState<boolean>(true)
    const [STArrow, setSTArrow] = useState<boolean>(true)
    const [S2EArrow, setS2EArrow] = useState<boolean>(true)
    const [EUArrow, setEUArrow] = useState<boolean>(true)
    const [E2SArrow, setE2SArrow] = useState<boolean>(true)
    const [ETArrow, setETArrow] = useState<boolean>(true)
    const [E2NArrow, setE2NArrow] = useState<boolean>(true)
    const [STotalBorder, setSTotalBorder] = useState("2px solid black")
    const [WTotalBorder, setWTotalBorder] = useState("2px solid black")
    const [NTotalBorder, setNTotalBorder] = useState("2px solid black")
    const [ETotalBorder, setETotalBorder] = useState("2px solid black")
    const [totalCarInputNHover, setTotalCarInputNHover] = useState<boolean>(false)
    const [totalTruckInputNHover, setTotalTruckInputNHover] = useState<boolean>(false)
    const [totalCyclistInputNHover, setTotalCyclistInputNHover] = useState<boolean>(false)
    const [totalVehicleInputNHover, setTotalVehicleInputNHover] = useState<boolean>(false)
    const [totalCarInputWHover, setTotalCarInputWHover] = useState<boolean>(false)
    const [totalTruckInputWHover, setTotalTruckInputWHover] = useState<boolean>(false)
    const [totalCyclistInputWHover, setTotalCyclistInputWHover] = useState<boolean>(false)
    const [totalVehicleInputWHover, setTotalVehicleInputWHover] = useState<boolean>(false)
    const [totalCarInputSHover, setTotalCarInputSHover] = useState<boolean>(false)
    const [totalTruckInputSHover, setTotalTruckInputSHover] = useState<boolean>(false)
    const [totalCyclistInputSHover, setTotalCyclistInputSHover] = useState<boolean>(false)
    const [totalVehicleInputSHover, setTotalVehicleInputSHover] = useState<boolean>(false)
    const [totalCarInputEHover, setTotalCarInputEHover] = useState<boolean>(false)
    const [totalTruckInputEHover, setTotalTruckInputEHover] = useState<boolean>(false)
    const [totalCyclistInputEHover, setTotalCyclistInputEHover] = useState<boolean>(false)
    const [totalVehicleInputEHover, setTotalVehicleInputEHover] = useState<boolean>(false)
    const [NWPedHover, setNWPedHover] = useState<boolean>(false)
    const [pedNLCHover, setPedNLCHover] = useState<boolean>(false)
    const [totalPedNHover, setTotalPedNHover] = useState<boolean>(false)
    const [pedNRCHover, setPedNRCHover] = useState<boolean>(false)
    const [totalVehicleWUHover, setTotalVehicleWUHover] = useState<boolean>(false)
    const [cyclistWUHover, setCyclistWUHover] = useState<boolean>(false)
    const [truckWUHover, setTruckWUHover] = useState<boolean>(false)
    const [carWUHover, setCarWUHover] = useState<boolean>(false)
    const [totalWHover, setTotalWHover] = useState<boolean>(false)
    const [totalVehicleWLHover, setTotalVehicleWLHover] = useState<boolean>(false)
    const [cyclistWLHover, setCyclistWLHover] = useState<boolean>(false)
    const [truckWLHover, setTruckWLHover] = useState<boolean>(false)
    const [carWLHover, setCarWLHover] = useState<boolean>(false)
    const [totalVehicleWHover, setTotalVehicleWHover] = useState<boolean>(false)
    const [totalVehicleWTHover, setTotalVehicleWTHover] = useState<boolean>(false)
    const [cyclistWTHover, setCyclistWTHover] = useState<boolean>(false)
    const [truckWTHover, setTruckWTHover] = useState<boolean>(false)
    const [carWTHover, setCarWTHover] = useState<boolean>(false)
    const [totalVehicleWRHover, setTotalVehicleWRHover] = useState<boolean>(false)
    const [cyclistWRHover, setCyclistWRHover] = useState<boolean>(false)
    const [truckWRHover, setTruckWRHover] = useState<boolean>(false)
    const [carWRHover, setCarWRHover] = useState<boolean>(false)
    const [SWPedHover, setSWPedHover] = useState<boolean>(false)
    const [pedWUCHover, setPedWUCHover] = useState<boolean>(false)
    const [pedWDCHover, setPedWDCHover] = useState<boolean>(false)
    const [totalPedWHover, setTotalPedWHover] = useState<boolean>(false)
    const [totalNHover, setTotalNHover] = useState<boolean>(false)
    const [totalVehicleNHover, setTotalVehicleNHover] = useState<boolean>(false)
    const [totalVehicleNRHover, setTotalVehicleNRHover] = useState<boolean>(false)
    const [cyclistNRHover, setCyclistNRHover] = useState<boolean>(false)
    const [truckNRHover, setTruckNRHover] = useState<boolean>(false)
    const [carNRHover, setCarNRHover] = useState<boolean>(false)
    const [totalVehicleNTHover, setTotalVehicleNTHover] = useState<boolean>(false)
    const [cyclistNTHover, setCyclistNTHover] = useState<boolean>(false)
    const [truckNTHover, setTruckNTHover] = useState<boolean>(false)
    const [carNTHover, setCarNTHover] = useState<boolean>(false)
    const [totalVehicleNLHover, setTotalVehicleNLHover] = useState<boolean>(false)
    const [cyclistNLHover, setCyclistNLHover] = useState<boolean>(false)
    const [truckNLHover, setTruckNLHover] = useState<boolean>(false)
    const [carNLHover, setCarNLHover] = useState<boolean>(false)
    const [totalVehicleNUHover, setTotalVehicleNUHover] = useState<boolean>(false)
    const [cyclistNUHover, setCyclistNUHover] = useState<boolean>(false)
    const [truckNUHover, setTruckNUHover] = useState<boolean>(false)
    const [carNUHover, setCarNUHover] = useState<boolean>(false)
    const [carSUHover, setCarSUHover] = useState<boolean>(false)
    const [carSLHover, setCarSLHover] = useState<boolean>(false)
    const [carSTHover, setCarSTHover] = useState<boolean>(false)
    const [carSRHover, setCarSRHover] = useState<boolean>(false)
    const [truckSUHover, setTruckSUHover] = useState<boolean>(false)
    const [truckSLHover, setTruckSLHover] = useState<boolean>(false)
    const [truckSTHover, setTruckSTHover] = useState<boolean>(false)
    const [truckSRHover, setTruckSRHover] = useState<boolean>(false)
    const [cyclistSUHover, setCyclistSUHover] = useState<boolean>(false)
    const [cyclistSLHover, setCyclistSLHover] = useState<boolean>(false)
    const [cyclistSTHover, setCyclistSTHover] = useState<boolean>(false)
    const [cyclistSRHover, setCyclistSRHover] = useState<boolean>(false)
    const [totalVehicleSUHover, setTotalVehicleSUHover] = useState<boolean>(false)
    const [totalVehicleSLHover, setTotalVehicleSLHover] = useState<boolean>(false)
    const [totalVehicleSTHover, setTotalVehicleSTHover] = useState<boolean>(false)
    const [totalVehicleSRHover, setTotalVehicleSRHover] = useState<boolean>(false)
    const [totalVehicleSHover, setTotalVehicleSHover] = useState<boolean>(false)
    const [totalSHover, setTotalSHover] = useState<boolean>(false)
    const [NEPedHover, setNEPedHover] = useState<boolean>(false)
    const [pedEUCHover, setPedEUCHover] = useState<boolean>(false)
    const [totalPedEHover, setTotalPedEHover] = useState<boolean>(false)
    const [pedEDCHover, setPedEDCHover] = useState<boolean>(false)
    const [SEPedHover, setSEPedHover] = useState<boolean>(false)
    const [pedSRCHover, setPedSRCHover] = useState<boolean>(false)
    const [totalPedSHover, setTotalPedSHover] = useState<boolean>(false)
    const [pedSLCHover, setPedSLCHover] = useState<boolean>(false)
    const [carERHover, setCarERHover] = useState<boolean>(false)
    const [truckERHover, setTruckERHover] = useState<boolean>(false)
    const [cyclistERHover, setCyclistERHover] = useState<boolean>(false)
    const [totalVehicleERHover, setTotalVehicleERHover] = useState<boolean>(false)
    const [carETHover, setCarETHover] = useState<boolean>(false)
    const [truckETHover, setTruckETHover] = useState<boolean>(false)
    const [cyclistETHover, setCyclistETHover] = useState<boolean>(false)
    const [totalVehicleETHover, setTotalVehicleETHover] = useState<boolean>(false)
    const [carELHover, setCarELHover] = useState<boolean>(false)
    const [truckELHover, setTruckELHover] = useState<boolean>(false)
    const [cyclistELHover, setCyclistELHover] = useState<boolean>(false)
    const [totalVehicleELHover, setTotalVehicleELHover] = useState<boolean>(false)
    const [carEUHover, setCarEUHover] = useState<boolean>(false)
    const [truckEUHover, setTruckEUHover] = useState<boolean>(false)
    const [cyclistEUHover, setCyclistEUHover] = useState<boolean>(false)
    const [totalVehicleEUHover, setTotalVehicleEUHover] = useState<boolean>(false)
    const [totalVehicleEHover, setTotalVehicleEHover] = useState<boolean>(false)
    const [totalEHover, setTotalEHover] = useState<boolean>(false)

    // Cells Hiding Conditions
    const NT_WE2Way_E1way_HoverHiding = props.sectionType === SectionDetailType.NorthTIntersection || props.sectionType === SectionDetailType.EastWestTwoWayRoad || props.sectionType === SectionDetailType.EastOneWayRoad
    const NT_HoverHiding = props.sectionType === SectionDetailType.NorthTIntersection
    const NT_WE2Way_W1Way_HoverHiding = props.sectionType === SectionDetailType.NorthTIntersection || props.sectionType === SectionDetailType.EastWestTwoWayRoad || props.sectionType === SectionDetailType.WestOneWayRoad
    const ET_HoverHiding = props.sectionType === SectionDetailType.EastTIntersection
    const ET_NS2Way_S1Way_HoverHiding = props.sectionType === SectionDetailType.EastTIntersection || props.sectionType === SectionDetailType.NorthSouthTwoWayRoad || props.sectionType === SectionDetailType.SouthOneWayRoad
    const ET_NS2Way_N1Way_HoverHiding = props.sectionType === SectionDetailType.EastTIntersection || props.sectionType === SectionDetailType.NorthSouthTwoWayRoad || props.sectionType === SectionDetailType.NorthOneWayRoad
    const ST_HoverHiding = props.sectionType === SectionDetailType.SouthTIntersection || props.sectionType === SectionDetailType.EastWestTwoWayRoad || props.sectionType === SectionDetailType.EastOneWayRoad
    const ST_WE2Way_W1way_HoverHiding = props.sectionType === SectionDetailType.SouthTIntersection || props.sectionType === SectionDetailType.EastWestTwoWayRoad || props.sectionType === SectionDetailType.WestOneWayRoad
    const WT_NS2Way_S1Way_HoverHiding = props.sectionType === SectionDetailType.WestTIntersection || props.sectionType === SectionDetailType.NorthSouthTwoWayRoad || props.sectionType === SectionDetailType.SouthOneWayRoad
    const WT_NS2Way_N1Way_HoverHiding = props.sectionType === SectionDetailType.WestTIntersection || props.sectionType === SectionDetailType.NorthSouthTwoWayRoad || props.sectionType === SectionDetailType.NorthOneWayRoad
    const WT_HoverHiding = props.sectionType === SectionDetailType.WestTIntersection
    const N1Way_HoverHiding = props.sectionType === SectionDetailType.NorthOneWayRoad
    const E1Way_HoverHiding = props.sectionType === SectionDetailType.EastOneWayRoad
    const S1Way_HoverHiding = props.sectionType === SectionDetailType.SouthOneWayRoad
    const W1Way_HoverHiding = props.sectionType === SectionDetailType.WestOneWayRoad

    //TODO : Change to comment value
    // const compass = "/images/compass.svg";
    const compass = "https://staticfile.tes.ca/Compass.svg";

    const TESYellow = "blue"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(SectionTypeHandler, [])

    function SectionTypeHandler() {
        if (props.sectionType === SectionDetailType.NorthTIntersection) {
            setDisplayNStreetName("none");
            setNVisibility("hidden");
            setNWPedVisibility("hidden")
            setNWNBlackBorder(false); setNNEBlackBorder(false); setNCBlackBorder(true);
            setN2WArrow(false); setNTArrow(false); setN2EArrow(false); setNUArrow(false); setE2NArrow(false); setSTArrow(false); setW2NArrow(false);

        } else if (props.sectionType === SectionDetailType.EastTIntersection) {
            setDisplayEStreetName("none");
            setEVisibility("hidden"); setNEVisibility("hidden")
            setNEPedVisibility("hidden")
            setNEEBlackBorder(false); setCEBlackBorder(true); setESEBlackBorder(false);
            setS2EArrow(false); setEUArrow(false); setE2SArrow(false); setETArrow(false); setE2NArrow(false); setWTArrow(false); setN2EArrow(false);

        } else if (props.sectionType === SectionDetailType.SouthTIntersection) {
            setDisplaySStreetName("none");
            setSVisibility("hidden");
            setSEPedVisibility("hidden")
            setSWSBlackBorder(false); setSSEBlackBorder(false); setCSBlackBorder(true);
            setS2EArrow(false); setSTArrow(false); setS2WArrow(false); setSUArrow(false); setW2SArrow(false); setNTArrow(false); setE2SArrow(false);

        } else if (props.sectionType === SectionDetailType.WestTIntersection) {
            setDisplayWStreetName("none");
            setWVisibility("hidden"); setSWVisibility("hidden")
            setNWWBlackBorder(false); setWCBlackBorder(true); setWSWBlackBorder(false);
            setN2WArrow(false); setWUArrow(false); setW2NArrow(false); setWTArrow(false); setW2SArrow(false); setS2WArrow(false); setETArrow(false);

        } else if (props.sectionType === SectionDetailType.NorthSouthTwoWayRoad) {
            setDisplayWStreetName("none"); setDisplayEStreetName("none");
            setNEVisibility("hidden"); setWVisibility("hidden"); setEVisibility("hidden"); setSWVisibility("hidden");
            setNEPedVisibility("hidden")
            setNWWBlackBorder(false); setWCBlackBorder(true); setWSWBlackBorder(false);
            setNEEBlackBorder(false); setCEBlackBorder(true); setESEBlackBorder(false);
            setN2WArrow(false); setWUArrow(false); setW2NArrow(false); setWTArrow(false); setW2SArrow(false); setN2EArrow(false);
            setS2WArrow(false); setE2NArrow(false); setETArrow(false); setE2SArrow(false); setEUArrow(false); setS2EArrow(false);

        } else if (props.sectionType === SectionDetailType.EastWestTwoWayRoad) {
            setDisplayNStreetName("none"); setDisplaySStreetName("none");
            setNVisibility("hidden"); setSVisibility("hidden");
            setNWPedVisibility("hidden"); setSEPedVisibility("hidden");
            setNWNBlackBorder(false); setNCBlackBorder(true); setNNEBlackBorder(false);
            setSWSBlackBorder(false); setCSBlackBorder(true); setSSEBlackBorder(false);
            setN2WArrow(false); setNUArrow(false); setW2NArrow(false); setNTArrow(false); setW2SArrow(false); setN2EArrow(false);
            setS2WArrow(false); setE2NArrow(false); setSTArrow(false); setE2SArrow(false); setSUArrow(false); setS2EArrow(false);

        } else if (props.sectionType === SectionDetailType.NorthOneWayRoad) {
            setDisplayWStreetName("none"); setDisplayEStreetName("none");
            setNWVisibility("hidden"); setNVisibility("hidden"); setNEVisibility("hidden");
            setWVisibility("hidden"); setEVisibility("hidden"); setSWVisibility("hidden");
            setNWPedVisibility("hidden"); setNEPedVisibility("hidden");
            setNWWBlackBorder(false); setWCBlackBorder(true); setWSWBlackBorder(false);
            setNEEBlackBorder(false); setCEBlackBorder(true); setESEBlackBorder(false);
            setSTotalBorder("0px")
            setN2WArrow(false); setWUArrow(false); setW2NArrow(false); setWTArrow(false); setW2SArrow(false); setN2EArrow(false);
            setS2WArrow(false); setE2NArrow(false); setETArrow(false); setE2SArrow(false); setEUArrow(false); setS2EArrow(false);
            setNTArrow(false); setNUArrow(false); setSUArrow(false);

        } else if (props.sectionType === SectionDetailType.EastOneWayRoad) {
            setDisplayNStreetName("none"); setDisplaySStreetName("none");
            setNVisibility("hidden"); setSVisibility("hidden"); setEVisibility("hidden"); setSEVisibility("hidden")
            setNEPedVisibility("hidden"); setSEPedVisibility("hidden"); setNWPedVisibility("hidden");
            setNWNBlackBorder(false); setNCBlackBorder(true); setNNEBlackBorder(false);
            setSWSBlackBorder(false); setCSBlackBorder(true); setSSEBlackBorder(false);
            setWTotalBorder("0px");
            setN2WArrow(false); setNUArrow(false); setW2NArrow(false); setNTArrow(false); setW2SArrow(false); setN2EArrow(false);
            setS2WArrow(false); setE2NArrow(false); setSTArrow(false); setE2SArrow(false); setSUArrow(false); setS2EArrow(false);
            setWUArrow(false); setETArrow(false); setEUArrow(false);

        } else if (props.sectionType === SectionDetailType.SouthOneWayRoad) {
            setDisplayWStreetName("none"); setDisplayEStreetName("none");
            setNEVisibility("hidden"); setWVisibility("hidden"); setSVisibility("hidden");
            setEVisibility("hidden"); setSEVisibility("hidden"); setSWVisibility("hidden");
            setNEPedVisibility("hidden"); setSEPedVisibility("hidden"); setSWPedVisibility("hidden");
            setNWWBlackBorder(false); setWCBlackBorder(true); setWSWBlackBorder(false);
            setNEEBlackBorder(false); setCEBlackBorder(true); setESEBlackBorder(false);
            setNTotalBorder("0px");
            setN2WArrow(false); setWUArrow(false); setW2NArrow(false); setWTArrow(false); setW2SArrow(false); setN2EArrow(false);
            setS2WArrow(false); setE2NArrow(false); setETArrow(false); setE2SArrow(false); setEUArrow(false); setS2EArrow(false);
            setSTArrow(false); setNUArrow(false); setSUArrow(false);

        } else if (props.sectionType === SectionDetailType.WestOneWayRoad) {
            setDisplayNStreetName("none"); setDisplaySStreetName("none");
            setNWVisibility("hidden"); setSWVisibility("hidden"); setWVisibility("hidden"); setNVisibility("hidden"); setSVisibility("hidden");
            setNWPedVisibility("hidden"); setSWPedVisibility("hidden"); setSEPedVisibility("hidden");
            setNWNBlackBorder(false); setNCBlackBorder(true); setNNEBlackBorder(false);
            setSWSBlackBorder(false); setCSBlackBorder(true); setSSEBlackBorder(false);
            setETotalBorder("0px");
            setN2WArrow(false); setNUArrow(false); setW2NArrow(false); setNTArrow(false); setW2SArrow(false); setN2EArrow(false);
            setS2WArrow(false); setE2NArrow(false); setSTArrow(false); setE2SArrow(false); setSUArrow(false); setS2EArrow(false);
            setWUArrow(false); setWTArrow(false); setEUArrow(false);
        }
    }

    return (
        <div className={`xmlImport ${compactViewModel ? "compactStyle" : ""}`}>
            <ScrollView width="100%" height="100%" useNative={true} direction="both" style={{ margin: "1rem 0rem" }} >
                {props.showPeriod &&
                    <div style={{ alignItems: "center", marginLeft: "3.5rem" }}>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>
                            <RadioGroup
                                items={timeRadioButtonsOptions}
                                layout="horizontal"
                                id="radio-group-with-selection"
                                value={selectedTimeRange}
                                valueExpr="id"
                                displayExpr="text"
                                onValueChange={e => { handleRadioChange(e); setOneDayRange(props.oneDayCalendarRange ? props.oneDayCalendarRange[0] : undefined) }}
                            />
                            {selectedTimeRange !== 0 &&
                                <div>
                                    <DateBox
                                        label={t("selectADay")}
                                        value={props.oneDayCalendarRange ? props.oneDayCalendarRange[0] : undefined}
                                        onValueChanged={(e) => setOneDayRange(e.value)}
                                        type="date"
                                        pickerType="calendar"
                                        displayFormat={props.generalSettings?.dateFormat}
                                        // showClearButton={true}
                                        min={props.oneDayCalendarRange ? props.oneDayCalendarRange[0] : undefined}
                                        max={props.oneDayCalendarRange ? props.oneDayCalendarRange[1] : undefined}
                                        style={{ marginLeft: "1rem" }}
                                    />
                                    {/* <Button
                          onClick={() => setOneDayRange(props.oneDayCalendarRange ? props.oneDayCalendarRange[0] : undefined)}
                          className='tes-modal-btn-add'
                          text={t('apply')}
                          style={{ marginTop: 10 }}
                        /> */}
                                </div>}
                        </div>
                        {selectedTimeRange === 7 &&
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <p className={design.radioButtonTitle} style={{ color: "#424242" }}>{t("selectACustomTimeInterval")}</p>
                                <DateBox
                                    label={t("from")}
                                    type="time"
                                    pickerType="rollers"
                                    displayFormat="HH:mm"
                                    value={startTime}
                                    onValueChanged={handleStartTimeChange}
                                    style={{ marginRight: "1rem" }}
                                />
                                <DateBox
                                    label={t("to")}
                                    type="time"
                                    pickerType="rollers"
                                    displayFormat="HH:mm"
                                    value={endTime}
                                    onValueChanged={handleEndTimeChange}
                                    style={{ marginRight: "1rem" }}
                                />
                            </div>}
                    </div>
                }
                <div style={{ height: props.size + "px", width: props.size + "px" }} className={design.mainContainer}>
                    <div style={{ width: "5%" }}>
                        <div style={{ height: "5%" }}></div>
                        <div style={{ height: "90%", width: "100%" }} className={design.centering}>
                            <label style={{ transform: "rotate(-90deg)", whiteSpace: "nowrap", display: displayWStreetName }} className={design.eastWestStreets}>{props.WestStreetName}</label>
                        </div>
                        <div style={{ height: "5%" }}></div>
                    </div>
                    <div style={{ width: "90%" }}>
                        <div style={{ height: "5%", display: displayNStreetName }} className={`${design.centering} ${design.legendText}`}>{props.NorthStreetName}</div>
                        <div style={{ height: "90%" }} className={design.mainContainer}>
                            {/* left column */}
                            <div className={design.mainColumns}>
                                {/* NW block */}
                                <div style={{ visibility: NWVisibility }} className={design.mainBlocks}>
                                    <div className={`${design.row16666} ${design.rightPad10}`} style={{ paddingTop: "20px", visibility: NWPedVisibility }}>
                                        <div className={design.column3_16666}></div>
                                        <div className={`${design.column16666} ${design.arrows}`} style={{ justifyContent: "left" }}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginLeft: "-25%", transform: "rotate(-90deg)",
                                                color: NWPedHover || pedNLCHover || totalPedNHover ? TESYellow : ""
                                            }}>straight</span>
                                        </div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{
                                            justifyContent: "center",
                                            color: pedNLCHover || totalPedNHover || pedNRCHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setNWPedHover(true)} onMouseOut={() => setNWPedHover(false)}>
                                            <div className={`${design.legendIcon} ${design.marginRight10}`}>
                                                <i className="fa-solid fa-person-walking"></i>
                                            </div>
                                            <div className={design.legendText}>{t("ped")}</div>
                                        </div>
                                        <div className={`${design.column16666} ${design.arrows}`} style={{ justifyContent: "center" }}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginRight: "-50%", transform: "rotate(90deg)",
                                                color: NWPedHover || totalPedNHover || pedNRCHover ? TESYellow : ""
                                            }}>straight</span>
                                        </div>
                                    </div>
                                    <div className={`${design.row16666} ${design.rightPad10}`} style={{ paddingTop: "15px", visibility: NWPedVisibility }}>
                                        <div className={design.column3_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ justifyContent: "center", color: totalPedNHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedNLCHover(true)} onMouseOut={() => setPedNLCHover(false)}>{SeparatorAdder(pedNLC)}</div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ justifyContent: "center", color: NWPedHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalPedNHover(true)} onMouseOut={() => setTotalPedNHover(false)}>{SeparatorAdder(totalPedN)}</div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ justifyContent: "center", color: totalPedNHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedNRCHover(true)} onMouseOut={() => setPedNRCHover(false)}>{SeparatorAdder(pedNRC)}</div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column2_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center",
                                            color: totalVehicleInputNHover || totalVehicleInputWHover || totalVehicleInputSHover || totalVehicleInputEHover || totalVehicleWUHover ||
                                                totalWHover || totalVehicleWLHover || totalVehicleWHover || totalVehicleWTHover || totalVehicleWRHover || totalNHover || totalVehicleNHover ||
                                                totalVehicleNRHover || totalVehicleNTHover || totalVehicleNLHover || totalVehicleNUHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setNWTotalHover(true)} onMouseOut={() => setNWTotalHover(false)}>
                                            <div className={design.legendText}>{t("total")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-plus"></i>
                                            </div>
                                        </div>
                                        <div className={design.column3_16666}>
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column2_16666}></div>
                                        <div className={design.column16666}></div>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center",
                                            color: totalCyclistInputNHover || totalCyclistInputWHover || totalCyclistInputSHover || totalCyclistInputEHover ||
                                                cyclistWUHover || cyclistWLHover || cyclistWTHover || cyclistWRHover || cyclistNRHover || cyclistNTHover || cyclistNLHover ||
                                                cyclistNUHover ? TESYellow : ""
                                        }} onMouseOver={() => setNWCyclistHover(true)} onMouseOut={() => setNWCyclistHover(false)}>
                                            <div className={design.legendText}>{t("cyclists")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-person-biking"></i>
                                            </div>
                                        </div>
                                        <div className={design.column2_16666}></div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column2_16666}></div>
                                        <div className={design.column16666}></div>
                                        <div className={design.column16666}></div>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center",
                                            color: totalTruckInputNHover || totalTruckInputWHover ||
                                                totalTruckInputSHover || totalTruckInputEHover || truckWUHover || truckWLHover || truckWTHover || truckWRHover || truckNRHover ||
                                                truckNTHover || truckNLHover || totalTruckInputNHover || truckNUHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setNWTruckHover(true)} onMouseOut={() => setNWTruckHover(false)}>
                                            <div className={design.legendText}>{t("trucks")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-truck"></i>
                                            </div>
                                        </div>
                                        <div className={design.column16666}></div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column2_16666}></div>
                                        <div className={design.column16666}></div>
                                        <div className={design.column2_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center", marginLeft: "-5px",
                                            color: totalCarInputNHover || totalCarInputWHover || totalCarInputSHover ||
                                                totalCarInputEHover || carWUHover || carWLHover || carWTHover || carWRHover || carNRHover || carNTHover ||
                                                carNLHover || carNUHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setNWCarHover(true)} onMouseOut={() => setNWCarHover(false)}>
                                            <div className={design.legendText} style={{ marginLeft: "-25px" }}>{t("cars")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-car-side"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* NWWBorder */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: NWWBlackBorder ? "black" : "white" }}></div>
                                {/* W block */}
                                <div style={{ visibility: WVisibility, marginTop: "5%" }} className={design.mainBlocks}>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${design.marginTop3}`} style={{ display: "block" }}></div>
                                        <div className={`${design.column16666} ${design.marginTop3}`} style={{ display: "block" }}>
                                            <div className={design.row50}></div>
                                            <div className={design.row50} style={{ borderLeft: WTotalBorder, borderTop: WTotalBorder }}></div>
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            paddingLeft: "1.52px",
                                            color: NWTotalHover || totalWHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleInputWHover(!E1Way_HoverHiding)} onMouseOut={() => setTotalVehicleInputWHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleInputW)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: WTotalBorder,
                                            paddingLeft: "1.52px", marginBottom: "5px", color: NWCyclistHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalCyclistInputWHover(!E1Way_HoverHiding)} onMouseOut={() => setTotalCyclistInputWHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(totalCyclistInputW)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWTruckHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalTruckInputWHover(!E1Way_HoverHiding)} onMouseOut={() => setTotalTruckInputWHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(totalTruckInputW)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWCarHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalCarInputWHover(!E1Way_HoverHiding)} onMouseOut={() => setTotalCarInputWHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(totalCarInputW)}
                                        </div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={design.column16666}></div>
                                        <div className={design.column16666} style={{ borderLeft: WTotalBorder, paddingLeft: "1.52px" }}></div>
                                        <div className={`${design.column16666} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: WTotalBorder, paddingLeft: "1.52px",
                                            color: NWTotalHover || totalVehicleInputWHover || totalVehicleWHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleWUHover(!E1Way_HoverHiding)} onMouseOut={() => setTotalVehicleWUHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleWU)}
                                        </div>
                                        <div className={`${design.column16666} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: WTotalBorder, paddingLeft: "1.52px",
                                            color: NWCyclistHover || totalCyclistInputWHover || totalVehicleWUHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setCyclistWUHover(!E1Way_HoverHiding)} onMouseOut={() => setCyclistWUHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(cyclistWU)}
                                        </div>
                                        <div className={`${design.column16666} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWTruckHover || totalTruckInputWHover || totalVehicleWUHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckWUHover(!E1Way_HoverHiding)} onMouseOut={() => setTruckWUHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(truckWU)}
                                        </div>
                                        <div className={`${design.column16666} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWCarHover || totalCarInputWHover || totalVehicleWUHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarWUHover(!E1Way_HoverHiding)} onMouseOut={() => setCarWUHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(carWU)}
                                        </div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${!E1Way_HoverHiding ? design.pointer : ""}`}
                                            onMouseOver={() => setTotalWHover(!E1Way_HoverHiding)} onMouseOut={() => setTotalWHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(totalW)}
                                        </div>
                                        <div className={`${design.column16666} ${design.secondColor}`} style={{ borderLeft: WTotalBorder, paddingLeft: "1.52px" }}>
                                            {E1Way_HoverHiding ? "" : 19}
                                        </div>
                                        <div className={`${design.column16666} ${!NT_WE2Way_E1way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: WTotalBorder, paddingLeft: "1.52px",
                                            color: NWTotalHover || totalVehicleInputNHover || totalVehicleWHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleWLHover(!NT_WE2Way_E1way_HoverHiding)} onMouseOut={() => setTotalVehicleWLHover(false)}>
                                            {NT_WE2Way_E1way_HoverHiding ? "" : SeparatorAdder(totalVehicleWL)}
                                        </div>
                                        <div className={`${design.column16666} ${!NT_WE2Way_E1way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: "2px solid black", paddingLeft: "1.52px",
                                            color: NWCyclistHover || totalCyclistInputNHover || totalVehicleWLHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setCyclistWLHover(!NT_WE2Way_E1way_HoverHiding)} onMouseOut={() => setCyclistWLHover(false)}>
                                            {NT_WE2Way_E1way_HoverHiding ? "" : SeparatorAdder(cyclistWL)}
                                        </div>
                                        <div className={`${design.column16666} ${!NT_WE2Way_E1way_HoverHiding ? design.pointer : ""}`} style={{
                                            color: NWTruckHover || totalTruckInputNHover ||
                                                totalVehicleWLHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTruckWLHover(!NT_WE2Way_E1way_HoverHiding)} onMouseOut={() => setTruckWLHover(false)}>
                                            {NT_WE2Way_E1way_HoverHiding ? "" : SeparatorAdder(truckWL)}
                                        </div>
                                        <div className={`${design.column16666} ${!NT_WE2Way_E1way_HoverHiding ? design.pointer : ""}`} style={{ color: NWCarHover || totalCarInputNHover || totalVehicleWLHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarWLHover(!NT_WE2Way_E1way_HoverHiding)} onMouseOut={() => setCarWLHover(false)}>
                                            {NT_WE2Way_E1way_HoverHiding ? "" : SeparatorAdder(carWL)}
                                        </div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={design.column16666}></div>
                                        <div className={`${design.column16666} ${!E1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: WTotalBorder, paddingLeft: "1.52px",
                                            color: totalWHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleWHover(!E1Way_HoverHiding)} onMouseOut={() => setTotalVehicleWHover(false)}>
                                            {E1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleW)}
                                        </div>
                                        <div className={`${design.column16666} ${!ET_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: WTotalBorder, paddingLeft: "1.52px",
                                            color: NWTotalHover || totalVehicleInputEHover ||
                                                totalVehicleWHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleWTHover(!ET_HoverHiding)} onMouseOut={() => setTotalVehicleWTHover(false)}>
                                            {ET_HoverHiding ? "" : SeparatorAdder(totalVehicleWT)}
                                        </div>
                                        <div className={`${design.column16666} ${!ET_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: "2px solid black", paddingLeft: "1.52px",
                                            color: NWCyclistHover || totalCyclistInputEHover
                                                || totalVehicleWTHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setCyclistWTHover(!ET_HoverHiding)} onMouseOut={() => setCyclistWTHover(false)}>
                                            {ET_HoverHiding ? "" : SeparatorAdder(cyclistWT)}
                                        </div>
                                        <div className={`${design.column16666} ${!ET_HoverHiding ? design.pointer : ""}`} style={{ color: NWTruckHover || totalTruckInputEHover || totalVehicleWTHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckWTHover(!ET_HoverHiding)} onMouseOut={() => setTruckWTHover(false)}>
                                            {ET_HoverHiding ? "" : SeparatorAdder(truckWT)}
                                        </div>
                                        <div className={`${design.column16666} ${!ET_HoverHiding ? design.pointer : ""}`} style={{ color: NWCarHover || totalCarInputEHover || totalVehicleWTHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarWTHover(!ET_HoverHiding)} onMouseOut={() => setCarWTHover(false)}>
                                            {ET_HoverHiding ? "" : SeparatorAdder(carWT)}
                                        </div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${design.marginBottom3}`}></div>
                                        <div className={`${design.column16666} ${design.marginBottom3} ${design.secondColor}`} style={{ paddingLeft: "1.52px" }}>29</div>
                                        <div className={`${design.column16666} ${design.marginBottom23} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: WTotalBorder, paddingLeft: "1.52px",
                                            color: NWTotalHover || totalVehicleInputSHover || totalVehicleWHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleWRHover(!ST_HoverHiding)} onMouseOut={() => setTotalVehicleWRHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(totalVehicleWR)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom23} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: "2px solid black", paddingLeft: "1.52px",
                                            color: NWCyclistHover || totalCyclistInputSHover || totalVehicleWRHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setCyclistWRHover(!ST_HoverHiding)} onMouseOut={() => setCyclistWRHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(cyclistWR)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom3} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            color: NWTruckHover || totalTruckInputSHover ||
                                                totalVehicleWRHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTruckWRHover(!ST_HoverHiding)} onMouseOut={() => setTruckWRHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(truckWR)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom3} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            color: NWCarHover || totalCarInputSHover ||
                                                totalVehicleWRHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setCarWRHover(!ST_HoverHiding)} onMouseOut={() => setCarWRHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(carWR)}
                                        </div>
                                    </div>
                                </div>
                                {/* WSWBorder */}
                                <div style={{ height: "1.5%", width: "100%", marginTop: "-5%", backgroundColor: WSWBlackBorder ? "black" : "white" }}></div>
                                {/* SW block */}
                                <div style={{ visibility: SWVisibility }} className={design.mainBlocks}>
                                    <div className={`${design.row16666} ${design.leftPad20}`} style={{ paddingTop: "20px" }}>
                                        <div className={`${design.column16666} ${design.arrows}`}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginLeft: "-15%", color: SWPedHover || pedWUCHover ||
                                                    totalPedWHover ? TESYellow : ""
                                            }}>straight</span>
                                        </div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ color: totalPedWHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedWUCHover(true)} onMouseOut={() => setPedWUCHover(false)}>{SeparatorAdder(pedWUC)}</div>
                                        <div className={design.column4_16666}></div>
                                    </div>
                                    <div className={`${design.row16666} ${design.leftPad20}`} style={{ paddingTop: "20px", marginBottom: "10px" }}>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ flexDirection: "column", color: pedWUCHover || pedWDCHover || totalPedWHover ? TESYellow : "" }}
                                            onMouseOver={() => setSWPedHover(true)} onMouseOut={() => setSWPedHover(false)}>
                                            <div className={design.legendText} style={{ marginBottom: "5px" }}>{t("ped")}</div>
                                            <div className={`${design.legendIcon} ${design.leftPad5}`}>
                                                <i className="fa-solid fa-person-walking"></i>
                                            </div>
                                        </div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ paddingTop: "10px", color: SWPedHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalPedWHover(true)} onMouseOut={() => setTotalPedWHover(false)}>{SeparatorAdder(totalPedW)}</div>
                                        <div className={design.column4_16666}></div>
                                    </div>
                                    <div className={`${design.row16666} ${design.leftPad20}`} style={{ paddingTop: "20px" }}>
                                        <div className={`${design.column16666} ${design.arrows}`}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginLeft: "-15%", transform: "rotate(180deg)", color: SWPedHover ||
                                                    pedWDCHover || totalPedWHover ? TESYellow : ""
                                            }}>straight</span>
                                        </div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ paddingTop: "5px", color: totalPedWHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedWDCHover(true)} onMouseOut={() => setPedWDCHover(false)}>{SeparatorAdder(pedWDC)}</div>
                                        <div className={design.column4_16666}></div>
                                    </div>
                                    <div className={design.row3_16666}></div>
                                </div>
                            </div>
                            {/* left Vertical border */}
                            <div style={{ height: "100%", width: "1.5%", display: "flex", flexDirection: "column" }}>
                                {/* NWNBorder */}
                                <div style={{ height: "32.333%", width: "100%", backgroundColor: NWNBlackBorder ? "black" : "white" }}></div>
                                {/* upper Left square border */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: "black" }}></div>
                                {/* WCBorder */}
                                <div style={{ height: "32.333%", width: "100%", backgroundColor: WCBlackBorder ? "black" : "white" }}></div>
                                {/* lower left square border */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: "black" }}></div>
                                {/* SWSBorder */}
                                <div style={{ height: "32.333%", width: "100%", backgroundColor: SWSBlackBorder ? "black" : "white" }}></div>
                            </div>
                            {/* middle column */}
                            <div className={design.mainColumns}>
                                {/* N block */}
                                <div style={{ visibility: NVisibility }} className={design.mainBlocks}>
                                    <div className={design.row16666}>
                                        <div className={design.column40}></div>
                                        <div className={`${design.column16666} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ marginTop: "5px" }}
                                            onMouseOver={() => setTotalNHover(!S1Way_HoverHiding)} onMouseOut={() => setTotalNHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(totalN)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${design.leftPad5} ${design.secondColor}`}>35</div>
                                        <div className={`${design.column20} ${design.leftPad5} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ borderTop: NTotalBorder, color: totalNHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalVehicleNHover(!S1Way_HoverHiding)} onMouseOut={() => setTotalVehicleNHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleN)}
                                        </div>
                                        <div className={`${design.column20} ${design.secondColor}`} style={{ borderTop: NTotalBorder }}>37</div>
                                        <div className={design.column20} style={{ borderTop: NTotalBorder }}></div>
                                        <div className={design.column20} style={{ flexDirection: "row" }}>
                                            <div style={{ width: "50%", borderTop: NTotalBorder }}></div>
                                            <div style={{ width: "50%", borderLeft: NTotalBorder }}></div>
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${!WT_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: NTotalBorder, marginLeft: "5px", color: NWTotalHover ||
                                                totalVehicleInputWHover || totalVehicleNHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleNRHover(!WT_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setTotalVehicleNRHover(false)}>
                                            {WT_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleNR)}
                                        </div>
                                        <div className={`${design.column20} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: NTotalBorder, marginLeft: "-5px", color: NWTotalHover ||
                                                totalVehicleInputSHover || totalVehicleNHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleNTHover(!ST_HoverHiding)} onMouseOut={() => setTotalVehicleNTHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(totalVehicleNT)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: NTotalBorder, color: NWTotalHover || totalVehicleInputEHover ||
                                                totalVehicleNHover ? TESYellow : ""
                                        }} onMouseOver={() => setTotalVehicleNLHover(!ET_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setTotalVehicleNLHover(false)}>
                                            {ET_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleNL)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: NTotalBorder, color: NWTotalHover || totalVehicleInputNHover ||
                                                totalVehicleNHover ? TESYellow : ""
                                        }} onMouseOver={() => setTotalVehicleNUHover(!S1Way_HoverHiding)} onMouseOut={() => setTotalVehicleNUHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleNU)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWTotalHover || totalNHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalVehicleInputNHover(!S1Way_HoverHiding)} onMouseOut={() => setTotalVehicleInputNHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleInputN)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${!WT_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: "2px solid black", marginLeft: "5px", color: NWCyclistHover ||
                                                totalCyclistInputWHover || totalVehicleNRHover ? TESYellow : ""
                                        }} onMouseOver={() => setCyclistNRHover(!WT_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setCyclistNRHover(false)}>
                                            {WT_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(cyclistNR)}
                                        </div>
                                        <div className={`${design.column20} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: "2px solid black", marginLeft: "-5px", color: NWCyclistHover ||
                                                totalCyclistInputSHover || totalVehicleNTHover ? TESYellow : ""
                                        }} onMouseOver={() => setCyclistNTHover(!ST_HoverHiding)} onMouseOut={() => setCyclistNTHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(cyclistNT)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: "2px solid black", color: NWCyclistHover || totalCyclistInputEHover ||
                                                totalVehicleNLHover ? TESYellow : ""
                                        }} onMouseOver={() => setCyclistNLHover(!ET_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setCyclistNLHover(false)}>
                                            {ET_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(cyclistNL)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: NTotalBorder, marginRight: "", color: NWCyclistHover ||
                                                totalCyclistInputNHover || totalVehicleNUHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setCyclistNUHover(!S1Way_HoverHiding)} onMouseOut={() => setCyclistNUHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(cyclistNU)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWCyclistHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalCyclistInputNHover(!S1Way_HoverHiding)} onMouseOut={() => setTotalCyclistInputNHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(totalCyclistInputN)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${!WT_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            marginLeft: "5px", color: NWTruckHover || totalTruckInputWHover ||
                                                totalVehicleNRHover ? TESYellow : ""
                                        }} onMouseOver={() => setTruckNRHover(!WT_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setTruckNRHover(false)}>
                                            {WT_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(truckNR)}
                                        </div>
                                        <div className={`${design.column20} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            marginLeft: "-5px", color: NWTruckHover || totalTruckInputSHover ||
                                                totalVehicleNTHover ? TESYellow : ""
                                        }} onMouseOver={() => setTruckNTHover(!ST_HoverHiding)} onMouseOut={() => setTruckNTHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(truckNT)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWTruckHover || totalTruckInputEHover || totalVehicleNLHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckNLHover(!ET_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setTruckNLHover(false)}>
                                            {ET_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(truckNL)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWTruckHover || totalTruckInputNHover || totalVehicleNUHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckNUHover(!S1Way_HoverHiding)} onMouseOut={() => setTruckNUHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(truckNU)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWTruckHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalTruckInputNHover(!S1Way_HoverHiding)} onMouseOut={() => setTotalTruckInputNHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(totalTruckInputN)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${!WT_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            marginLeft: "5px", color: NWCarHover || totalCarInputWHover ||
                                                totalVehicleNRHover ? TESYellow : ""
                                        }} onMouseOver={() => setCarNRHover(!WT_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setCarNRHover(false)}>
                                            {WT_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(carNR)}
                                        </div>
                                        <div className={`${design.column20} ${!ST_HoverHiding ? design.pointer : ""}`} style={{
                                            marginLeft: "-5px", color: NWCarHover || totalCarInputSHover ||
                                                totalVehicleNTHover ? TESYellow : ""
                                        }} onMouseOver={() => setCarNTHover(!ST_HoverHiding)} onMouseOut={() => setCarNTHover(false)}>
                                            {ST_HoverHiding ? "" : SeparatorAdder(carNT)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWCarHover || totalCarInputEHover || totalVehicleNLHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarNLHover(!ET_NS2Way_S1Way_HoverHiding)} onMouseOut={() => setCarNLHover(false)}>
                                            {ET_NS2Way_S1Way_HoverHiding ? "" : SeparatorAdder(carNL)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWCarHover || totalCarInputNHover || totalVehicleNUHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarNUHover(!S1Way_HoverHiding)} onMouseOut={() => setCarNUHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(carNU)}
                                        </div>
                                        <div className={`${design.column20} ${!S1Way_HoverHiding ? design.pointer : ""}`} style={{ color: NWCarHover ? TESYellow : "", }}
                                            onMouseOver={() => setTotalCarInputNHover(!S1Way_HoverHiding)} onMouseOut={() => setTotalCarInputNHover(false)}>
                                            {S1Way_HoverHiding ? "" : SeparatorAdder(totalCarInputN)}
                                        </div>
                                    </div>
                                </div>
                                {/* NCBorder */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: NCBlackBorder ? "black" : "white" }}></div>
                                {/* Center block */}
                                <div className={design.centerBlock}>
                                    <div className={design.column20} style={{ flexDirection: "column" }}>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/* N2W Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: "rotate(180deg)",
                                                color: totalCarInputWHover || totalTruckInputWHover || totalCyclistInputWHover || totalVehicleInputWHover ||
                                                    totalWHover || totalNHover || totalVehicleNHover || totalVehicleNRHover || cyclistNRHover || truckNRHover || carNRHover ? TESYellow : ""
                                            }}>{N2WArrow ? "turn_right" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*WU Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: props.leftHandDrive ? "rotate(90deg)" : "rotate(90deg) scaleX(-1)",
                                                color: totalCarInputWHover || totalTruckInputWHover || totalCyclistInputWHover || totalVehicleInputWHover ||
                                                    totalVehicleWUHover || cyclistWUHover || truckWUHover || carWUHover || totalWHover || totalVehicleWHover ? TESYellow : ""
                                            }}>{WUArrow ? "u_turn_right" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*W2N Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: "rotate(90deg)",
                                                color: totalCarInputNHover || totalTruckInputNHover || totalCyclistInputNHover || totalVehicleInputNHover ||
                                                    totalVehicleWLHover || cyclistWLHover || truckWLHover || carWLHover || totalVehicleWHover || totalWHover || totalNHover ? TESYellow : ""
                                            }}>{W2NArrow ? "turn_left" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*WT Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: "rotate(90deg)",
                                                color: totalCarInputEHover || totalTruckInputEHover || totalCyclistInputEHover || totalVehicleInputEHover ||
                                                    totalVehicleWHover || totalVehicleWTHover || cyclistWTHover || truckWTHover || carWTHover || totalWHover || totalEHover ? TESYellow : ""
                                            }}>{WTArrow ? "straight" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*W2S Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: "rotate(90deg)", color: totalCarInputSHover || totalTruckInputSHover ||
                                                    totalCyclistInputSHover || totalVehicleInputSHover || totalVehicleWHover || totalVehicleWRHover || cyclistWRHover ||
                                                    truckWRHover || carWRHover || totalWHover || totalSHover ? TESYellow : ""
                                            }}>{W2SArrow ? "turn_right" : ""}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={design.column60}>
                                        <div className={design.row20}>
                                            <div className={`${design.column2_16666} ${design.centering} ${design.arrows}`}>
                                                {/*NT Arrow */}
                                                <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                    transform: "rotate(180deg)", color: totalCarInputSHover || totalTruckInputSHover
                                                        || totalCyclistInputSHover || totalVehicleInputSHover || totalNHover || totalVehicleNHover ||
                                                        totalVehicleNTHover || cyclistNTHover || truckNTHover || carNTHover || totalSHover ? TESYellow : ""
                                                }}>{NTArrow ? "straight" : ""}</span>
                                            </div>
                                            <div className={`${design.column2_16666} ${design.centering} ${design.arrows}`}>
                                                {/*N2E Arrow */}
                                                <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                    transform: "rotate(180deg)",
                                                    color: totalCarInputEHover || totalTruckInputEHover || totalCyclistInputEHover || totalVehicleInputEHover || totalNHover ||
                                                        totalVehicleNHover || totalVehicleNLHover || cyclistNLHover || truckNLHover || carNLHover || totalEHover ? TESYellow : ""
                                                }}>{N2EArrow ? "turn_left" : ""}
                                                </span>
                                            </div>
                                            <div className={`${design.column2_16666} ${design.centering}`}>
                                                {/*NU Arrow */}
                                                <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                    transform: props.leftHandDrive ? "rotate(180deg)" : "rotate(180deg) scaleX(-1)",
                                                    color: totalCarInputNHover || totalTruckInputNHover || totalCyclistInputNHover || totalVehicleInputNHover || totalNHover ||
                                                        totalVehicleNHover || totalVehicleNUHover || cyclistNUHover || truckNUHover || carNUHover ? TESYellow : ""
                                                }}>{NUArrow ? "u_turn_right" : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className={design.row60}>
                                            <img src={compass} alt="Tes compass" style={{ width: "75%", margin: "auto" }} />
                                        </div>
                                        <div className={design.row20}>
                                            <div className={`${design.column2_16666} ${design.centering} ${design.arrows}`}>
                                                {/*SU Arrow */}
                                                <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                    transform: props.leftHandDrive ? "" : "scaleX(-1)",
                                                    color: totalCarInputSHover || totalTruckInputSHover || totalCyclistInputSHover ||
                                                        totalVehicleInputSHover || carSUHover || truckSUHover || cyclistSUHover || totalVehicleSUHover || totalVehicleSHover || totalSHover ? TESYellow : ""
                                                }}> {SUArrow ? "u_turn_right" : ""}
                                                </span>
                                            </div>
                                            <div className={`${design.column2_16666} ${design.centering}`}>
                                                {/*S2W Arrow */}
                                                <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                    color: totalCarInputWHover || totalTruckInputWHover || totalCyclistInputWHover ||
                                                        totalVehicleInputWHover || totalWHover || carSLHover || truckSLHover || cyclistSLHover || totalVehicleSLHover || totalVehicleSHover || totalSHover ? TESYellow : ""
                                                }}> {S2WArrow ? "turn_left" : ""}
                                                </span>
                                            </div>
                                            <div className={`${design.column2_16666} ${design.centering} ${design.arrows}`}>
                                                {/*ST Arrow */}
                                                <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                    color: totalCarInputNHover || totalTruckInputNHover || totalCyclistInputNHover ||
                                                        totalVehicleInputNHover || totalNHover || carSTHover || truckSTHover || cyclistSTHover || totalVehicleSTHover || totalVehicleSHover || totalSHover ? TESYellow : ""
                                                }}> {STArrow ? "straight" : ""}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={design.column20} style={{ flexDirection: "column" }}>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*E2N Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: "rotate(-90deg)",
                                                color: totalCarInputNHover || totalTruckInputNHover || totalCyclistInputNHover || totalVehicleInputNHover || totalNHover || carERHover ||
                                                    truckERHover || cyclistERHover || totalVehicleERHover || totalVehicleEHover || totalEHover ? TESYellow : ""
                                            }}>{E2NArrow ? "turn_right" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*ET Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: "rotate(-90deg)",
                                                color: totalCarInputWHover || totalTruckInputWHover || totalCyclistInputWHover || totalVehicleInputWHover || totalWHover || carETHover
                                                    || truckETHover || cyclistETHover || totalVehicleETHover || totalVehicleEHover || totalEHover ? TESYellow : ""
                                            }}>{ETArrow ? "straight" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering}`}>
                                            {/*E2S Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: "rotate(-90deg)", color: totalCarInputSHover || totalTruckInputSHover ||
                                                    totalCyclistInputSHover || totalVehicleInputSHover || totalSHover || carELHover || truckELHover || cyclistELHover || totalVehicleELHover ||
                                                    totalVehicleEHover || totalEHover ? TESYellow : ""
                                            }}>{E2SArrow ? "turn_left" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*EU Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                transform: props.leftHandDrive ? "rotate(-90deg) scaleX(-1)" : "rotate(-90deg)",
                                                color: totalCarInputEHover || totalTruckInputEHover || totalCyclistInputEHover || totalVehicleInputEHover || carEUHover || truckEUHover ||
                                                    cyclistEUHover || totalVehicleEUHover || totalVehicleEHover || totalEHover ? TESYellow : ""
                                            }}>{EUArrow ? "u_turn_left" : ""}
                                            </span>
                                        </div>
                                        <div className={`${design.row20} ${design.centering} ${design.arrows}`}>
                                            {/*S2E Arrow */}
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                color: totalCarInputEHover || totalTruckInputEHover || totalCyclistInputEHover || totalVehicleInputEHover || carSRHover || truckSRHover ||
                                                    cyclistSRHover || totalVehicleSRHover || totalVehicleSRHover || totalVehicleSHover || totalSHover || totalEHover ? TESYellow : ""
                                            }}>{S2EArrow ? "turn_right" : ""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* CSBorder */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: CSBlackBorder ? "black" : "white" }}></div>
                                {/* S block */}
                                <div style={{ visibility: SVisibility }} className={design.mainBlocks}>
                                    <div className={design.row16666} style={{ marginTop: "5px" }}>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ marginLeft: "5px", color: SECarHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalCarInputSHover(!N1Way_HoverHiding)} onMouseOut={() => setTotalCarInputSHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(totalCarInputS)}
                                        </div>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SECarHover || totalCarInputSHover || totalVehicleSUHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarSUHover(!N1Way_HoverHiding)} onMouseOut={() => setCarSUHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(carSU)}
                                        </div>
                                        <div className={`${design.column20} ${!WT_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SECarHover || totalCarInputWHover || totalVehicleSLHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarSLHover(!WT_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setCarSLHover(false)}>
                                            {WT_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(carSL)}
                                        </div>
                                        <div className={`${design.column20} ${!NT_HoverHiding ? design.pointer : ""}`} style={{ color: SECarHover || totalCarInputNHover || totalVehicleSTHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarSTHover(!NT_HoverHiding)} onMouseOut={() => setCarSTHover(false)}>
                                            {NT_HoverHiding ? "" : SeparatorAdder(carST)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SECarHover || totalCarInputEHover || totalVehicleSRHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarSRHover(!ET_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setCarSRHover(false)}>
                                            {ET_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(carSR)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ marginLeft: "5px", color: SETruckHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalTruckInputSHover(true)} onMouseOut={() => setTotalTruckInputSHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(totalTruckInputS)}
                                        </div>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SETruckHover || totalTruckInputSHover || totalVehicleSUHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckSUHover(!N1Way_HoverHiding)} onMouseOut={() => setTruckSUHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(truckSU)}
                                        </div>
                                        <div className={`${design.column20} ${!WT_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SETruckHover || totalTruckInputWHover || totalVehicleSLHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckSLHover(!WT_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setTruckSLHover(false)}>
                                            {WT_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(truckSL)}
                                        </div>
                                        <div className={`${design.column20} ${!NT_HoverHiding ? design.pointer : ""}`} style={{ color: SETruckHover || totalTruckInputNHover || totalVehicleSTHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckSTHover(!NT_HoverHiding)} onMouseOut={() => setTruckSTHover(false)}>
                                            {NT_HoverHiding ? "" : SeparatorAdder(truckST)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SETruckHover || totalTruckInputEHover || totalVehicleSRHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckSRHover(!ET_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setTruckSRHover(false)}>
                                            {ET_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(truckSR)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ marginLeft: "5px", color: SECyclistHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalCyclistInputSHover(!N1Way_HoverHiding)} onMouseOut={() => setTotalCyclistInputSHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(totalCyclistInputS)}
                                        </div>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SECyclistHover || totalCyclistInputSHover || totalVehicleSUHover ? TESYellow : "" }}
                                            onMouseOver={() => setCyclistSUHover(!N1Way_HoverHiding)} onMouseOut={() => setCyclistSUHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(cyclistSU)}
                                        </div>
                                        <div className={`${design.column20} ${!WT_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SECyclistHover || totalCyclistInputWHover || totalVehicleSLHover ? TESYellow : "" }}
                                            onMouseOver={() => setCyclistSLHover(!WT_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setCyclistSLHover(false)}>
                                            {WT_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(cyclistSL)}
                                        </div>
                                        <div className={`${design.column20} ${!NT_HoverHiding ? design.pointer : ""}`} style={{ color: SECyclistHover || totalCyclistInputNHover || totalVehicleSTHover ? TESYellow : "" }}
                                            onMouseOver={() => setCyclistSTHover(!NT_HoverHiding)} onMouseOut={() => setCyclistSTHover(false)}>
                                            {NT_HoverHiding ? "" : SeparatorAdder(cyclistST)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SECyclistHover || totalCyclistInputEHover || totalVehicleSRHover ? TESYellow : "" }}
                                            onMouseOver={() => setCyclistSRHover(!ET_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setCyclistSRHover(false)}>
                                            {ET_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(cyclistSR)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ marginLeft: "5px", color: SETotalHover || totalSHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalVehicleInputSHover(!N1Way_HoverHiding)} onMouseOut={() => setTotalVehicleInputSHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleInputS)}
                                        </div>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: STotalBorder, color: SETotalHover || totalVehicleInputSHover ||
                                                totalVehicleSHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleSUHover(!N1Way_HoverHiding)} onMouseOut={() => setTotalVehicleSUHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleSU)}
                                        </div>
                                        <div className={`${design.column20} ${!WT_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: "2px solid black", color: SETotalHover || totalVehicleInputWHover ||
                                                totalVehicleSHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleSLHover(!WT_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setTotalVehicleSLHover(false)}>
                                            {WT_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleSL)}
                                        </div>
                                        <div className={`${design.column20} ${!NT_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: "2px solid black", color: SETotalHover || totalVehicleInputNHover ||
                                                totalVehicleSHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleSTHover(!NT_HoverHiding)} onMouseOut={() => setTotalVehicleSTHover(false)}>
                                            {NT_HoverHiding ? "" : SeparatorAdder(totalVehicleST)}
                                        </div>
                                        <div className={`${design.column20} ${!ET_NS2Way_N1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderTop: "2px solid black", marginRight: "5px", color: SETotalHover ||
                                                totalVehicleInputEHover || totalVehicleSHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleSRHover(!ET_NS2Way_N1Way_HoverHiding)} onMouseOut={() => setTotalVehicleSRHover(false)}>
                                            {ET_NS2Way_N1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleSR)}
                                        </div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column20} style={{ marginLeft: "5px" }}>
                                            <div style={{ width: "50%" }}></div>
                                            <div style={{ width: "50%", borderBottom: STotalBorder, borderLeft: STotalBorder }}></div>
                                        </div>
                                        <div className={design.column20} style={{ borderTop: STotalBorder, borderBottom: STotalBorder }}></div>
                                        <div className={`${design.column20} ${design.secondColor}`} style={{ borderTop: STotalBorder, borderBottom: STotalBorder }}>78</div>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`} style={{ borderTop: STotalBorder, borderBottom: STotalBorder, color: totalSHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalVehicleSHover(N1Way_HoverHiding)} onMouseOut={() => setTotalVehicleSHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleS)}
                                        </div>
                                        <div className={`${design.column20} ${design.secondColor}`} style={{ borderTop: STotalBorder, marginRight: "5px" }}>80</div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column40}></div>
                                        <div className={`${design.column20} ${!N1Way_HoverHiding ? design.pointer : ""}`}
                                            onMouseOver={() => setTotalSHover(!N1Way_HoverHiding)} onMouseOut={() => setTotalSHover(false)}>
                                            {N1Way_HoverHiding ? "" : SeparatorAdder(totalS)}
                                        </div>
                                        <div className={design.column40}></div>
                                    </div>
                                </div>
                            </div>
                            {/* right Vertical border */}
                            <div style={{ height: "100%", width: "1.5%", display: "flex", flexDirection: "column" }}>
                                {/* NNEBorder */}
                                <div style={{ height: "32.333%", width: "100%", backgroundColor: NNEBlackBorder ? "black" : "white" }}></div>
                                {/* upper right square border */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: "black" }}></div>
                                {/* CEBorder */}
                                <div style={{ height: "32.333%", width: "100%", backgroundColor: CEBlackBorder ? "black" : "white" }}></div>
                                {/* lower right square border */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: "black" }}></div>
                                {/* SSEBorder */}
                                <div style={{ height: "32.333%", width: "100%", backgroundColor: SSEBlackBorder ? "black" : "white" }}></div>
                            </div>
                            {/* right column */}
                            <div className={design.mainColumns}>
                                {/* NE block */}
                                <div style={{ visibility: NEVisibility }} className={design.mainBlocks}>
                                    <div className={design.row3_16666}></div>
                                    <div className={design.row16666} style={{ paddingTop: "5px" }}>
                                        <div className={design.column4_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ visibility: NEPedVisibility, color: totalPedEHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedEUCHover(true)} onMouseOut={() => setPedEUCHover(false)}>{SeparatorAdder(pedEUC)}
                                        </div>
                                        <div className={`${design.column16666} ${design.arrows}`}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginLeft: "-15%", visibility: NEPedVisibility, color: NEPedHover ||
                                                    pedEUCHover || totalPedEHover ? TESYellow : ""
                                            }}>straight
                                            </span>
                                        </div>
                                    </div>
                                    <div className={design.row16666} style={{ paddingTop: "5px" }}>
                                        <div className={design.column4_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ visibility: NEPedVisibility, color: NEPedHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalPedEHover(true)} onMouseOut={() => setTotalPedEHover(false)}>{SeparatorAdder(totalPedE)}</div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{
                                            visibility: NEPedVisibility, color: pedEUCHover || totalPedEHover ||
                                                pedEDCHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setNEPedHover(true)} onMouseOut={() => setNEPedHover(false)}>
                                            <div className={design.legendText} style={{ marginBottom: "5px" }}>{t("ped")}</div>
                                            <div className={`${design.legendIcon} ${design.leftPad5}`}>
                                                <i className="fa-solid fa-person-walking"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={design.row16666} style={{ paddingTop: "5px" }}>
                                        <div className={design.column4_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ visibility: NEPedVisibility, color: totalPedEHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedEDCHover(true)} onMouseOut={() => setPedEDCHover(false)}>{SeparatorAdder(pedEDC)}</div>
                                        <div className={`${design.column16666} ${design.arrows}`}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginLeft: "-15%", marginBottom: "35%", transform: "rotate(180deg)",
                                                visibility: NEPedVisibility, color: NEPedHover || totalPedEHover || pedEDCHover ? TESYellow : ""
                                            }}>straight
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* NEEBorder */}
                                <div style={{ height: "1.5%", width: "100%", backgroundColor: NEEBlackBorder ? "black" : "white" }}></div>
                                {/* E block */}
                                <div style={{ visibility: EVisibility, marginTop: "5%" }} className={design.mainBlocks}>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!NT_WE2Way_W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            color: SECarHover || totalCarInputNHover ||
                                                totalVehicleERHover ? TESYellow : ""
                                        }} onMouseOver={() => setCarERHover(!NT_WE2Way_W1Way_HoverHiding)} onMouseOut={() => setCarERHover(false)}>
                                            {NT_WE2Way_W1Way_HoverHiding ? "" : SeparatorAdder(carER)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!NT_WE2Way_W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            color: SETruckHover || totalTruckInputNHover ||
                                                totalVehicleERHover ? TESYellow : ""
                                        }} onMouseOver={() => setTruckERHover(!NT_WE2Way_W1Way_HoverHiding)} onMouseOut={() => setTruckERHover(false)}>
                                            {NT_WE2Way_W1Way_HoverHiding ? "" : SeparatorAdder(truckER)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!NT_WE2Way_W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            color: SECyclistHover || totalCyclistInputNHover ||
                                                totalVehicleERHover ? TESYellow : ""
                                        }} onMouseOver={() => setCyclistERHover(!NT_WE2Way_W1Way_HoverHiding)} onMouseOut={() => setCyclistERHover(false)}>
                                            {NT_WE2Way_W1Way_HoverHiding ? "" : SeparatorAdder(cyclistER)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${!NT_WE2Way_W1Way_HoverHiding ? design.pointer : ""}`}
                                            style={{
                                                borderLeft: "2px solid black", borderRight: ETotalBorder, paddingLeft: "1.52px", color: SETotalHover || totalVehicleInputNHover ||
                                                    totalVehicleEHover ? TESYellow : ""
                                            }} onMouseOver={() => setTotalVehicleERHover(!NT_WE2Way_W1Way_HoverHiding)} onMouseOut={() => setTotalVehicleERHover(false)}>
                                            {NT_WE2Way_W1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleER)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginTop3} ${design.secondColor}`} style={{ paddingLeft: "1.52px" }}>x</div>
                                        <div className={`${design.column16666} ${design.marginTop3}`}></div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${!WT_HoverHiding ? design.pointer : ""}`} style={{ color: SECarHover || totalCarInputWHover || totalVehicleETHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarETHover(!WT_HoverHiding)} onMouseOut={() => setCarETHover(false)}>
                                            {WT_HoverHiding ? "" : SeparatorAdder(carET)}
                                        </div>
                                        <div className={`${design.column16666} ${!WT_HoverHiding ? design.pointer : ""}`} style={{ color: SETruckHover || totalTruckInputWHover || totalVehicleETHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckETHover(!WT_HoverHiding)} onMouseOut={() => setTruckETHover(false)}>
                                            {WT_HoverHiding ? "" : SeparatorAdder(truckET)}
                                        </div>
                                        <div className={`${design.column16666} ${!WT_HoverHiding ? design.pointer : ""}`} style={{ color: SECyclistHover || totalCyclistInputWHover || totalVehicleETHover ? TESYellow : "" }}
                                            onMouseOver={() => setCyclistETHover(!WT_HoverHiding)} onMouseOut={() => setCyclistETHover(false)}>
                                            {WT_HoverHiding ? "" : SeparatorAdder(cyclistET)}
                                        </div>
                                        <div className={`${design.column16666} ${!WT_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: "2px solid black", borderRight: ETotalBorder, paddingLeft: "1.52px",
                                            color: SETotalHover || totalVehicleInputWHover || totalVehicleEHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleETHover(!WT_HoverHiding)} onMouseOut={() => setTotalVehicleETHover(false)}>
                                            {WT_HoverHiding ? "" : SeparatorAdder(totalVehicleET)}
                                        </div>
                                        <div className={`${design.column16666} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{ borderRight: ETotalBorder, paddingLeft: "1.52px", color: totalEHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalVehicleEHover(!W1Way_HoverHiding)} onMouseOut={() => setTotalVehicleEHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleE)}
                                        </div>
                                        <div className={design.column16666} style={{ paddingLeft: "1.52px" }}></div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${!ST_WE2Way_W1way_HoverHiding ? design.pointer : ""}`} style={{ color: SECarHover || totalCarInputSHover || totalVehicleELHover ? TESYellow : "" }}
                                            onMouseOver={() => setCarELHover(!ST_WE2Way_W1way_HoverHiding)} onMouseOut={() => setCarELHover(false)}>
                                            {ST_WE2Way_W1way_HoverHiding ? "" : SeparatorAdder(carEL)}
                                        </div>
                                        <div className={`${design.column16666} ${!ST_WE2Way_W1way_HoverHiding ? design.pointer : ""}`} style={{ color: SETruckHover || totalTruckInputSHover || totalVehicleELHover ? TESYellow : "" }}
                                            onMouseOver={() => setTruckELHover(!ST_WE2Way_W1way_HoverHiding)} onMouseOut={() => setTruckELHover(false)}>
                                            {ST_WE2Way_W1way_HoverHiding ? "" : SeparatorAdder(truckEL)}
                                        </div>
                                        <div className={`${design.column16666} ${!ST_WE2Way_W1way_HoverHiding ? design.pointer : ""}`} style={{ color: SECyclistHover || totalCyclistInputSHover || totalVehicleELHover ? TESYellow : "" ? TESYellow : "" }}
                                            onMouseOver={() => setCyclistELHover(!ST_WE2Way_W1way_HoverHiding)} onMouseOut={() => setCyclistELHover(false)}>
                                            {ST_WE2Way_W1way_HoverHiding ? "" : SeparatorAdder(cyclistEL)}
                                        </div>
                                        <div className={`${design.column16666} ${!ST_WE2Way_W1way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: "2px solid black", borderRight: ETotalBorder, paddingLeft: "1.52px",
                                            color: SETotalHover || totalVehicleInputSHover || totalVehicleEHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleELHover(!ST_WE2Way_W1way_HoverHiding)} onMouseOut={() => setTotalVehicleELHover(false)}>
                                            {ST_WE2Way_W1way_HoverHiding ? "" : SeparatorAdder(totalVehicleEL)}
                                        </div>
                                        <div className={`${design.column16666} ${design.secondColor}`} style={{ borderRight: ETotalBorder, paddingLeft: "1.52px" }}>95</div>
                                        <div className={`${design.column16666} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{ paddingLeft: "1.52px" }}
                                            onMouseOver={() => setTotalEHover(!W1Way_HoverHiding)} onMouseOut={() => setTotalEHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(totalE)}
                                        </div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            paddingLeft: "1.52px", color: SECarHover || totalCarInputEHover ||
                                                totalVehicleEUHover ? TESYellow : ""
                                        }} onMouseOver={() => setCarEUHover(!W1Way_HoverHiding)} onMouseOut={() => setCarEUHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(carEU)}
                                        </div>
                                        <div className={`${design.column16666} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            paddingLeft: "1.52px", color: SETruckHover || totalTruckInputEHover ||
                                                totalVehicleEUHover ? TESYellow : ""
                                        }} onMouseOver={() => setTruckEUHover(!W1Way_HoverHiding)} onMouseOut={() => setTruckEUHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(truckEU)}
                                        </div>
                                        <div className={`${design.column16666} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            paddingLeft: "1.52px", color: SECyclistHover || totalCyclistInputEHover ||
                                                totalVehicleEUHover ? TESYellow : ""
                                        }} onMouseOver={() => setCyclistEUHover(!W1Way_HoverHiding)} onMouseOut={() => setCyclistEUHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(cyclistEU)}
                                        </div>
                                        <div className={`${design.column16666} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: ETotalBorder, borderRight: ETotalBorder, paddingLeft: "1.52px", marginBottom: "5px",
                                            color: SETotalHover || totalVehicleInputEHover || totalVehicleEHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalVehicleEUHover(!W1Way_HoverHiding)} onMouseOut={() => setTotalVehicleEUHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleEU)}
                                        </div>
                                        <div className={design.column16666} style={{ borderRight: ETotalBorder }}></div>
                                        <div className={design.column16666} style={{ paddingLeft: "1.52px" }}></div>
                                    </div>
                                    <div className={`${design.row20} ${design.leftPad5}`}>
                                        <div className={`${design.column16666} ${design.marginBottom3} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SECarHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalCarInputEHover(!W1Way_HoverHiding)} onMouseOut={() => setTotalCarInputEHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(totalCarInputE)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom3} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{ color: SETruckHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalTruckInputEHover(!W1Way_HoverHiding)} onMouseOut={() => setTotalTruckInputEHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(totalTruckInputE)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom3} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            paddingLeft: "1.52px",
                                            color: SECyclistHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setTotalCyclistInputEHover(!W1Way_HoverHiding)} onMouseOut={() => setTotalCyclistInputEHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(totalCyclistInputE)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom23} ${!W1Way_HoverHiding ? design.pointer : ""}`} style={{
                                            borderLeft: ETotalBorder, paddingLeft: "1.52px",
                                            color: SETotalHover || totalEHover ? TESYellow : ""
                                        }} onMouseOver={() => setTotalVehicleInputEHover(!W1Way_HoverHiding)} onMouseOut={() => setTotalVehicleInputEHover(false)}>
                                            {W1Way_HoverHiding ? "" : SeparatorAdder(totalVehicleInputE)}
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom3}`} style={{ display: "flex", flexDirection: "column" }}>
                                            <div style={{ borderRight: ETotalBorder, borderBottom: ETotalBorder, height: "100%" }}></div>
                                            <div style={{ height: "100%" }}></div>
                                        </div>
                                        <div className={`${design.column16666} ${design.marginBottom3}`}></div>
                                    </div>
                                </div>
                                {/* ESEBorder */}
                                <div style={{ height: "1.5%", width: "100%", marginTop: "-5%", backgroundColor: ESEBlackBorder ? "black" : "white" }}></div>
                                {/* SE block */}
                                <div className={design.mainBlocks} style={{ visibility: SEVisibility }}>
                                    <div className={`${design.row16666} ${design.leftPad10}`} style={{ marginTop: "5px" }}>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center", marginLeft: "-5px",
                                            color: totalCarInputNHover || totalCarInputWHover || totalCarInputSHover ||
                                                totalCarInputEHover || carSUHover || carSLHover || carSTHover || carSRHover || carERHover || carETHover || carELHover || carEUHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setSECarHover(true)} onMouseOut={() => setSECarHover(false)}>
                                            <div className={design.legendText} style={{ marginLeft: "25px" }}>{t("cars")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-car-side"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={design.column5_16666}></div>
                                    <div className={design.row16666}>
                                        <div className={design.column16666}></div>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center",
                                            color: totalTruckInputNHover || totalTruckInputWHover || totalTruckInputSHover ||
                                                totalTruckInputEHover || truckSUHover || truckSLHover || truckSTHover || truckSRHover || truckERHover || truckETHover || truckELHover || truckEUHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setSETruckHover(true)} onMouseOut={() => setSETruckHover(false)}>
                                            <div className={design.legendText}>{t("trucks")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-truck"></i>
                                            </div>
                                        </div>
                                        <div className={design.column4_16666}></div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column2_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center",
                                            color: totalCyclistInputNHover || totalCyclistInputWHover || totalCyclistInputSHover ||
                                                totalCyclistInputEHover || cyclistSUHover || cyclistSLHover || cyclistSTHover || cyclistSRHover || cyclistERHover || cyclistETHover ||
                                                cyclistELHover || cyclistEUHover ? TESYellow : ""
                                        }} onMouseOver={() => setSECyclistHover(true)} onMouseOut={() => setSECyclistHover(false)}>
                                            <div className={design.legendText}>{t("cyclists")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-person-biking"></i>
                                            </div>
                                        </div>
                                        <div className={design.column3_16666}></div>
                                    </div>
                                    <div className={design.row16666}>
                                        <div className={design.column3_16666}></div>
                                        <div className={`${design.column16666} ${design.pointer} ${design.marginBottom10}`} style={{
                                            flexDirection: "column", alignItems: "center",
                                            color: totalVehicleInputNHover || totalVehicleInputWHover || totalVehicleInputSHover ||
                                                totalVehicleInputEHover || totalVehicleSUHover || totalVehicleSLHover || totalVehicleSTHover || totalVehicleSRHover || totalVehicleSHover
                                                || totalSHover || totalVehicleERHover || totalVehicleETHover || totalVehicleELHover || totalVehicleEUHover || totalVehicleEHover || totalEHover ? TESYellow : ""
                                        }}
                                            onMouseOver={() => setSETotalHover(true)} onMouseOut={() => setSETotalHover(false)}>
                                            <div className={design.legendText}>{t("total")}</div>
                                            <div className={design.legendIcon}>
                                                <i className="fa-solid fa-plus"></i>
                                            </div>
                                        </div>
                                        <div className={design.column2_16666}></div>
                                    </div>
                                    <div className={`${design.row16666} ${design.leftPad10}`} style={{ paddingTop: "5px", visibility: SEPedVisibility }}>
                                        <div className={`${design.column16666} ${design.arrows}`} style={{ justifyContent: "center" }}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginRight: "15%", transform: "rotate(-90deg)", color: SEPedHover ||
                                                    pedSLCHover || totalPedSHover ? TESYellow : ""
                                            }}>straight</span>
                                        </div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{
                                            justifyContent: "center", alignItems: "center", color: pedSLCHover ||
                                                pedSRCHover || totalPedSHover ? TESYellow : ""
                                        }} onMouseOver={() => setSEPedHover(true)} onMouseOut={() => setSEPedHover(false)}>
                                            <div className={`${design.legendIcon} ${design.marginRight10}`}>
                                                <i className="fa-solid fa-person-walking"></i>
                                            </div>
                                            <div className={design.legendText}>{t("ped")}</div>
                                        </div>
                                        <div className={`${design.column16666} ${design.arrows}`} style={{ justifyContent: "center" }}>
                                            <span className={`${"material-icons"} ${design.arrows}`} style={{
                                                marginRight: "-15%", transform: "rotate(90deg)", color: SEPedHover ||
                                                    pedSRCHover || totalPedSHover ? TESYellow : ""
                                            }}>straight</span>
                                        </div>
                                        <div className={design.column3_16666}></div>
                                    </div>
                                    <div className={`${design.row16666} ${design.leftPad10}`} style={{ visibility: SEPedVisibility }}>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ justifyContent: "center", color: totalPedSHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedSLCHover(true)} onMouseOut={() => setPedSLCHover(false)}>{SeparatorAdder(pedSLC)}</div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ justifyContent: "center", color: SEPedHover ? TESYellow : "" }}
                                            onMouseOver={() => setTotalPedSHover(true)} onMouseOut={() => setTotalPedSHover(false)}>{SeparatorAdder(totalPedS)}</div>
                                        <div className={`${design.column16666} ${design.pointer}`} style={{ justifyContent: "center", color: totalPedSHover ? TESYellow : "" }}
                                            onMouseOver={() => setPedSRCHover(true)} onMouseOut={() => setPedSRCHover(false)}>{SeparatorAdder(pedSRC)}</div>
                                        <div className={design.column3_16666}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: "5%", display: displaySStreetName }} className={`${design.centering} ${design.legendText}`}>{props.SouthStreetName}</div>
                    </div>
                    <div style={{ width: "5%" }}>
                        <div style={{ height: "5%" }}></div>
                        <div style={{ height: "90%", width: "100%" }} className={design.centering}>
                            <label style={{ transform: "rotate(90deg)", whiteSpace: "nowrap", display: displayEStreetName }} className={design.eastWestStreets}>{props.EastStreetName}</label>
                        </div>
                        <div style={{ height: "5%" }}></div>
                    </div>
                </div>
            </ScrollView>
        </div>
    )
}

export default SectionsDiagram