//#region imports
import {
  Form,
  ScrollView,
  SelectBox,
  TagBox,
} from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo,
  Summary
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { ValidationGroup, ValidationGroupRef } from "devextreme-react/validation-group";
import { RequiredRule, Validator } from "devextreme-react/validator";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Permission from "../../../../components/permission/permision";
import getTitle from "../../../../components/title/getTitle";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useAuth } from "../../../../contexts/auth";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import {
  ModalMode,
  ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import {
  LazyLoadingRequestBase,
  NameValue,
  RequestResponseResult,
} from "../../../../types/general/generalTypes";
import {
  StudyType,
} from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { CountDuration } from "../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { OnExporting } from "../../../../utils/dataGridTools";
import { Enum2Array, EnumFlag2Array } from "../../../../utils/enumTools";
import {
  RequestErrorHandling,
  TesPost
} from "../../../../utils/rest";
import "./countDurations.scss";

//#endregion imports
const CountDurations = () => {
  //#region consts
  const [initData, setInitData] = useState<CountDuration[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<CountDuration>(new CountDuration());
  const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
  const lstDuration: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]
  const validationRef = useRef<ValidationGroupRef>(null);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  //#endregion
  //#region functions
  const title = getTitle('/trafficStudy/setups/countDurations', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstStudyType(Enum2Array(StudyType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  async function getInitData() {
    try {
      var res = await TesPost(
        TrafficApiUrl() +
        "/api/Setups/GetCustomerCountDuration",
        {
          customerId: localStorage.getItem("selectedCustomerId")
        } as LazyLoadingRequestBase,
        true
      ) as RequestResponseResult<CountDuration[]>
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }


  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new CountDuration());
    } else {
      setSelectedData(new CountDuration());
      setShowModal(false);
    }
  }
  const onValueChanged = (key: string, value: any) => {
    if (modalMode === ModalMode.Edit && key === "studyType") {
      notify(t('recordCanNotBeChanged'), "warning", 5000)
      return
    }
    setSelectedData({ ...selectedData, [key]: value });
  };

  function onEdit(d: any) {
    let newData = new CountDuration();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }
  async function onAdd() {
    try {
      const id = uuidv4()
      setSelectedData({ ...selectedData, id: id })
      const postObj = {
        customerId: localStorage.getItem("selectedCustomerId") as string,
        divisionId: selectedData.divisionId,
        studyType: selectedData.studyType,
        durations: selectedData.durations,
        id: id
      };
      if (activeLoading) activeLoading(true);
      const res: RequestResponseResult<null> = await TesPost(
        TrafficApiUrl() + "/api/Setups/AddUpdateCountDuration",
        postObj as CountDuration,
        true
      );
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        getInitData();
        onCloseModal();
        return;
      }
      else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      const postObj = {
        customerId: localStorage.getItem("selectedCustomerId") as string,
        divisionId: selectedData.divisionId,
        studyType: selectedData.studyType,
        durations: selectedData.durations,
        id: selectedData.id
      };
      if (activeLoading) activeLoading(true);
      const res: RequestResponseResult<null> = await TesPost(
        TrafficApiUrl() + "/api/Setups/AddUpdateCountDuration",
        postObj as CountDuration,
        true
      );
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        getInitData();
        onCloseModal();
        return;
      }
      else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  // async function onDelete(d: any) {
  //   let myDialog = custom({
  //     title: t("warning"),
  //     messageHtml: t("deleteWarningMessage"),
  //     buttons: [
  //       {
  //         text: t("yes"),
  //         onClick: async (e: any) => {
  //           try {

  //             let res = (await TesDelete(
  //               TrafficApiUrl() + "/api/Setups/DeleteReportingPeriod/" + d.row.data.id,

  //               true
  //             )) as RequestResponseResult<null>;
  //             if (res.responseCode === ResponseCode.OK) {
  //               await getInitData();
  //               onCloseModal();
  //               notify(t("dataSuccessfullyDeleted"), "success", 5000);
  //               if (activeLoading) activeLoading(false);
  //             } else {
  //               await RequestErrorHandling(res);;
  //             }
  //           } catch {
  //             notify(t("someErrorOccurred"), "error", 5000);
  //           }
  //           return { buttonText: e.component.option("text") };
  //         },
  //       },
  //       {
  //         text: t("no"),
  //         onClick: (e) => {
  //           return { buttonText: e.component.option("text") };
  //         },
  //       },
  //     ],
  //   });
  //   myDialog.show();
  // }
  //#endregion functions

  return (
    <Permission
      allowed={[TrafficStudyPermissions.TrafficStudy_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("countDurations")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("countDuration")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="customerId"
                  caption={t("customerId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="divisionId"
                  caption={t("divisionId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column dataField="studyType" caption={t("studyType")}
                  calculateDisplayValue={function (rowData: any) {
                    var values = EnumFlag2Array(StudyType, rowData.studyType);
                    var res = [];
                    if (values !== undefined) {
                      for (let i = 0; i < values.length; i++) {
                        res.push(StudyType[values[i]]);
                      }
                    }
                    return res.join(",");
                  }}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column dataField="durations" caption={t("durations")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    //onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Popup for add/edit */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <ValidationGroup ref={validationRef}>
                <Form colCount={2}>
                  <SimpleItem colSpan={1}>

                    <SelectBox
                      placeholder=""
                      label={t("studyType")}
                      labelMode="floating"
                      value={selectedData?.studyType}
                      onValueChange={(e) => onValueChanged("studyType", e)}
                      items={SortObjectByPropName(lstStudyType, "name")}
                      valueExpr="value"
                      displayExpr="name"
                      showClearButton={true}
                      searchEnabled={true}
                    >
                      <Validator>
                        <RequiredRule message={t("fieldIsRequired")} />
                      </Validator>
                    </SelectBox>
                  </SimpleItem>

                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("durations")}
                      labelMode='floating'
                      className="modalInput"
                      dataSource={lstDuration}
                      value={selectedData?.durations}
                      onValueChange={(e) => onValueChanged("durations", e)}
                    >
                    </TagBox>
                  </SimpleItem>
                </Form>
              </ValidationGroup>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default CountDurations;
