import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';
import { exportPivotGrid } from 'devextreme/excel_exporter';

function calculateFileTypeCell(d: any) {
    if (d && d.data && d.data.fileName) {
        const fileName = d.data.fileName;
        const lastDotIndex = fileName.lastIndexOf('.');
        
        const extension = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : fileName.toLowerCase();
        const iconSize: string = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}')).viewMode === "compact" ? "35" : "50";

        if (d.data.fileType === 4) {
            return (<img src={require('../assets/img/fileManagement/folder.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
        } else {
            if (extension === "trf") {
                return (<img src={require('../assets/img/fileManagement/trf.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            }  else if (extension === "ent") {
                return (<img src={require('../assets/img/fileManagement/ent.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "mp4") {
                return (<img src={require('../assets/img/fileManagement/mp4.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "vol") {
                return (<img src={require('../assets/img/fileManagement/vol.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "avi") {
                return (<img src={require('../assets/img/fileManagement/avi.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "flv") {
                return (<img src={require('../assets/img/fileManagement/flv.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "mkv") {
                return (<img src={require('../assets/img/fileManagement/mkv.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "mpeg") {
                return (<img src={require('../assets/img/fileManagement/mpeg.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "mpg") {
                return (<img src={require('../assets/img/fileManagement/mpg.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "vob") {
                return (<img src={require('../assets/img/fileManagement/vob.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "wmv") {
                return (<img src={require('../assets/img/fileManagement/wmv.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "bak") {
                return (<img src={require('../assets/img/fileManagement/bak.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "jpeg") {
                return (<img src={require('../assets/img/fileManagement/jpeg.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "jpg") {
                return (<img src={require('../assets/img/fileManagement/jpg.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "png") {
                return (<img src={require('../assets/img/fileManagement/png.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "bmp") {
                return (<img src={require('../assets/img/fileManagement/bmp.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "tif") {
                return (<img src={require('../assets/img/fileManagement/tif.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "jcd") {
                return (<img src={require('../assets/img/fileManagement/jcd.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "shx") {
                return (<img src={require('../assets/img/fileManagement/shx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "spd") {
                return (<img src={require('../assets/img/fileManagement/spd.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "sbx") {
                return (<img src={require('../assets/img/fileManagement/sbx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "cur") {
                return (<img src={require('../assets/img/fileManagement/cur.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "dat") {
                return (<img src={require('../assets/img/fileManagement/dat.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "sdd") {
                return (<img src={require('../assets/img/fileManagement/sdd.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "dmp") {
                return (<img src={require('../assets/img/fileManagement/dmp.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "doc") {
                return (<img src={require('../assets/img/fileManagement/doc.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "docx") {
                return (<img src={require('../assets/img/fileManagement/docx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "wmf") {
                return (<img src={require('../assets/img/fileManagement/wmf.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "exe") {
                return (<img src={require('../assets/img/fileManagement/exe.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "msi") {
                return (<img src={require('../assets/img/fileManagement/msi.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "swf") {
                return (<img src={require('../assets/img/fileManagement/swf.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "gif") {
                return (<img src={require('../assets/img/fileManagement/gif.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "atx") {
                return (<img src={require('../assets/img/fileManagement/atx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "sbn") {
                return (<img src={require('../assets/img/fileManagement/sbn.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "gz") {
                return (<img src={require('../assets/img/fileManagement/gz.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "old") {
                return (<img src={require('../assets/img/fileManagement/old.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "htm") {
                return (<img src={require('../assets/img/fileManagement/htm.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "html") {
                return (<img src={require('../assets/img/fileManagement/html.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "atr") {
                return (<img src={require('../assets/img/fileManagement/atr.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "bin") {
                return (<img src={require('../assets/img/fileManagement/bin.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "iso") {
                return (<img src={require('../assets/img/fileManagement/iso.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "cpg") {
                return (<img src={require('../assets/img/fileManagement/cpg.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "kmz") {
                return (<img src={require('../assets/img/fileManagement/kmz.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "log") {
                return (<img src={require('../assets/img/fileManagement/log.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "shp") {
                return (<img src={require('../assets/img/fileManagement/shp.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "tfr") {
                return (<img src={require('../assets/img/fileManagement/tfr.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "prj") {
                return (<img src={require('../assets/img/fileManagement/prj.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "spx") {
                return (<img src={require('../assets/img/fileManagement/spx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "new") {
                return (<img src={require('../assets/img/fileManagement/new.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "pdf") {
                return (<img src={require('../assets/img/fileManagement/pdf.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "ppt") {
                return (<img src={require('../assets/img/fileManagement/ppt.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "pptx") {
                return (<img src={require('../assets/img/fileManagement/pptx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "psd") {
                return (<img src={require('../assets/img/fileManagement/psd.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "ai") {
                return (<img src={require('../assets/img/fileManagement/ai.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "rar") {
                return (<img src={require('../assets/img/fileManagement/rar.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "7z") {
                return (<img src={require('../assets/img/fileManagement/7z.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "rmp") {
                return (<img src={require('../assets/img/fileManagement/rmp.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "pvr") {
                return (<img src={require('../assets/img/fileManagement/pvr.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "dbf") {
                return (<img src={require('../assets/img/fileManagement/dbf.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "ds-store" || extension === "ds_store") {
                return (<img src={require('../assets/img/fileManagement/ds-store.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "mdb") {
                return (<img src={require('../assets/img/fileManagement/mdb.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "cls") {
                return (<img src={require('../assets/img/fileManagement/cls.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "rst") {
                return (<img src={require('../assets/img/fileManagement/rst.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "txt") {
                return (<img src={require('../assets/img/fileManagement/txt.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "prn") {
                return (<img src={require('../assets/img/fileManagement/prn2.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "prnx") {
                return (<img src={require('../assets/img/fileManagement/prnx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "csv") {
                return (<img src={require('../assets/img/fileManagement/csv.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "xls") {
                return (<img src={require('../assets/img/fileManagement/xls.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "xlsx") {
                return (<img src={require('../assets/img/fileManagement/xlsx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "zip") {
                return (<img src={require('../assets/img/fileManagement/zip.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "xml") {
                return (<img src={require('../assets/img/fileManagement/xml.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "sbc") {
                return (<img src={require('../assets/img/fileManagement/sbc.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension === "gpx") {
                return (<img src={require('../assets/img/fileManagement/gpx.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            } else if (extension.startsWith("0")) {
                return (<img src={require('../assets/img/fileManagement/part.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            }else {
                return (<img src={require('../assets/img/fileManagement/blank.png')} alt="user prof" className=" mr-15" width={iconSize} height={iconSize} />);
            }
        }
    } else {
        // Handle the case where d, d.data, or d.data.fileName is undefined
        return null; // or any default value or UI element
    }
}

function OnExporting(e:any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
            options.excelCell.font = { name: 'Arial', size: 12 };
            options.excelCell.alignment = { horizontal: 'left' };
        } 
    }).then(function() {
        workbook.xlsx.writeBuffer()
            .then(function(buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
            });
    });
}

function onExportingPivotGrid(e:any) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportPivotGrid({
        component: e.component,
        worksheet: worksheet,
        customizeCell: function(options) {
            options.excelCell.font = { name: 'Arial', size: 12 };
            options.excelCell.alignment = { horizontal: 'left' };
        } 
    }).then(function() {
        workbook.xlsx.writeBuffer()
            .then(function(buffer) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PivotGrid.xlsx');
            });
    });
    e.cancel = true;
}

export  {calculateFileTypeCell,OnExporting,onExportingPivotGrid};