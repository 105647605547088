import DataGrid, {
    Column,
    DataGridRef,
    GroupItem,
    HeaderFilter,
    Lookup,
    Search,
    SortByGroupSummaryInfo, Summary
} from 'devextreme-react/data-grid';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MMSResStatus } from '../../../../../../types/infrastructure/enums/infrastructureEnums';
import { OnExporting } from '../../../../../../utils/dataGridTools';
import { Enum2Array } from '../../../../../../utils/enumTools';
import { SortObjectByPropName } from '../../../../../../utils/arrayTools';

// props
interface IPros {
    data: any
}
const DetailTemplate = (props: IPros) => {
    const { t } = useTranslation();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const lstStatus = Enum2Array(MMSResStatus);

    const headerFilterData = [
        {
            text: t("calculated"),
            value: ['status', '=', 1]
        },
        {
            text: t("aadtNotAvailable"),
            value: ['status', '=', 2]
        },
        {
            text: t("postedSpeedNotAvailable"),
            value: ['status', '=', 4]
        }
        , {
            text: t("aadtAndPostedSpeedNotAvailable"),
            value: ['status', '=', 6]
        }
    ];

    const headerLookup = [
        {
            name: t("calculated"),
            value: 1
        },
        {
            name: t("aadtNotAvailable"),
            value: 2
        },
        {
            name: t("postedSpeedNotAvailable"),
            value: 4
        }
        ,
        {
            name: t("aadtAndPostedSpeedNotAvailable"),
            value: 6
        }
    ];

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <DataGrid id="gridContainer"
                    ref={dataGridRef}
                    dataSource={props.data.data.mmsResultDetails}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onExporting={OnExporting}
                >
                    <SortByGroupSummaryInfo
                        summaryItem="Total Count"
                        sortOrder="desc"
                    />
                    <Summary>
                        <GroupItem
                            summaryType="count"
                            alignByColumn
                            name="Total Count"
                        />
                    </Summary>

                    <Column dataField="locationDescription" caption={t("locationDescription")} width={400}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="geoId" caption={t("geoId")} width={120}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    {/* <Column
                  dataField="municipalityId"
                  allowHeaderFiltering={true}
                  caption={t("municipality")}
                  width={170}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(initDataTesCodeValues?.municipalities, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter dataSource={initDataTesCodeValues?.municipalities?.map(x => ({ text: x.name, value: x.id }))}>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="jurisdictionId"
                  allowHeaderFiltering={true}
                  caption={t("jurisdiction")}
                  width={170}
                >
                  <Search enabled />
                  <Lookup
                    dataSource={SortObjectByPropName(initDataTesCodeValues?.jurisdictions, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                  <HeaderFilter dataSource={initDataTesCodeValues?.jurisdictions?.map(x => ({ text: x.name, value: x.id }))}>
                    <Search enabled />
                  </HeaderFilter>
                </Column> */}
                    <Column dataField="postedSpeed" caption={t("postedSpeed")} width={70}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="aadt" caption={t("aadt")} width={60}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="mms" caption={t("mms")} width={60}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="status"
                        caption={t('status')}
                    >
                        <HeaderFilter dataSource={headerFilterData}>
                            <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={headerLookup} valueExpr="value" displayExpr="name" />
                    </Column>

                </DataGrid>
            </div>
        </React.Fragment>
    );


}
export default DetailTemplate;