const reportLocations = {
  TrafficStudy_StudyDetails_TmcResults: "TrafficStudy_StudyDetails_TmcResults",
  TrafficStudy_StudyDetails_SignalWarrants: "TrafficStudy_StudyDetails_SignalWarrants",
  TrafficStudy_StudyDetails_AllWayStopWarrants: "TrafficStudy_StudyDetails_AllWayStopWarrants",
  TrafficStudy_StudyDetails_SpeedStatistics: "TrafficStudy_StudyDetails_SpeedStatistics",
  TrafficStudy_IntersectionAadtDetails_VolumeHistory: "TrafficStudy_IntersectionAadtDetails_VolumeHistory",
  TrafficStudy_RoadSegmentAadtDetails_VolumeHistory: "TrafficStudy_RoadSegmentAadtDetails_VolumeHistory",
  TrafficStudy_StudyDetails_VolumeDetails: "TrafficStudy_StudyDetails_VolumeDetails",
  TrafficStudy_StudyDetails_Speed: "TrafficStudy_StudyDetails_Speed",
  TrafficStudy_StudyDetails_TMCDetails: "TrafficStudy_StudyDetails_TMCDetails",
  TrafficStudy_IntersectionAadts: "TrafficStudy_IntersectionAadts",


  Collision_Collisions: "Collision_Collisions",
  Collision_CollisionDetails: "Collision_CollisionDetails",


  Sign_Signs: "Sign_Signs",
  Sign_Supports: "Sign_Supports",
  Sign_SignDetails: "Sign_SignDetails",
  Sign_SupportDetails: "Sign_SupportDetails",
  Maintenance_jobs: "Maintenance_jobs",
  Maintenance_jobDetails: "Maintenance_jobDetails",


  Infrastructure_Details_Collision: "Infrastructure_Details_Collision"

};

export default reportLocations;