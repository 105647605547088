import React, { useEffect, useState } from "react";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import notify from "devextreme/ui/notify";
import { Button, NumberBox, TextBox } from "devextreme-react";
import "./allWayStopWarrantSetting.scss";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import { AllWayStopWarrantsSetting } from "../../../../types/trafficStudy/trafficStudyTypes";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import Form, { SimpleItem } from "devextreme-react/form";
import { LazyLoadingRequestBase, RequestResponseResult } from "../../../../types/general/generalTypes";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import getTitle from "../../../../components/title/getTitle";

const AllWayStopWarrantSettings = () => {
  const [initData, setInitData] = useState<AllWayStopWarrantsSetting>(new AllWayStopWarrantsSetting())
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('/trafficStudy/setups/allWayStopWarrantSetting', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  async function getInitData() {
    try {
      var res = await TesPost(
        TrafficApiUrl() +
        "/api/Setups/GetCustomerAllWayStopWarrantSettings",
        {
          customerId: localStorage.getItem("selectedCustomerId")
        } as LazyLoadingRequestBase,
        true
      ) as RequestResponseResult<AllWayStopWarrantsSetting>
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  function onValueChange(name: string, value: any) {
    setInitData({ ...initData, [name]: value });
  }

  async function onUpdate() {
    console.log(initData)
    try {
      if (activeLoading) activeLoading(true);
      let postObj: AllWayStopWarrantsSetting = { ...initData, customerId: localStorage.getItem('selectedCustomerId')! }
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Setups/AddUpdateAllWayStopWarrantSettings",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.WorkOrderExist) {
        notify(t("workOrderExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`allWayStopWarrant ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("allWayStopWarrant")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div className="row" style={{ marginTop: 15 }}>
                <div className="rightColumn">
                  <Button
                    id="rowButtons"
                    onClick={() => LockHandler()}
                    icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </div>
                <div className="rightColumn">
                  {isLocked === false && (
                    <Button
                      id="rowButtons"
                      onClick={() => onUpdate()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <TextBox
                    className="firstLine"
                    label={t("name")}
                    labelMode='floating'
                    value={initData?.name}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("name", e)}
                  >
                  </TextBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="firstLine"
                    label={t('urbanArterialTotalVolumeAllLegs')}
                    labelMode='floating'
                    value={initData?.urbanArterialTotalVolumeAllLegs}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("urbanArterialTotalVolumeAllLegs", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('urbanArterialTotalVehPedVolumeCrossingMajor')}
                    labelMode='floating'
                    value={initData?.urbanArterialTotalVehPedVolumeCrossingMajor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("urbanArterialTotalVehPedVolumeCrossingMajor", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('urbanArterialTotalDelayCrossingMajor')}
                    labelMode='floating'
                    value={initData?.urbanArterialTotalDelayCrossingMajor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("urbanArterialTotalDelayCrossingMajor", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('urbanArterialSplitOnMajor4Leg')}
                    labelMode='floating'
                    value={initData?.urbanArterialSplitOnMajor4Leg}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("urbanArterialSplitOnMajor4Leg", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('urbanArterialSplitOnMajor3Leg')}
                    labelMode='floating'
                    value={initData?.urbanArterialSplitOnMajor3Leg}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("urbanArterialSplitOnMajor3Leg", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('collectorRuralArterialTotalVolumeAllLegs')}
                    labelMode='floating'
                    value={initData?.collectorRuralArterialTotalVolumeAllLegs}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("collectorRuralArterialTotalVolumeAllLegs", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('collectorRuralArterialTotalVehPedVolumeCrossingMajor')}
                    labelMode='floating'
                    value={initData?.collectorRuralArterialTotalVehPedVolumeCrossingMajor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("collectorRuralArterialTotalVehPedVolumeCrossingMajor", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('collectorRuralArterialTotalDelayCrossingMajor')}
                    labelMode='floating'
                    value={initData?.collectorRuralArterialTotalDelayCrossingMajor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("collectorRuralArterialTotalDelayCrossingMajor", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('collectorRuralArterialSplitOnMajor4Leg')}
                    labelMode='floating'
                    value={initData?.collectorRuralArterialSplitOnMajor4Leg}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("collectorRuralArterialSplitOnMajor4Leg", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('collectorRuralArterialSplitOnMajor3Leg')}
                    labelMode='floating'
                    value={initData?.collectorRuralArterialSplitOnMajor3Leg}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("collectorRuralArterialSplitOnMajor3Leg", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('localTotalVolumeAllLegs')}
                    labelMode='floating'
                    value={initData?.localTotalVolumeAllLegs}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("localTotalVolumeAllLegs", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('localTotalVehPedVolumeCrossingMajor')}
                    labelMode='floating'
                    value={initData?.localTotalVehPedVolumeCrossingMajor}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("localTotalVehPedVolumeCrossingMajor", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('localSplitOnMajor4Leg')}
                    labelMode='floating'
                    value={initData?.localSplitOnMajor4Leg}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("localSplitOnMajor4Leg", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('localSplitOnMajor3Leg')}
                    labelMode='floating'
                    value={initData?.localSplitOnMajor3Leg}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("localSplitOnMajor3Leg", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="middleLine"
                    label={t('localCollectorRuralArterialCollisionNo')}
                    labelMode='floating'
                    value={initData?.localCollectorRuralArterialCollisionNo}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("localCollectorRuralArterialCollisionNo", e)}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <NumberBox
                    format="#,###"
                    step={0}
                    className="lastLine"
                    label={t('urbanArterialCollisionNo')}
                    labelMode='floating'
                    value={initData?.urbanArterialCollisionNo}
                    disabled={isLocked}
                    onValueChange={(e) => onValueChange("urbanArterialCollisionNo", e)}
                  />
                </SimpleItem>
              </Form>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default AllWayStopWarrantSettings;
