export enum ImportCheckResponseStatus {
    Ok = 1,
    WorkOrderNotExist = 2,
    StudyAlreadyHasData = 3,
    BinNoIsNotCorrect = 4,
    FileNotFound = 5,
    BinMapNotFound = 6,
    FileBinFormatIsWrong = 7,
    FileSheetNotExist = 8,
    MinSpeedConvertingError = 9,
    MaxSpeedConvertingError = 10,
    BinMapIsEmpty = 11,
    FileFormatIsWrong = 12,
    DateFormatIsWrong = 13,
    DataIsNotUTCFormat = 14,
    SpeedIsOutOfLimit = 15, // bin map does not contain definition for that speed
    LengthIsOutOfLimit = 16, // bin map does not contain definition for that vehicle length(class)
    FileAlreadyExistInDb = 17, 
}
export enum ImportActionType {
    Ignore = 1,
    OverWriteData = 2,
    AppendData = 3,
    CreateWorkOrder = 4
}

export enum ImportStatus {
    Completed = 1,
    Error = 2,
    FileNotFound = 3,
    RawDataExist = 4,
    WorkOrderNotExist = 5,
    SpeedParseError = 6,
    DateTimePaseError = 7,
    SpeedBinNotFound = 8
}

export enum ImportRequestStatus {
    FileIsSelected = 1,
    FileCheckDone = 2,
    ImportInProgress = 3,
    ImportFailed = 4,
    ImportDone = 5,
    FileCheckFailed = 6
}
export enum RawDataDataSourceType {
    File = 0,
    MobileApp = 1,
}

export enum TrafficCounterType {
    TesBoard = 1
}
export enum BinMapClassificationType {
    AutoDetect = 0,
    HasClassificationInsideBinMapping = 1,
    HasClassificationNotInsideBinMapping = 2,
    NoClassification = 3,
}

export enum AADTQualityFactor {
    NotFound = -2,
    NoYearsAvailable = -1,
    Poor = 0,
    Fair = 1,
    Good = 2
}


export enum AADTPredictionStatus {
    Success = 1,
    OverTheLimit = 2,
    LimitNotChecked = 3,
    LessThanZero = 4,
    NotPredicted = 5,
    Failure = 6,
}
