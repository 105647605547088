import { useEffect, useState } from "react";
import { TextBox } from "devextreme-react";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { t } from "i18next";
import React from "react";
import { GeneralSetting } from "../../../../../types/setting/gridSettingTypes";
import { TesGet, TesPost } from "../../../../../utils/rest";
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { SettingApiUrl } from "../../../../../environment/routeSettings";

interface IProps {
  clientId: string | undefined
}
const URLs = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<GeneralSetting>(
    new GeneralSetting()
  );

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        SettingApiUrl() +
        "/api/ClientGeneralSettings/" +
        props.clientId,
        true
      )
    );
  }

  async function onSave() {
    try {
      const postObj = {
        ...initData,
        customerId: props.clientId,
      };
      if (activeLoading) activeLoading(true);
      await TesPost(
        SettingApiUrl() + "/api/ClientGeneralSettings",
        postObj,
        true
      );
      await getInitData();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  const onValueChanged = (key: string, value: any) => {
    setInitData({ ...initData, [key]: value });
  };

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2} className='popupFields'>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("homePageImageUrl")}
              labelMode='floating'
              value={initData.webHomeImage}
              onValueChange={(e) => onValueChanged("webHomeImage", e)}
              className="modalInput"
              style={{ marginTop: "0" }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("syncUrl")}
              labelMode='floating'
              value={initData.syncUrl}
              onValueChange={(e) => onValueChanged("syncUrl", e)}
              className="modalInput"
              style={{ marginBottom: 0 }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("syncInterval")}
              labelMode='floating'
              value={initData.syncInterval}
              onValueChange={(e) => onValueChanged("syncInterval", e)}
              className="modalInput"
              style={{ marginBottom: 0 }}
            />
          </SimpleItem>
        </Form>
        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default URLs;
