import {
  LocationType,
  SectionDetailType,
  SectionType,
} from "./../enums/infrastructureEnums";

export class VMLocation {
  [key: string]: any;
  id?: string;
  customerId?: string;
  divisionId?: string;
  latitude?: number;
  longitude?: number;
  description?: string;
  geoId?: string;
  locationType?: LocationType;
  postedSpeed?: number;
  sectionDetailType?: SectionDetailType;
  sectionType?: SectionType;
  coordinate?: VMCoordination;
  jurisdictionId?: string;
  municipalityId?: string;
}

export class VMCoordination {
  id!: string;
  longitude!: number;
  latitude!: number;
}

export class VMLstGeoId {
  intersectionGeoIds!: string[];
  midblockGeoIds!: string[];
}
export class AreaDTO {
  constructor() {
    this.locations = [];
  }
  [key: string]: any;
  id!: string;
  customerId!: string;
  name!: string;
  userId!: string;
  userIds?: string[];
  groupIds?: string[];
  locations!: VMLocation[];
  locationCount!:number;
}

export class FromMapRequestDTO {
  customerId!: string;
  geoId!: string;
  locationType!: LocationType;
}

export class SimplifiedLocationDTO {
  id?: string;
  locationDescription?: string;
  geoId?: string;
  locationType?: LocationType;
  postedSpeed?: number;
  sectionDetailType?: SectionDetailType;
  sectionType?: SectionType;
  isIntersection?: boolean;
}

export class FromMapResponseDTO {
  simplifiedLocation?: SimplifiedLocationDTO;
}
