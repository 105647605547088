import React, { useRef, useState, useEffect, useMemo } from "react";
import { useInitializeMap } from "../../../../../../components/gisMap/hooks/useInitializeMap";
import "./gisMap.scss";
import {
  adjustViewZooming,
  addClickOnMapTrigger,
  onSelectedFeature_v1,
} from "../../../../../../components/gisMap/utils";
import LoadPanel from "devextreme-react/load-panel";
import { addPopupActions } from "../../../../../../components/gisMap/utils/popupsActions";
import { StudyType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { useIntersectionLayer } from "../../../../../../components/gisMap/hooks/useIntersectionsLayer";
import { useRoadSegmentLayer } from "../../../../../../components/gisMap/hooks/useRoadSegmentLayer";
import { useCustomLayers } from "../../../../../../components/gisMap/hooks/useCustomLayers";
import { LocationType } from "../../../../../../types/infrastructure/enums/infrastructureEnums";
import { useTranslation } from "react-i18next";
import { useTrafficRoadSegmentAADTLayer } from "../../../../../../components/gisMap/hookLayers/useTrafficRoadSegmentAADTLayer";
import { useTrafficIntersectionAADTsLayer } from "../../../../../../components/gisMap/hookLayers/useTrafficIntersectionAADTsLayer";
import { useTrafficComplianceLayer } from "../../../../../../components/gisMap/hookLayers/useTrafficComplianceLayer";
import { useTrafficAverageSpeedLayer } from "../../../../../../components/gisMap/hookLayers/ueeTrafficAverageSpeedLayer";
import { useTrafficPostedSpeedLayer } from "../../../../../../components/gisMap/hookLayers/useTrafficPostedSpeedLayer";
import { useLatestSpeedStudyLayer } from "../../../../../../components/gisMap/hookLayers/useLatestSpeedStudyLayer";
import { useLatestVolumeStudyLayer } from "../../../../../../components/gisMap/hookLayers/useLatestVolumeStudyLayer";
import { useLatestTMCStudyLayer } from "../../../../../../components/gisMap/hookLayers/useLatestTMCStudyLayer";
import { useCollisionBaseOnCoordinateLayer } from "../../../../../../components/gisMap/hookLayers/useCollisionBaseOnCoordinateLayer";
import { useCollisionBaseOnLocationLayer } from "../../../../../../components/gisMap/hookLayers/useCollisionBaseOnLocationLayer";

export function GisMap_v2({
  initDataGis,
  tesModule,
  collisionToMapData,
  collisionToMapReq,
  trafficToMapData,
  trafficToMapReq,
  go2Intersection,
  go2RoadSegment,
  getLocationId,
  mapTest,
  clickedOnMap,
  shouldReCreateLayers,
}) {
  const { t } = useTranslation();

  const mapEl = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLayerLoading, setIsLayerLoading] = useState(false);

  const {
    view,
    map,
    legend,
    legendExpand,
    isInitialLoading,
    measurementWidget,
  } = useInitializeMap({
    mapEl,
    initDataGis,
    isMovingMapEnabled: false,
    hasLegend: true,
    hasLayerListWidget: true,
    hasPrintWidget: true,
    zoomBehavior: {
      isDefault: false,
      zoomOptions: {
        disableMouseWheel: true,
        disableDoubleClickZoom: true,
        disableDoubleClickWithControlPressed: false,
        disableKeyboardZoom: true,
        disablePopupZoom: true,
      },
    },
  });

  const { intersectionLayer, intersectionUId, isIntersectionLoading } =
    useIntersectionLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });
  const { roadSegmentLayer, roadSegmentUId, isRoadSegmentLoading } =
    useRoadSegmentLayer({
      view,
      map,
      mapEl,
      initDataGis,
      tesModule,
    });

  const { isCustomLayerLoading } = useCustomLayers({ map, mapEl, initDataGis });

  const preparedDataForLayer = useMemo(() => {
    console.log("!!trafficToMapData", trafficToMapData);
    return {
      roadSegmentAADT: trafficToMapData
        ? trafficToMapData.trafficLocations.filter(
            (x) => x.roadSegmentAADT != 0
          )
        : [],
      intersectionAADT: trafficToMapData
        ? trafficToMapData.trafficLocations.filter(
            (x) => x.intersectionAADT != 0
          )
        : [],
      compliance: trafficToMapData
        ? trafficToMapData.trafficLocations.filter((x) => x.compliance != 0)
        : [],
      averageSpeed: trafficToMapData ? trafficToMapData.trafficLocations : [],
      postedSpeed: trafficToMapData ? trafficToMapData.trafficLocations : [],
      latestSpeedStudy: trafficToMapData?.studies
        ? trafficToMapData.studies.filter(
            (x) => x.studyType.toString() === StudyType.Speed.toString()
          )
        : [],
      latestVolumeStudy: trafficToMapData?.studies
        ? trafficToMapData.studies.filter(
            (x) => x.studyType.toString() === StudyType.Volume.toString()
          )
        : [],
      latestTMCStudy: trafficToMapData?.studies
        ? trafficToMapData.studies.filter(
            (x) => x.studyType.toString() === StudyType.TMC.toString()
          )
        : [],
      clusterData: collisionToMapData ? collisionToMapData.geoJsonData : {},
      collisionLocations: collisionToMapData
        ? collisionToMapData.collisionLocations
        : [],
    };
  }, [collisionToMapData, trafficToMapData]);
  const showLayerData = useMemo(() => {
    return {
      roadSegmentAADT: trafficToMapReq.showRoadSegmentAADT,
      intersectionAADT: trafficToMapReq.showIntersectionAADT,
      compliance: trafficToMapReq.showCompliance,
      averageSpeed: trafficToMapReq.showAverageSpeed,
      postedSpeed: trafficToMapReq.showPostedSpeed,
      latestSpeedStudy: trafficToMapReq.showLatestSpeedStudy,
      latestVolumeStudy: trafficToMapReq.showLatestVolumeStudy,
      latestTMCStudy: trafficToMapReq.showLatestTMCStudy,
      clusterData: collisionToMapReq.showClusterData,
      collisionLocations: collisionToMapReq.showCollisionLocations,
    };
  }, [collisionToMapReq, trafficToMapReq]);

  const { isTrafficRoadSegmentAADTLayerLoading } =
    useTrafficRoadSegmentAADTLayer({
      showLayer: showLayerData.roadSegmentAADT,
      legend,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      lstStudyLocations: preparedDataForLayer.roadSegmentAADT,
      roadSegmentLayer,
    });
  const { isTrafficIntersectionAADTsLayerLoading } =
    useTrafficIntersectionAADTsLayer({
      showLayer: showLayerData.intersectionAADT,
      legend,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      lstStudyLocations: preparedDataForLayer.intersectionAADT,
      intersectionLayer,
    });
  const { isTrafficComplianceLayerLoading } = useTrafficComplianceLayer({
    showLayer: showLayerData.compliance,
    legend,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.compliance,
    roadSegmentLayer,
  });
  const { isTrafficAverageSpeedLayerLoading } = useTrafficAverageSpeedLayer({
    showLayer: showLayerData.averageSpeed,
    legend,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.averageSpeed,
    roadSegmentLayer,
  });
  const { isTrafficPostedSpeedLayerLoading } = useTrafficPostedSpeedLayer({
    showLayer: showLayerData.postedSpeed,
    legend,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.postedSpeed,
    roadSegmentLayer,
  });
  const { isTrafficSpeedStudyLayerLoading } = useLatestSpeedStudyLayer({
    showLayer: showLayerData.latestSpeedStudy,
    legend,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.latestSpeedStudy,
    roadSegmentLayer,
  });
  const { isLatestVolumeStudyLayerLoading } = useLatestVolumeStudyLayer({
    showLayer: showLayerData.latestVolumeStudy,
    legend,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.latestVolumeStudy,
    roadSegmentLayer,
  });
  const { isLatestTMCStudyLayerLoading } = useLatestTMCStudyLayer({
    showLayer: showLayerData.latestTMCStudy,
    legend,
    legendExpand,
    map,
    view,
    initDataGis,
    reCreateLayerEvenItExists: shouldReCreateLayers,
    lstStudyLocations: preparedDataForLayer.latestTMCStudy,
    intersectionLayer,
  });
  const { isCollisionBaseOnCoordinateLayerLoading } =
    useCollisionBaseOnCoordinateLayer({
      showLayer: showLayerData.clusterData,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      collisionGeoJsonData: preparedDataForLayer.clusterData,
    });
  const { isCollisionBaseOnLocationLayerLoading } =
    useCollisionBaseOnLocationLayer({
      showLayer: showLayerData.collisionLocations,
      legendExpand,
      map,
      view,
      initDataGis,
      reCreateLayerEvenItExists: shouldReCreateLayers,
      lstCollisionLocations: preparedDataForLayer.collisionLocations,
      roadSegmentLayer,
      intersectionLayer,
    });

  const onSelectedFeatureCallback = (
    graphic,
    roadSegmentUId,
    intersectionUId,
    initDataGis
  ) => {
    if (
      graphic.layer?.uid.toString().toLowerCase().trim() ===
      roadSegmentUId.toString().toLowerCase().trim()
    ) {
      getLocationId({
        customerId: localStorage.getItem("selectedCustomerId"),
        locationType: LocationType.Midblock,
        geoId: graphic.attributes[initDataGis.midblockGeoIdName],
      });
    }
    if (
      graphic.layer?.uid.toString().toLowerCase().trim() ===
      intersectionUId.toString().toLowerCase().trim()
    ) {
      getLocationId({
        customerId: localStorage.getItem("selectedCustomerId"),
        locationType: LocationType.Intersection,
        geoId: graphic.attributes[initDataGis.intersectionGeoIdName],
      });
    }
  };

  useEffect(() => {
    setIsLayerLoading(true);
    if (
      !isTrafficRoadSegmentAADTLayerLoading &&
      !isTrafficIntersectionAADTsLayerLoading &&
      !isTrafficComplianceLayerLoading &&
      !isTrafficAverageSpeedLayerLoading &&
      !isTrafficPostedSpeedLayerLoading &&
      !isTrafficSpeedStudyLayerLoading &&
      !isLatestVolumeStudyLayerLoading &&
      !isLatestTMCStudyLayerLoading &&
      !isCollisionBaseOnCoordinateLayerLoading &&
      !isCollisionBaseOnLocationLayerLoading
    ) {
      setIsLayerLoading(false);
    }
  }, [
    isTrafficRoadSegmentAADTLayerLoading,
    isTrafficIntersectionAADTsLayerLoading,
    isTrafficComplianceLayerLoading,
    isTrafficAverageSpeedLayerLoading,
    isTrafficPostedSpeedLayerLoading,
    isTrafficSpeedStudyLayerLoading,
    isLatestVolumeStudyLayerLoading,
    isLatestTMCStudyLayerLoading,
    isCollisionBaseOnCoordinateLayerLoading,
    isCollisionBaseOnLocationLayerLoading,
  ]);

  useEffect(() => {
    if (
      !isInitialLoading &&
      !isRoadSegmentLoading &&
      !isIntersectionLoading &&
      !isCustomLayerLoading &&
      view &&
      roadSegmentLayer &&
      intersectionLayer
    ) {
      adjustViewZooming({
        expand: 1.1,
        view,
        layers: [intersectionLayer, roadSegmentLayer],
        finishedZoomingCallback: () => {
          setIsLoading(false);
        },
      });
    }
  }, [
    isInitialLoading,
    isRoadSegmentLoading,
    isIntersectionLoading,
    isCustomLayerLoading,
    view,
    roadSegmentLayer,
    intersectionLayer,
  ]);

  useEffect(() => {
    if (view && intersectionLayer && roadSegmentLayer && initDataGis !== null) {
      addClickOnMapTrigger(view, clickedOnMap);

      addPopupActions({
        view,
        initDataGis,
        intersectionLayer,
        roadSegmentLayer,
        go2RoadSegment,
        go2Intersection,
      });
    }
  }, [view, intersectionLayer, roadSegmentLayer, initDataGis]);

  useEffect(() => {
    if (
      view &&
      initDataGis !== null &&
      (roadSegmentUId !== "" || intersectionUId !== "")
    ) {
      onSelectedFeature_v1(view, (graphic) =>
        onSelectedFeatureCallback(
          graphic,
          roadSegmentUId,
          intersectionUId,
          initDataGis
        )
      );
    }
  }, [view, initDataGis, roadSegmentUId, intersectionUId]);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      {isLoading && <div className={"mapLoadingContainer"}>{t("loading")}</div>}
      {isLayerLoading && (
        <div className={"mapLoadingRowContainer_fullHeight"}>
          {t("loading")}
        </div>
      )}
      <div
        ref={mapEl}
        onMouseOver={() => {
          view && view.focus();
        }}
        className={"full-Height"}
      >
        <div
          id="distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "6.1rem", marginRight: "0.9rem" }}
          title="Distance Measurement Tool"
          onClick={() => {
            measurementWidget.activeTool = "distance";
          }}
        >
          <span className="esri-icon-measure-line"></span>
        </div>

        <div
          id="clear-distance"
          className="esri-widget esri-widget--button esri-widget esri-interactive esri-ui-top-right esri-ui-corner ToolsSquareShadow"
          style={{ marginTop: "8.15rem", marginRight: "0.9rem" }}
          title="Clear Measurement Tool"
          onClick={() => {
            measurementWidget.clear();
          }}
        >
          <span className="esri-icon-trash"></span>
        </div>
        <div id="info" className="esri-widget"></div>
      </div>
    </div>
  );
}
