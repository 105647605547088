import {useEffect, useRef, useState} from "react";
import { loadModules } from "esri-loader";
import { TesMapModule } from "../../../types/general/enums/generalEnums";
import { useTranslation } from "react-i18next";
import { isLayerExist, layerSuccessfullyLoadedInDomCallback } from "../utils";
import {LocationType} from "../../../types/infrastructure/enums/infrastructureEnums";

export function useRoadSegmentLayer({
  view,
  map,
  mapEl,
  initDataGis,
  tesModule,
}) {
  const { t } = useTranslation();

  const roadSegmentLayer = useRef(null);
  const [roadSegmentUId, setRoadSegmentUId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function getRoadSegmentPopupAction() {
    let lstRoadSegmentActions = [];
    if (
      tesModule.toString() === TesMapModule.Collision.toString() ||
      tesModule.toString() === TesMapModule.Infrastructure.toString()
    ) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show all collisions",
        },
        {
          id: "new-collision-midblock",
          image: "https://staticfile.tes.ca/gisMap/plus.png",
          title: "Add new collision",
        },
      ];
    }

    if (
      tesModule.toString() === TesMapModule.CollisionDetails.toString() ||
      tesModule.toString() === TesMapModule.StudyDetails.toString() ||
      tesModule.toString() === TesMapModule.CollisionGeoCode.toString()
    ) {
      lstRoadSegmentActions = [
        {
          id: "select-roadSegment",
          image: "https://staticfile.tes.ca/gisMap/select.png",
          title: "Select This Infrastructure",
        },
      ];
    }
    if (
      tesModule.toString() ===
      TesMapModule.CollisionOverviewDashboard.toString()
    ) {
      lstRoadSegmentActions = [
        {
          id: "go-to-midblock",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show Road Segment details",
        },
      ];
    }

    if (
      tesModule.toString() === TesMapModule.Infrastructure.toString() ||
      tesModule.toString() === TesMapModule.InfrastructureDetails.toString()
    ) {
      lstRoadSegmentActions = [
        {
          id: "go-to-midblock",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Road Segment Details",
        },
        //these two not in the original code that load in modal
        {
          id: "open-google-maps",
          image: "https://staticfile.tes.ca/gisMap/googleMaps.png",
          title: "Google Maps",
        },
        {
          id: "street-view",
          image: "https://staticfile.tes.ca/gisMap/streetView.png",
          title: "Street View",
        },
      ];
    }

    if (
      tesModule.toString() === TesMapModule.Sign.toString() ||
      tesModule.toString() === TesMapModule.Support.toString()
    ) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title:
            tesModule.toString() === TesMapModule.Support.toString()
              ? t("showSupports")
              : tesModule.toString() === TesMapModule.Sign.toString()
                ? t("showSigns")
                : "",
        },
      ];
    }
    //these are new
    if (tesModule === TesMapModule.Study) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show all Studies",
        },
      ];
    }
    if (tesModule === TesMapModule.AADT) {
      lstRoadSegmentActions = [
        {
          id: "show-all-data-of-geoId",
          image: "https://staticfile.tes.ca/gisMap/midBlock.png",
          title: "Show all AADTs",
        },
      ];
    }
    return lstRoadSegmentActions;
  }
  const getRoadSegmentFieldInfos = () => {
    const layerAttributes = initDataGis?.gisLayerAttributes
    let filedInfos = [{
      fieldName: initDataGis.midblockGeoIdName,
      label: "Geo ID",
    }];
    if(layerAttributes?.length > 0) {
      layerAttributes.forEach((attribute) => {
        if(attribute.layerType === LocationType.Midblock) {
          filedInfos.push({
            fieldName: attribute.name,
            label: attribute.label,
          });
        }
      })
    }
    return filedInfos;
  }

  useEffect(() => {
    if (map && initDataGis !== null && mapEl && view) {
      if (isLayerExist(map, "road-segment-layer")) return;
      setIsLoading(true);
      loadModules(["esri/layers/FeatureLayer"]).then(([FeatureLayer]) => {
        const featureLayers = new FeatureLayer({
          title: "Road Segment",
          id: "road-segment-layer",
          copyright: "TNS",
          url: initDataGis.midblockFileAddress,
          apiKey: initDataGis.arcGisApiKey,
          minScale: 0, // Always visible
          maxScale: 0, // Always visible
          outFields: [initDataGis.midblockGeoIdName],
          popupTemplate: {
            lastEditInfoEnabled: false,
            actions: getRoadSegmentPopupAction(),
            content: [
              {
                type: "fields",
                fieldInfos: getRoadSegmentFieldInfos(),
              },
            ],
          },
          renderer: {
            type: "simple",
            symbol: {
              type: "simple-line", // Use a simple fill symbol
              color: [0, 0, 0], // Set the color to black (RGB values)
              size: 1,
            },
          },
        });

        featureLayers.load().then(() => {
          map.add(featureLayers);
        });

        roadSegmentLayer.current = featureLayers;
        setRoadSegmentUId(featureLayers.uid);

        layerSuccessfullyLoadedInDomCallback(view, featureLayers, () => {
          setIsLoading(false);
        });
      });
    }
  }, [mapEl, initDataGis, map, view]);

  return {
    roadSegmentLayer: roadSegmentLayer.current,
    roadSegmentUId: roadSegmentUId,
    isRoadSegmentLoading: isLoading,
  };
}
