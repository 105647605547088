import { LocationType } from './../../infrastructure/enums/infrastructureEnums';
import { TesSign } from './../signTypes';
import { Support } from './../supportTypes';
import { INameId } from "../../general/generalTypes";

export class VMCode {
    id!: string;
    name!: string;
    code!: string;
    dimensionId!: string;
    materialCost!: number;
    labourCost!: number;
    installationCost!: number;
}

export class GeneralSetting {
    signImagesURL!: string;
    currencySymbol!: string;
}

export class VMWebSign {
    signReflectiveCoatingTitle!: string;
    signReflectiveCoatingId!: string;
    signReflectiveRatingTitle!: string;
    signReflectiveRatingId!: string;
    dateInstalled!: Date;
    dateExpired!: Date;
    signConditionTitle!: string;
    signConditionId!: string;
    signFaceMaterialId!: string;
    retroRefTextSymbol!: number;
    signDimensionTitle!: string;
    signDimensionId!: string;
    signTypeTitle!: string;
    signDescriptionTitle!: string;
    materialCost!: number;
    labourCost!: number;
    installationCost!: number;
    height!: number;
    retroRefBackground!: number;
    retroRefTestFailed!: number;
    signFaceMaterialTitle!: string;
    signFacingDirectionTitle!: string;
    id!: string;
    customerId!: string;
    locationId!: string;
    locationDescription!: string;
    locationType!: LocationType;
    geoId!: string;
    latitude!: number;
    longitude!: number;
    signFacingDirectionId!: string;
    xCoord!: number;
    yCoord!: number;
    version!: number;
    signLocationTypeTitle!: string;
    signLocationTypeId!: string;
    signId!: string;
    supportTitle!: string;
    supportId!: string;
    signCodeTitle!: string;
    signCodeId!: string;
    totalCost!: number;
    note: string | undefined;
    statusId: string | undefined
}


export class VMAppSetups {
    signs!: TesSign[];
    [key: string]: any;
    signCode!: VMCode[];
    signDescription!: INameId[];
    signDimension!: INameId[];
    signType!: INameId[];
    signCondition!: INameId[];
    signFaceMaterial!: INameId[];
    signFacingDirection!: INameId[];
    signLocationType!: INameId[];
    signReflectiveCoating!: INameId[];
    signReflectiveRating!: INameId[];
    supports!: Support[];
    supportCode!: VMCode[];
    supportDescription!: INameId[];
    supportMaterial!: INameId[];
    supportType!: INameId[];
    supportCondition!: INameId[];
    supportLocationType!: INameId[];
    supportPosition!: INameId[];
    generalSetting!: GeneralSetting;
}

export type VMSignUpdate = {
    id?: string;
    customerId?: string;
    note?: string;
    retroRefTestFailed?: boolean;
    height?: number;
    installationCost?: number;
    labourCost?: number;
    materialCost?: number;
    signDimensionId?: string;
    retroRefBackground?: number;
    retroRefTextSymbol?: number;
    signConditionId?: string;
    deletedAttachments?: string[];
    dateExpired?: Date;
    signReflectiveRatingId?: string;
    signReflectiveCoatingId?: string;
    signFaceMaterialId?: string;
    signFacingDirectionId?: string;
    signCodeId?: string;
    supportId?: string;
    signId?: string;
    signLocationTypeId?: string;
    dateInstalled?: Date;
    assets?: string[]
}

export class VMWebSignCode {
    [key: string]: any;
    id!: string;
    customerId!: string;
    code!: string;
    index!: number;
    signTypeTitle!: string;
    signTypeId!: string;
    signDimensionTitle!: string;
    signDimensionId!: string;
    signDescriptionTitle!: string;
    signDescriptionId!: string;
    materialCost!: number;
    labourCost!: number;
    installationCost!: number;
    isDeleted!: boolean;
}

