import React, { useEffect, useState } from 'react';
import SectionsDiagram from '../../../../../../components/sectionsDiagram/sectionsDiagram';
import { SectionDetailType } from '../../../../../../types/infrastructure/enums/infrastructureEnums';
import { FlatRawDataDTO, FlatRawDataReq, StudyBaseDTO, StudyVolumeWebDetailsDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { AddDays2Date, CombineDateAndTime, CreateDateAsUTC, FormatDT, GetHourDiff } from '../../../../../../utils/dateTimeTools';
import { useAuth } from '../../../../../../contexts/auth';
import { TrafficApiUrl } from '../../../../../../environment/routeSettings';
import { RequestResponseResult } from '../../../../../../types/general/generalTypes';
import { RequestErrorHandling, TesPost } from '../../../../../../utils/rest';
import { ResponseCode } from '../../../../../../types/general/enums/generalEnums';
import { SimpleItem, Form } from 'devextreme-react/form';
import { Button, CheckBox, DateBox } from 'devextreme-react';
import { GeneralSetting } from '../../../../../../types/setting/gridSettingTypes';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import GroupedDataByTimeAndClassificationType from '../../../../../../components/volumeTimeChart/volumeTimeChart';
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Title, Export as ChartExport, Tooltip, ArgumentAxis, Export, Margin, Label, Point } from 'devextreme-react/chart';
import { FieldType } from '../../../../../../types/field/enums/fieldEnums';
import { StudyType } from '../../../../../../types/trafficStudy/enums/trafficStudyEnums';
import { SignalWarrantAnalysisDetails } from '../../../../../../types/systematicScreening/systematicScreeningTypes';

// props
interface IPros {
  initDataBase: SignalWarrantAnalysisDetails,
  generalSettings: GeneralSetting | null;
}


const Diagram = (props: IPros) => {

  const { activeLoading } = useAuth();
  const [dateRange, setDateRange] = useState<[Date, Date]>();
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const { t } = useTranslation();
  const [isCarVisible, setIsCarVisible] = useState(true);
  const [isVehicleVisible, setIsVehicleVisible] = useState(true);
  const [isCyclistVisible, setIsCyclistVisible] = useState(false);
  const [isTruckVisible, setIsTruckVisible] = useState(false);
  const [isPedestrianVisible, setIsPedestrianVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();

  useEffect(() => {
    // if (dataLoaded === false && dateRange !== undefined) {
    //   applyPeriodFilter();
    //   setDataLoaded(true);
    // }
    if (selectedDate && dateRange && selectedDate < dateRange[0]) {
      setSelectedDate(dateRange[0]);
    }
  }, [dataLoaded, dateRange]);



  function isValidDate(dateObject: Date) {
    return new Date(dateObject).toString() !== 'Invalid Date';
  }



  function customizeArgumentAxisLabels({ value }: { value: any }) {
    const date = new Date(value);
    const minutes = date.getMinutes();
    const hour = date.getHours();
    return minutes === 0 ? `${hour}:00` : '';
  }

  function customizeTooltip({ argument, seriesName, value }: { argument: any, seriesName: any, value: any }) {
    return {
      text: `${FormatDT(argument, FieldType.DateTime, true, props.generalSettings)}, ${seriesName}, ${value}`
    };
  }

  function filterChartData() {
    const groupedData = GroupedDataByTimeAndClassificationType(props.initDataBase.flatRawDatas);
    return groupedData;
  }

  //console.log("TSTST " + new Date(dateRange![0].getFullYear(), dateRange![0].getMonth(), dateRange![0].getDay() + 8))
  return (
    <React.Fragment>

      <SectionsDiagram size={900}
        NorthStreetName=""
        EastStreetName=""
        SouthStreetName=""
        WestStreetName=""
        leftHandDrive={false}
        data={props.initDataBase.flatRawDatas}
        oneDayCalendarRange={dateRange}
        generalSettings={props.generalSettings}
        sectionType={props.initDataBase.analysisResult.sectionType}
        initDataBase={new StudyBaseDTO()}
        showPeriod={false}

      />

      {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <DateBox
          label={t("date")}
          value={selectedDate || (dateRange ? dateRange[0] : undefined)}
          onValueChanged={(e) => setSelectedDate(e.value)}
          type="date"
          displayFormat={props.generalSettings?.dateFormat}
          pickerType="calendar"
          // showClearButton={true}
          min={dateRange ? dateRange[0] : undefined}
          max={dateRange ? dateRange[1] : undefined}
        />
      </div> */}

      <div style={{ marginTop: "-1rem" }}>
        <Chart id="chart" dataSource={filterChartData()}>
          <Margin top={0} bottom={10} left={20} right={20} />
          <CommonSeriesSettings argumentField="startDT" type="line" />
          <Series
            valueField="totalCountVehicle"
            name={t('total')}
            color="#F0B70D"
            axis="leftAxis"
            visible={isVehicleVisible}
          >
            <Point visible={true} symbol="circle" size={8} />
          </Series>
          <Series
            valueField="totalCountCar"
            name={t('totalCar')}
            color="#0b1d78"
            axis="leftAxis"
            visible={isCarVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>
          <Series
            valueField="totalCountCyclist"
            name={t('totalCyclist')}
            color="#f05442"
            axis="rightAxis"
            visible={isCyclistVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>
          <Series
            valueField="totalCountTruck"
            name={t('totalTruck')}
            color="#ab006f"
            axis="rightAxis"
            visible={isTruckVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>
          <Series
            valueField="totalPedestrian"
            name={t('totalPedestrian')}
            color="#5E1788"
            axis="rightAxis"
            visible={isPedestrianVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>

          <ValueAxis
            name="leftAxis"
            position="left"
            // valueType="startDT"
            showZero={true}
            valueMarginsEnabled={false}
            allowDecimals={false}
          >
            <Title text={t("totalCar/totalVolume")} />
          </ValueAxis>
          <ValueAxis
            name="rightAxis"
            position="right"
            showZero={true}
            valueMarginsEnabled={false}
            allowDecimals={false}
          >
            <Title text={t("totalCyclist/totalTruck/totalPedestrianVolume")} />
          </ValueAxis>
          <ArgumentAxis>
            <Label customizeText={customizeArgumentAxisLabels} />
          </ArgumentAxis>
          <Legend position="inside" horizontalAlignment="right" />
          <Export enabled={true} printingEnabled={false} />
          <Tooltip enabled={true} location="edge" />
          <Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip} />
        </Chart>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <CheckBox
            text={t('total')}
            value={isVehicleVisible}
            onValueChanged={(e) => setIsVehicleVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalCar')}
            value={isCarVisible}
            onValueChanged={(e) => setIsCarVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalCyclist')}
            value={isCyclistVisible}
            onValueChanged={(e) => setIsCyclistVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalTruck')}
            value={isTruckVisible}
            onValueChanged={(e) => setIsTruckVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalPedestrian')}
            value={isPedestrianVisible}
            onValueChanged={(e) => setIsPedestrianVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
        </div>
      </div>

    </React.Fragment>
  );


}
export default Diagram;