export interface IHospParams {
    [key: string]: any;
    hospitalizationId?: string;
}
export class Patient {
    id!: string;
    accidentNumber!: number;
    hospital!: string;
    patientName!: string;
    patientId!: string;
    admittedDate!: Date;
    diagnosis!: string;
    dischargedDate!: Date;
}

export class Hospitalization {
    id?: string;
    customerId?: string;
    accidentNumber!: string;
    patients!: Patient[];
}

