export enum SignSetupType {
  SignCondition = 1,
  SignFaceMaterial = 2,
  SignFacingDirection = 3,
  SignLocationType = 4,
  SignReflectiveCoating = 5,
  SignReflectiveRating = 6,
  SignType = 7,
  SignDimension = 8,
  SignDescription = 9,
}

export enum AttachmentType {
  Unknown = 0,
  Picture = 1,
  Document = 2,
  XML = 3,
}

export enum NewAttachmentType {
  compressed = 0,
  video = 1,
  location = 2,
  clone = 3,
  image = 4,
  word = 5,
  html = 6,
  note =7,
  pdf = 8,
  music = 9,
  powerpoint = 10,
  excel = 11,
  unknown = 12
}

export enum ChangeLogStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}
