//#region imports
import { Form, NumberBox, TextArea } from 'devextreme-react';
import DateBox from 'devextreme-react/date-box';
import { SimpleItem } from 'devextreme-react/form';
import { SelectBox } from 'devextreme-react/select-box';
import { Switch } from 'devextreme-react/switch';
import TextBox from 'devextreme-react/text-box';
import { ValidationGroup, ValidationGroupRef } from 'devextreme-react/validation-group';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../../../contexts/auth';
import { GeneralSetting } from '../../../../../contexts/clientSetting';
import { AuthApiUrl } from '../../../../../environment/routeSettings';
import { CustomerCollision } from '../../../../../types/collision/collisionTypes';
import { VMCollisionGroupedField, VMField } from '../../../../../types/field/dto/fieldDTO';
import { FieldType } from '../../../../../types/field/enums/fieldEnums';
import { INameId } from '../../../../../types/general/generalTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { DatePickerDateCalculator } from '../../../../../utils/dateTimeTools';
import { TesGet } from '../../../../../utils/rest';
//#endregion imports
//#region props
interface IPros {
    initDataCollision: CustomerCollision;
    initDataFields: VMCollisionGroupedField;
    validationRef: React.RefObject<ValidationGroupRef>
    generalSettings: GeneralSetting | null;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    setInitDataCollision: React.Dispatch<React.SetStateAction<any>>
    isLocked: boolean
}
//#endregion props
const Remarks = (props: IPros) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [initDataUserDivisions, setInitDataUserDivisions] = useState<INameId[]>([]);
    const { activeLoading } = useAuth();

    //#region functions
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitDataUserDivisions();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInitDataUserDivisions() {
        setInitDataUserDivisions(await TesGet(AuthApiUrl() + "/api/divisions/GetUserDivisionUI/" + localStorage.getItem('selectedCustomerId'), true));
    }
    function onChangeField(name: string, value: any, isUpperCase: boolean) {
        if (name === "divisionId") {
            props.setInitDataCollision({
                ...props.initDataCollision,
                [name]: value
            })
            props.setDataChanged(true)
        } else {
            props.setInitDataCollision({
                ...props.initDataCollision,
                remark: {
                    ...props.initDataCollision.remark,
                    [name]: isUpperCase ? value.toString().toUpperCase() : value
                }
            })
        }
        props.setDataChanged(true)
    }

    function generateRandom(name: string) {
        var d = new Date();
        const res = d.getFullYear() + String(d.getMonth() + 1).padStart(2, '0') +
            String(d.getDate()).padStart(2, '0') + String(d.getHours()).padStart(2, '0') +
            String(d.getMinutes()).padStart(2, '0') + String(d.getMinutes()).padStart(2, '0') +
            String(d.getSeconds()).padStart(2, '0') + String(d.getMilliseconds()).padStart(2, '0')
        onChangeField(name, res, false)
        return res
    }
    //#endregion functions
    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    {props.initDataFields?.remarkFields.length === 0 &&
                        <div className='row' >
                            <div className='halfColumn' >
                                <p style={{ fontWeight: 'bold' }}>{t("thereIsNoDataInThisTab")}</p>
                            </div>
                        </div>
                    }

                    <Form
                        colCount={2}>
                        {/* eslint-disable-next-line array-callback-return */}
                        {props.initDataFields && props.initDataFields.remarkFields.map((f: VMField, i: number) => {
                            if (f.fieldType === FieldType.Integration) {
                                return (
                                    <SimpleItem colSpan={1} >
                                        <TextBox
                                            label={f.labelText}
                                            labelMode='floating'
                                            key={i}
                                            value={props.initDataCollision.remark && props.initDataCollision.remark[f.name]}
                                            onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                            disabled={props.isLocked}
                                        >
                                            {f.isRequired &&
                                                <Validator>
                                                    <RequiredRule message={t('fieldIsRequired')} />
                                                </Validator>
                                            }
                                        </TextBox>
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.Number) {
                                return (
                                    <SimpleItem colSpan={1} >
                                        <NumberBox
                                            step={0}
                                            label={f.labelText}
                                            labelMode='floating'
                                            key={i}
                                            value={props.initDataCollision.remark && props.initDataCollision.remark[f.name]}
                                            onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                            disabled={props.isLocked}
                                        >
                                            {f.isRequired &&
                                                <Validator>
                                                    <RequiredRule message={t('fieldIsRequired')} />
                                                </Validator>
                                            }
                                        </NumberBox>
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.Boolean) {
                                return (
                                    <SimpleItem colSpan={1} >
                                        <div >{f.labelText}</div>
                                        <Switch
                                            style={{ marginTop: 5, fontWeight: 'bold' }}
                                            value={props.initDataCollision.remark && props.initDataCollision.remark[f.name]}
                                            onValueChange={e => onChangeField(f.name, e, false)}
                                            hint={f.labelText}
                                            disabled={props.isLocked}
                                        />
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.Date) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            type="date"
                                            label={f.labelText}
                                            onValueChange={e => onChangeField(f.name, e, false)}
                                            pickerType="calendar"
                                            placeholder={props.generalSettings?.dateFormat}
                                            displayFormat={props.generalSettings?.dateFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            value={props.initDataCollision.remark && (f.exactDT === true ? DatePickerDateCalculator(props.initDataCollision.remark[f.name]) : props.initDataCollision.remark[f.name])}
                                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                            disabled={props.isLocked}
                                            showClearButton={true}
                                        >
                                            {f.isRequired &&

                                                <Validator>
                                                    <RequiredRule message={t('fieldIsRequired')} />
                                                </Validator>
                                            }
                                        </DateBox>
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.Time) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            type="time"
                                            label={f.labelText}
                                            onValueChange={e => onChangeField(f.name, e, false)}
                                            value={props.initDataCollision.remark && (f.exactDT === true ? DatePickerDateCalculator(props.initDataCollision.remark[f.name]) : props.initDataCollision.remark[f.name])}
                                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                            pickerType="rollers"
                                            placeholder={props.generalSettings?.timeFormat}
                                            displayFormat={props.generalSettings?.timeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked}
                                            showClearButton={true}
                                        >
                                            {f.isRequired &&
                                                <Validator>
                                                    <RequiredRule message={t('fieldIsRequired')} />
                                                </Validator>
                                            }
                                        </DateBox>
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.DateTime) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            type="datetime"
                                            label={f.labelText}
                                            onValueChange={e => onChangeField(f.name, e, false)}
                                            value={props.initDataCollision.remark && (f.exactDT === true ? DatePickerDateCalculator(props.initDataCollision.remark[f.name]) : props.initDataCollision.remark[f.name])}
                                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                            pickerType="calendar"
                                            placeholder={props.generalSettings?.dateTimeFormat}
                                            displayFormat={props.generalSettings?.dateTimeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked}
                                            showClearButton={true}
                                        >
                                            {f.isRequired &&
                                                <Validator>
                                                    <RequiredRule message={t('fieldIsRequired')} />
                                                </Validator>
                                            }
                                        </DateBox>
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.List) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <SelectBox
                                            placeholder=""
                                            label={f.labelText}
                                            valueExpr='id'
                                            displayExpr='name'
                                            value={props.initDataCollision.remark && props.initDataCollision.remark[f.name]}
                                            onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                            items={SortObjectByPropName(f.fieldValues, "name")}
                                            disabled={props.isLocked}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        >
                                            {f.isRequired &&
                                                <Validator>
                                                    <RequiredRule message={t('fieldIsRequired')} />
                                                </Validator>
                                            }
                                        </SelectBox>
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.AutoGenerate) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <TextBox
                                            label={f.labelText}
                                            defaultValue={props.initDataCollision.remark != null ? props.initDataCollision.remark[f.name] : generateRandom(f.name)}
                                            disabled={props.isLocked}
                                            onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                        />
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.TextArea) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <TextArea
                                            label={f.labelText}
                                            value={props.initDataCollision.remark && props.initDataCollision.remark[f.name]}
                                            onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                            disabled={props.isLocked}
                                        >
                                            {f.isRequired &&
                                                <Validator>
                                                    <RequiredRule message={t('fieldIsRequired')} />
                                                </Validator>
                                            }
                                        </TextArea>
                                    </SimpleItem>
                                );
                            }
                        })
                        }
                    </Form>
                </ValidationGroup>

            </div>

        </React.Fragment>
    );
}
export default Remarks;