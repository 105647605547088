//imports
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  Pager,
  Paging,
  Search,
  SearchPanel,
  Selection,
  SortByGroupSummaryInfo,
  StateStoring,
  Summary
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import Permission from "../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { InfrastructureApiUrl } from "../../../environment/routeSettings";
import {
  GridType,
  TesMapModule,
} from "../../../types/general/enums/generalEnums";
import { GridSetting, LazyLoadingRequest, NameValue } from "../../../types/general/generalTypes";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { OnExporting } from "../../../utils/dataGridTools";
import { Enum2Array } from "../../../utils/enumTools";
import { TesPost } from "../../../utils/rest";

const dataSource = new CustomStore({
  key: "_id",
  load: async (loadOption) => {
    return await TesPost(
      `${InfrastructureApiUrl()
      }/api/Collisions/GetLocations/`,
      {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
        loadOptions: loadOption,
        // filter: {type: }
      } as LazyLoadingRequest,
      true
    );
  },
});

const Locations = () => {
  const [showMap, setShowMap] = useState(false);
  const history = useNavigate();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [lstLocationType, setLstLocationType] = useState<NameValue[]>([])
  const { activeLoading } = useAuth();
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  // const { generalSetting } = useClientSetting();


  useEffect(() => {
    async function fetchMyAPI() {
      try {
        setLstLocationType(Enum2Array(LocationType));

        //if (activeLoading) activeLoading(true);
        //   if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }

    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function onRowClicked(data: any) {
    if (data.data._id !== undefined) {
      if (data.data.LocationType === LocationType.Intersection) {
        history("/infrastructure/intersectionDetails/" + data.data._id);

      } else if (data.data.LocationType === LocationType.Midblock) {
        history("/infrastructure/roadSegmentDetails/" + data.data._id);

      }

    }
  }

  const { t } = useTranslation();

  return (
    <Permission
      allowed={[
        InfrastructurePermissions.Infrastructure_D_Site,
        InfrastructurePermissions.Infrastructure_R_Site,
        InfrastructurePermissions.Infrastructure_V_Site,
      ]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <h2 className={"content-block"}>{t("locations")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="leftColumn" style={{ display: "flex" }}>

                <Button onClick={() => setShowMap(true)} icon="fa-solid fa-map-location-dot" />
                <GridProfilePicker
                  customerId={localStorage.getItem("selectedCustomerId") ?? ""}
                  gridType={GridType.Location}
                  dataGridRef={dataGridRef}
                  setGridSetting={setGridSetting}
                />
              </div>

            </div>

            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={dataSource}
              rowAlternationEnabled={true}
              showBorders={true}
              onRowClick={onRowClicked}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              columnAutoWidth={gridSetting.horizontalScrolling}
            //onContentReady={e => {e.component.deselectAll()}}
            //onFilterValueChange={e => {dataGridRef?.current?.instance().deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <StateStoring
                enabled={true}
                type="localStorage"
                storageKey={GridType.Location.toString()}
                savingTimeout={500}
              />
              <SortByGroupSummaryInfo
                summaryItem="Total Count"
                sortOrder="desc"
              />
              <Summary>
                <GroupItem
                  summaryType="count"
                  alignByColumn
                  name="Total Count"
                />
              </Summary>
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
                allowSelectAll={false}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column alignment="left" dataField="GeoId"
                allowSorting={false}
                caption={t("geoId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="Description"
                allowSorting={false}
                caption={t("description")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column alignment="left" dataField="LocationType"
                allowSorting={false}
                caption={t('locationType')}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup
                  dataSource={SortObjectByPropName(lstLocationType, "name")}
                  valueExpr="value"
                  displayExpr="name"
                />
              </Column>
            </DataGrid>
          </div>
        </div>

        {/* Map Popup */}
        <Popup
          width={"50%"}
          height={"auto"}
          visible={showMap}
          resizeEnabled={true}
          showTitle={true}
          title={t("map")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowMap(false)}
        >
          <GeneralGisMapManager
            tesModule={TesMapModule.Infrastructure}
            dataGridRef={dataGridRef}
            showGis={showMap}
            setShowMap={setShowMap}
            locId="_id"
          />
        </Popup>
      </React.Fragment>
    </Permission>
  );
};
export default Locations;
