import notify from "devextreme/ui/notify"
import { LocationType } from "../types/infrastructure/enums/infrastructureEnums"
import { t } from "i18next"

const RightClickMenu = (e: any, rowId: any, url?: string, locationType?: LocationType, locationId?: string) => {
  if (e.itemData.text === "Open In New Tab" || e.itemData.text === "Open Latest Study") {
    window.open(`${url}${rowId}`, "_blank")?.focus()
  } else if (e.itemData.text === "Open Location") {
    if (locationType === undefined || locationType === null) {
      notify(t("locationTypeUndefined"), 'error', 3000)
    } else if (locationId === undefined || locationId === null) {
      notify(t("locationIdUndefined"), 'error', 3000)
    } else if (locationType === LocationType.Intersection) {
      window.open(`/infrastructure/intersectionDetails/${locationId}`, "_blank")?.focus()
    } else if (locationType === LocationType.Midblock) {
      window.open(`/infrastructure/roadSegmentDetails/${locationId}`, "_blank")?.focus()
    }
  } else if (e.itemData.text === "other") {
    console.log("onItemClick", e, "rowId", rowId)
  }
}

const SidebarContextMenu = (sidebarClickedData: any) => {
  // console.log(sidebarClickedData)
  window.open(sidebarClickedData.path, "_blank")?.focus()

}

export { RightClickMenu, SidebarContextMenu }