//imports
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterBuilderPopup,
    FilterPanel,
    FilterRow,
    Grouping,
    GroupItem,
    GroupPanel,
    HeaderFilter,
    Lookup,
    Pager,
    Paging,
    Search,
    SearchPanel,
    SortByGroupSummaryInfo,
    StateStoring,
    Summary
} from "devextreme-react/data-grid";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Permission from "../../../../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../../../../constants/Permissions";
import { useClientSetting } from "../../../../../../../contexts/clientSetting";
import { GridType } from "../../../../../../../types/general/enums/generalEnums";
import { TrafficSectionAADT } from "../../../../../../../types/trafficStudy/trafficStudyTypes";
import { SortObjectByPropName } from "../../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../../utils/dataGridTools";
import { TesCodeValue } from "../../../../../../../types/infrastructure/infrastructureTypes";
import { Enum2Array } from "../../../../../../../utils/enumTools";
import { CountCodeType, PCSPatternType } from "../../../../../../../types/trafficStudy/enums/trafficStudyEnums";

interface IPros {
    gridData: TrafficSectionAADT[]
    initDataTesCodeValues: TesCodeValue
 }

const VolumeHistory = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const { t } = useTranslation();
  const { customerDivisions } = useClientSetting();
  const lstPatternType = Enum2Array(PCSPatternType)
  const lstCountCode = Enum2Array(CountCodeType)

  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  return (
    <Permission allowed={[
        TrafficStudyPermissions.TrafficStudy_D_Study,
        TrafficStudyPermissions.TrafficStudy_E_Study,
        TrafficStudyPermissions.TrafficStudy_V_Study,
        ]}
        hasFeedBackElement={true}
      >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={props.gridData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey={GridType.MatchingVolumeHistory.toString()}
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
                <Column
                    dataField="municipalityId"
                    allowHeaderFiltering={true}
                    caption={t("municipality")}
                >
                    <Search enabled />
                    <Lookup
                        dataSource={SortObjectByPropName(props.initDataTesCodeValues?.municipalities, "name")}
                        valueExpr="id"
                        displayExpr="name"
                    />
                    <HeaderFilter dataSource={props.initDataTesCodeValues?.municipalities?.map(x => ({ text: x.name, value: x.id }))}>
                        <Search enabled />
                    </HeaderFilter>
                </Column>
                <Column
                    dataField="jurisdictionId"
                    allowHeaderFiltering={true}
                    caption={t("jurisdiction")}
                >
                    <Search enabled />
                    <Lookup
                        dataSource={SortObjectByPropName(props.initDataTesCodeValues?.jurisdictions, "name")}
                        valueExpr="id"
                        displayExpr="name"
                    />
                    <HeaderFilter dataSource={props.initDataTesCodeValues?.jurisdictions?.map(x => ({ text: x.name, value: x.id }))}>
                        <Search enabled />
                    </HeaderFilter>
                </Column>
                <Column dataField="year" caption={t("year")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="patternType"
                  allowSorting={false}
                  caption={t('patternType')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstPatternType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column
                  dataField="countCode"
                  allowSorting={false}
                  caption={t('countCode')}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstCountCode, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="aadt" caption={t("aadt")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="mpAadt" caption={t("mpAadt")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default VolumeHistory;
