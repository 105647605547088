import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../types/field/enums/fieldEnums";

export const adjustViewZooming = async ({
  expand,
  view,
  layers,
  finishedZoomingCallback = () => {},
}) => {
  try {
    await view.when();

    const extents = await Promise.all(
      layers.map((layer) => layer.queryExtent())
    );

    const combinedExtent = extents.reduce((acc, extent) => {
      return acc ? acc.union(extent.extent) : extent.extent;
    }, null);

    if (combinedExtent) {
      await view.when();
      await view.goTo(combinedExtent.expand(expand)); // Expand to add some padding
      finishedZoomingCallback(true);
    }
  } catch (error) {
    console.error("Error adjusting view:", error);
    finishedZoomingCallback(false);
  }
};

export const addPrintWidget = async (view) => {
  try {
    const [Expand, Print] = await loadModules([
      "esri/widgets/Expand",
      "esri/widgets/Print",
    ]);

    await view.when();

    if (!view.ui.find("printTools")) {
      const printWidget = new Print({
        view: view,
        printServiceUrl:
          "https://utility.arcgisonline.com/arcgis/rest/services/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task",
      });

      const printTools = new Expand({
        id: "printTools",
        content: printWidget,
        view: view,
        expanded: false,
      });

      view.ui.add(printTools, "top-right");
    } else {
      console.log("Print widget is already added to the view.");
    }
  } catch (error) {
    console.error("Error adding print feature:", error);
  }
};

export const addLayerListWidget = async (view) => {
  try {
    const [Expand, LayerList] = await loadModules([
      "esri/widgets/Expand",
      "esri/widgets/LayerList",
    ]);

    await view.when();

    if (!view.ui.find("layerList")) {
      const layerList = new Expand({
        content: new LayerList({
          view: view,
        }),
        view: view,
        expanded: false,
      });
      view.ui.add(layerList, "top-right");
    } else {
      console.log("Layer widget is already added to the view.");
    }
  } catch (error) {
    console.error("Error adding print feature:", error);
  }
};

export const addMeasurementWidget = async (view) => {
  try {
    const [Measurement] = await loadModules(["esri/widgets/Measurement"]);

    await view.when();

    if (!view.ui.find("distance")) {
      const measurementWidget = new Measurement();
      measurementWidget.view = view;
      view.ui.add(measurementWidget, "bottom-right");

      return measurementWidget;
    } else {
      console.log("measurementWidget is already added to the view.");
    }
  } catch (error) {
    console.error("Error adding measurementWidget:", error);
  }
};

export const addBasemapGalleryWidget = async (view, initDataGis) => {
  try {
    const [TileLayer, BasemapGallery, Basemap, Expand] = await loadModules([
      "esri/layers/TileLayer",
      "esri/widgets/BasemapGallery",
      "esri/Basemap",
      "esri/widgets/Expand",
    ]);

    await view.when();

    const tileLayers = initDataGis?.baseMapLayers.map((baseMapLayer) => {
      return new TileLayer({
        url: baseMapLayer.url,
        title: baseMapLayer.title,
      });
    });

    const basemapGallery = new BasemapGallery({
      view: view,
      source: {
        query: {
          title: "Canada Basemaps",
          owner: "Esri_cy_CA",
        },
        updateBasemapsCallback: function (items) {
          tileLayers.forEach((layer) => {
            let customBasemap = new Basemap({
              title: layer.title,
              baseLayers: [layer],
            });
            items.push(customBasemap);
          });

          return items;
        },
      },
    });
    const basemapExpand = new Expand({
      content: basemapGallery,
      view: view,
      expanded: false,
      expandIconClass: "esri-icon-basemap", // Optional: Change the icon
      expandTooltip: "Basemap", // Custom tooltip text
    });

    view.ui.add(basemapExpand, "bottom-right");

    setTimeout(function () {
      // Customizing the Basemap Gallery appearance (optional)
      const basemapGalleryItems = document.querySelectorAll(
        ".esri-basemap-gallery__basemap"
      );
      basemapGalleryItems.forEach(function (item) {
        const label = item.querySelector(
          ".esri-basemap-gallery__basemap-label"
        );
        if (label) {
          label.style.display = "none"; // Hide text labels
        }
        const icon = item.querySelector(
          ".esri-basemap-gallery__basemap-thumbnail"
        );
        if (icon) {
          icon.style.width = "40px"; // Adjust icon size if needed
          icon.style.height = "40px"; // Adjust icon size if needed
        }
      });
    }, 1000);
  } catch (error) {
    console.error("Error adding BasemapGallery feature:", error);
  }
};

export const customizeZoomingBehavior = async (
  view,
  options,
  withoutPermissionZoomTryingCallback = () => {}
) => {
  if (!view) return;
  await view.when();
  const {
    disableMouseWheel = true,
    disableDoubleClickZoom = true,
    disableDoubleClickWithControlPressed = true,
    disableKeyboardZoom = true,
    disablePopupZoom = true,
  } = options;

  const withoutPermissionZoomTrying = () => {
    withoutPermissionZoomTryingCallback(true);
  };

  // Remove default popup zoom actions if required, if add another popup in any layer it isn't affected
  if (disablePopupZoom) {
    view.popup.actions = view.popup.actions.filter(function (action) {
      return action.id !== "zoom-to";
    });
  }

  if (disableMouseWheel) {
    view.on("mouse-wheel", (event) => {
      if (!event.native.ctrlKey) {
        event.stopPropagation();
        withoutPermissionZoomTrying();
      } else {
        withoutPermissionZoomTryingCallback(false);
      }
    });
    view.on("key-down", function (event) {
      if (event.key === "Control") {
        withoutPermissionZoomTryingCallback(false);
      }
    });
    view.on("key-up", function () {
      view.on("mouse-wheel", function (event) {
        if (!event.native.ctrlKey) {
          event.stopPropagation();
          withoutPermissionZoomTrying();
        }
      });
    });
  }

  if (disableDoubleClickZoom) {
    view.on("double-click", (event) => {
      event.stopPropagation();
    });
  }
  if (disableDoubleClickWithControlPressed) {
    view.on("double-click", ["Control"], (event) => {
      event.stopPropagation();
    });
  }
  if (disableKeyboardZoom) {
    view.on("key-down", function (event) {
      const prohibitedKeys = ["+", "-", "Shift", "_", "="];
      if (prohibitedKeys.indexOf(event.key) !== -1) {
        event.stopPropagation();
      }
    });
  }
};

export const getAveragePaths = (paths) => {
  let sumX = 0;
  let sumY = 0;
  for (let i = 0; i < paths.length; i++) {
    sumX = sumX + paths[i][0];
    sumY = sumY + paths[i][1];
  }
  return [sumX / paths.length, sumY / paths.length];
};

export const webMercatorToLatLng = (x, y) => {
  const originShift = (2 * Math.PI * 6378137) / 2.0;
  const lng = (x / originShift) * 180.0;
  let lat = (y / originShift) * 180.0;

  lat =
    (180 / Math.PI) *
    (2 * Math.atan(Math.exp((lat * Math.PI) / 180.0)) - Math.PI / 2.0);
  return [lat, lng];
};

export const openInGoogleMap = (selectedFeature) => {
  let point = [0, 0];
  let coordinate = [0, 0];
  if (selectedFeature.geometry?.paths) {
    point = getAveragePaths(selectedFeature.geometry?.paths[0]);
    coordinate = webMercatorToLatLng(point[0], point[1]);
  } else {
    coordinate = [
      selectedFeature.geometry?.latitude,
      selectedFeature.geometry?.longitude,
    ];
  }
  window.open(
    `https://www.google.com/maps?q=${coordinate[0]},${coordinate[1]}`,
    "_blank"
  );
};

export const openStreetView = (selectedFeature) => {
  let point = [0, 0];
  let coordinate = [0, 0];
  if (selectedFeature.geometry?.paths) {
    point = getAveragePaths(selectedFeature.geometry?.paths[0]);
    coordinate = webMercatorToLatLng(point[0], point[1]);
  } else {
    coordinate = [
      selectedFeature.geometry?.latitude,
      selectedFeature.geometry?.longitude,
    ];
  }
  window.open(
    `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${coordinate[0]},${coordinate[1]}`,
    "_blank"
  );
};

export const onSelectedFeature_v1 = (view, callback) => {
  view.popup.watch("selectedFeature", (graphic) => {
    if (graphic) {
      callback(graphic);
    }
  });
};

export const isLayerExist = (map, layerId) => {
  return map.layers.some((layer) => layer.id === layerId);
};

export const removeLayer = (map, layerId) => {
  if (map) {
    const layer = map.layers?.find((x) => x.id === layerId);
    layer && map.remove(layer);
  }
};

export const getLayerByLayerId = (map, layerId) => {
  const foundLayer = map.layers.find((l) => l.id === layerId);
  return foundLayer ? foundLayer : null;
};

export const layerSuccessfullyLoadedInDomCallback = (view, layer, callback) => {
  view.on("layerview-create", (event) => {
    if (event.layer === layer) {
      callback();
    }
  });
};

export const addClickOnMapTrigger = (view, callback) => {
  view.when(() => {
    view.on("click", (event) => {
      const screenPoint = event.screenPoint;
      view.hitTest(screenPoint).then((response) => {
        if (response.results.length > 0) {
          callback(event, response.results[0]);
        }
      });
    });
  });
};

export const addDoubleClickWithControlOnMapTrigger = (view, callback) => {
  view.when(() => {
    view.on("double-click", ["Control"], function (event) {
      callback(event);
      event.stopPropagation();
    });
  });
};

export const createGraphic = (view, lat, long, id) => {
  loadModules(["esri/Graphic"], {
    css: true,
  }).then(([Graphic]) => {
    view.when(() => {
      const pointGraphic = new Graphic({
        geometry: {
          type: "point", // autocasts as new Point()
          longitude: long,
          latitude: lat,
        },
        symbol: {
          type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
          color: "#12492f",
          size: 8,
        },
        attributes: {
          id: id,
        },
      });

      view.graphics.add(pointGraphic);
    });
  });
};

export const findRoadSegments = ({
  view,
  roadSegmentLayer,
  initDataGis,
  shouldGoToFirstFoundItem,
  lstRoadSegmentGeoIds,
  setRoadSegmentHighlightedRefs,
  foundRoadSegmentLatLongCallback,
}) => {
  loadModules(["esri/core/reactiveUtils", "esri/geometry/Point"], {
    css: true,
  }).then(([reactiveUtils, Point]) => {
    if (!view || !roadSegmentLayer || lstRoadSegmentGeoIds.length === 0) return;

    const strRoadSegmentQuery = lstRoadSegmentGeoIds
      .map((s) =>
        initDataGis.midblockGeoIdType.toString() === FieldType.String.toString()
          ? `${initDataGis.midblockGeoIdName}='${s}'`
          : `${initDataGis.midblockGeoIdName}=${s}`
      )
      .join(" OR ");

    if (!strRoadSegmentQuery) {
      return;
    }
    const query = {
      outFields: ["*"],
      where: strRoadSegmentQuery,
      returnGeometry: true,
    };

    //because of querying locally, need all features appear in screen, in esri v4 api can handle it without zoom out
    roadSegmentLayer.load().then(() => {
      // Set the view extent to the data extent
      view.extent = roadSegmentLayer.fullExtent;
    });

    setTimeout(() => {
      // queries when view and layer has been loaded
      view?.whenLayerView(roadSegmentLayer).then(function (layerView) {
        reactiveUtils
          .whenOnce(() => !layerView.updating)
          .then(() => {
            layerView
              .queryFeatures(query)
              .then(function (results) {
                const graphics = results.features;

                const path = graphics[0]?.geometry?.paths[0]; // Get the first path of the polyline
                const numPoints = path.length;
                // Calculate the midpoint by averaging all x and y coordinates
                const avgX =
                  path.reduce((sum, point) => sum + point[0], 0) / numPoints;
                const avgY =
                  path.reduce((sum, point) => sum + point[1], 0) / numPoints;
                const point = new Point({
                  x: avgX,
                  y: avgY,
                  spatialReference: view.spatialReference,
                });
                foundRoadSegmentLatLongCallback({
                  lat: point.latitude,
                  long: point.longitude,
                  x: point.x,
                  y: point.y,
                });

                const highlight = layerView.highlight(graphics);
                setRoadSegmentHighlightedRefs([highlight]);

                if (shouldGoToFirstFoundItem) {
                  view.goTo(
                    {
                      target: graphics[0].geometry,
                    },
                    {
                      animate: true,
                      duration: 2000,
                      easing: "ease-out",
                    }
                  );
                }
              })
              .catch();
          });
      });
    }, 0);
  });
};

export const findIntersections = ({
  view,
  intersectionLayer,
  initDataGis,
  shouldGoToFirstFoundItem,
  lstIntersectionGeoIds,
  setIntersectionHighlightedRefs,
  foundIntersectionLatLongCallback,
}) => {
  loadModules(["esri/core/reactiveUtils"], {
    css: true,
  }).then(([reactiveUtils]) => {
    if (!view || !intersectionLayer || lstIntersectionGeoIds.length === 0)
      return;

    const strIntersectionQuery = lstIntersectionGeoIds
      .map((s) =>
        initDataGis.intersectionGeoIdType.toString() ===
        FieldType.String.toString()
          ? `${initDataGis.intersectionGeoIdName}='${s}'`
          : `${initDataGis.intersectionGeoIdName}=${s}`
      )
      .join(" OR ");

    if (!strIntersectionQuery) {
      return;
    }
    const query = {
      outFields: ["*"],
      where: strIntersectionQuery,
      returnGeometry: true,
    };

    //because of querying locally, need all features appear in screen, in esri v4 api can handle it without zoom out
    intersectionLayer.load().then(() => {
      // Set the view extent to the data extent
      view.extent = intersectionLayer.fullExtent;
    });

    setTimeout(() => {
      // queries when view and layer has been loaded
      view?.whenLayerView(intersectionLayer).then(function (layerView) {
        reactiveUtils
          .whenOnce(() => !layerView.updating)
          .then(() => {
            layerView
              .queryFeatures(query)
              .then(function (results) {
                const graphics = results.features;

                foundIntersectionLatLongCallback({
                  lat: graphics?.[0]?.geometry?.latitude,
                  long: graphics?.[0]?.geometry?.longitude,
                  x: graphics?.[0]?.geometry?.x,
                  y: graphics?.[0]?.geometry?.y,
                });

                const highlight = layerView.highlight(graphics);
                setIntersectionHighlightedRefs([highlight]);

                if (shouldGoToFirstFoundItem) {
                  view.goTo(
                    {
                      target: graphics[0].geometry,
                      zoom: 15, // Optionally set the zoom level
                    },
                    {
                      animate: true,
                      duration: 2000,
                      easing: "ease-out",
                    }
                  );
                }
              })
              .catch();
          });
      });
    }, 0);
  });
};

function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    const context = this;
    timeout = setTimeout(() => {
      return func.apply(context, args);
    }, delay);
  };
}

export function afterMapStationaryWithDebounceCallback(view, callback, delay) {
  view.watch(
    "stationary",
    debounce((isStationary) => {
      if (isStationary) {
        callback();
      }
    }, delay)
  );
}

export const generateQueryIds = (initDataGis, geoIdTypeKey, locations) => {
  return locations
    .map((loc) => {
      if (
        initDataGis?.[geoIdTypeKey].toString() === FieldType.String.toString()
      ) {
        return `'${loc.geoId}'`;
      } else if (
        initDataGis?.[geoIdTypeKey].toString() === FieldType.Number.toString()
      ) {
        return Number(loc.geoId) ? loc.geoId : 0;
      } else {
        return loc.geoId;
      }
    })
    .join(",");
};
