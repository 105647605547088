//imports
import React, { useEffect, useState } from 'react';
import TextBox from 'devextreme-react/text-box';
import { useTranslation } from "react-i18next";
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../../contexts/auth';
import { Form, SimpleItem } from 'devextreme-react/form';
import { MakeId, MakeInt, MakeSpecialChar } from '../../../utils/stringTools';
import design from './winAppKey.module.scss';
import { TesGet } from '../../../utils/rest';
import { AuthApiUrl } from '../../../environment/routeSettings';


const WindowsAppKey = () => {
    const [key, setKey] = useState<string>("");
    const { t } = useTranslation();
    const { activeLoading } = useAuth();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                // await getInitData();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getTesCode() {
        try {
            if (activeLoading) activeLoading(true);
            const data = await TesGet(AuthApiUrl() + "/api/user/getTESApplicationCode", true);
            setKey(data.code)
            if (activeLoading) activeLoading(false);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 2500);
        }
    }


    return (
        <div className={`tesWinAppKey ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <h2 className={'content-block'}>{t('windowsAppKey')}</h2>
                <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'}>
                        <Form colCount={2} className='popupFields'>
                            <SimpleItem colSpan={1}>
                                <TextBox
                                    label={t('key')}
                                    labelMode={'static'}
                                    value={key}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <i
                                    className={`${"fa-solid fa-key fa-2xl"} ${design.keyIcon} ${design.tooltip}`}
                                    onClick={() => getTesCode()}
                                >
                                    <label className={design.tooltipText}>{t("generatePassword")}</label>
                                </i>
                            </SimpleItem>
                        </Form>
                    </div>
                </div>
            </React.Fragment >
        </div>
    );
}
export default WindowsAppKey;