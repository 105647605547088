import { ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import DateBox from 'devextreme-react/date-box';
import { Form, SimpleItem } from 'devextreme-react/form';
import { TextBox } from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import UserPicker from '../../../../../../components/userPicker/userPicker';
import { useAuth } from '../../../../../../contexts/auth';
import { GeneralSetting } from '../../../../../../contexts/clientSetting';
import { TrafficApiUrl } from '../../../../../../environment/routeSettings';
import { ResponseCode } from '../../../../../../types/general/enums/generalEnums';
import { RequestResponseResult } from '../../../../../../types/general/generalTypes';
import { StudyBaseDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { IStudyParams } from '../../../../../../types/trafficStudy/trafficStudyTypes';
import { DatePickerDateCalculator } from '../../../../../../utils/dateTimeTools';
import { RequestErrorHandling, TesGet } from '../../../../../../utils/rest';
import design from "./workOrder.module.scss";


// props
interface IPros {
    params: Partial<IStudyParams>,
    initDataBase: StudyBaseDTO,
    generalSettings: GeneralSetting | null;
    setInitDataBase: React.Dispatch<React.SetStateAction<any>>
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroupRef>;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
}
const WorkOrder = (props: IPros) => {
    const { t } = useTranslation();
    const { activeLoading } = useAuth();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        async function fetchMyAPI() {
        }
        fetchMyAPI()
    }, [props.initDataBase.id]);

    function onValueChangeWorkOrder(name: string, value: any) {
        props.setInitDataBase({ ...props.initDataBase, workOrder: { ...props.initDataBase.workOrder, [name]: value } })
        props.setDataChanged(true)
    }

    useEffect(() => {
        if (props.initDataBase.workOrder.startDT?.toString().startsWith("0001") || props.initDataBase.workOrder.endDT?.toString().startsWith("0001")) {
            props.setInitDataBase({ ...props.initDataBase, workOrder: { ...props.initDataBase.workOrder, startDT: "", endDT: "" } })
        }
    }, []);

    // function onValueChangeGeneral(name: string, value: any) {
    //     props.setInitDataBase({ ...props.initDataBase, [name]: value })
    //     props.setDataChanged(true)
    // }

    async function getNewWorkOrderNo() {
        if (!props.isLocked) {
            try {
                if (activeLoading) activeLoading(true);
                var res = (await TesGet(`${TrafficApiUrl()}/api/Study/GetNewWorkOrderNo/${props.initDataBase.customerId}/${props.params.studyType}`, true)) as RequestResponseResult<number>;;
                if (res.responseCode === ResponseCode.OK) {
                    onValueChangeWorkOrder("no", res.results)
                } else {
                    await RequestErrorHandling(res);
                }
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred"), "error", 5000);

            }
        }

    }
    return (
        <div className={`studyDetails-workOrder ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <div className='row' style={{ marginTop: 20, padding: 10 }}>
                    <ValidationGroup
                        ref={props.validationRef}
                    >
                        <Form colCount={24} className='popupFields'>
                            <SimpleItem colSpan={11} >
                                <TextBox
                                    label={t('no')}
                                    labelMode='floating'
                                    value={props.initDataBase.workOrder?.no}
                                    onValueChange={(e) => onValueChangeWorkOrder("no", e)}
                                    className="modalInput"
                                    disabled={props.isLocked}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1} >
                                <i
                                    className={`${"fa-solid fa-calculator"} ${design.calculatorIcon} ${props.isLocked ? design.calculatorLocked : design.calculatorUnlocked}`}
                                    onClick={getNewWorkOrderNo}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={12} >
                                <UserPicker
                                    customerId={props.initDataBase.customerId ?? ""}
                                    label={t("user")}
                                    name="userId"
                                    title={t('assignUser')}
                                    onChangeFields={onValueChangeWorkOrder}
                                    userId={props.initDataBase.workOrder?.userId ?? ""}
                                    isLocked={props.isLocked}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={12}>
                                <DateBox
                                    type="datetime"
                                    label={t('startDT')}
                                    value={DatePickerDateCalculator(props.initDataBase.workOrder?.startDT)}
                                    onValueChange={(e) => onValueChangeWorkOrder("startDT", e)}
                                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                    pickerType="calendar"
                                    placeholder={props.generalSettings?.dateTimeFormat}
                                    displayFormat={props.generalSettings?.dateTimeFormat}
                                    useMaskBehavior={true}
                                    openOnFieldClick={true}
                                    disabled={props.isLocked}
                                    showClearButton={true}
                                >
                                </DateBox>
                            </SimpleItem>
                            <SimpleItem colSpan={12}>
                                <DateBox
                                    type="datetime"
                                    label={t('endDT')}
                                    value={DatePickerDateCalculator(props.initDataBase.workOrder?.endDT)}
                                    dateSerializationFormat={"yyyy-MM-ddTHH:mm:ss"}
                                    onValueChange={(e) => onValueChangeWorkOrder("endDT", e)}
                                    pickerType="calendar"
                                    placeholder={props.generalSettings?.dateTimeFormat}
                                    displayFormat={props.generalSettings?.dateTimeFormat}
                                    useMaskBehavior={true}
                                    openOnFieldClick={true}
                                    disabled={props.isLocked}
                                    showClearButton={true}
                                >
                                </DateBox>
                            </SimpleItem>
                        </Form>
                    </ValidationGroup>
                </div>
            </React.Fragment>
        </div>
    );


}
export default WorkOrder;