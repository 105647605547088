export enum EmailTemplateType {
  Defualt = 0,
  ResetPassword = 1,
  SetPassword = 2,
  UsernamePassword = 3,
  AccountCreated = 4,
  TrafficImportResualt = 100,
  CollisionXmlImportSuccess = 200,
  CollisionXmlExportSuccess = 201,


  GenericTemplate = 300,


}
