export enum CollisionStatus {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}
export enum ApprovalStatus {
  Submited = 1,
  ReSubmited = 2,
  Rejected = 3,
  Approved = 4,
}
export enum CollisionSeverity {
  PDO = 1,
  Injury = 2,
  Fatal = 3,
}
export enum InjuryLevel {
  Level1 = 1, //Lowest
  Level2 = 2,
  Level3 = 3, //Highest
}
export enum CollisionValidationStatus {
  Valid = 0,
  Info = 1,
  Warning = 2,
  Error = 3,
}
export enum DashboardReportType {
  Overview = 1,
  EmphasisArea = 2,
  Comparative = 3,
  HeatMap = 4,
}
export enum CollisionDiagramType {
  Cross = 1,
  T_East = 2,
  T_North = 3,
  T_West = 4,
  T_South = 5,
  RoadSegment_NS = 6,
  RoadSegment_WE = 7,
}

export enum CoordinationSourceType {
  ArcGIS = 1,
  GoogleMap = 2,
  PoliceForm = 3,
  MobileApp = 4,
  UserModified = 5,
}

export enum CollisionMapMode {
  None = -1,
  showClusterData = 1,
  showCollisionLocations = 2,
}
export enum ImportFieldCategory {
  Root = 0,
  General = 1,
  Vehicle = 2,
  Driver = 3,
  InvolvedPerson = 4,
  Location = 5,
  Road = 6,
  Light = 7,
  Environment = 8,
  Attachment = 9,
  Pedestrian = 10
}

export enum CollisionImportStatus {
  Success = 1,
  Failed = 2,
  //This imported collision is an amendment to an already existing collision in db
  IsAmendmentToDB = 3,
  SimplifiedCollisionNotCalculated = 4,
  CollisionExistsInCurrentBatch = 5,
  CollisionExistsInDB = 6,
  OrgAccNumExistInCurrentBatch = 7,
  //Imported collision is an amendment of another imported collision in the same batch
  AmendmentInCurrentBatch = 8,
  //We are not using this anymore
  AmendmentInDB = 9,
  Error = 10,
  MergedWithDb = 11,
  IsInDeletedList = 12
}

export enum GeoCodeStatus {
  NotGeoCoded = 1,
  GeoCoded = 2,
  GeoCodeTeamReviewNeeded = 3,
  GeoCodeClientReviewNeeded = 4,
  Impossible2GeoCode = 5,
  PrivateProperty = 6,
  AutomaticallyGeoCoded = 7,
  Amendment = 8,
  Delete = 9,
  Previous = 100,
}

export enum InvolvedAsType {
  Person = 1,
  Pedestrian = 2,
  Driver = 3,
  Passenger = 4,
}

export enum CollisionDataSourceType {
  TesMobileApp = 1,
  XML = 2,
  TesWinApp = 3,
  ManuallyProcessed = 4,
}
export enum CollisionExportType {
  Export = 1,
  Move = 2,
  Copy = 3,
}

export enum CollisionTransferMatchBy {
  GeoId = 1,
  TargetCloneGeoId = 2
}

export enum CollisionTesStatus {
  None = 0,
  Merged = 1,
  NoDuplicate = 2,
  ManualCheck = 3,
  ToBeDeletedFromMerge = 4,
  Error = 5,
  AmendmentInDB = 6,
  AmendmentInBatch = 7
}

export enum CollisionCleaningMode {
  MD5Exist = 1,
  OrgAccNumExist = 2,
  CleanAccNumHourDifferenceExist = 3,
  NoDuplicateFound = 4,
}

export enum CollisionPeopleInvolvement {
  Other = 1,
  Pedestrian = 2,
  Cyclist = 3,
  MotorCyclist = 4

}

export enum XMLImportAction {
  Merge = 0,
  Ignore = 1
}

export enum QueryAvgType {
  Total = 0,
  AvgYearly = 1,
}

export enum CollisionMergeAction {
  DeleteKeepFile = 0,
  NoDuplicate = 1
}
export enum CollisionSingleAction {
  Delete = 0,
  Keep = 1
}

export enum CollisionMergeStatus {
  Done = 1,
  ReviewNeeded = 2,
  None = 3
}

export enum CollisionTransferAction {
  Ignore = 1,
  Overwrite = 2
}

export enum XMLImportType {
  Ontario = 1,
  SpringField = 2
}

export enum CollisionOriginalSourceType {
  XML = 1,
  Manual = 2,
  Car = 3
}
export enum CollisionAttachmentType {
  None = 0,      // No attachments
  XML = 1,  // 1
  PDF = 2,  // 2
  JPG = 4,  // 4
  PNG = 8,  // 8
}

