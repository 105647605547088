import { DataGridRef } from 'devextreme-react/cjs/data-grid';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import { InfrastructureApiUrl } from '../../environment/routeSettings';
import { ResponseCode, TesMapModule } from '../../types/general/enums/generalEnums';
import { RequestResponseResult } from '../../types/general/generalTypes';
import { VMCoordination, VMLocation, VMLstGeoId } from '../../types/infrastructure/dto/locationdDto';
import { LocationType } from '../../types/infrastructure/enums/infrastructureEnums';
import { IInfrastructureSetting } from '../../types/infrastructure/infrastructureTypes';
import { RankingMap } from '../../types/safety/safetyTypes';
import { RequestErrorHandling, TesGet, TesPost } from '../../utils/rest';
import { GeneralGisMapNew } from './generalGisMapNew';
import {GeneralGisMap_v1} from "./generalGisMap_v1";
interface IPros {
    dataGridRef?: React.RefObject<DataGridRef<any, any>>
    showGis: boolean
    setShowMap?: React.Dispatch<React.SetStateAction<any>>;
    tesModule: TesMapModule;
    lstIntersectionGeoIdIn?: string[];
    setLstRoadSegmentGeoIdIn?: string[];
    locId: string;
    addLocationFromMap?: (lstLocation: VMLocation[]) => void;
    lstRanking?: RankingMap[];
}

const GeneralGisMapManager = (props: IPros) => {
    const [initDataGis, setInitDataGis] = useState<IInfrastructureSetting | null>(null);
    const [lstCoordinations, setLstCoordinations] = useState<VMCoordination[]>([]);
    const [specifiedInfrastructureData, setSpecifiedInfrastructureData] = useState<{geoId: string, locationType: LocationType}[]>([]);

    const { t } = useTranslation();
    const history = useNavigate();
    const { activeLoading } = useAuth();
    const componentRef = useRef(null);

    const preparedInfrastructureData = (intersectionGeoIds: string[] |undefined, roadSegmentGeoIds: string[]|undefined) => {
        let lstData: {geoId: string, locationType: LocationType}[] =[]
        if (intersectionGeoIds && intersectionGeoIds.length > 0) {
            intersectionGeoIds.forEach(x => {
                lstData.push({
                    geoId: x,
                    locationType: LocationType.Intersection
                })
            })
        }
        if(roadSegmentGeoIds && roadSegmentGeoIds.length > 0){
            roadSegmentGeoIds.forEach(x => {
                lstData.push({
                    geoId: x,
                    locationType: LocationType.Midblock
                })
            })
        }
        return lstData;
    }

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitDataGisMap();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (props.showGis) {
            fetchMyAPI()

            const handleMouseWheel = (event: any) => {
                if (event.ctrlKey) {
                    event.preventDefault();
                }
            };

            const componentElement: any = componentRef.current;
            if (componentElement != null) {
                componentElement.addEventListener('mousewheel', handleMouseWheel, { passive: false });
                return () => {
                    componentElement.removeEventListener('mousewheel', handleMouseWheel);
                };
            }

        }


        return () => {
            // clean up the map view
            setSpecifiedInfrastructureData([])
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showGis]);

    useEffect(() => {
        if (props.tesModule === TesMapModule.CollisionOverviewDashboard || props.tesModule === TesMapModule.AreaDetailsView) {
            setSpecifiedInfrastructureData(preparedInfrastructureData(props.lstIntersectionGeoIdIn,props.setLstRoadSegmentGeoIdIn))
            //console.log("LST GEO " + props.setLstRoadSegmentGeoIdIn)
        }
    }, [props.lstIntersectionGeoIdIn, props.setLstRoadSegmentGeoIdIn, props.tesModule]);

    async function getInitDataGisMap() {

        const res0 = await TesGet(InfrastructureApiUrl() + "/api/Settings/GetInfrastructureSettings/" + localStorage.getItem('selectedCustomerId'), true) as RequestResponseResult<IInfrastructureSetting>
        if (res0.responseCode === ResponseCode.OK) {
            setInitDataGis(res0.results);
            var countData = await props.dataGridRef?.current?.instance().getSelectedRowKeys();


            if ((countData?.length ?? 0) > 12000) {
                notify(t('selectCorrectNumberOfMap'), 'warning', 5000);
                if (props.setShowMap) props.setShowMap(false)
                return;
            }

            var rowData = await props.dataGridRef?.current?.instance().getSelectedRowsData();
            if (props.tesModule === TesMapModule.Collision || props.tesModule === TesMapModule.Infrastructure || props.tesModule === TesMapModule.Study || props.tesModule === TesMapModule.AADT) {
                setLstCoordinations((await rowData?.map(x => {
                    var co = new VMCoordination();
                    co.id = x._id;
                    co.latitude = x.MapLocation?.Latitude ?? 0;
                    co.longitude = x.MapLocation?.Longitude ?? 0;
                    return co;
                }) ?? []));
                var res = await TesPost(InfrastructureApiUrl() + "/api/Locations/GetLocationGeoId", rowData?.filter(x => x[props.locId] != null).map(x => x[props.locId]), true) as RequestResponseResult<VMLocation>
                if (res.responseCode === ResponseCode.OK) {
                    setSpecifiedInfrastructureData(preparedInfrastructureData(res.results.intersectionGeoIds, res.results.midblockGeoIds))
                } else {
                    await RequestErrorHandling(res);
                }
            } else if (props.tesModule === TesMapModule.Sign || props.tesModule === TesMapModule.Support) {
                setLstCoordinations((await rowData?.map(x => {
                    var co = new VMCoordination();
                    co.id = x.id;
                    co.latitude = x.latitude;
                    co.longitude = x.longitude;
                    return co;
                }) ?? []));

                var res2 = await TesPost(InfrastructureApiUrl() + "/api/Locations/GetLocationGeoId", rowData?.filter(x => x[props.locId] != null).map(x => x[props.locId]), true) as RequestResponseResult<VMLstGeoId>
                if (res2.responseCode === ResponseCode.OK) {
                    setSpecifiedInfrastructureData(preparedInfrastructureData(res2.results.intersectionGeoIds, res2.results.midblockGeoIds))
                } else {
                    await RequestErrorHandling(res2);
                }

            } else if (props.tesModule === TesMapModule.CollisionOverviewDashboard) {
              setSpecifiedInfrastructureData(preparedInfrastructureData(props.lstIntersectionGeoIdIn,props.setLstRoadSegmentGeoIdIn));
            }
        } else {
            await RequestErrorHandling(res0);
        }
    }

    async function addCollisionFromMap(geoId: string, locationType: LocationType) {
        try {
            if (activeLoading) activeLoading(true);
            var postObj: VMLocation = {
                locationType: locationType,
                geoId: geoId,
                customerId: localStorage.getItem('selectedCustomerId') ?? ""
            }
            var res = await TesPost(InfrastructureApiUrl() + "/api/Locations/GetLocationIdByGeoId", postObj, true) as RequestResponseResult<VMLocation>;
            if (res.responseCode === ResponseCode.OK) {
                history('/collision/collisionDetails/' + res.results.id);
            } else if (res.responseCode === ResponseCode.InfrastructureNotExists) {
                await RequestErrorHandling(res);;
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);;
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    function go2CollisionDetails(id: string) {
        history('/collision/collisionDetails/' + id);
    }
    async function go2Intersection(geoId: string) {
        var res = await getLocationData(geoId, LocationType.Intersection);
        if (res) {
            history('/infrastructure/intersectionDetails/' + res.id);
        }
    }
    async function go2RoadSegment(geoId: string) {
        var res = await getLocationData(geoId, LocationType.Midblock);
        if (res) {
            history('/infrastructure/roadSegmentDetails/' + res.id);
        }
    }

    async function getLocationData(geoId: string, locationType: LocationType) {
        try {
            if (activeLoading) activeLoading(true);
            var postObj: VMLocation = {
                locationType: locationType,
                geoId: geoId,
                customerId: localStorage.getItem('selectedCustomerId') ?? ""
            }
            var res = await TesPost(InfrastructureApiUrl() + "/api/Locations/GetLocationIdByGeoId", postObj, true) as RequestResponseResult<VMLocation>;
            if (res.responseCode === ResponseCode.OK) {
                return res.results
            } else if (res.responseCode === ResponseCode.InfrastructureNotExists) {
                await RequestErrorHandling(res);
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);;
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }
    function go2SignSupport(id: string) {
        if (props.tesModule === TesMapModule.Sign) {
            history('/sign/signDetails/' + id);
        }
        if (props.tesModule === TesMapModule.Support) {
            history('/sign/supportDetails/' + id);
        }
    }

    function filterByGeoId(d: any[]) {
        if (d.length > 1000) {
            notify(t("selectCorrectNumberOfInfrastructures"), "warning", 5000);
            return;
        }
        var filterData = d.map(x => x.geoId)
        props.dataGridRef?.current?.instance().deselectAll();
        props.dataGridRef?.current?.instance().beginUpdate();
        props.dataGridRef?.current?.instance().columnOption((props.tesModule === TesMapModule.Collision || props.tesModule === TesMapModule.Infrastructure) ? 'GeoId' : "geoId", 'selectedFilterOperation', "anyof");
        props.dataGridRef?.current?.instance().columnOption((props.tesModule === TesMapModule.Collision || props.tesModule === TesMapModule.Infrastructure) ? 'GeoId' : "geoId", 'filterValues', filterData);
        props.dataGridRef?.current?.instance().endUpdate();
        if (props.setShowMap) props.setShowMap(false)
    }

    function filterByLatLong(d: any) {
        props.dataGridRef?.current?.instance().deselectAll();
        props.dataGridRef?.current?.instance().beginUpdate();

        props.dataGridRef?.current?.instance().columnOption('longitude', 'selectedFilterOperation', 'between');
        props.dataGridRef?.current?.instance().columnOption('longitude', 'filterValue', [d.longitudeMin, d.longitudeMax]);
        props.dataGridRef?.current?.instance().columnOption('latitude', 'selectedFilterOperation', 'between');
        props.dataGridRef?.current?.instance().columnOption('latitude', 'filterValue', [d.latitudeMin, d.latitudeMax]);

        props.dataGridRef?.current?.instance().endUpdate();
        if (props.setShowMap) props.setShowMap(false)
    }

    function addLocationFromMap(lstData: VMLocation[]) {
        props.addLocationFromMap!(lstData);
        if (props.setShowMap) props.setShowMap(false)
    }

    return (
        <div ref={componentRef}>
            {initDataGis !== null && (

                // <GeneralGisMapNew
                //     tesModule={props.tesModule}
                //     lstRanking={props.lstRanking}
                //     initDataGis={initDataGis}
                //     lstIntersectionGeoIds={lstIntersectionGeoId}
                //     lstRoadSegmentGeoIds={lstRoadSegmentGeoId}
                //     lstLatLng={lstCoordinations}
                //     go2Collision={go2CollisionDetails}
                //     filterByGeoId={filterByGeoId}
                //     go2Intersection={go2Intersection}
                //     go2RoadSegment={go2RoadSegment}
                //     go2SignSupport={go2SignSupport}
                //     filterByLatLong={filterByLatLong}
                //     handleLocationChangeDirectly={null}
                //     selectLocation={null}
                //     addLocationFromMap={addLocationFromMap}
                //     isLocked={false}
                // />
                <GeneralGisMap_v1
                    tesModule={props.tesModule}
                    initDataGis={initDataGis}
                    specifiedInfrastructureData={specifiedInfrastructureData}
                    lstLatLng={lstCoordinations}
                    go2Collision={go2CollisionDetails}
                    filterByGeoId={filterByGeoId}
                    go2Intersection={go2Intersection}
                    go2RoadSegment={go2RoadSegment}
                    go2SignSupport={go2SignSupport}
                    filterByLatLong={filterByLatLong}
                    handleLocationChangeDirectly={null}
                    selectLocation={null}
                    addLocationFromMap={addLocationFromMap}
                />
                )
            }
        </div>)


}

export default GeneralGisMapManager;