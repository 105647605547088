import { BrowserRouter } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';

import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { ClientSettingProvider } from './contexts/clientSetting';
import { useScreenSizeClass } from './utils/media-query';
import Content from './Content';
import NotAuthenticatedContent from './NotAuthenticatedContent';

import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './dx-styles.scss';
import './themes/ourStyles/ourStyle.scss'
import './react-i18next.d'
import useClaims from './apis/claims';
import deMessages from "devextreme/localization/messages/de.json";
import frMessages from "devextreme/localization/messages/fr.json";
import { locale, loadMessages } from "devextreme/localization";
import { Cost } from './types/maintenance/maintenanceTypes';
function App() {
  const { user, loading } = useAuth();
  const { data: claims, isLoading } = useClaims();
  let nameDict = claims?.find((claim: any) => claim.type === 'name') || claims?.find((claim: any) => claim.type === 'sub');
  let username = nameDict?.value;
  let logoutUrl = claims?.find((claim: any) => claim.type === 'bff:logout_url')
  loadMessages(deMessages);
  loadMessages(frMessages);
  locale(getLocale());

  if (isLoading)
    return <LoadPanel visible={true} />;

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  // if (!user && username) {
  // //  getUserProfile!();
  // }

  if (username && user) {
    localStorage.setItem("logoutUrl", logoutUrl.value);
    return <Content />;
  }

  if (!username) {
    return <NotAuthenticatedContent />;
  }

  return <div></div>


  function getLocale() {
    const storageLocale = localStorage.getItem('TesLng');
    return storageLocale != null ? storageLocale : 'en';
  }

}

export default function TesApp() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <BrowserRouter>
      <AuthProvider>
        <NavigationProvider>
          <ClientSettingProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </ClientSettingProvider>
        </NavigationProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

