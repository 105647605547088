import { useEffect, useState } from "react";
import { TextBox } from "devextreme-react";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button } from 'devextreme-react/button';
import { t } from "i18next";
import React from "react";
import { SignSetting } from '../../../../../types/sign/signTypes'
import { TesGet, TesPost } from "../../../../../utils/rest";
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { SignApiUrl } from "../../../../../environment/routeSettings";
interface IProps {
  clientId: string | undefined
}
const Sign = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<SignSetting>(new SignSetting());

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(SignApiUrl() + "/api/signSettings/" + props.clientId, true));
  }

  async function onSave() {
    try {
      const postObj = {
        ...initData,
        customerId: props.clientId,
      };
      if (activeLoading) activeLoading(true);
      await TesPost(
        SignApiUrl() + "/api/SignSettings",
        postObj,
        true
      );
      await getInitData();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  const onValueChange = (name: string, value: any) => {
    setInitData({ ...initData, [name]: value });
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2} className='popupFields'>
          <SimpleItem colSpan={1}>
            <TextBox
              style={{ marginBottom: 20 }}
              label={t("signImagesURL")}
              labelMode='floating'
              value={initData?.signImagesURL}
              onValueChange={(e) => onValueChange("signImagesURL", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              style={{ marginBottom: 20 }}
              label={t("currencySymbol")}
              labelMode='floating'
              value={initData?.currencySymbol}
              onValueChange={(e) => onValueChange("currencySymbol", e)}
            />
          </SimpleItem>
        </Form><div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Sign;
