import i18n from "../../../../react-i18next.d";

const tabTitles = [
  {
    id: 0,
    text: i18n.t("general"),
    icon: "fa-solid fa-info",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("location"),
    icon: "fa-solid fa-map",
    content: "Work Order tab content",
  },
];

const tabTitlesWithAdjFactor = [
  {
    id: 0,
    text: i18n.t("general"),
    icon: "fa-solid fa-info",
    content: "Details tab content",
  },
  {
    id: 1,
    text: i18n.t("location"),
    icon: "fa-solid fa-map",
    content: "Work Order tab content",
  },
  {
    id: 2,
    text: i18n.t("adjustmentFactors"),
    icon: "fa-solid fa-calculator",
    content: "Work Order tab content",
  }
];

export {tabTitles, tabTitlesWithAdjFactor};
