import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../types/field/enums/fieldEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";

export const useTrafficPostedSpeedLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstStudyLocations,
  roadSegmentLayer,
  reCreateLayerEvenItExists = false,
}) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "PostedSpeedLayer")) {
          removeLayer(map, "PostedSpeedLayer");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstStudyLocations?.length !== 0 &&
      roadSegmentLayer
    ) {
      if (isLayerExist(map, "PostedSpeedLayer")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "PostedSpeedLayer");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);
      loadModules([
        "esri/layers/FeatureLayer",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
        "esri/renderers/UniqueValueRenderer",
        "esri/core/reactiveUtils",
      ]).then(
        ([
          FeatureLayer,
          Graphic,
          webMercatorUtils,
          UniqueValueRenderer,
          reactiveUtils,
        ]) => {
          if (isCancelled.current) {
            setIsLayerLoading(false);
            isUnderConstruction.current = false;
            return;
          }

          const midblockLocations = lstStudyLocations.filter(
            (loc) =>
              loc.locationType.toString() ===
                LocationType.Midblock.toString() && loc.postedSpeed > 0
          );

          if (midblockLocations.length === 0) return;

          let queryIds = generateQueryIds(
            initDataGis,
            "midblockGeoIdType",
            midblockLocations
          );

          const strRoadSegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

          const roadSegmentQuery = {
            outFields: ["*"],
            where: strRoadSegmentQuery,
            returnGeometry: true,
          };

          function getSizeByScale(scale, fieldValue) {
            switch (fieldValue) {
              case 30:
                if (scale < 72500) return "25px";
                if (scale < 288500) return "10px";
                return "1px";
              case 40:
                if (scale < 72500) return "25px";
                if (scale < 288500) return "10px";
                return "1px";
              case 50:
                if (scale < 72500) return "25px";
                if (scale < 288500) return "10px";
                return "4px";
              case 60:
                if (scale < 72500) return "25px";
                if (scale < 288500) return "12px";
                return "6px";
              case 70:
                if (scale < 72500) return "25px";
                if (scale < 288500) return "16px";
                return "8px";
              case 80:
                return "25px";
              case 90:
                return "25px";
              case 100:
                return "25px";
              case 110:
                return "25px";
              case 120:
                return "25px";
            }
          }

          function queryingAndCreateLayer() {
            setIsLayerLoading(true);
            isUnderConstruction.current = true;
            view
              ?.whenLayerView(roadSegmentLayer)
              .then((roadSegmentLayerView) => {
                reactiveUtils
                  .whenOnce(() => !roadSegmentLayerView.updating)
                  .then(() => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    roadSegmentLayerView
                      .queryFeatures(roadSegmentQuery)
                      .then((results) => {
                        console.log("!!resLength", results.features.length);
                        if (isCancelled.current || !isTheLastMovement.current) {
                          setIsLayerLoading(false);
                          isUnderConstruction.current = false;
                          return;
                        }
                        const lstNewRoadSegmentGraphics = results.features
                          .filter(
                            (feature) => feature.geometry?.paths?.length > 0
                          )
                          .map((place) => {
                            const paths = place.geometry.paths[0];
                            const [avgX, avgY] = getAveragePaths(paths);
                            // Convert to Longitude/Latitude and back to Web Mercator coordinates
                            const [lng, lat] = webMercatorUtils.xyToLngLat(
                              avgX,
                              avgY
                            );
                            const [x, y] = webMercatorUtils.lngLatToXY(
                              lng,
                              lat
                            );

                            // Find matching location's average speed
                            const matchingLocation = lstStudyLocations.find(
                              (loc) =>
                                loc.geoId.toString() ===
                                place.attributes[
                                  initDataGis.midblockGeoIdName
                                ].toString()
                            );

                            return new Graphic({
                              attributes: {
                                [initDataGis.midblockGeoIdName]:
                                  place.attributes[
                                    initDataGis.midblockGeoIdName
                                  ].toString(),
                                postedSpeed:
                                  matchingLocation?.postedSpeed.toString(),
                              },
                              geometry: {
                                type: "point",
                                x,
                                y,
                                spatialReference: {
                                  wkid: place.geometry?.spatialReference?.wkid,
                                },
                              },
                            });
                          });

                        const renderer = new UniqueValueRenderer({
                          field: "postedSpeed",
                          uniqueValueInfos: [
                            {
                              value: "30",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/30.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/30.png",
                                width: getSizeByScale(view.scale, 30),
                                height: getSizeByScale(view.scale, 30),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "40",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/40.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/40.png",
                                width: getSizeByScale(view.scale, 40),
                                height: getSizeByScale(view.scale, 40),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "50",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/50.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/50.png",
                                width: getSizeByScale(view.scale, 50),
                                height: getSizeByScale(view.scale, 50),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "60",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/60.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/60.png",
                                width: getSizeByScale(view.scale, 60),
                                height: getSizeByScale(view.scale, 60),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "70",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/70.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/70.png",
                                width: getSizeByScale(view.scale, 70),
                                height: getSizeByScale(view.scale, 70),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "80",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/80.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/80.png",
                                width: getSizeByScale(view.scale, 80),
                                height: getSizeByScale(view.scale, 80),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "90",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/90.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/90.png",
                                width: getSizeByScale(view.scale, 90),
                                height: getSizeByScale(view.scale, 90),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "100",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/100.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/100.png",
                                width: getSizeByScale(view.scale, 100),
                                height: getSizeByScale(view.scale, 100),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "110",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/110.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/110.png",
                                width: getSizeByScale(view.scale, 110),
                                height: getSizeByScale(view.scale, 110),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                            {
                              value: "120",
                              symbol: {
                                type: "picture-marker",
                                url:
                                  "http://staticfile.tes.ca/gisMap/speed/120.png" +
                                  `?v=${new Date().getTime()}`,
                                // url: "https://cloud.tes.ca/gear/staticfiles/gisMap/speed/120.png",
                                width: getSizeByScale(view.scale, 120),
                                height: getSizeByScale(view.scale, 120),
                                // width: "25px",
                                // height: "25px"
                              },
                            },
                          ],
                        });

                        const postedSpeedLayer = new FeatureLayer({
                          id: "PostedSpeedLayer",
                          title: "Posted Speed",
                          source: lstNewRoadSegmentGraphics,
                          objectIdField: "ObjectId",
                          outFields: ["*"],
                          fields: [
                            {
                              alias: "Posted Speed",
                              defaultValue: null,
                              editable: true,
                              length: -1,
                              name: "postedSpeed",
                              nullable: true,
                              type: "string",
                            },
                            {
                              alias: initDataGis.midblockGeoIdName,
                              defaultValue: null,
                              editable: true,
                              length: -1,
                              name: initDataGis.midblockGeoIdName,
                              nullable: false,
                              type: "string",
                            },
                          ],
                          // popupTemplates can still be viewed on
                          // individual features
                          popupTemplate: {
                            title: "Posted Speed",
                            content: [
                              {
                                type: "fields",
                                fieldInfos: [
                                  {
                                    fieldName: initDataGis.midblockGeoIdName,
                                    label: "Geo ID",
                                  },
                                  {
                                    fieldName: "postedSpeed",
                                    label: "Posted Speed",
                                  },
                                ],
                              },
                            ],
                          },
                          renderer: renderer,
                        });

                        postedSpeedLayer.load().then(() => {
                          if (
                            isCancelled.current ||
                            !isTheLastMovement.current
                          ) {
                            setIsLayerLoading(false);
                            isUnderConstruction.current = false;
                            return;
                          }
                          removeLayer(map, "PostedSpeedLayer");
                          map.add(postedSpeedLayer);
                          legendExpand.expanded = false;
                        });

                        view.watch("scale", (newScale) => {
                          renderer.uniqueValueInfos.forEach((info) => {
                            info.symbol.width = getSizeByScale(
                              newScale,
                              Number(info.value)
                            );
                            info.symbol.height = getSizeByScale(
                              newScale,
                              Number(info.value)
                            );
                          });

                          postedSpeedLayer.renderer = renderer;
                        });

                        layerSuccessfullyLoadedInDomCallback(
                          view,
                          postedSpeedLayer,
                          () => {
                            setIsLayerLoading(false);
                            isUnderConstruction.current = false;
                          }
                        );
                      });
                  });
              });
          }
          view.when(() => {
            if (isCancelled.current) {
              setIsLayerLoading(false);
              return;
            }
            if (
              isLayerExist(map, "PostedSpeedLayer") ||
              isUnderConstruction.current
            ) {
              return;
            }
            queryingAndCreateLayer();
          });
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              if (isLayerExist(map, "PostedSpeedLayer")) {
                isTheLastMovement.current = false;
              }
            },
            1
          );
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              isTheLastMovement.current = true;
              if (!isCancelled.current && !isUnderConstruction.current)
                queryingAndCreateLayer();
            },
            700
          );
        }
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstStudyLocations,
    roadSegmentLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isTrafficPostedSpeedLayerLoading: isLayerLoading,
  };
};
