import { Button, ContextMenu, DataGrid, Popup, ScrollView, SelectBox, TextBox, Validator } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { Column, ColumnChooser, DataGridRef, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupPanel, HeaderFilter, Lookup, Pager, Paging, Search, SearchPanel } from "devextreme-react/data-grid";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permission from "../../../../../../components/permission/permision";
import SafetyPermissions from "../../../../../../constants/Permissions/SafetyPermissions";
import { useAuth } from "../../../../../../contexts/auth";
import { SafetyApiUrl } from "../../../../../../environment/routeSettings";
import { ModalMode } from "../../../../../../types/general/enums/generalEnums";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { SiteTypeDTO } from "../../../../../../types/safety/dto/safetyDtos";
import { SiteSubTypeAnalysisType, SiteSubTypeServerityType } from "../../../../../../types/safety/enums/siteTypeEnums";
import { ISiteTypeParam, SiteSubType, SpfFunction } from "../../../../../../types/safety/safetyTypes";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { TesGet } from "../../../../../../utils/rest";
import { RightClickMenu } from "../../../../../../utils/rightClickMenu";

// props
interface IPros {
  initData: SiteTypeDTO;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  params: Readonly<Partial<ISiteTypeParam>>;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
}

const SiteSubTypes = (props: IPros) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<SiteSubType>(new SiteSubType());
  const [lstAnalysisType, setAnalysisLstType] = useState<NameValue[]>([]);
  const [lstSeverityType, setLstSeverityType] = useState<NameValue[]>([]);
  const [SPFFunctions, setSPFFunctions] = useState<SpfFunction[]>([new SpfFunction()]);
  const [isRendered, setIsRendered] = useState<boolean>(true);
  const validationRef = useRef<ValidationGroupRef>(null);
  const { activeLoading } = useAuth();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (isRendered) {
          setAnalysisLstType(Enum2Array(SiteSubTypeAnalysisType));
          setLstSeverityType(Enum2Array(SiteSubTypeServerityType));
          getSPFFunctions();
        }
        setIsRendered(false);
      } catch (ex) {
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRendered]);


  async function getSPFFunctions() {
    setSPFFunctions(await TesGet(SafetyApiUrl() + "/api/Settings/GetCustomerSpfFunction/" + localStorage.getItem("selectedCustomerId"), true));
  }
  function onValueChange(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value })
    props.setDataChanged(true)
  }

  function onNew() {
    history("/safety/setups/siteSubTypeDetails/" + props.params.siteTypeId + "/AddNew");
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new SiteSubType());
    } else {
      setSelectedData(new SiteSubType());
      setShowModal(false);
    }
  }

  async function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {
        dataGridRef.current?.instance().refresh()
        onCloseModal()
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onEdit(d: any) {
    let newData = new SiteSubType();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  function onUpdate(d: any) {
    history("/safety/setups/siteSubTypeDetails/" + d.data._id);
  }

  function onRowClick(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(`/safety/setups/siteSubTypeDetails/${props.params.siteTypeId}/${e.data.id}`, "_blank");
    } else {
      history(`/safety/setups/siteSubTypeDetails/${props.params.siteTypeId}/${e.data.id}`);
    }
  }

  return (
    <Permission allowed={[SafetyPermissions.Safety_D_Setups]} hasFeedBackElement={true}>
      <React.Fragment>
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, `/safety/setups/siteSubTypeDetails/${props.params.siteTypeId}/`)}
        />
        <h2 className={"content-block"}>{t("siteSubType")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  onClick={onNew}
                  icon="fa-solid fa-circle-plus"
                  text={t("siteSubType")}
                  disabled={props.isLocked}
                />
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initData.siteSubTypes}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              onRowClick={onRowClick}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              onContextMenuPreparing={e => { setSelectedRowData(e) }}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="description" caption={t("description")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="type" caption={t("type")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup dataSource={SortObjectByPropName(lstAnalysisType, "name")} displayExpr="name" valueExpr="value" />
              </Column>
              <Column dataField="fatalInjurySpfFunctionId" caption={t("fatalInjurySpfFunction")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup dataSource={SortObjectByPropName(SPFFunctions, "name")} displayExpr="name" valueExpr="id" />
              </Column>
              <Column dataField="pdoSpfFunctionId" caption={t("pdoSpfFunction")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
                <Lookup dataSource={SortObjectByPropName(SPFFunctions, "name")} displayExpr="name" valueExpr="id" />
              </Column>
            </DataGrid>
          </div>
        </div>

        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            {/* <ValidationGroup ref={validationRef}> */}
            <Form colCount={2} className="popupFields">
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("description")}
                  labelMode='floating'
                  value={selectedData.description}
                  onValueChange={(e) => onValueChange("description", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </TextBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("analysisType")}
                  labelMode='floating'
                  value={selectedData.type}
                  onValueChange={(e) => onValueChange("type", e)}
                  className="modalInput"
                  items={SortObjectByPropName(lstAnalysisType, "name")}
                  valueExpr={"value"}
                  displayExpr={"name"}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("serverityType")}
                  labelMode='floating'
                  value={selectedData.serverityType}
                  onValueChange={(e) => onValueChange("serverityType", e)}
                  className="modalInput"
                  valueExpr={"value"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(lstSeverityType, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("fatalInjurySpfFunction")}
                  labelMode='floating'
                  value={selectedData.fatalInjurySpfFunctionId}
                  onValueChange={(e) => onValueChange("fatalInjurySpfFunctionId", e)}
                  className="modalInput"
                  valueExpr={"id"}
                  displayExpr={"name"}
                  items={SortObjectByPropName(SPFFunctions, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>
            </Form>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t("serverityType")}
                labelMode='floating'
                value={selectedData.serverityType}
                onValueChange={(e) => onValueChange("serverityType", e)}
                className="modalInput"
                valueExpr={"value"}
                displayExpr={"name"}
                showClearButton={true}
                searchEnabled={true}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </SelectBox>
            </SimpleItem>
            {/* </ValidationGroup> */}
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add ? (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                ) : (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onUpdate}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>
      </React.Fragment>
    </Permission>
  );
};
export default SiteSubTypes;
