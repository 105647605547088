import { FlatRawDataDTO, VolumeTimeChartDTO } from "../../types/trafficStudy/dtos/studyDto";
import { VehicleClassificationType } from "../../types/trafficStudy/enums/trafficStudyEnums";

function GroupedDataByTimeAndClassificationType(data: FlatRawDataDTO[]): VolumeTimeChartDTO[] {
  const groupedData: { [key: string]: VolumeTimeChartDTO & { totalCountVehicle: number } } = {};

  data.forEach((item) => {
    const { startDT, vehicleClassificationType, count } = item;
    const startDTString = startDT?.toString(); // Convert startDT to string

    if (!groupedData[startDTString!]) {
      groupedData[startDTString!] = {
        startDT: startDT!, // Assign default value of new Date() when startDT is undefined
        totalCountCar: 0,
        totalCountTruck: 0,
        totalCountCyclist: 0,
        totalCountVehicle: 0, // Add total property with initial value 0
        totalPedestrian: 0,
      };
    }

    if (vehicleClassificationType === VehicleClassificationType.Car) {
      groupedData[startDTString!].totalCountCar += count!;
    } else if (vehicleClassificationType === VehicleClassificationType.Unknown) {
      groupedData[startDTString!].totalCountCar += count!;
    } else if (vehicleClassificationType === VehicleClassificationType.Truck || vehicleClassificationType === VehicleClassificationType.HeavyTruck || vehicleClassificationType === VehicleClassificationType.Bus) {
      groupedData[startDTString!].totalCountTruck += count!;
    } else if (vehicleClassificationType === VehicleClassificationType.Cyclist) {
      groupedData[startDTString!].totalCountCyclist += count!;
    } else if (vehicleClassificationType === VehicleClassificationType.Pedestrian) {
      groupedData[startDTString!].totalPedestrian += count!;
    }

    // Update the total property
    groupedData[startDTString!].totalCountVehicle =
      groupedData[startDTString!].totalCountCar +
      groupedData[startDTString!].totalCountTruck +
      groupedData[startDTString!].totalCountCyclist +
      groupedData[startDTString!].totalPedestrian;
  });

  return Object.values(groupedData);
}

export default GroupedDataByTimeAndClassificationType;
