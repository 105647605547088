import { useEffect, useMemo, useState } from "react";
import "devexpress-reporting/dx-webdocumentviewer";
import { ClientReport } from "../../types/report/reportTypes";
import { ReportApiUrl } from "../../environment/routeSettings";
import { fetchSetup } from "@devexpress/analytics-core/analytics-utils";
import DevexpressReportViewer, {
  RequestOptions,
  Callbacks,
} from "devexpress-reporting-react/dx-report-viewer";

interface IPros {
  selectedReport: ClientReport;
  lstSelectedIds: string[];
  payload?: string | null;
  isOpen: boolean;
  endPointURL?: string;
}
const ReportViewer = (props: IPros) => {
  const [reportUrl, setReportUrl] = useState("");

  fetchSetup.fetchSettings = {
    headers: { "X-CSRF": "1" },
  };

  const generatedReportUrl = useMemo(() => {
    if (
      props.isOpen &&
      props.selectedReport.id !== undefined &&
      (props.lstSelectedIds.length > 0 || props.payload !== undefined)
    ) {
      const url = {
        ReportName: props.selectedReport.name,
        ReportId: props.selectedReport.id,
        CustomerId: props.selectedReport.customerId,
        Token: `Bearer ${localStorage.getItem("tesToken")}`,
        ListIds: props.lstSelectedIds,
        Payload: props.payload,
        IsDesigner: false,
        EndPointURL: props.endPointURL,
      };
      return JSON.stringify(url);
    }
  }, [props.isOpen, props.selectedReport, props.lstSelectedIds, props.payload]);

  useEffect(() => {
    generatedReportUrl && setReportUrl(generatedReportUrl);
  }, [generatedReportUrl]);

  return (
    <>
      {reportUrl && (
        <DevexpressReportViewer reportUrl={reportUrl}>
          <RequestOptions host={ReportApiUrl()! + "/"} invokeAction="DXXRDV" />
          <Callbacks />
        </DevexpressReportViewer>
      )}
    </>
  );
};

export default ReportViewer;