function SumIntArray(inData: (number | undefined)[]): number {
    if (inData === undefined) {
        return 0;
    } else {
        let sum = 0
        inData.forEach(x => sum += x!)
        return sum
    }
}

export { SumIntArray }


// For <Lookup>, <SelectBox> use the value of "displayExpr" as second argument (sortProperty)
function SortObjectByPropName<T>(users: T[] | undefined, sortProperty: string): T[] {
    if (users === null || users === undefined || users.length === 0) {
        return [];
    }

    return users.sort((a, b) => {
        type ObjectKey = keyof typeof a;
        const myVar = sortProperty as ObjectKey;

        const propA = String(a[myVar]).toLowerCase();
        const propB = String(b[myVar]).toLowerCase();

        if (propA < propB) {
            return -1;
        }
        if (propA > propB) {
            return 1;
        }
        return 0;
    });
}

function sortArrayOfObjectByDate(arr: any[]): any[] {
    return arr.sort((a, b) => {
        // Convert the startDT strings to Date objects for comparison
        const dateA = new Date(a.startDT);
        const dateB = new Date(b.startDT);

        if (dateA > dateB) {
            return -1; // Sort in descending order by startDT
        } else if (dateA < dateB) {
            return 1;
        } else {
            // If startDT is the same, sort by averageSpeed in descending order
            return b.averageSpeed - a.averageSpeed;
        }
    });
}

export { SortObjectByPropName as SortObjectByPropName, sortArrayOfObjectByDate as sortArrayOfObjectByDate }