import Tabs from "devextreme-react/tabs";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import Details from "./components/details/details";
import tabTitles from "./data/index";

import { Button } from "devextreme-react";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { useTranslation } from "react-i18next";
import Permission from "../../../components/permission/permision";
import ShareURL from "../../../components/shareURL/shareURL";
import { ModuleOfModulePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { ModuleOfModuleApiUrl } from "../../../environment/routeSettings";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { ClientModule, IModuleParams } from "../../../types/moduleOfModules/moduleOfModulesTypes";
import ModuleTabs from "./components/tabs";
import "./moduleDetails.scss";

const ModuleDetails = () => {
  const [initDataModule, setInitDataModule] = useState<ClientModule>(new ClientModule());
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabValidation = useRef<ValidationGroupRef>(null);
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<IModuleParams>();
  const [isLocked, setIsLocked] = useState(true);
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          if (params.moduleId !== "AddNew") {
            await getInitDataModule(params.moduleId!);
          }
          else {
            setIsLocked(false)
          }
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function getInitDataModule(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        ModuleOfModuleApiUrl() + "/api/ClientModule/GetClientModuleDetailsById/" + id,
        true
      )) as RequestResponseResult<any>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitDataModule(res.results)
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.moduleId !== "AddNew") {
                  onUpdate();
                  setDataChanged(false)
                  history(-1);
                } else {
                  onAdd();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setDataChanged(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  //add and update functions
  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        ModuleOfModuleApiUrl() + "/api/ClientModule/UpdateClientModule",
        initDataModule,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        // history(-1);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }

  async function onAdd() {
    try {
      const postObj: ClientModule = {
        ...initDataModule,
        customerId: localStorage.getItem("selectedCustomerId")!
      }
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        ModuleOfModuleApiUrl() + "/api/ClientModule/AddClientModule",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
        return;
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[ModuleOfModulePermissions.ModuleOfModule_D_Module]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
            {params.intersectionId !== "AddNew" && (
              <div style={{ marginTop: "1rem" }}>
                <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                  <span>{t('name')}: {initDataModule?.name}</span>
                </p>
                <Button
                  onClick={() => copyTextToClipboard()}
                  icon="fa-solid fa-copy"
                  hint={t("copy")}
                />
                <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
              </div>
            )}
            <div className="row" style={{ marginTop: 15 }}>
              <div className="leftColumn">
                <Button
                  onClick={goBackPermission}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                {params.moduleId !== "AddNew" && (
                  <ShareURL
                    url={url}
                  />
                )}
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
              <div className="rightColumn">
                {params.moduleId === "AddNew" && (
                  <Button onClick={onAdd} icon="fa-solid fa-floppy-disk" hint={t("save")} />
                )}
                {params.moduleId !== "AddNew" && isLocked === false && (
                  <Button onClick={onUpdate} icon="fa-solid fa-floppy-disk" hint={t("update")} />
                )}
              </div>
            </div>
          </div>
          <div className={"dx-card "}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />

            {selectedIndex === 0 &&
              <Details
                initDataModule={initDataModule}
                setInitDataModule={setInitDataModule}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
              />
            }

            {selectedIndex === 1 &&
              <ModuleTabs
                initDataModule={initDataModule}
                setInitDataModule={setInitDataModule}
                tabsValidation={tabValidation}
                isLocked={isLocked}
                setDataChanged={setDataChanged}
              />
            }
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default ModuleDetails;
