import { DayOfWeek, Month, ResponseCode } from "../general/enums/generalEnums";
import { ErrorMessage } from "../general/generalTypes";
import { LocationType } from "../infrastructure/enums/infrastructureEnums";
import { Location } from "../infrastructure/infrastructureTypes";
import { AnalysisType, SafetyPerformanceMeasureType } from "./enums/analysisEnums";
import { SiteSubTypeAnalysisType, SiteSubTypeServerityType } from "./enums/siteTypeEnums";
import { DistanceUnit, SpfEquationType } from "./enums/spfFunctionEnums";

export class Workbook {
  id!: string;
  customerId!: string;
  title!: string;
  description!: string;
  areaIds!: string[];
  analysis!: AnalysisBase[];
}

export class WorkbookDetail {
  description!: string;
}

export class EmpiricalBayesSetting {
  id!: string;
  customerId!: string;
  windowLength!: number;
  sptExpectedWeight!: number;
  sptExcessWeight!: number;
  incrementLength!: number;
  safetyPerformanceMeasure!: SafetyPerformanceMeasureType[];
  irr!: number;
}

export class CollisionCost {
  id!: string;
  customerId!: string;
  year!: number;
  pdoCollisionCost!: number;
  fatalCollisionCost!: number;
  fatalCollisionFactor!: number;
  injuryCollisionCost!: number;
  injuryCollisionFactor!: number;
  injuryLevel1CollisionCost!: number;
  injuryLevel1CollisionFactor!: number;
  injuryLevel2CollisionCost!: number;
  injuryLevel2CollisionFactor!: number;
  injuryLevel3CollisionCost!: number;
  injuryLevel3CollisionFactor!: number;
}
export interface ISiteTypeParam {
  [key: string]: any;
  siteTypeId?: string;
}

export interface ISiteSubTypeDetailsParam {
  [key: string]: any;
  siteTypeId?: string;
  subTypeId?: string;
}

export class SiteSubType {
  [key: string]: any;
  id?: string;
  description!: string;
  type!: SiteSubTypeAnalysisType;
  fatalInjurySpfFunctionId!: string;
  pdoSpfFunctionId!: string;
  serverityType!: SiteSubTypeServerityType;
  locations!: Location[]
}


export class SiteType {
  id!: string;
  customerId!: string;
  name!: string;
  siteSubTypes?: SiteSubType[]
}



export class SpfFunction {
  id!: string;
  customerId!: string;
  name!: string;
  calibrationYear!: number;
  alpha!: number;
  variableB!: number;
  variableC!: number;
  variableD!: number;
  variableE!: number;
  dispersionFactor!: number;
  distanceUnit!: DistanceUnit;
  equationType!: SpfEquationType;
}
export class AnalysisBase {
  analysisType!: AnalysisType;
  id!: string;
  workbookId!: string;
  customerId!: string;
  title!: string;
  analysisDateTime!: string;
}
export interface IWorkbookParams {
  [key: string]: any;
  workbookId?: string
}

export interface IAnalysisParams {
  [key: string]: any;
  analysisId?: string
}

export class EmpiricalBayesAnalysis {
  id!: string;
  workbookId!: string;
  customerId!: string;
  title!: string;
  analysisDateTime!: string;
  analysisType!: AnalysisType;
  cost!: CollisionCost;
  years!: number[];
  empiricalBayesSetting!: EmpiricalBayesSetting;
  runOverRep!: boolean;
  months!: Month[];
  daysOfWeek!: DayOfWeek[];
  animalInvolvement!: number;
  bicycleInvolvement!: number;
  pedestrianInvolved!: number;
  initialImpactTypes!: string[];
  weatherConditions!: string[];
  roadSurfaceConditions!: string[];
  results!: NetworkScreeningResult[];
  errors!: ErrorMessageRequestResponseResult;
}

export class NetworkScreeningResult {
  locationId!: string;
  locationGeoId!: string;
  locationDescription!: string;
  locationType!: LocationType;
  lhrs!: number;
  offset!: number;
  excessRank!: number;
  expectedRank!: number;
  adjustedRank!: number;
  excess!: number;
  observedFI!: number;
  predictedFI!: number;
  expectedFI!: number;
  observedTotal!: number;
  predictedTotal!: number;
  expectedTotal!: number;
  overRep!: string[];
  endOffset!: number;
  loss!: string;
  costRCFI!: number;
  excessRatioFI!: number;
  excessRatioTotal!: number;
}

export class RankingMap {
  locationType!: LocationType;
  geoId!: string;
  rank!: number;
  properties?: { [key: string]: any };
}

export class ErrorMessageRequestResponseResult {
  id!: string;
  messages!: ErrorMessage[];
  responseCode!: ResponseCode;
  results!: ErrorMessage;
}