import { ScrollView, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Pager,
  Paging,
  Search,
  SearchPanel,
} from "devextreme-react/data-grid";
import { Form, SimpleItem } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../../../../../contexts/auth";
import { InfrastructureApiUrl } from "../../../../../environment/routeSettings";
import { ModalMode, ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../../types/general/generalTypes";
import {
  GISLayerSetting,
  MapSetting,
} from "../../../../../types/infrastructure/infrastructureTypes";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../../utils/rest";
interface IProps {
  clientId: string | undefined
}
const GISLayers = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<MapSetting>(new MapSetting());
  const [showModal, setShowModal] = useState(false);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [currentGisLayer, setCurrentGisLayer] = useState<GISLayerSetting>(new GISLayerSetting());

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    const res = await TesGet(
      InfrastructureApiUrl() +
      "/api/Settings/GetInfrastructureSettings/" +
      props.clientId,
      true
    ) as RequestResponseResult<MapSetting>
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  //modal functions
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setCurrentGisLayer(new GISLayerSetting());
      setShowModal(false);
    }
  }
  //modal mode functions
  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onEdit(d: any) {
    let newData = new GISLayerSetting();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setCurrentGisLayer(newData);
    setShowModal(true);
  }

  //change fields functions
  const onValueChanged = (key: string, value: any) => {
    setCurrentGisLayer({ ...currentGisLayer, [key]: value });
  };


  function onAdd() {
    setInitData({
      ...initData,
      customerId: props.clientId!,
      gisLayers: [...initData.gisLayers, { ...currentGisLayer, id: uuidv4() }],
    });
    setShowModal(false);
  }

  function onUpdate() {
    const gisLayers = initData.gisLayers;
    const selectedIndex: number = gisLayers?.findIndex(
      (x: GISLayerSetting) => x.id === currentGisLayer.id
    );
    gisLayers[selectedIndex] = currentGisLayer;
    setInitData({
      ...initData,
      customerId: props.clientId!,
      gisLayers: gisLayers,
    });
    dataGridRef.current?.instance().refresh();
    setShowModal(false);
  }

  //delete functions
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
              setInitData({
                ...initData,
                gisLayers: [
                  ...initData.gisLayers.filter(x => x.id !== d.row.data.id)
                ]
              })
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function onSave() {
    const postObj = {
      ...initData,
      customerId: props.clientId
    }
    const res = await TesPost(
      InfrastructureApiUrl() + "/api/settings/updateSetting",
      postObj,
      true
    ) as RequestResponseResult<any>;
    if (res.responseCode === ResponseCode.OK) {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } catch {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred"), "error", 5000);
      }
    } else {
      await RequestErrorHandling(res);
    }
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <div className="row">
          <div className="rightColumn">
            <Button text={t("gisLayer")} onClick={onNew} icon="fa-solid fa-circle-plus" />
          </div>
        </div>
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={initData?.gisLayers}
          rowAlternationEnabled={true}
          showBorders={true}
          style={{ height: "100%" }}
          //keyExpr="id"
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
          onExporting={OnExporting}
        >
          <Export enabled={true} allowExportSelectedData={true} />
          <Grouping contextMenuEnabled={true} autoExpandAll={false} />
          <GroupPanel visible={true} /> {/* or "auto" */}
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true} applyFilter="auto" />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} width={285} placeholder={t("search...")} />
          <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
            <Search enabled />
          </ColumnChooser>
          <Column dataField={"name"} caption={t("name")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField={"id"} caption={t("id")} visible={false}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField={"url"} caption={t("url")}>
            {" "}
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
            <DevExpressButton
              hint={t("edit")}
              onClick={onEdit}
              icon="fa-solid fa-pencil"
            ></DevExpressButton>
            <DevExpressButton
              hint={t("delete")}
              onClick={onDelete}
              icon="fa-solid fa-trash-can"
            ></DevExpressButton>
          </Column>
        </DataGrid>
        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>

      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t("add") : t("update")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={1} className="popupFields">
            <SimpleItem>
              <TextBox
                label={t("name")}
                labelMode='floating'
                value={currentGisLayer.name}
                onValueChange={(e) => onValueChanged("name", e)}
                className="modalInput"
                style={{ marginBottom: "-1rem" }}
              />
            </SimpleItem>
            <SimpleItem>
              <TextBox
                label={t("url")}
                labelMode='floating'
                value={currentGisLayer.url}
                onValueChange={(e) => onValueChanged("url", e)}
                className="modalInput"
              />
            </SimpleItem>
          </Form>
          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ? (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAdd}
                  text={t("add")}
                />
              ) : (
                <Button
                  className="tes-modal-btn-add"
                  onClick={onUpdate}
                  text={t("update")}
                />
              )}
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};
export default GISLayers;
