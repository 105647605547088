import React, { useState, createContext, useContext, useEffect } from 'react';

interface INavigationData {
  currentPath: string;

}
export enum MenuStatus {
  Closed = 1,
  Opened = 2,
  TemporaryOpened = 3
};
type NavigationContextType = {
  setNavigationData?: (data: INavigationData) => Promise<void>;
  navigationData: INavigationData;
  menuStatus?: MenuStatus;
  setMenuStatus?: React.Dispatch<React.SetStateAction<any>>
};
const NavigationContext = createContext<NavigationContextType>({ navigationData: { currentPath: '' }, menuStatus: MenuStatus.Opened });
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props: any) {
  const [navigationData, setNavigationData] = useState({ currentPath: '' });
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Opened);

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData, menuStatus, setMenuStatus }}
      {...props}
    />
  );
}


function withNavigationWatcher(Component: any, path: string) {
  const WrappedComponent = function (props: any) {
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      if (setNavigationData) setNavigationData({ currentPath: path });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, setNavigationData]);

    return <Component {...props} />;
  }
  return <WrappedComponent />;
}

export {
  NavigationProvider,
  useNavigation,
  withNavigationWatcher
}
