import { DataGrid, Form, NumberBox, Popup, ScrollView, SelectBox, Switch, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { TesGet, TesPost, TesPut } from "../../../../../utils/rest";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../../../../contexts/auth";
import { AuthApiUrl, CollisionApiUrl, FieldApiUrl } from "../../../../../environment/routeSettings";
import { SimpleItem } from "devextreme-react/form";
import { Enum2Array } from "../../../../../utils/enumTools";
import { CoordinationSourceType } from "../../../../../types/collision/enums/collisionEnums";
import { NameValue } from "../../../../../types/general/generalTypes";
import { DashboardSettings, EmphasisAreaSetting, GeneralSettings, UnknownBoolTreatment } from "../../../../../types/collision/collisionTypes";
import { Column, ColumnChooser, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, Search, SearchPanel, Button as DevExpressButton, Lookup, DataGridRef } from "devextreme-react/data-grid";
import { ModalMode } from "../../../../../types/general/enums/generalEnums";
import { TesField } from "../../../../../types/field/fieldType";
import { FieldCategoryType, FieldServiceType, FieldType } from "../../../../../types/field/enums/fieldEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { custom } from "devextreme/ui/dialog";
import { ValidationGroupRef } from "devextreme-react/cjs/validation-group";

interface IProps {
  clientId: string | undefined
}
const Integration = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<GeneralSettings>(new GeneralSettings());
  const [currentPopupData, setCurrentPopupData] = useState<EmphasisAreaSetting>(new EmphasisAreaSetting());
  const [initDataFields, setInitDataFields] = useState<TesField[]>([]);
  const [, setLstCoordinationSourceType] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const validationRef = useRef<ValidationGroupRef>(null);
  const [lstUnknownTreatment, setLstUnknownTreatment] = useState<NameValue[]>([]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        getInitData();
        getInitDataFields()
        setLstCoordinationSourceType(Enum2Array(CoordinationSourceType))
        setLstUnknownTreatment(Enum2Array(UnknownBoolTreatment));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onSave() {
    try {
      const postObj = {
        ...initData,
        customerId: props.clientId
      }
      if (activeLoading) activeLoading(true);
      await TesPost(
        CollisionApiUrl() + "/api/settings/UpdateSetting",
        postObj,
        true
      );
      await getInitData();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function getInitData() {
    setInitData(
      await TesGet(
        CollisionApiUrl() +
        "/api/Settings/GetCustomerSettings/" +
        props.clientId,
        true
      )
    );
  }

  async function getInitDataFields() {
    setInitDataFields(
      await TesGet(
        FieldApiUrl() +
        "/api/TesFields/GetAllFields/" +
        props.clientId +
        "/" +
        FieldServiceType.Collision,
        true
      )
    );
  }

  function onValueChange(key: string, value: any) {
    setCurrentPopupData({ ...currentPopupData, [key]: value })
    setInitData({ ...initData, [key]: value });
  }

  function onPopupValueChange(key: string, value: any) {
    setCurrentPopupData({ ...currentPopupData, [key]: value });
  }

  function onCloseModal() {
    setShowModal(false);
    setCurrentPopupData(new EmphasisAreaSetting);
  }

  function onAdd() {
    try {
      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {

        if (
          initData.dashboardSettings?.emphasisAreaSettings && initData.dashboardSettings?.emphasisAreaSettings?.some(x => x.order === currentPopupData.order)
        ) {
          notify(t("thisOrderAlreadyExist"), "error", 5000);
          return;
        }
        var fieldName = initDataFields.find(x => x.id === currentPopupData.fieldId)?.name ?? ""
        var emSettings: EmphasisAreaSetting[];
        if (initData.dashboardSettings?.emphasisAreaSettings === undefined) {
          emSettings = [{ ...currentPopupData, fieldName: fieldName }]
        } else {
          emSettings = [...initData.dashboardSettings?.emphasisAreaSettings, { ...currentPopupData, fieldName: fieldName }]

        }


        setInitData({ ...initData, dashboardSettings: { ...initData.dashboardSettings ?? new DashboardSettings(), emphasisAreaSettings: emSettings } })
        onCloseModal();
        dataGridRef.current?.instance().refresh()
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);

      const validationRes = validationRef.current?.instance().validate();
      if (validationRes?.isValid) {

        const newData: GeneralSettings = {
          ...initData,
          dashboardSettings: {
            ...initData.dashboardSettings,
            emphasisAreaSettings: [...initData.dashboardSettings?.emphasisAreaSettings?.filter(x => x.fieldId !== currentPopupData.fieldId)!, currentPopupData]!,
            useOnlyGeoCoded: initData.dashboardSettings?.useOnlyGeoCoded!,
            minimumCollisionLevel: initData.dashboardSettings?.minimumCollisionLevel!
          },
        };
        setInitData(newData)

        dataGridRef.current?.instance().refresh();

        onCloseModal();
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      }

      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setCurrentPopupData({
      ...currentPopupData,
      title: "",
      description: "",
      unknownTreatment: UnknownBoolTreatment.Ignore
    });

    // Show the popup modal
    setShowModal(true);
  }

  function onDelete(d: any) {

    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [{
        text: t("yes"),
        onClick: async (e) => {
          setInitData({ ...initData, dashboardSettings: { ...initData?.dashboardSettings!, emphasisAreaSettings: [...initData.dashboardSettings?.emphasisAreaSettings?.splice(d.row.dataIndex, 1)!] } })
          setInitData(initData)
          return { buttonText: e.component.option("text") }
        }
      },
      {
        text: t("no"),
        onClick: (e) => {
          return { buttonText: e.component.option("text") }
        }
      },]
    });
    myDialog.show();

  }

  function onEdit(d: any) {
    let newData = new EmphasisAreaSetting;
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setCurrentPopupData(newData)
    setShowModal(true);
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2}>
          {/* <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('geoCodeCityName')}
              labelMode='floating'
              value={initData?.geoCodeCityName}
              onValueChange={(e) => onValueChange("geoCodeCityName", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('arcGISToken')}
              labelMode='floating'
              value={initData?.arcGISToken}
              onValueChange={(e) => onValueChange("arcGISToken", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('arcGISGeoCodeServiceAddress')}
              labelMode='floating'
              value={initData?.arcGISGeoCodeServiceAddress}
              onValueChange={(e) => onValueChange("arcGISGeoCodeServiceAddress", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              placeholder=""
              label={t('googleAPIKey')}
              labelMode='floating'
              value={initData?.googleAPIKey}
              onValueChange={(e) => onValueChange("googleAPIKey", e)}
            />
          </SimpleItem>
          
          <SimpleItem colSpan={1}>
            <NumberBox
              label={t('minimumCollisionLevel')}
              labelMode='floating'
              value={initData.dashboardSettings?.minimumCollisionLevel}
              onValueChange={e => setInitData({ ...initData, dashboardSettings: { ...initData.dashboardSettings!, minimumCollisionLevel: e } })}
            />
          </SimpleItem>
          <SimpleItem colSpan={2}>
            <div>{t("UseOnlyGeoCoded")}</div>
            <Switch
              style={{ marginTop: 5 }}
              value={initData.dashboardSettings?.useOnlyGeoCoded}
              onValueChange={e => setInitData({ ...initData, dashboardSettings: { ...initData.dashboardSettings!, useOnlyGeoCoded: e } })}
            />
          </SimpleItem>
         */}
          <SimpleItem colSpan={2}>
            <h2>{t('apiWhitelist')}</h2>
          </SimpleItem>
          <SimpleItem colSpan={2}>
            <div className="rightColumn">
              <Button
                style={{ margin: "-3rem 0" }}
                onClick={onNew}
                icon="fa-solid fa-circle-plus"
                text={t("add")}
              />
            </div>
          </SimpleItem>
          <SimpleItem colSpan={2}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={initData.dashboardSettings?.emphasisAreaSettings ?? []}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
            // onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>

              <Column dataField="ipAddress" caption={t("ipAddress")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                />
                <DevExpressButton
                  hint={t("edit")}
                  onClick={onEdit}
                  icon="fa-solid fa-pencil"
                />
              </Column>
            </DataGrid>
            {/* </ValidationGroup> */}
          </SimpleItem>
        </Form>
        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>

      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t("add") : t("update")}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <ValidationGroup ref={validationRef}>
            <Form colCount={2}>

              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("ipAddress")}
                  labelMode='floating'
                  onValueChange={(e) => onPopupValueChange("ipAddress", e)}
                  value={currentPopupData.title}
                />
              </SimpleItem>

            </Form>
          </ValidationGroup>
          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              {modalMode === ModalMode.Add ?
                <Button
                  className='tes-modal-btn-add'
                  onClick={onAdd}
                  text={t('add')}
                />
                :
                <Button
                  className='tes-modal-btn-add'
                  onClick={onUpdate}
                  text={t('update')}
                />
              }
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};
export default Integration;
