import { Button, Popup } from 'devextreme-react';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Export,
    FilterRow, HeaderFilter,
    Lookup,
    Pager, Paging,
    Search,
    SearchPanel,
    Selection
} from 'devextreme-react/data-grid';
import Form, { SimpleItem } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../../../contexts/auth';
import { CollisionApiUrl } from '../../../../../../environment/routeSettings';
import { ImportXmlRequestTask, XmlImportLog } from "../../../../../../types/collision/collisionTypes";
import { CollisionImportStatus } from '../../../../../../types/collision/enums/collisionEnums';
import { NameValue } from '../../../../../../types/general/generalTypes';
import { Enum2Array } from '../../../../../../utils/enumTools';
import { TesPost } from '../../../../../../utils/rest';

// props
interface IPros {
    initData: XmlImportLog;
}

const Logs = (props: IPros) => {
    const { t } = useTranslation();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [lstCollisionImportStatus, setLstCollisionImportStatus] = useState<NameValue[]>([]);
    const [showImportMessage, setShowImportMessage] = useState<boolean>(false);
    const { activeLoading } = useAuth();
    const history = useNavigate();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const user = useAuth();
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                setLstCollisionImportStatus(Enum2Array(CollisionImportStatus));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const goToDetailsPage = useCallback((data: any) => {
        console.log(data)
        if (data.data.collisionId !== "00000000-0000-0000-0000-000000000000") {
            history("/collision/collisionDetails/" + data.data.collisionId);
        } else {
            notify(t('collisionIsNotImported'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history]);




    async function onReImport() {
        var gridData = await dataGridRef.current?.instance().getSelectedRowKeys();
        if (gridData?.length === 0 ?? true) {
            notify(t('pleaseSelectSomeData'), 'warning', 5000);
            return;
        }
        const filePaths: string[] = [];
        gridData!.forEach((element: { filePath: string; }) => {
            filePaths.push(element.filePath);
        });
        //call the import function here 
        const postObj: ImportXmlRequestTask = {
            emails: [user.user?.email!],
            filePaths: filePaths,
            xmlImportAction: props.initData.xmlImportAction,
            customerId: localStorage.getItem("selectedCustomerId") as string,
            xmlImportSettingId: props.initData.importSettingId
        }
        try {
            if (activeLoading) activeLoading(true);
            await TesPost(CollisionApiUrl() + "/api/XMLImport/ImportCheck", postObj, true)
            if (activeLoading) activeLoading(false);
            setShowImportMessage(true)
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 5000);
        }
    }
    function onClosePopup() {
        history("/collision/import/xml/xmlImportLogs");
        setShowImportMessage(false)
    }

    return (
        <div className={`xmlImportLogDetails-logs ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <div className='row' style={{ marginTop: 20, padding: 10 }}>
                    <div style={{ paddingLeft: "1rem", paddingBottom: "1rem" }} >
                        <Button
                            text={t('reImport')}
                            onClick={onReImport}
                        />
                    </div>
                    <DataGrid
                        id="logsGridContainer"
                        ref={dataGridRef}
                        dataSource={props.initData?.logs}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        style={{ margin: "0 1rem" }}
                        onRowClick={goToDetailsPage}
                    >

                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                        />

                        <Export enabled={true}
                            allowExportSelectedData={true}
                        />
                        <Selection
                            mode="multiple"
                            selectAllMode={'allPages'}
                            showCheckBoxesMode={'always'}
                        />
                        <FilterRow visible={true}
                            applyFilter="auto" />

                        <HeaderFilter visible={true} />


                        <SearchPanel visible={true}
                            width={285}
                            placeholder={t("search...")} />

                        <ColumnChooser width={350} height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                        >
                            <Search enabled />
                        </ColumnChooser>
                        <Column dataField={'id'}
                            caption={t('id')}
                            visible={false}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>

                        </Column>
                        <Column dataField={'filePath'}
                            caption={t('filePath')}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>

                        </Column>

                        <Column dataField={'status'}
                            caption={t("status")}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                            <Lookup dataSource={lstCollisionImportStatus} displayExpr="name" valueExpr="value" />
                        </Column>

                        <Column dataField={'note'}
                            caption={t('note')}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                    </DataGrid>
                </div>

                <Popup
                    width={"30%"}
                    height={"auto"}
                    visible={showImportMessage}
                    resizeEnabled={true}
                    showTitle={true}
                    title={t("xmlImport")}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHiding={() => setShowImportMessage(false)}
                >
                    <div style={{ padding: '0.5rem' }}>
                        <Form colCount={2}>
                            <SimpleItem colSpan={2}>
                                <p className="title" style={{ marginBottom: '0.5rem', fontSize: '1rem', fontWeight: 'bold' }}>{t('xmlImportMessage')}</p>
                            </SimpleItem>
                        </Form>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '0.5rem' }}>
                        <Button
                            className="tes-modal-btn-add"
                            onClick={() => onClosePopup()}
                            text={t("close")}
                            style={{ padding: '0.3rem 0.5rem', fontSize: '0.8rem', fontWeight: 'bold' }}
                        />
                    </div>
                </Popup>


            </React.Fragment>
        </div>
    );
}
export default Logs;