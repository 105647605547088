import { t } from "i18next";

const tabTitles = [
    {
        id: 0,
        text: t('details'),
        icon: 'fa-solid fa-ellipsis',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: t('collisions'),
        icon: 'fa-solid fa-car-burst',
        content: 'Location tab content',
    }

];
export default tabTitles;

