import { LocationType } from './../../infrastructure/enums/infrastructureEnums';

export class VMWebSupport {
    id!: string;
    customerId!: string;
    supportId!: string;
    locationId!: string;
    locationDescription!: string;
    locationType!: LocationType;
    geoId!: string;
    signs!: string[];
    supportLocationTypeTitle!: string;
    supportLocationTypeId!: string;
    supportTypeTitle!: string;
    supportTypeId!: string;
    supportMaterialTitle!: string;
    supportMaterialId!: string;
    supportDescriptionTitle!: string;
    supportDescriptionId!: string;
    latitude!: number;
    longitude!: number;
    xCoord!: number;
    yCoord!: string;
    supportCodeTitle!: string;
    supportCodeId!: string;
    height!: number;
    supportPositionTitle!: string;
    supportPositionId!: string;
    dateInstalled!: Date;
    dateExpired!: Date;
    supportConditionTitle!: string;
    supportConditionId!: string;
    materialCost!: number;
    note: string | undefined;
    statusId: string | undefined;
}
export class VMWebSupportCode {
    [key: string]: any;
    id!: string;
    customerId!: string;
    index!: number;
    code!: string;
    supportTitleType!: string;
    supportTypeId!: string;
    supportMaterialTitle!: string;
    supportMaterialId!: string;
    supportDescriptionTitle!: string;
    supportDescriptionId!: string;
    materialCost!: number;
    labourCost!: number;
    installationCost!: number;
    isDeleted!: boolean;
}

export class VMSupportUpdate {
    id!: string;
    customerId!: string;
    version!: number;
    supportId!: string;
    locationId!: string;
    locationDescription!: string;
    locationType!: LocationType;
    geoId!: string;
    supportLocationTypeId!: string;
    latitude!: number;
    longitude!: number;
    xCoord!: number;
    yCoord!: number;
    supportCodeId!: string;
    height!: string;
    supportPositionId!: string;
    dateInstalled!: Date;
    dateExpired!: Date;
    supportConditionId!: string;
    note!: string;
    deletedAttachments?: string[];
    signs!: string[];
    assets!: string[]
}
