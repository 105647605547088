import DataGrid, { Column, ColumnChooser, DataGridRef, Export, FilterRow, HeaderFilter, Pager, Paging, Search, SearchPanel, } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import TextBox from 'devextreme-react/text-box';
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { VMCode } from "../../types/sign/dto/signDto";
import { TesSign } from "../../types/sign/signTypes";
import { OnExporting } from "../../utils/dataGridTools";

interface IProps {
    label: string;
    signCodes: VMCode[],
    initDataSign: TesSign,
    onChangeFields: (name: string, value: string) => void;
    signCodeDesc: string,
    setSignCodeDesc: React.Dispatch<React.SetStateAction<string>>
    isLocked: boolean;
}


const SignCodePicker = (props: IProps) => {
    const [showSignCodePicker, setShowSignCodePicker] = useState(false);
    const dataGridRef = useRef<DataGridRef<any, any>>(null);


    function onRowClick(selectedRowData: any) {
        setShowSignCodePicker(false);
        const sc: VMCode = props.signCodes.find((x: VMCode) => x.id === selectedRowData.data?.id)!;
        props.setSignCodeDesc(sc.name)
        props.onChangeFields("signCodeId", selectedRowData.data?.id)
    }

    async function onOpenPopUp() {
        if (!props.isLocked) {
            setShowSignCodePicker(true);
        }
    }

    return (
        <React.Fragment>
            <label onClick={() => onOpenPopUp()}>
                <TextBox
                    label={props.label}
                    labelMode='floating'
                    value={props.signCodeDesc}
                    disabled={props.isLocked}
                />
            </label>
            <Popup
                width={"60%"}
                visible={showSignCodePicker}
                resizeEnabled={true}
                showTitle={true}
                title={t("signCodePicker")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowSignCodePicker(false)}
            >
                <div style={{ height: "100%" }}>
                    <DataGrid
                        ref={dataGridRef}
                        dataSource={props.signCodes}
                        style={{ height: '100%' }}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        selection={{ mode: 'single' }}
                        onRowClick={onRowClick}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        onExporting={OnExporting}
                    >
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                        />
                        <FilterRow visible={true}
                            applyFilter="auto" />

                        <HeaderFilter visible={true} />

                        <SearchPanel visible={true}
                            width={285}
                            placeholder={t("search...")} />

                        <ColumnChooser width={350} height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                        >
                            <Search enabled />
                        </ColumnChooser>

                        <Column dataField="name"
                            caption={t("signCode")}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                    </DataGrid>
                </div>
            </Popup>


        </React.Fragment>
    );
}
export default SignCodePicker;