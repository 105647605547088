import { Form, ValidationGroup } from 'devextreme-react';
import { ValidationGroupRef } from 'devextreme-react/cjs/validation-group';
import { SimpleItem } from 'devextreme-react/form';
import { t } from 'i18next';
import React from 'react';
import InfrastructureLocationPicker from '../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker';
import { FieldCategoryType } from '../../../../../types/field/enums/fieldEnums';
import { TesField } from '../../../../../types/field/fieldType';
import { TesMapModule } from '../../../../../types/general/enums/generalEnums';
import { LocationPickerMode } from '../../../../../types/infrastructure/enums/infrastructureEnums';
import { Location } from '../../../../../types/infrastructure/infrastructureTypes';
import { GeneralSetting } from '../../../../../types/setting/gridSettingTypes';

// props
interface IPros {
    initDataFields: TesField[] | undefined;
    location: Location;
    setLocation: React.Dispatch<React.SetStateAction<any>>
    generalSettings: GeneralSetting | null;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroupRef>;
    pageMode: number
}


const LocationMap = (props: IPros) => {

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <ValidationGroup ref={props.validationRef}>
                    {/* {props.initDataFields?.filter(x => x.fieldCategoryType === FieldCategoryType.InfrastructureIntersectionGeneral).length === 0 &&
                        <div className='row' style={{ marginTop: 20, padding: 10 }}>
                            <div className='halfColumn' >
                                <p style={{ fontWeight: 'bold' }}>{t("thereIsNoDataInThisTab")}</p>
                            </div>
                        </div>
                    } */}
                    <Form colCount={2}>
                        <SimpleItem colSpan={2}>
                            {props.location &&
                                <InfrastructureLocationPicker
                                    didMount={true}
                                    initLocationData={{
                                        customerId: props.location.customerId,
                                        id: props.location.id,
                                    }}
                                    tesModule={TesMapModule.InfrastructureDetails}
                                    componentMode={LocationPickerMode.None}
                                    renderMap={true}
                                    renderCoordinate={false}
                                    showIcons={true}
                                    selectedMapLocation={{ latitude: props.location.latitude, longitude: props.location.longitude, xCoord: props.location.xCoord, yCoord: props.location.yCoord }}
                                />
                            }
                        </SimpleItem>



                    </Form>
                </ValidationGroup>
            </div>

        </React.Fragment>
    );
}
export default LocationMap;