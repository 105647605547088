export enum FieldCategoryType {
  CollisionGeneral = 1,
  CollisionVehicle = 2,
  CollisionPerson = 3,
  CollisionRoad = 4,
  CollisionDriver = 5,
  CollisionPedestrian = 6,
  CollisionPassenger = 15,
  CollisionRemark = 17,

  InfrastructureMidBlockGeneral = 7,
  InfrastructureMidBlockDetails = 8,
  InfrastructureMidBlockOperation = 9,
  InfrastructureMidBlockCrossSection = 10,

  InfrastructureIntersectionGeneral = 11,
  InfrastructureIntersectionDetails = 12,
  InfrastructureIntersectionApproachDetails = 13,
  InfrastructureIntersectionOperation = 14,
  InfrastructureStreetGeneral = 16,

  InfrastructureMISGeneral = 30,
  
  InfrastructureTrafficSectionGeneral = 40,

  CountingStationGeneral = 50,

  PermanentCountingStationGeneral = 60,



  InfrastructureIntersectionRoot = 201,
  InfrastructureMidBlockRoot = 202,
}

export enum FieldType {
  String = 1,
  Number = 2,
  Boolean = 3,
  Date = 4,
  Time = 5,
  DateTime = 6,
  List = 7,
  AutoGenerate = 8,
  TextArea = 9,
  Integration = 10,
  TesEnum = 11,
  DoubleNumber = 12,
  DecimalNumber = 13,
}

export enum FieldServiceType {
  Collision = 1,
  Infrastructure = 2,
  ModuleOfModule = 3,
}

export enum FieldPermission {
  Deny = 1,
  View = 2,
  Edit = 3,
}
export enum ValidationRuleLevel {
  info = 1,
  warning = 2,
  error = 3,
}

export enum SimplifiedTesField {
  AccNumber = 1,
  AccidentDate = 2,
  TrafficControl = 3,
  IsCyclistInvolved = 4,
  IsPedestrianInvolved = 5,
  Severity = 6,
  Municipality = 7,
  InitialImpactType = 8,
  RoadSurfaceCondition = 9,
  SelfReported = 10,
  LightingCondition = 11,
  DriverLostControl = 12,
  SpeedRelated = 13,
  WeatherCondition = 14,
  Jurisdiction = 15,

  Index = 16,
  Sex = 17,
  Injured = 18,
  Fatal = 19,
  AgeGroup = 20,
  Action = 21,
  Condition = 22,

  Maneuver = 23,
  IsIntersection = 24,
  IsDistractedDriver = 25,
  IsAggressiveDriver = 26,
  IsOldDriver = 27,
  IsYoungDriver = 28,
  IsHeavyVehicleInvolved = 29,
  HasInjury = 30,
  HasFatal = 31,
  IsMotorcycleInvolved = 32,
  InitialDirectionOfTravel = 33,
  IsAnimalInvolved = 34,

  InjuryLevel = 35,
  InitialImpactTypeClient = 36,
  RunOffRoad = 37,

  RoadName = 38,

  GeoCodeNotRequired = 39,
  PoliceFormAddress = 40,
  IsImpairedDriverInvolved = 41,
  PersonAge = 42,
  PersonBirthday = 43,

  //Infrastructure
  GeoId = 100,
  LocationDescription = 101,
  SectionType = 102,
  SectionDetailType = 103,
  PostedSpeed = 104,
  Length = 105,
  YearOfImprovement = 106,
  IsSignalized = 107,
  TrafficControlTes = 108,
  TrafficControlClient = 109,
  RIN = 110

}
export enum SimplifiedFieldCategory {
  SimplifiedCollision = 1,
  SimplifiedPerson = 2,
  SimplifiedVehicle = 3,
  SimplifiedIntersection = 4,
  SimplifiedRoadSegment = 5,
  SimplifiedRoad = 6,
}

export enum FieldLocation2Show {
  None = 0,
  GridView = 2,
  DetailView = 4,
  MobileApp = 8,
  GridColumnChooser = 16,
  GeoCodingPage = 32,
}
