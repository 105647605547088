import { ApplicationName, LogType } from './enums/logEnums';
export class Log {
    id!: string;
    applicationName!: ApplicationName;
    pcName!: string;
    ip!: string;
    errorMessages!: string;
    codePlace!: string;
    logDate!: Date;
    logType!: LogType;
    userId!: string;
}