import { Switch } from "devextreme-react";
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column,
    ColumnChooser,
    DataGridRef,
    Button as DevExpressButton,
    Export,
    FilterRow, HeaderFilter,
    Pager, Paging,
    Search,
    SearchPanel,
    Selection
} from 'devextreme-react/data-grid';
import Form, { SimpleItem } from "devextreme-react/form";
import { Popup } from 'devextreme-react/popup';
import ScrollView from "devextreme-react/scroll-view";
import Tabs from "devextreme-react/tabs";
import TextBox from 'devextreme-react/text-box';
import { custom } from 'devextreme/ui/dialog';
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { AuthApiUrl, SettingApiUrl } from "../../environment/routeSettings";
import { GridType, ModalMode } from "../../types/general/enums/generalEnums";
import { GridSetting, NameValue } from "../../types/general/generalTypes";
import { ReplaceGridProfile } from "../../types/setting/dto/gridSettingDTo";
import { GridShareStatus } from "../../types/setting/enums/settingsEnums";
import { GridProfile, UserGridProfile } from "../../types/setting/gridSettingTypes";
import { OnExporting } from "../../utils/dataGridTools";
import { Enum2Array } from "../../utils/enumTools";
import { useScreenSize } from "../../utils/media-query";
import { TesDelete, TesGet, TesPost } from '../../utils/rest';
import CollapsibleCard from "../collapsibleCard/collapsibleCard";
import tabTitles from "./data/data";
import './gridProfilePicker.scss';

interface IProps {
    gridType: GridType;
    customerId: string;
    dataGridRef: React.RefObject<DataGridRef<any, any>>
    setGridSetting?: React.Dispatch<React.SetStateAction<GridSetting>>
    disabled?: boolean;
}


const GridProfilePicker = (props: IProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalAdd, setShowModalAdd] = useState<boolean>(false);
    const [showModalShare, setShowModalShare] = useState<boolean>(false);
    const [initData, setInitData] = useState<UserGridProfile[]>();
    const dataGridRef = useRef<DataGridRef<any, any>>(null);
    const [selectedData, setSelectedData] = useState<GridProfile>(new GridProfile());
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [initDataUser, setInitDataUser] = useState([]);
    const [initDataGroup, setInitDataGroup] = useState([]);
    const dataGridUserRef = useRef<DataGridRef<any, any>>(null);
    const dataGridGroupRef = useRef<DataGridRef<any, any>>(null);
    const [lstShareStatus, setLstSHareStatus] = useState<NameValue[]>([new NameValue()]);
    const { activeLoading } = useAuth();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [popupHeight, setPopupHeight] = useState<number | string>(670)
    const [gridHeight, setGridHeight] = useState<number | string>(340)
    const { isXLarge, is2xLarge } = useScreenSize();


    useEffect(() => {
        if (isXLarge) { setPopupHeight(830); setGridHeight(500) }
        if (is2xLarge) { setPopupHeight("auto"); setGridHeight("auto") }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        setLstSHareStatus(Enum2Array(GridShareStatus))
        if (showModal) {
            fetchMyAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);


    async function getInitData() {
        setInitData(await TesGet(SettingApiUrl() + "/api/GridProfiles/GetUserGridProfile/" + props.gridType + "/" + props.customerId, true));
    }




    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitialDataUser();
                await getInitialDataGroup();
                await getInitialDataShare();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (showModalShare) {
            fetchMyAPI()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModalShare]);


    async function getInitialDataUser() {
        setInitDataUser(await TesGet(AuthApiUrl() + "/api/user/GetUsersOfClient/" + localStorage.getItem('selectedCustomerId'), true));
    };

    async function getInitialDataGroup() {
        setInitDataGroup(await TesGet(AuthApiUrl() + "/api/Groups/" + localStorage.getItem('selectedCustomerId'), true));
    };

    async function getInitialDataShare() {
        var res = (await TesGet(SettingApiUrl() + "/api/GridProfiles/GetUserSharedGridProfile/" + selectedData.id, true)) as string[];
        dataGridGroupRef?.current?.instance().selectRows(res, true);
        dataGridUserRef?.current?.instance().selectRows(res, true);
    };

    function onNew() {
        setModalMode(ModalMode.Add);
        setShowModalAdd(true);
    }
    const onValueChanged = (key: string, value: any) => {
        setSelectedData({ ...selectedData, [key]: value });
    }
    function onCloseModalNew() {
        if (modalMode === ModalMode.Add) {
            setShowModalAdd(false);
        } else {
            setSelectedData(new GridProfile());
            setShowModalAdd(false);
        }
    }
    async function onAdd() {
        try {
            if (activeLoading) activeLoading(true);
            selectedData.gridSetting = JSON.stringify(props.dataGridRef.current?.instance().state());
            selectedData.gridType = props.gridType;
            selectedData.customerId = props.customerId;
            await TesPost(SettingApiUrl() + "/api/GridProfiles", selectedData, true);
            await getInitData();
            onCloseModalNew();
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyAdded"), "success", 5000);

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 5000);
        }
    }
    async function onUpdate() {
        try {
            if (activeLoading) activeLoading(true);
            await TesPost(SettingApiUrl() + "/api/GridProfiles/UpdateName", selectedData, true);
            await getInitData();
            onCloseModalNew();
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyUpdated"), "success", 5000);

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 5000);
        }
    }


    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e: any) => {

                    try {
                        await TesDelete(SettingApiUrl() + "/api/GridProfiles/" + d.row.data.id, true);
                        await getInitData();
                        notify(t("dataSuccessfullyDeleted"), "success", 5000);

                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }


                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e: any) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }
    function onEdit(d: any) {
        let newData = new GridProfile();
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModalAdd(true);
    }
    async function onReplace(d: any) {
        let myDialog = custom({
            title: t("replace"),
            messageHtml: t("areYouSureWantToReplace?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e: any) => {
                    try {
                        var postObj = new ReplaceGridProfile();
                        postObj.id = d.row.data.id;
                        postObj.NewGridSetting = JSON.stringify(props.dataGridRef.current?.instance().state())
                        await TesPost(SettingApiUrl() + "/api/GridProfiles/ReplaceGridSetting", postObj, true);
                        await getInitData();
                        notify(t("dataSuccessfullyReplaced"), "success", 5000);

                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e: any) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }

    function onShare(d: any) {
        let newData = new GridProfile();
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModalShare(true);
    }

    async function onUpdateShare() {
        try {
            if (activeLoading) activeLoading(true);
            const postObj = {
                ListUserIds: dataGridUserRef?.current?.instance().getSelectedRowKeys(),
                LisGroupIds: dataGridGroupRef?.current?.instance().getSelectedRowKeys()
            }
            await TesPost(SettingApiUrl() + "/api/GridProfiles/ShareWithUser/" + selectedData.id, postObj, true);
            setShowModalShare(false);
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyUpdated"), "success", 5000);

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 5000);
        }
    }

    function calculateLevelTypeCell(d: any) {
        if (d.data.shareStatus === GridShareStatus.Private) {
            return (
                <Button
                    text={t('private')}
                    className="tes-modal-btn-add"
                    style={{ borderRadius: '0.7rem', height: "1.4rem", width: "10rem" }}
                // stylingMode='outlined'
                // type='danger'
                />
            )
        }
        if (d.data.shareStatus === GridShareStatus.SharedWithMe) {
            return (
                <Button
                    text={t('sharedWithMe')}
                    className="tes-modal-btn-add"
                    style={{ borderRadius: '0.7rem', height: "1.4rem", width: "10rem" }}
                // stylingMode='outlined'
                // type='default'
                />)
        }
        if (d.data.shareStatus === GridShareStatus.Shared) {
            return (
                <Button
                    height={40}
                    text={t('shared')}
                    className="tes-modal-btn-add"
                // stylingMode='outlined'
                // type='normal'
                />)
        }
    }

    async function onRowClick(d: any) {
        if (d.data.gridSetting !== undefined) {
            var res = await TesGet(SettingApiUrl() + "/api/GridProfiles/GetGridProfile/" + d.data.id, true);
            // console.log("res " + JSON.stringify(res))
            props.dataGridRef.current?.instance().state(res)
            setShowModal(false);
        }
    }
    function onTabsSelectionChanged(args: any) {
        if (args.name === "selectedIndex") {
            setSelectedIndex(args.value);
        }
    }

    function OnResetSetting(d: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("resetWarningMessage"),
            buttons: [
                {
                    text: t("yes"),
                    onClick: (e) => {
                        try {
                            localStorage.removeItem(props.gridType.toString());
                            props.dataGridRef.current?.instance().refresh();
                            notify(t("settingsResetToDefaultSuccessfully"), "success", 5000);
                            window.location.reload();
                        } catch {
                            notify(t("someErrorOccurred"), "error", 5000);
                        }
                        return { buttonText: e.component.option("text") };
                    },
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") };
                    },
                },
            ],
        });
        myDialog.show();
    }

    return (
        <React.Fragment>
            <Button
                style={{ marginLeft: 10 }}
                onClick={() => setShowModal(true)}
                icon="fa-solid fa-gears"
                hint={t('gridViewManager')}
                disabled={props.disabled}
            />

            {/* Main Grid */}
            <Popup
                width={"65%"}
                height={popupHeight}
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t("gridViewManager")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowModal(false)}
            >
                <div>
                    <CollapsibleCard isOpen={true} title={t("gridSettings")}>
                        <Form colCount={24}>
                            <SimpleItem colSpan={5}>
                                <Button
                                    className="tes-modal-btn-add"
                                    style={{ marginLeft: 10 }}
                                    onClick={OnResetSetting}
                                    icon="fa-solid fa-rotate-right "
                                    // hint={t('resetToDefault')}
                                    text={t('resetToDefault')}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={5}>
                                <div style={{ display: "inline-block", margin: "0.5rem" }}>{t("horizontalScrolling")}</div>
                                <Switch
                                    style={{ marginTop: "0rem" }}
                                    onValueChange={(e) => {
                                        const newSettingObj = { ...JSON.parse((localStorage.getItem("GridSetting")!)), horizontalScrolling: e }
                                        localStorage.setItem("GridSetting", JSON.stringify(newSettingObj));
                                        props.setGridSetting && props.setGridSetting(newSettingObj);
                                        props.dataGridRef.current?.instance().refresh()
                                    }}
                                    value={JSON.parse(localStorage.getItem("GridSetting") ?? "{}")?.horizontalScrolling ?? false}
                                />
                            </SimpleItem>
                        </Form>
                    </CollapsibleCard>
                </div>
                <div className="row">
                    <div className='rightColumn'>
                        <Button
                            onClick={() => onNew()}
                            icon="fa-solid fa-floppy-disk"
                            hint={t("save")}
                        />
                    </div>
                </div>
                <DataGrid
                    ref={dataGridRef}
                    dataSource={initData}
                    height={gridHeight}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    selection={{ mode: 'single' }}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onRowClick={onRowClick}
                    onExporting={OnExporting}
                >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />
                    <FilterRow visible={true}
                        applyFilter="auto" />

                    <HeaderFilter visible={true} />

                    <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />

                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>
                    <Column dataField="id"
                        caption={t("id")} visible={false}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="name"
                        caption={t("name")}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="shareStatus"
                        cellRender={calculateLevelTypeCell.bind(this)}
                        caption={t("status")}>
                        {/* <Lookup dataSource={lstShareStatus} valueExpr="value" displayExpr="name"  /> */}

                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column type="buttons"
                        caption={t("actions")} fixedPosition="right" width={120}>
                        <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil" cssClass="GridViewManagerActionIconsFirst" />
                        <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can" cssClass="GridViewManagerActionIcons" />
                        <DevExpressButton hint={t('share')} onClick={onShare} icon="fa-solid fa-share-nodes" cssClass="GridViewManagerActionIcons" />
                        <DevExpressButton hint={t('replace')} onClick={onReplace} icon="fa-solid fa-repeat" cssClass="GridViewManagerActionIcons" />
                    </Column>
                </DataGrid>
            </Popup>



            {/*Add/Edit */}
            <Popup
                width={"40%"}
                height={"Auto"}
                visible={showModalAdd}
                resizeEnabled={true}
                showTitle={true}
                title={modalMode === ModalMode.Add ? t('add') : t('update')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowModalAdd(false)}
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={2}>
                        <SimpleItem colSpan={2} >
                            <TextBox
                                label={t("name")}
                                labelMode='floating'
                                value={selectedData.name}
                                onValueChange={(e) => onValueChanged("name", e)}
                            />
                        </SimpleItem>
                    </Form>
                    <div>

                        <div className='rightColumn' >
                            <Button
                                className='tes-modal-btn-cancel'
                                style={{ marginRight: 20 }}
                                onClick={() => onCloseModalNew()}
                                text={t('cancel')}
                            />
                            {modalMode === ModalMode.Add ?
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={onAdd}
                                    text={t('add')}
                                />
                                :
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={onUpdate}
                                    text={t('update')}
                                />
                            }
                        </div>
                    </div>
                </ScrollView>
            </Popup>


            {/* Share */}
            <Popup
                width={"60%"}
                height={"37rem"}
                visible={showModalShare}
                resizeEnabled={true}
                showTitle={true}
                title={t('shareGridLayout')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowModalShare(false)}
            >
                {/* <ScrollView width="100%" height="100%"> */}
                <Form colCount={2}>
                    <SimpleItem colSpan={2}>
                        <div className={"dx-card"}>
                            <Tabs
                                width={"100%"}
                                dataSource={tabTitles}
                                selectedIndex={selectedIndex}
                                onOptionChanged={onTabsSelectionChanged}
                            />
                            {selectedIndex === 0 && (
                                <div className="div-content">
                                    <DataGrid id="gridContainer"
                                        ref={dataGridUserRef}
                                        dataSource={initDataUser}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        hoverStateEnabled={true}
                                        remoteOperations={true}
                                        allowColumnReordering={true}
                                        allowColumnResizing={true}
                                        columnAutoWidth={true}
                                        keyExpr="id"
                                        height={"20rem"}
                                        onExporting={OnExporting}
                                    //onContentReady={e => {e.component.deselectAll()}}
                                    //onFilterValueChange={e => {dataGridUserRef?.current?.instance().deselectAll()}}
                                    >
                                        <Export enabled={true} allowExportSelectedData={true} />
                                        <Paging enabled={true} defaultPageSize={100} />
                                        <Pager
                                            showPageSizeSelector={true}
                                            allowedPageSizes={[100, 200, 300, 400, 500]}
                                            showNavigationButtons={true}
                                            showInfo={true}
                                            visible={true}
                                        />

                                        <Selection
                                            mode="multiple"
                                            selectAllMode={'allPages'}
                                            showCheckBoxesMode={'always'}
                                        />

                                        <FilterRow visible={true}
                                            applyFilter="auto" />

                                        <HeaderFilter visible={true} />


                                        <SearchPanel visible={true}
                                            width={285}
                                            placeholder={t("search...")} />
                                        <ColumnChooser width={350} height={400}
                                            enabled={true}
                                            mode="select"
                                            sortOrder="asc"
                                        >
                                            <Search enabled />
                                        </ColumnChooser>
                                        <Column dataField="userName"
                                            caption={t("username")}>
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                        <Column dataField="firstName"
                                            caption={t("firstName")}>
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                        <Column dataField="lastName"
                                            caption={t("lastName")}>
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>

                                    </DataGrid>
                                </div>
                            )}

                            {selectedIndex === 1 && (
                                <div className="div-content">
                                    <DataGrid id="gridContainer"
                                        ref={dataGridGroupRef}
                                        dataSource={initDataGroup}
                                        rowAlternationEnabled={true}
                                        showBorders={true}
                                        hoverStateEnabled={true}
                                        remoteOperations={true}
                                        allowColumnReordering={true}
                                        allowColumnResizing={true}
                                        columnAutoWidth={true}
                                        keyExpr="id"
                                        height={"20rem"}
                                        onExporting={OnExporting}
                                    //onContentReady={e => {e.component.deselectAll()}}
                                    //onFilterValueChange={e => {dataGridGroupRef?.current?.instance().deselectAll()}}
                                    >
                                        <Export enabled={true} allowExportSelectedData={true} />
                                        <Paging enabled={true} defaultPageSize={100} />
                                        <Pager
                                            showPageSizeSelector={true}
                                            allowedPageSizes={[100, 200, 300, 400, 500]}
                                            showNavigationButtons={true}
                                            showInfo={true}
                                            visible={true}
                                        />

                                        <Selection
                                            mode="multiple"
                                            selectAllMode={'allPages'}
                                            showCheckBoxesMode={'always'}
                                        />

                                        <FilterRow visible={true}
                                            applyFilter="auto" />

                                        <HeaderFilter visible={true} />


                                        <SearchPanel visible={true}
                                            width={285}
                                            placeholder={t("search...")} />
                                        <ColumnChooser width={350} height={400}
                                            enabled={true}
                                            mode="select"
                                            sortOrder="asc"
                                        >
                                            <Search enabled />
                                        </ColumnChooser>

                                        <Column dataField="name"
                                            caption={t("groupName")}>
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>

                                    </DataGrid>
                                </div>
                            )}
                            <div className='rightColumn' style={{ marginTop: "-2.5rem", marginBottom: "0.75rem" }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setShowModalShare(false)}
                                    text={t('cancel')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={onUpdateShare}
                                    text={t('share')}
                                />
                            </div>
                        </div>
                    </SimpleItem>
                </Form>
                {/* </ScrollView> */}
            </Popup>


        </React.Fragment >
    );
}
export default GridProfilePicker;