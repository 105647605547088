const InfrastructurePermissions = {
    Infrastructure_D_Street: "Infrastructure_D_Street",
    Infrastructure_R_Street: "Infrastructure_R_Street",
    Infrastructure_V_Street: "Infrastructure_V_Street",
    Infrastructure_D_Site: "Infrastructure_D_Site",
    Infrastructure_R_Site: "Infrastructure_R_Site",
    Infrastructure_V_Site: "Infrastructure_V_Site",
    Infrastructure_D_Setups: "Infrastructure_D_Setups",
    Infrastructure_D_Area: "Infrastructure_D_Area"
};
export default InfrastructurePermissions;