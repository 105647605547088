import { FileType } from "../../fileManagement/dto/enums/fileManagementEnums";
import { INameId } from "../../general/generalTypes";
import { TrafficCounterType } from "../enums/importEnums";
import { StudyType } from "../enums/trafficStudyEnums";

export class TrafficCounterDTO{
    id!: string;
    customerId!: string;
    divisionId!: string;
    name!: string;
    maxBinNo!: number;
    studyTypes!: StudyType;
    trafficCounterType!:TrafficCounterType
    isDisabled!:boolean
  }

  export class SimpleTrafficCounterDTO {
    id!: string;
    name!: string;
    binMaps!: INameId[];
  }

  export class TrafficCounterImportDataGridDTO {
    trafficCounterId!: string;
    binMapId!: string;
    fileType!:FileType;
    fileKey!: string;
    studyType?:StudyType;
  }