import { useRef, useEffect, useState } from "react";
import { loadModules } from "esri-loader";
import {
  addBasemapGalleryWidget,
  addLayerListWidget,
  addMeasurementWidget,
  addPrintWidget,
  customizeZoomingBehavior,
} from "../utils";

export function useInitializeMap({
  mapEl,
  initDataGis,
  isMovingMapEnabled,
  hasLegend = false,
  hasLayerListWidget = true,
  hasPrintWidget = true,
  hasMeasurementWidget = true,
  zoomBehavior = {
    isDefault: true,
    zoomOptions: {},
    withoutPermissionZoomTryingCallback: (value) => {},
  },
}) {
  const _view = useRef(null);
  const _map = useRef(null);
  const _legend = useRef(null);
  const _legendExpand = useRef(null);
  const _measurementWidget = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const addUIMovingButtons = async (view) => {
    await view.when();
    //TODO: check when and what is this
    // it seems not use in this component and use in modal's map
    if (isMovingMapEnabled) {
      view.ui.add("go-to-location", "top-left");
      view.ui.add("go-to-coordinate", "top-left");
    }
  };
  useEffect(() => {
    if (!initDataGis || !initDataGis?.id || !mapEl.current || _view.current) return;
    setIsLoading(true);
    loadModules(
      [
        "esri/views/MapView",
        "esri/Map",
        "esri/widgets/Legend",
        "esri/widgets/Expand",
      ],
      { css: true }
    ).then(async ([MapView, Map, Legend, Expand]) => {
      const map = new Map({
        basemap: "osm",
      });
      const view = new MapView({
        map,
        container: mapEl.current,
        zoom: 12,
        center: [initDataGis.latitude, initDataGis.longitude],
      });

      if (hasLegend) {
        view.when(() => {
          const legend = new Legend({
            view: view,
            style: "card",
            layerInfos: [],
          });
          const legendExpand = new Expand({
            content: legend,
            view: view,
            expanded: false,
          });

          view.ui.add(legendExpand, "bottom-left");
          _legend.current = legend;
          _legendExpand.current = legendExpand;
        });
      }
      if (hasMeasurementWidget)
        _measurementWidget.current = await addMeasurementWidget(view);

      if (hasLayerListWidget) await addLayerListWidget(view);

      if (hasPrintWidget) await addPrintWidget(view);

      if (initDataGis?.baseLayersEnabled)
        await addBasemapGalleryWidget(view, initDataGis);

      if (!zoomBehavior.isDefault)
        await customizeZoomingBehavior(
          view,
          zoomBehavior.zoomOptions,
          zoomBehavior.withoutPermissionZoomTryingCallback
        );

      _view.current = view;
      _map.current = map;

      addUIMovingButtons(view);
      setIsLoading(false);
    });
  }, [mapEl, initDataGis]);

  return {
    view: _view.current,
    map: _map.current,
    legend: _legend.current,
    legendExpand: _legendExpand.current,
    measurementWidget: _measurementWidget.current,
    isInitialLoading: isLoading,
  };
}
