import { loadModules } from "esri-loader";
import {
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";

export const useCollisionBaseOnCoordinateLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  collisionGeoJsonData,
  reCreateLayerEvenItExists = false,
}) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "ClusterOfCollisionCoordination")) {
          removeLayer(map, "ClusterOfCollisionCoordination");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      collisionGeoJsonData?.features?.length > 0
    ) {
      if (isLayerExist(map, "ClusterOfCollisionCoordination")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "ClusterOfCollisionCoordination");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);
      loadModules(["esri/layers/FeatureLayer", "esri/Graphic"]).then(
        ([FeatureLayer, Graphic]) => {
          if (isCancelled.current) {
            setIsLayerLoading(false);
            isUnderConstruction.current = false;
            return;
          }
          setIsLayerLoading(true);
          isUnderConstruction.current = true;
          const clusterConfig = {
            type: "cluster",
            clusterRadius: "100px",
            popupTemplate: {
              title: "Collision summary",
              content: [
                {
                  type: "text",
                  text: "{cluster_count} collision occurred in this area",
                },
              ],
            },
            clusterMinSize: "24px",
            clusterMaxSize: "60px",
            labelingInfo: [
              {
                deconflictionStrategy: "none",
                labelExpressionInfo: {
                  expression: "Text($feature.cluster_count, '#,###')",
                },
                symbol: {
                  type: "text",
                  color: "#424242",
                  font: {
                    weight: "bold",
                    family: "Noto Sans",
                    size: "12px",
                  },
                },
                labelPlacement: "center-center",
              },
            ],
          };

          const graphics = collisionGeoJsonData.features.map((place) => {
            return new Graphic({
              attributes: {
                ObjectId: place.id,
              },
              geometry: {
                longitude: place.geometry.coordinates[0],
                latitude: place.geometry.coordinates[1],
                type: "point",
              },
            });
          });
          const clusterLayer = new FeatureLayer({
            id: "ClusterOfCollisionCoordination",
            title: "Cluster of Collision Coordinations",
            source: graphics,
            objectIdField: "ObjectId",
            featureReduction: clusterConfig,
            renderer: {
              type: "simple",
              field: "cluster_count",
              legendOptions: {
                title: "cluster Data",
              },
              symbol: {
                type: "simple-marker",
                size: 4,
                color: "#f0b70d",
                outline: {
                  color: "rgba(42, 42, 42, 0.5)",
                  width: 5,
                },
              },
            },
          });

          clusterLayer.load().then(() => {
            if (isCancelled.current) {
              setIsLayerLoading(false);
              isUnderConstruction.current = false;
              return;
            }
            map.add(clusterLayer);
            legendExpand.expanded = false;
          });

          layerSuccessfullyLoadedInDomCallback(view, clusterLayer, () => {
            setIsLayerLoading(false);
            isUnderConstruction.current = false;
          });
        }
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    collisionGeoJsonData,
    reCreateLayerEvenItExists,
  ]);

  return {
    isCollisionBaseOnCoordinateLayerLoading: isLayerLoading,
  };
};
