import { loadModules } from "esri-loader";
import { LocationType } from "../../../types/infrastructure/enums/infrastructureEnums";
import { FieldType } from "../../../types/field/enums/fieldEnums";
import {
  afterMapStationaryWithDebounceCallback,
  generateQueryIds,
  getAveragePaths,
  isLayerExist,
  layerSuccessfullyLoadedInDomCallback,
  removeLayer,
} from "../utils";
import { useEffect, useRef, useState } from "react";

export const useCollisionBaseOnLocationLayer = ({
  showLayer,
  legendExpand,
  map,
  view,
  initDataGis,
  lstCollisionLocations,
  roadSegmentLayer,
  intersectionLayer,
  reCreateLayerEvenItExists = false,
}) => {
  const [isLayerLoading, setIsLayerLoading] = useState(false);
  const isCancelled = useRef(false);
  const isUnderConstruction = useRef(false);
  const isTheLastMovement = useRef(true);

  useEffect(() => {
    isCancelled.current = !showLayer;
    if (map) {
      if (!showLayer) {
        if (isLayerExist(map, "ClusterOfCollisionLocation")) {
          removeLayer(map, "ClusterOfCollisionLocation");
        }
      }
    }
  }, [map, showLayer]);
  useEffect(() => {
    if (
      map &&
      view &&
      showLayer &&
      initDataGis &&
      lstCollisionLocations?.length > 0 &&
      roadSegmentLayer &&
      intersectionLayer
    ) {
      if (isLayerExist(map, "ClusterOfCollisionLocation")) {
        if (reCreateLayerEvenItExists) {
          removeLayer(map, "ClusterOfCollisionLocation");
        } else {
          return;
        }
      }
      setIsLayerLoading(true);
      loadModules([
        "esri/layers/FeatureLayer",
        "esri/core/reactiveUtils",
        "esri/layers/support/AggregateField",
        "esri/Graphic",
        "esri/geometry/support/webMercatorUtils",
      ]).then(
        ([
          FeatureLayer,
          reactiveUtils,
          AggregateField,
          Graphic,
          webMercatorUtils,
        ]) => {
          if (isCancelled.current) {
            setIsLayerLoading(false);
            isUnderConstruction.current = false;
            return;
          }

          const intersectionLocations = lstCollisionLocations.filter(
            (loc) =>
              loc.locationType.toString() ===
              LocationType.Intersection.toString()
          );

          if (intersectionLocations.length === 0) return;

          let queryIds = generateQueryIds(
            initDataGis,
            "intersectionGeoIdType",
            intersectionLocations
          );

          const strIntersectionQuery = `${initDataGis.intersectionGeoIdName} IN (${queryIds})`;

          const intersectionQuery = {
            outFields: ["*"],
            where: strIntersectionQuery,
            returnGeometry: true,
          };

          function queryingAndCreateLayer() {
            setIsLayerLoading(true);
            isUnderConstruction.current = true;
            view
              ?.whenLayerView(intersectionLayer)
              .then(function (intersectionLayerView) {
                reactiveUtils
                  .whenOnce(() => !intersectionLayerView.updating)
                  .then(() => {
                    if (isCancelled.current || !isTheLastMovement.current) {
                      setIsLayerLoading(false);
                      isUnderConstruction.current = false;
                      return;
                    }
                    intersectionLayerView
                      .queryFeatures(intersectionQuery)
                      .then(function (intersectionResults) {
                        console.log(
                          "!!intersectionlength",
                          intersectionResults.features.length
                        );
                        if (isCancelled.current || !isTheLastMovement.current) {
                          setIsLayerLoading(false);
                          isUnderConstruction.current = false;
                          return;
                        }
                        const intersectionGraphics =
                          intersectionResults.features;
                        // Process your full list of features here

                        intersectionGraphics.forEach((graphic) => {
                          const matchedLocation = lstCollisionLocations.find(
                            (loc) =>
                              loc.geoId.toString() ===
                              graphic.attributes[
                                initDataGis.intersectionGeoIdName
                              ].toString()
                          );
                          graphic.attributes[initDataGis.midblockGeoIdName] =
                            "";
                          graphic.attributes["totalCollisionCount"] =
                            matchedLocation?.total;
                          graphic.attributes["injuryCollisionCount"] =
                            matchedLocation?.injury;
                          graphic.attributes["fatalCollisionCount"] =
                            matchedLocation?.fatal;
                          graphic.attributes["pdoCollisionCount"] =
                            matchedLocation?.pdo;
                          graphic.attributes["pdoCollisionCount"] =
                            matchedLocation?.pdo;
                        });

                        const roadsegmentLocations =
                          lstCollisionLocations.filter(
                            (loc) =>
                              loc.locationType.toString() ===
                              LocationType.Midblock.toString()
                          );

                        if (roadsegmentLocations.length === 0) return;

                        let queryIds = generateQueryIds(
                          initDataGis,
                          "midblockGeoIdType",
                          roadsegmentLocations
                        );

                        const strRoadsegmentQuery = `${initDataGis.midblockGeoIdName} IN (${queryIds})`;

                        const roadsegmentQuery = {
                          outFields: ["*"],
                          where: strRoadsegmentQuery,
                          returnGeometry: true,
                        };

                        view
                          ?.whenLayerView(roadSegmentLayer)
                          .then((roadSegmentLayerView) => {
                            reactiveUtils
                              .whenOnce(() => !roadSegmentLayerView.updating)
                              .then(() => {
                                if (
                                  isCancelled.current ||
                                  !isTheLastMovement.current
                                ) {
                                  setIsLayerLoading(false);
                                  isUnderConstruction.current = false;
                                  return;
                                }
                                roadSegmentLayerView
                                  .queryFeatures(roadsegmentQuery)
                                  .then(function (roadsegmentResults) {
                                    console.log(
                                      "!!roadsegmentlength",
                                      roadsegmentResults.features.length
                                    );
                                    if (
                                      isCancelled.current ||
                                      !isTheLastMovement.current
                                    ) {
                                      setIsLayerLoading(false);
                                      isUnderConstruction.current = false;
                                      return;
                                    }
                                    const roadsegmentGraphics =
                                      roadsegmentResults.features
                                        .filter(
                                          (feature) =>
                                            feature.geometry?.paths?.length > 0
                                        )
                                        .map((place) => {
                                          const paths = place.geometry.paths[0];
                                          const [avgX, avgY] =
                                            getAveragePaths(paths);
                                          // Convert to Longitude/Latitude and back to Web Mercator coordinates
                                          const [lng, lat] =
                                            webMercatorUtils.xyToLngLat(
                                              avgX,
                                              avgY
                                            );
                                          const [x, y] =
                                            webMercatorUtils.lngLatToXY(
                                              lng,
                                              lat
                                            );

                                          // Find matching location's average speed
                                          const matchingLocation =
                                            lstCollisionLocations.find(
                                              (loc) =>
                                                loc.geoId.toString() ===
                                                place.attributes[
                                                  initDataGis.midblockGeoIdName
                                                ].toString()
                                            );

                                          return new Graphic({
                                            attributes: {
                                              FID: 0,
                                              InterID: "",
                                              totalCollisionCount:
                                                matchingLocation?.total,
                                              injuryCollisionCount:
                                                matchingLocation?.injury,
                                              fatalCollisionCount:
                                                matchingLocation?.fatal,
                                              pdoCollisionCount:
                                                matchingLocation?.pdo,
                                              [initDataGis.midblockGeoIdName]:
                                                place.attributes[
                                                  initDataGis.midblockGeoIdName
                                                ],
                                            },
                                            geometry: {
                                              type: "point",
                                              x,
                                              y,
                                              spatialReference: {
                                                wkid: place.geometry
                                                  ?.spatialReference?.wkid,
                                              },
                                            },
                                          });
                                        });
                                    const includedColor = "#de2c30";
                                    const clusterConfig = {
                                      type: "cluster",
                                      fields: [
                                        new AggregateField({
                                          name: "aggregateCount",
                                          statisticType: "count",
                                        }),
                                        new AggregateField({
                                          name: "SUM_FATAL",
                                          onStatisticField:
                                            "fatalCollisionCount",
                                          statisticType: "sum",
                                        }),
                                        new AggregateField({
                                          name: "SUM_INJURY",
                                          onStatisticField:
                                            "injuryCollisionCount",
                                          statisticType: "sum",
                                        }),
                                        new AggregateField({
                                          name: "SUM_PDO",
                                          onStatisticField: "pdoCollisionCount",
                                          statisticType: "sum",
                                        }),
                                        new AggregateField({
                                          name: "SUM_TOTAL",
                                          onStatisticField:
                                            "totalCollisionCount",
                                          statisticType: "sum",
                                        }),
                                      ],
                                      renderer: {
                                        type: "class-breaks",
                                        field: "SUM_FATAL",
                                        legendOptions: {
                                          title: "cluster Data",
                                        },
                                        classBreakInfos: [
                                          {
                                            minValue: 0,
                                            maxValue: 0,
                                            label: "No fatalities",
                                            symbol: {
                                              type: "simple-marker",
                                              color: "#dfb586",
                                              outline: {
                                                color: "rgba(89,60,38,0.3)",
                                                width: 0.3,
                                              },
                                            },
                                          },
                                          {
                                            minValue: 1,
                                            maxValue: 99999999999,
                                            label: "Includes fatalities",
                                            symbol: {
                                              type: "simple-marker",
                                              color: includedColor,
                                              outline: {
                                                color: "rgba(153, 31, 23, 0.3)",
                                                width: 0.3,
                                              },
                                            },
                                          },
                                        ],
                                      },
                                      clusterRadius: "100px",
                                      popupTemplate: {
                                        title: "Collision summary",
                                        content: [
                                          {
                                            type: "text",
                                            text: "{SUM_TOTAL} collision occurred in this area",
                                          },
                                          {
                                            type: "fields",
                                            fieldInfos: [
                                              {
                                                fieldName: "SUM_TOTAL",
                                                label: "Total collisions",
                                                format: {
                                                  places: 0,
                                                  digitSeparator: true,
                                                },
                                              },
                                              {
                                                fieldName: "SUM_FATAL",
                                                label: "Total Fatal",
                                              },
                                              {
                                                fieldName: "SUM_INJURY",
                                                label: "Total Injury",
                                              },
                                              {
                                                fieldName: "SUM_PDO",
                                                label: "Total PDO",
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                      clusterMinSize: "24px",
                                      clusterMaxSize: "60px",
                                      labelingInfo: [
                                        {
                                          deconflictionStrategy: "none",
                                          labelExpressionInfo: {
                                            expression: `
                                                                                            var label = Text($feature.aggregateCount, '#,###');
                                                                                            var fatalities = $feature.SUM_FATAL;
                                                                                            if(fatalities > 0){
                                                                                              label += \`\n(\${fatalities})\`
                                                                                            }
                                                                                            return label;
                                                                                          `,
                                          },
                                          symbol: {
                                            type: "text",
                                            color: "white",
                                            font: {
                                              weight: "bold",
                                              family: "Noto Sans",
                                              size: "12px",
                                            },
                                            // haloColor: includedColor,
                                            haloColor: "#320000",
                                            haloSize: 1,
                                          },
                                          labelPlacement: "center-center",
                                        },
                                      ],
                                    };

                                    const collisionBaseOnLocationLayer =
                                      new FeatureLayer({
                                        id: "ClusterOfCollisionLocation",
                                        source: [
                                          ...intersectionGraphics,
                                          ...roadsegmentGraphics,
                                        ],
                                        title: "Cluster of Collision Locations",
                                        featureReduction: clusterConfig,
                                        objectIdField: "ObjectId",
                                        labelingInfo: [
                                          {
                                            deconflictionStrategy: "none",
                                            labelExpressionInfo: {
                                              expression:
                                                "Text($feature.totalCollisionCount, '#,###')",
                                            },
                                            symbol: {
                                              type: "text",
                                              color: "white",
                                              font: {
                                                weight: "bold",
                                                family: "Noto Sans",
                                                size: "12px",
                                              },
                                              // haloColor: includedColor,
                                              haloColor: "#320000",
                                              haloSize: 1,
                                            },
                                            labelPlacement: "center-center",
                                          },
                                        ],
                                        fields: [
                                          {
                                            alias: "pdoCollisionCount",
                                            defaultValue: null,
                                            editable: true,
                                            length: -1,
                                            name: "pdoCollisionCount",
                                            nullable: true,
                                            type: "integer",
                                          },
                                          {
                                            alias: "totalCollisionCount",
                                            defaultValue: null,
                                            editable: true,
                                            length: -1,
                                            name: "totalCollisionCount",
                                            nullable: true,
                                            type: "integer",
                                          },
                                          {
                                            alias: "injuryCollisionCount",
                                            defaultValue: null,
                                            editable: true,
                                            length: -1,
                                            name: "injuryCollisionCount",
                                            nullable: true,
                                            type: "integer",
                                          },
                                          {
                                            alias: "fatalCollisionCount",
                                            defaultValue: null,
                                            editable: true,
                                            length: -1,
                                            name: "fatalCollisionCount",
                                            nullable: true,
                                            type: "integer",
                                          },
                                        ],
                                        // popupTemplates can still be viewed on
                                        // individual features
                                        popupTemplate: {
                                          title: "Crash location",
                                          content: [
                                            {
                                              type: "fields",
                                              fieldInfos: [
                                                {
                                                  fieldName:
                                                    "totalCollisionCount",
                                                  label: "Total",
                                                },
                                                {
                                                  fieldName: "INJURY",
                                                  label: "Injuries",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        renderer: {
                                          type: "simple",
                                          field: "INJURY",
                                          symbol: {
                                            type: "simple-marker",
                                            size: 4,
                                            color: "#f0b70d",
                                            outline: {
                                              color: "rgba(42, 42, 42, 0.5)",
                                              width: 5,
                                            },
                                          },
                                        },
                                      });

                                    collisionBaseOnLocationLayer
                                      .load()
                                      .then(() => {
                                        if (
                                          isCancelled.current ||
                                          !isTheLastMovement.current
                                        ) {
                                          setIsLayerLoading(false);
                                          isUnderConstruction.current = false;
                                          return;
                                        }
                                        removeLayer(
                                          map,
                                          "ClusterOfCollisionLocation"
                                        );
                                        map.add(collisionBaseOnLocationLayer);
                                        legendExpand.expanded = false;
                                      });

                                    layerSuccessfullyLoadedInDomCallback(
                                      view,
                                      collisionBaseOnLocationLayer,
                                      () => {
                                        setIsLayerLoading(false);
                                        isUnderConstruction.current = false;
                                      }
                                    );
                                  });
                              });
                          });
                      });
                  });
              });
          }
          view.when(() => {
            if (isCancelled.current) {
              setIsLayerLoading(false);
              return;
            }
            if (
              isLayerExist(map, "ClusterOfCollisionLocation") ||
              isUnderConstruction.current
            ) {
              return;
            }
            queryingAndCreateLayer();
          });
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              if (isLayerExist(map, "ClusterOfCollisionLocation")) {
                isTheLastMovement.current = false;
              }
            },
            1
          );
          afterMapStationaryWithDebounceCallback(
            view,
            () => {
              isTheLastMovement.current = true;
              if (!isCancelled.current && !isUnderConstruction.current)
                queryingAndCreateLayer();
            },
            700
          );
        }
      );
    }
  }, [
    map,
    view,
    showLayer,
    initDataGis,
    lstCollisionLocations,
    roadSegmentLayer,
    intersectionLayer,
    reCreateLayerEvenItExists,
  ]);

  return {
    isCollisionBaseOnLocationLayerLoading: isLayerLoading,
  };
};
