//#region imports
import { NumberBox, ScrollView, SelectBox, Switch, TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import DataGrid, {
  Column,
  ColumnChooser,
  DataGridRef,
  Button as DevExpressButton,
  Export,
  FilterRow, HeaderFilter,
  Lookup,
  Pager, Paging,
  Search,
  SearchPanel
} from 'devextreme-react/data-grid';
import { Form, SimpleItem } from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import { ValidationGroup, ValidationGroupRef } from 'devextreme-react/validation-group';
import { RequiredRule, Validator } from 'devextreme-react/validator';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useAuth } from '../../../../../contexts/auth';
import { ModalMode } from '../../../../../types/general/enums/generalEnums';
import { NameValue } from '../../../../../types/general/generalTypes';
import { TabType } from '../../../../../types/moduleOfModules/enums/moduleOfModulesEnums';
import { ClientModule, ModuleTab } from '../../../../../types/moduleOfModules/moduleOfModulesTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { OnExporting } from '../../../../../utils/dataGridTools';
import { Enum2Array } from '../../../../../utils/enumTools';
//#endregion imports

// props
interface IPros {
  initDataModule: ClientModule,
  tabsValidation: React.RefObject<ValidationGroupRef>;
  setInitDataModule: React.Dispatch<React.SetStateAction<any>>
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
}


const ReportingPeriod = (props: IPros) => {
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<ModuleTab>(new ModuleTab());
  const [lstTabType, setLstTabType] = useState<NameValue[]>([]);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstTabType(Enum2Array(TabType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }

    }
    fetchMyAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  function onNew() {
    setSelectedData(new ModuleTab());
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    setShowModal(false);
  }

  function onCancel() {
    props.setDataChanged(false)
    setShowModal(false)
  }


  function onValueChange(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value });
    props.setDataChanged(true)
  }

  function onAdd() {
    props.setInitDataModule({
      ...props.initDataModule,
      moduleTabs: [
        ...props.initDataModule.moduleTabs,
        { ...selectedData, id: uuid() }
      ]
    });

    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }

  function onEdit(d: any) {
    setModalMode(ModalMode.Edit);
    setSelectedData(d.row.data);
    setShowModal(true);
  }

  function onUpdate() {
    const tabs = props.initDataModule?.moduleTabs;
    const selectedDataIndex: number = tabs.findIndex((x: ModuleTab) => x.id === selectedData.id);
    tabs[selectedDataIndex] = selectedData;
    props.setInitDataModule({
      ...props.initDataModule,
      moduleTabs: tabs
    });
    dataGridRef.current?.instance().refresh();
    onCloseModal();
  }

  //#endregion functions
  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <div className='rightColumn'>
          <Button
            onClick={onNew}
            icon="fa-solid fa-circle-plus"
            text={t('tab')}
            disabled={props.isLocked}
          />
        </div>
      </div>
      <DataGrid id="gridContainer"
        ref={dataGridRef}
        dataSource={props.initDataModule.moduleTabs}
        rowAlternationEnabled={true}
        showBorders={true}
        hoverStateEnabled={true}
        remoteOperations={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        style={{ margin: "0 1rem 1rem" }}
        onExporting={OnExporting}
      >
        <Export enabled={true} allowExportSelectedData={true} />
        <Paging enabled={true} defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[100, 200, 300, 400, 500]}
          showNavigationButtons={true}
          showInfo={true}
          visible={true}
        />
        <FilterRow visible={true}
          applyFilter="auto" />

        <HeaderFilter visible={true} />

        <SearchPanel visible={true}
          width={285}
          placeholder={t("search...")} />
        <ColumnChooser width={350} height={400}
          enabled={true}
          mode="select"
          sortOrder="asc"
        >
          <Search enabled />
        </ColumnChooser>
        <Column dataField="id" caption={t("id")} visible={false}>
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column
          dataField="customerId"
          caption={t("customerId")}
          visible={false}
        >
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column dataField="order" caption={t("order")} visible={true} alignment="left">
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column dataField="name" caption={t("name")} visible={true}>
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>

        <Column dataField="type" caption={t("type")}>
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
          <Lookup
            dataSource={SortObjectByPropName(lstTabType, "name")}
            valueExpr="value"
            displayExpr="name"
          />
        </Column>

        <Column dataField="defaultTab" caption={t("defaultTab")} visible={true} alignment="left">
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>

        <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
          <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

          </DevExpressButton>
          {/* <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                    </DevExpressButton> */}
        </Column>
      </DataGrid>

      {/* Popup for adding/editing */}
      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t('add') : t('update')}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width='100%' height='100%'>
          <ValidationGroup ref={props.tabsValidation}>
            <Form colCount={2} className='popupFields'>
              <SimpleItem colSpan={1}>
                <TextBox
                  label={t("name")}
                  labelMode='floating'
                  value={selectedData.name}
                  onValueChange={(e) => onValueChange("name", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </TextBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <NumberBox
                  step={0}
                  label={t("order")}
                  labelMode='floating'
                  value={selectedData.order}
                  onValueChange={(e) => onValueChange("order", e)}
                  className="modalInput"
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("type")}
                  labelMode='floating'
                  value={selectedData.type}
                  onValueChange={(e) => onValueChange("type", e)}
                  items={SortObjectByPropName(lstTabType, "name")}
                  valueExpr="value"
                  displayExpr="name"
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={1} >
                <div >{t('defaultTab')}</div>
                <Switch
                  style={{ marginTop: 5, fontWeight: 'bold' }}
                  value={selectedData.defaultTab}
                  onValueChange={e => onValueChange("defaultTab", e)}
                  hint={t('defaultTab')}
                />
              </SimpleItem>
            </Form>
          </ValidationGroup>
          <div>
            <div className='rightColumn'>
              <Button
                className='tes-modal-btn-cancel'
                style={{ marginRight: 20, marginTop: '10px' }}
                onClick={() => onCancel()}
                text={t('cancel')}
              />
              {modalMode === ModalMode.Add ?
                <Button
                  style={{ marginTop: '10px' }}
                  className='tes-modal-btn-add'
                  onClick={onAdd}
                  text={t('add')}
                />
                :
                <Button
                  style={{ marginTop: '10px' }}
                  className='tes-modal-btn-add'
                  onClick={onUpdate}
                  text={t('update')}
                />
              }
            </div>
          </div>

        </ScrollView>
      </Popup>

    </React.Fragment>
  );

}
export default ReportingPeriod;