import { GridType } from "../types/general/enums/generalEnums";
import { Enum2Array } from "./enumTools";

async function RemoveData4LogOut() {
    window.location.replace(localStorage.getItem("logoutUrl")!);
    localStorage.removeItem("tesToken")
    localStorage.removeItem("selectedCustomerId")
    localStorage.removeItem("selectedDivisionId")
    localStorage.removeItem("singleDivision")
    localStorage.removeItem("selectedCustomerName")
    localStorage.removeItem("divisionSystem")
    localStorage.removeItem("selectedDivision")
    localStorage.removeItem("defaultDivisionId")
    Enum2Array(GridType).forEach(element => {
      localStorage.removeItem(element.value)
    });
}

export { RemoveData4LogOut }