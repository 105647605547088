import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { QueryClientProvider, QueryClient } from 'react-query';
import ReactGA from "react-ga4";
import { EnvConfig } from './environment/environment-model';


let globalEnvConfig = (window as any).globalEnvConfig! as EnvConfig;
if (globalEnvConfig.GoogleAnalyticsId) {
    ReactGA.initialize(globalEnvConfig.GoogleAnalyticsId);
}

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={new QueryClient()}>
            <App />
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();