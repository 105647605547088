export enum TesMapModule {
  Collision = 1,
  Infrastructure = 2,
  Sign = 3,
  Support = 4,
  job = 5,
  CollisionDetails = 6,
  StudyDetails = 7,
  CollisionOverviewDashboard = 8,
  InfrastructureDetails = 9,
  AreaDetails = 10,
  AreaDetailsView = 11,
  AADTDetails = 12,
  PatrolArea = 13,
  Study = 14,
  AADT = 15,
  CollisionGeoCode = 16,
  Safety = 17,
}

export enum GridType {
  //Collision
  Collision = 100,
  CollisionDraft = 101,
  RoadInCollisionDetails = 102,
  VehicleInCollisionDetails = 103,
  DriverInCollisionDetails = 104,
  PassengerInCollisionDetails = 105,
  PedestrianInCollisionDetails = 106,
  PeopleInCollisionDetails = 107,
  PeopleInvolvedInCollisionDetails = 108,
  CollisionField = 109,
  CollisionCodeGroup = 110,
  CollisionCodeValues = 111,
  CollisionTesFields = 112,
  CollisionPeopleInvolved = 113,
  CollisionVehicles = 114,
  CollisionSimplified = 115,
  CollisionAssignGeoCode = 116,
  CollisionXMLRedaction = 117,
  CollisionXMLRedactionDetails = 118,
  CollisionDriver = 119,
  CollisionPedestrian = 120,
  CollisionPassenger = 121,

  //Infrastructure
  Intersection = 300,
  RoadSegment = 301,
  Street = 302,
  CollisionsInIntersectionDetails = 303,
  CollisionsInRoadSegmentDetails = 304,
  InfrastructureField = 305,
  InfrastructureCodeGroup = 306,
  InfrastructureCodeValues = 307,
  InfrastructureMunicipalityPopulation = 308,
  Location = 309,
  SignsInIntersectionDetails = 310,
  SupportsInIntersectionDetails = 311,
  SiteList = 312,
  MMS = 313,
  MMSDetails = 314,
  MMSDetailsResults = 315,
  MMSDetailsSmoothing = 316,
  countStation = 317,
  TrafficSection = 318,
  MIS = 319,
  MISDetails = 320,

  //SIGN
  Signs = 400,
  Supports = 401,

  //Maintenance
  MaintenancesInSignDetails = 500,
  MaintenancesInSupportDetails = 501,
  Jobs = 502,
  Personnel = 503,

  //Setting
  Client = 600,
  Users = 601,
  Divisions = 602,
  Roles = 603,
  ReportSettings = 604,

  //Traffic Study
  AllStudies = 700,
  WorkOrderPicker = 701,
  IntersectionAADTs = 702,
  RoadSegmentAADTs = 703,
  SpeedStudies = 704,
  VolumeStudies = 705,
  TMCStudies = 706,
  AADTPredictionModalGrid = 707,
  MatchingProject = 708,
  MatchingResults = 709,
  MatchingVolumeHistory = 710,
  MatchingCurveStat = 711,
  MatchingHolidays = 712,
  DataCollectionCycle = 713,
  TrendingProject = 714,
  TrendingResults =715,

}
export enum Permission {
  Deny = 1,
  View = 2,
  Edit = 3,
}

export enum ModalMode {
  Add = 1,
  Edit = 2,
}

export enum ResponseCode {
  GenericError = 50,

  OK = 200,
  NotFound = 404,

  //Generic Error
  CreatingError = 1001,
  DeletingError = 1002,
  UpdatingError = 1003,
  GetError = 1004,

  CustomerIdIsNotEqual = 1005,

  IdNotExists = 1006,
  InputIsInvalid = 1007,
  CalculationError = 1008,
  DateHasOverlap = 1009,

  //Identity
  TokenExpired = 2001,
  UserNotExists = 2002,
  TwoFactorIsNotValid = 2003,
  UsernamePasswordNotValid = 2004,
  LoginBlocked = 2005,
  PasswordIsNotComplex = 2006,
  CaptchaIsNotValid = 2007,
  CustomerNotPermitted = 2008,
  DivisionNotPermitted = 2009,
  UserNotPermitted = 2010,
  NotPermitted = 2011,
  UserNameExist = 2012,
  EmailExist = 2013,
  RecalculationDivisionNotPermitted = 2014,

  //Infrastructure
  InfrastructureNotExists = 3001,

  //Collision

  CollisionNotExists = 4001,
  CollisionValidationError = 4002,
  Collision4GeoCodeNotExists = 4003,

  //Traffic Study

  WorkOrderExist = 5001,
  RawDataNotExist = 5002,
  AADTFactorsNotExist = 5003,
  AADTSourceIsWrong = 5004,
  AADTNotExistForLocation = 5005,
  SignalWarrantSettingsNotExist = 5006,
  StopWarrantSettingsNotExist = 5007,
  RawDataHasDuplicateDateTime = 5008,

  //Safety

  SpfError = 6001,
  SiteSubTypeNotAssign2Location = 6002,
  CollisionNotObservedInSiteSubtype = 6003,
  SeverityWeightNotExist = 6004,
  AADTCollisionNotExist = 6005,
}

export enum MessageType {
  Error = 1,
  Warning = 2,
  Information = 3,
}

export enum Month {
  NotSet = 0,
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export enum DayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

export enum YesNo {
  None = 0,
  Yes = 1,
  No = 2,
}

export enum PageMode {
  Add = 1,
  Update = 2,
}

export enum PagePermission {
  Deny = 1,
  View = 2,
  Edit = 3,
}
