import { ReportLocationType, ReportType } from "./enums/reportEnums";

export class ClientReport {
  constructor() {
    this.reportLocations = [];
  }
  id!: string;
  customerId!: string;
  name!: string;
  //TODO:REMOVE
  reportType!: ReportType;
  reportLocation!: ReportLocationType;
  isShownOnSingle!: boolean;
  isShownOnMulti!: boolean;

  //New
  tesReportType!: TesReportType;
  tesReportTypeId!: string;
  reportLocations!: ReportLocation[];

  reportFile!: any[];
}

export class ReportRequest {
  reportId!: string;
  customerId!: string;
  reportName!: string;
  token!: string;
  listIds!: string[];
  payload!: string;
  reportType!: ReportType;
  endPointURL!: string;
  forceChartSeriesLabels!: boolean;
  isDesigner!: boolean;
}

export class UiReportRequest {
  customerId!: string;
  lstLocations!: string[];
}

export class TesReportType {
  id!: string;
  name!: string;
  endPointURL!: string;
}

export class ReportLocation {
  id!: string;
  name!: string;
  clientReportId!: string;
}
export class CollisionValidationReport {
  collisionIdentifier!: string;
  level!: string;
  message!: string
}

export class ReportGeneralSettings {
  id!: string;
  customerId!: string;
  divisionId!: string;
  logoURL!: string;
  clientName!: string;
  footerCopyRight!: string;
  headerCopyRight!: string;
  clientContact!: string;
  divisionGeneralSettings!: ReportGeneralSettings[];
}

export class MultipleClientReport {
 clientName!: string;
 reportName!: string;
}