import React, { useEffect, useState } from 'react';
import { DataGrid } from 'devextreme-react';
import { Column, ColumnChooser, Export, Search } from 'devextreme-react/data-grid';
import "./4.scss";
import { t } from 'i18next';
import { Justification, Justification4 } from '../../../../../../../../types/trafficStudy/dtos/studyDto';
import { OnExporting } from '../../../../../../../../utils/dataGridTools';

// props
interface IPros {
    justificationName: string,
    signalWarrants: Justification
}



const TabFour = (props: IPros) => {
    const [selectedJustification, setSelectedJustification] = useState<Justification4>();

    useEffect(() => {
        async function fetchMyAPI() {
            var justification4 = props.signalWarrants.justification4.find(x => x.name === props.justificationName);
            setSelectedJustification(justification4);

        }
        fetchMyAPI()
    }, [props.justificationName, props.signalWarrants]);

    return (
        <React.Fragment>
            <h2 className={"content-block"} style={{ margin: "1rem" }}>{t("justification4MinimumFourHourVolume")}</h2>
            {selectedJustification?.note && selectedJustification.note !== "" && (
                <p style={{ fontSize: "1rem", padding: "0", margin: "1.5rem 0 0 1rem" }}>
                    <span style={{ fontWeight: "bolder" }}>{t("note")}:{" "}</span>{selectedJustification?.note}
                </p>
            )}
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <DataGrid
                    id="grid"
                    dataSource={selectedJustification?.results}
                    allowColumnReordering={true}
                    showBorders={true}
                    onExporting={OnExporting}
                >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>
                    <Column dataField="hour" caption={t("hourEnding")}></Column>
                    <Column dataField="majorStreetVolume" caption={t("majorStreetVolume")} format="#,###"></Column>
                    <Column dataField="minorStreetHigherApproachVolume" caption={t("minorStreetHigherApproachVolume")} format="#,###"></Column>
                    <Column dataField="minValueForJustification" caption={t("minValueForJustification")} ></Column>
                    <Column dataField="satisfied" caption={t("requirementSatisfied")}></Column>
                </DataGrid>
            </div>
        </React.Fragment>
    );


}
export default TabFour;