import { Button, Popup, TagBox } from "devextreme-react";
import { DataGridRef } from 'devextreme-react/cjs/data-grid';
import { Form, SimpleItem } from "devextreme-react/form";
import ScrollView from "devextreme-react/scroll-view";
import notify from 'devextreme/ui/notify';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import ListSelectionLimit from '../../constants/Limits';
import { useAuth } from '../../contexts/auth';
import { InfrastructureApiUrl } from '../../environment/routeSettings';
import { TesField } from '../../types/field/fieldType';
import { ResponseCode, TesMapModule } from '../../types/general/enums/generalEnums';
import { RequestResponseResult } from '../../types/general/generalTypes';
import { VMLocation } from '../../types/infrastructure/dto/locationdDto';
import { Area } from '../../types/infrastructure/infrastructureTypes';
import { SortObjectByPropName } from '../../utils/arrayTools';
import { RequestErrorHandling, TesPost } from '../../utils/rest';

interface IPros {
    dataGridRef: React.RefObject<DataGridRef<any, any>>;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<any>>;
    tesModule: TesMapModule;
}

const GridFilter = (props: IPros) => {
    // const [showModal, setShowModal] = useState<boolean>(false);
    // const [horizontalScrolling, setHorizontalScrolling] = useState<boolean>(false);
    // const [loading, setLoading] = useState<boolean>(false);
    const [initDataSiteList, setInitDataSiteList] = useState<Area[]>([]);
    const [siteListFilter, setSiteListFilter] = useState<TesField[]>([]);
    const { activeLoading } = useAuth();


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitDataSiteList();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getInitDataSiteList() {
        const postObj = {
            customerId: localStorage.getItem("selectedCustomerId"),
            divisionId: localStorage.getItem("selectedDivisionId"),
        }
        const res = await TesPost(InfrastructureApiUrl() + "/api/areas",
            postObj
            , true) as RequestResponseResult<Area[]>
        if (res.responseCode === ResponseCode.OK) {
            setInitDataSiteList(res.results)
        } else {
            await RequestErrorHandling(res);
        }
    }


    function onCloseFilterModal() {
        props.setShowModal(false);
    }

    async function onValueChanged(e: any) {
        setSiteListFilter(e);
    }

    async function onApplySiteListFilter() {
        const isLocationCountGreaterThan1000 = initDataSiteList.some((item: any) =>
            item.locationCount > ListSelectionLimit && siteListFilter.includes(item.id)
        );

        if (isLocationCountGreaterThan1000) {
            notify(t("youCantAddASiteListWhitMoreThan1000Locations"), "error", 3000);
            return; // Exit the function early
        }

        try {
            const postObj = siteListFilter;
            if (activeLoading) activeLoading(true);
            const res: RequestResponseResult<VMLocation[]> = await TesPost(
                InfrastructureApiUrl() + "/api/areas/GetGeoIds",
                postObj,
                true
            );

            var geoIdColName = "geoId";
            if (props.tesModule === TesMapModule.Collision) {
                geoIdColName = "GeoId";
            }

            props.dataGridRef.current?.instance().deselectAll();
            props.dataGridRef.current?.instance().beginUpdate();
            props.dataGridRef.current?.instance().columnOption(geoIdColName, "filterOperations", [
                "=",
            ]);
            props.dataGridRef.current?.instance().columnOption(
                geoIdColName,
                "filterType",
                "include"
            );
            props.dataGridRef.current?.instance().columnOption(
                geoIdColName,
                "filterValues",
                res.results.map((x) => x.geoId)
            );
            props.dataGridRef.current?.instance().endUpdate();
            props.setShowModal(false);
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyFiltered"), "success", 5000);
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
            props.setShowModal(false);
        }
    }

    return (
        <React.Fragment>
            <Popup
                width={"50%"}
                height="auto"
                visible={props.showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t("filter")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={onCloseFilterModal}
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={2}>
                        <SimpleItem colSpan={2}>
                            <TagBox
                                label={t("siteList")}
                                labelMode='floating'
                                className="modalInput"
                                dataSource={SortObjectByPropName(initDataSiteList, "name")}
                                valueExpr="id"
                                displayExpr="name"
                                onValueChange={(e) => onValueChanged(e)}
                            />
                        </SimpleItem>
                    </Form>
                    <div>
                        <div className="rightColumn">
                            <Button
                                className="tes-modal-btn-add"
                                onClick={onApplySiteListFilter}
                                text={t("apply")}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>
        </React.Fragment>
    )


}

export default GridFilter;