import { ContextMenu, DataGrid, NumberBox, Switch, TagBox } from 'devextreme-react';
import { Column, ColumnChooser, DataGridRef, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Lookup, Pager, Paging, Search, SearchPanel, Selection } from 'devextreme-react/data-grid';
import { Form, SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import Permission from '../../../../../components/permission/permision';
import SystematicScreeningPermissions from '../../../../../constants/Permissions/systematicScreeningPermissions';
import { NameValue, NameValueNumber } from '../../../../../types/general/generalTypes';
import { SWPProximityCheckRes, SignalWarrantsPredictionStatus } from '../../../../../types/systematicScreening/enums/systematicScreeningEnums';
import { SignalWarrantsPredAnalysis } from '../../../../../types/systematicScreening/systematicScreeningTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { OnExporting } from '../../../../../utils/dataGridTools';
import { Enum2Array } from '../../../../../utils/enumTools';
import { RightClickMenu } from '../../../../../utils/rightClickMenu';

// props
interface IPros {
  isLocked: boolean
  initData: SignalWarrantsPredAnalysis;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
}
const Results = (props: IPros) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGridRef<any, any>>(null);
  // const dropDownOptions: any[] = [{ name: t("showExcessRank"), value: 1 }, { name: t('showExpectedRank'), value: 2 }];
  // const [mapRes, setMapRes] = useState<RankingMap[]>([]);
  const currentYear = new Date().getFullYear();
  const last15Years: NameValueNumber[] = Array.from({ length: 15 }, (_, index) => {
    const year = currentYear - index;
    return { name: year.toString(), value: year };
  });
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [lstSignalWarrantsPredictionStatus, setLstSignalWarrantsPredictionStatus] = useState<NameValue[]>([]);
  const [lstSWPProximityCheckRes, setLstSWPProximityCheckRes] = useState<NameValue[]>([]);


  const items = [
    { text: t('openLatestStudy'), icon: "fa-solid fa-up-right-from-square" },
    { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
  ];
  useEffect(() => {
    setLstSignalWarrantsPredictionStatus(Enum2Array(SignalWarrantsPredictionStatus));
    setLstSWPProximityCheckRes(Enum2Array(SWPProximityCheckRes));

  }, []);

  function onValueChanged(name: string, value: any) {
    props.setInitData({
      ...props.initData,
      analysisSettings: { ...props.initData.analysisSettings, [name]: value }
    })
  }
  // async function onShowOnMap(value: any) {
  //   try {
  //     var gridData = await dataGridRef.current?.instance().getSelectedRowsData() as SignalWarrantsPredictionResult[];
  //     if (gridData?.length === 0 ?? true) {
  //       notify(t('pleaseSelectSomeData'), 'warning', 5000);
  //       return
  //     }
  //     if (activeLoading) activeLoading(true);

  //     if (value === 1) {
  //       var rankings = gridData.map(x => {
  //         var res: RankingMap = {
  //           geoId: x.locationGeoId,
  //           locationType: x.locationType,
  //           rank: x.excessRank,
  //           properties:
  //           {
  //             expectedRank: x.expectedRank,
  //             adjustedRank: x.adjustedRank,
  //             excess: x.excess.toFixed(2),
  //             observedFI: x.observedFI.toFixed(2),
  //             observedTotal: x.observedTotal.toFixed(2),
  //           },
  //         };
  //         return res;
  //       })
  //       setMapRes(rankings)
  //       rankings = [];
  //       if (activeLoading) activeLoading(false);
  //     } else if (value === 2) {
  //       var rankings = gridData.map(x => {
  //         var res: RankingMap = {
  //           geoId: x.locationGeoId,
  //           locationType: x.locationType,
  //           rank: x.expectedRank,
  //           properties:
  //           {
  //             excessRank: x.excessRank,
  //             adjustedRank: x.adjustedRank,
  //             excess: x.excess.toFixed(2),
  //             observedFI: x.observedFI.toFixed(2),
  //             observedTotal: x.observedTotal.toFixed(2),
  //           },
  //         };
  //         return res;
  //       })
  //       setMapRes(rankings)
  //       rankings = [];
  //       if (activeLoading) activeLoading(false);
  //     }
  //     setShowMap(true)
  //     await dataGridRef.current?.instance().deselectAll()
  //   } catch (ex) {
  //     await dataGridRef.current?.instance().deselectAll()
  //     if (activeLoading) activeLoading(false);
  //     notify(t("errorInFetchReports") + ex, "error", 5000);
  //   }
  // }


  function onRowClicked(e: any) {
    if (e.data !== null) {
      window.open(`/systemicScreening/signalWarrantAnalysisDetails/${e.data.id}`, "_blank");
    }
  }

  return (
    <Permission allowed={[SystematicScreeningPermissions.SystematicScreening_D_SWP,
    SystematicScreeningPermissions.SystematicScreening_V_SWP,
    ]} hasFeedBackElement={true}>
      <React.Fragment>
        <ContextMenu
          dataSource={items}
          width={100}
          target=".dx-data-row"
          onItemClick={e => {
            e.itemData?.text === 'Open Latest Study' ?
              RightClickMenu(e, selectedRowData.row.data.latestStudyId, "/trafficStudy/studies/studyDetails/2/")
              :
              window.open(`/infrastructure/intersectionDetails/${selectedRowData.row.data.locationId}`, "_blank")?.focus()
          }
          }
        />
        <div className='row' style={{ marginTop: 20, padding: 10 }}>
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <CollapsibleCard title={t('signalWarrantPredictionAnalysisSettings')} isOpen={false}>
                <Form colCount={3}>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("distanceThreshold")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.distanceThreshold}
                      onValueChange={(e) => onValueChanged("distanceThreshold", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("eastUserDefinedGrowth")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.eastUserDefinedGrowth}
                      onValueChange={(e) => onValueChanged("eastUserDefinedGrowth", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("westUserDefinedGrowth")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.westUserDefinedGrowth}
                      onValueChange={(e) => onValueChanged("westUserDefinedGrowth", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("northUserDefinedGrowth")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.northUserDefinedGrowth}
                      onValueChange={(e) => onValueChanged("northUserDefinedGrowth", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("southUserDefinedGrowth")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.southUserDefinedGrowth}
                      onValueChange={(e) => onValueChanged("southUserDefinedGrowth", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("predictionYear")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.predictionYear}
                      onValueChange={(e) => onValueChanged("predictionYear", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("pastYearsPrediction")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.pastYearsPrediction}
                      onValueChange={(e) => onValueChanged("pastYearsPrediction", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("maxGrowthRateThreshold")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.maxGrowthRateThreshold}
                      onValueChange={(e) => onValueChanged("maxGrowthRateThreshold", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("minGrowthRateThreshold")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.minGrowthRateThreshold}
                      onValueChange={(e) => onValueChanged("minGrowthRateThreshold", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1} >
                    <NumberBox
                      label={t("municipalityDefaultGrowth")}
                      labelMode='floating'
                      value={props.initData?.analysisSettings?.municipalityDefaultGrowth}
                      onValueChange={(e) => onValueChanged("municipalityDefaultGrowth", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>

                  <SimpleItem colSpan={1} >
                    <div >{t('runForAllYears')}</div>
                    <Switch
                      style={{ marginTop: 5, fontWeight: 'bold' }}
                      value={props.initData?.analysisSettings?.runForAllYears}
                      onValueChange={e => onValueChanged(
                        "runForAllYears", e)}
                      hint={t('runForAllYears')}
                    />
                  </SimpleItem>
                  <SimpleItem colSpan={1}>
                    <TagBox
                      label={t("excludeYears")}
                      labelMode='floating'
                      className="modalInput"
                      dataSource={SortObjectByPropName(last15Years, "name")}
                      value={props.initData?.analysisSettings?.excludeYears}
                      valueExpr="value"
                      displayExpr="name"
                      onValueChange={(e) => onValueChanged("excludeYears", e)}
                      disabled={props.isLocked}
                    />
                  </SimpleItem>
                </Form>
              </CollapsibleCard>
            </SimpleItem>
            <SimpleItem colSpan={2}>
              <div className='row' style={{ marginTop: 10, padding: 10, position: 'relative' }}>
                <h6 className={"content-block"} style={{ margin: "0rem 0rem 1rem 0.4rem", fontSize: 19 }}>{t("signalWarrantPredictionAnalysisResult")}</h6>
                <DataGrid
                  id="grid"
                  key="id"
                  keyExpr="id"
                  ref={dataGridRef}
                  dataSource={props.initData.results}
                  onRowClick={onRowClicked}
                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                  allowColumnReordering={true}
                  showBorders={true}
                  rowAlternationEnabled={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onExporting={OnExporting}
                >
                  <ContextMenu
                    dataSource={items}
                    width={100}
                    target=".dx-data-row"
                    onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/systemicScreening/signalWarrantAnalysisDetails/")}
                  />
                  <Selection
                    mode="multiple"
                    selectAllMode={"allPages"}
                    showCheckBoxesMode={"always"}
                    deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
                  //recursive={false}
                  />

                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} />
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true}
                    width={285}
                    placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400}
                    enabled={true}
                    mode="select"
                    sortOrder="asc"
                  >
                    <Search enabled />
                  </ColumnChooser>

                  <Column caption={t("location")}>
                    <Column caption={t("locationId")} alignment="left" dataField="locationId" allowSorting={true} visible={false}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("locationDescription")} alignment="left" dataField="locationDescription" allowSorting={true}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("geoId")} alignment="left" dataField="geoId" allowSorting={true}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("trafficSignal")} alignment="left" dataField="trafficSignal" allowSorting={true}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  </Column>


                  <Column caption={t("latestStudy")}>
                    <Column caption={t("latestStudyYear")} alignment="left" dataField="latestStudyYear" allowSorting={true}  >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("latestJustificationSummary")} alignment="left" dataField="latestJustificationSummary" allowSorting={true} format="#0.###" >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>

                    <Column caption={t("latestStudyIsJustified")} alignment="left" dataField="latestIsJustified" allowSorting={true} format="#0.###" >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  </Column>

                  <Column caption={t("currentYear")}>
                    <Column caption={t("currentYear")} alignment="left" dataField="currentYear" allowSorting={true}  >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("currentYearJustificationSummary")} alignment="left" dataField="currentYearJustificationSummary" allowSorting={true} format="#0.###">
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>

                    <Column caption={t("currentYearIsJustified")} alignment="left" dataField="currentYearIsJustified" allowSorting={true} format="#0.###" >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  </Column>

                  <Column caption={t("prediction")}>
                    <Column caption={t("predictionYear")} alignment="left" dataField="predictionYear" allowSorting={true}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("predictionJustificationSummary")} alignment="left" dataField="predictionJustificationSummary" allowSorting={true}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("predictionIsJustified")} alignment="left" dataField="predictionIsJustified" allowSorting={true} format="#0.###" >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("rank")} alignment="left" dataField="rank" allowSorting={true} sortOrder={'asc'}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                  </Column>

                  <Column caption={t("status")} dataType="boolean" alignment="left" dataField="status" allowSorting={true} visible={false}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup dataSource={SortObjectByPropName(lstSignalWarrantsPredictionStatus, "name")} valueExpr="value" displayExpr="name" />

                  </Column>

                  <Column caption={t("proximityCheck")} visible={false}>

                    <Column caption={t("proximityCheckPassed")} dataType="boolean" alignment="left" dataField="proximityCheckPassed" allowSorting={true} sortOrder={'asc'} visible={false}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column caption={t("proximityCheckDetails")} visible={false}>
                      <Column caption={t("north")} alignment="left" dataField="detailedProximityCheckRes.north" allowSorting={true} visible={false}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={SortObjectByPropName(lstSWPProximityCheckRes, "name")} valueExpr="value" displayExpr="name" />
                      </Column>
                      <Column caption={t("south")} alignment="left" dataField="detailedProximityCheckRes.south" allowSorting={true} visible={false}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={SortObjectByPropName(lstSWPProximityCheckRes, "name")} valueExpr="value" displayExpr="name" />
                      </Column>
                      <Column caption={t("east")} alignment="left" dataField="detailedProximityCheckRes.east" allowSorting={true} visible={false}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={SortObjectByPropName(lstSWPProximityCheckRes, "name")} valueExpr="value" displayExpr="name" />
                      </Column>
                      <Column caption={t("west")} alignment="left" dataField="detailedProximityCheckRes.west" allowSorting={true} visible={false}>
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                        <Lookup dataSource={SortObjectByPropName(lstSWPProximityCheckRes, "name")} valueExpr="value" displayExpr="name" />
                      </Column>


                    </Column>

                  </Column>


                  {/* <Column caption={t("manuallyExcluded")} dataType="boolean" alignment="left" dataField="manuallyExcluded" allowSorting={true} format="#0.###" visible={false}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column> */}

                </DataGrid>
              </div>
            </SimpleItem>
          </Form>
        </div>


        {/* Map Popup
        <Popup
          width={"50%"}
          height={"auto"}
          visible={showMap}
          resizeEnabled={true}
          showTitle={true}
          title={t("map")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => setShowMap(false)}
        >
          <GeneralGisMapManager
            tesModule={TesMapModule.CollisionOverviewDashboard}
            lstRanking={mapRes}
            dataGridRef={dataGridRef}
            showGis={showMap}
            setShowMap={setShowMap}
            locId="LocationId"
          />
        </Popup> */}

      </React.Fragment>
    </Permission>
  );


}
export default Results;